import {
  deleteNoticeAPI,
  loadNoticeAPI,
  loadNoticeListAPI,
  loadNoticeTypeAPI,
  registNoticeAPI,
  updateNoticeAPI,
} from "@/api/notice";
import {
  NoticeListType,
  NoticeSchFormType,
  NoticeTypesType,
  NoticeDetailType,
} from "@/types/notice";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const useNoticeManager = () => {
  const queryClient = useQueryClient();
  const [noticeData, setNoticeData] = useState<NoticeListType | null>(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let schParams: NoticeSchFormType = {
    schFldYmd: params.get("schFldYmd") || "rgstYmd",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schUseYn: params.get("schUseYn") || "",
    schNotfUseYn: params.get("schNotfUseYn") || "",
    schNotfTp: params.get("schNotfTp") || "",
    schFld: params.get("schFld") || "",
    schTxt: params.get("schTxt") || "",
    pg: params.get("pg") || "1",
    pgSz: params.get("pgSz") || "10",
    sordFld: params.get("sordFld") || "rgstYmd",
    sordMetd: params.get("sordMetd") || "DESC",
  };

  const {
    data: noticeList,
    isFetching,
    isSuccess,
  } = useQuery<
    AxiosResponse<{
      data: NoticeListType;
    }>,
    AxiosError,
    NoticeListType
  >({
    queryKey: ["loadNoticeList", schParams],
    queryFn: async () => await loadNoticeListAPI(schParams),
    select: (res) => {
      res.data.data?.noticeList.list.map((item: any, i: number) => {
        item.index = i;
      });
      return res.data.data;
    },
  });

  const resetQuery = () => {
    queryClient.resetQueries({ queryKey: ["loadNoticeList"] });
  };

  useEffect(() => {
    if (noticeList) setNoticeData(noticeList);
  }, [isSuccess, noticeList]);

  return {
    schParams,
    isFetching,
    isSuccess,
    noticeData,
    resetQuery,
  };
};

export const useNoticeDetailManager = () => {
  const params = useParams();
  const queryClient = useQueryClient();
  const {
    data: noticeData,
    isFetching,
    isSuccess,
  } = useQuery<
    AxiosResponse<{
      data: NoticeDetailType;
    }>,
    AxiosError,
    NoticeDetailType
  >({
    queryKey: ["loadNotice", params.seq],
    queryFn: async () => await loadNoticeAPI(params.seq!),
    enabled: !!params.seq,
    select: (res) => {
      return res.data.data;
    },
  });

  const registNotice = useMutation({
    mutationFn: (form: any) => registNoticeAPI(form),
  });

  const updateNotice = useMutation({
    mutationFn: (form: any) => updateNoticeAPI(form),
  });

  const deleteNotice = useMutation({
    mutationFn: (form: any) => deleteNoticeAPI(form),
  });

  const resetQuery = () => {
    queryClient.resetQueries({ queryKey: ["loadNotice"] });
  };

  return {
    isFetching,
    isSuccess,
    noticeData,
    registNotice,
    updateNotice,
    deleteNotice,
    resetQuery,
  };
};

export const useNoticeTypeManager = () => {
  const { data: noticeTypes } = useQuery<
    AxiosResponse<{
      data: NoticeTypesType;
    }>,
    AxiosError,
    NoticeTypesType
  >({
    queryKey: ["loadNoticeTypes"],
    queryFn: async () => await loadNoticeTypeAPI(),
    select: (res) => {
      return res.data.data;
    },
  });

  return { noticeTypes };
};
