import {
  PermissionListSchFormType, PermissionRegistParamsType,
} from "@/types/system/permission";
import axios from "axios";

// 페이징 권한 리스트 조회
export const loadPermissionListAPI = async (
  form: PermissionListSchFormType
) => {
  return axios.get("/api/st/auth/page-list", { params: { ...form } });
};

//관리자 계정 권한 리스트 조회
export const loadPermissionAllListAPI = async (

) => {
  return axios.get("/api/st/auth/common-full-list");
};


// // 권한 상세 조회
export const loadPermissionDetailAPI = async (seq: string) => {
  return axios.get("/api/st/auth/get", {
    params: {
      seq: seq,
    },
  });
};

// // 권한 저장
export const registPermissionAPI = async (params: PermissionRegistParamsType) => {
  return axios.post("/api/st/auth/regist", { ...params });
};

// // 권한 수정
export const updatePermissionAPI = async (params: PermissionRegistParamsType) => {
  return axios.post("/api/st/auth/update", { ...params });
};

// // 권한 삭제
export const removePermissionAPI = async (seq: string) => {
  return axios.post("api/st/auth/delete", { seq: seq });
};
