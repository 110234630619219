import {
  NurseListSchFormType,
  NurseOrderCancelParamsType,
  NurseRegistParamsType,
} from "@/types/nurse";
import axios from "axios";

//간호사 물품신청 리스트 조회
export const loadNurseListAPI = async (form: NurseListSchFormType) => {
  return axios.get("/api/ap/apply-nurse/page-list", {
    params: { ...form },
  });
};

//간호사 물품신청
export const registNurseAPI = async (form: NurseRegistParamsType) => {
  return axios.post("/api/ap/apply-nurse/regist", { ...form });
};

//간호사 물품수정
export const updateNurseAPI = async (form: NurseRegistParamsType) => {
  return axios.post("/api/ap/apply-nurse/update", { ...form });
};

//간호사 물품취소
export const cancelNurseAPI = async (form: NurseOrderCancelParamsType) => {
  return axios.post("/api/ap/apply-nurse/update-canc", { ...form });
};

//간호사 물품신청 상세내역 조회
export const loadNurseDetailAPI = async (seq: string) => {
  return axios.get("/api/ap/apply-nurse/get", {
    params: {
      seq: seq,
    },
  });
};
