import {
  NoticeFormType,
  NoticeListType,
  NoticeSchFormType,
  NoticeType,
} from "@/types/notice";
import axios from "axios";

export const loadNoticeListAPI = async (form: NoticeSchFormType) => {
  return axios.get("/api/cs/notice/page-list", {
    params: { ...form },
  });
};

export const loadNoticeAPI = async (seq: string) => {
  return axios.get("/api/cs/notice/get", {
    params: {
      seq: seq,
    },
  });
};

export const registNoticeAPI = async (form: NoticeFormType) => {
  return axios.post("/api/cs/notice/regist", { ...form });
};

export const updateNoticeAPI = async (form: NoticeFormType) => {
  return axios.post("/api/cs/notice/update", { ...form });
};

export const deleteNoticeAPI = async (seq: string) => {
  return axios.post("/api/cs/notice/delete", { seq: seq });
};

export const loadNoticeTypeAPI = async () => {
  return axios.get("/api/cs/notice/get-type");
};
