import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { loadHeightListAPI } from "@/api/dashboard/height";
import { HeightListType } from "@/types/dashboard/height";

export const useHeight = () => {
  const [schForm, setSchForm] = useState<{ schY: string | null }>({
    schY: "0",
  });

  const initialData: HeightListType = {
    childHeightList: [],
    heightList: [],
    ChildSexType: { "1": "남자", "2": "여자" },
    ChildDiagnosisType: {
      "1": "ISS",
      "2": "GHD",
      "3": "SGA",
      "4": "Turner",
      "5": "Noonan",
      "6": "CRD",
      "7": "AGHD",
      "8": "unknown",
    },
  };

  const {
    data: listData = initialData,
    isFetching,
    refetch,
  } = useQuery<
    AxiosResponse<{ data: HeightListType }>,
    AxiosError,
    HeightListType
  >({
    queryKey: ["loadHeightList", schForm],
    queryFn: () => loadHeightListAPI(schForm),
    select: (res) => res.data.data,
    enabled: schForm.schY ? true : false,
  });

  const handleChangeInput = (label: string | null) => {
    setSchForm((prev) => ({ ...prev, schY: label }));
  };

  return { listData, schForm, handleChangeInput };
};
