
import { ProvisionListSchFormType, ProvisionRegistParamsType } from "@/types/system/provision";
import axios from "axios";

// 약관 리스트 조회
export const loadProvisionListAPI = async (
    form: ProvisionListSchFormType
) => {
    return axios.get("/api/st/provision/page-list", { params: { ...form } });
};

//약관 타입 조회
export const loadProvisionTypeAPI = async (

) => {
    return axios.get("/api/st/provision/get-type")
};

// 권한 상세 조회
export const loadProvisionDetailAPI = async (seq: string) => {
    return axios.get("/api/st/provision/get", {
        params: {
            seq: seq,
        },
    });
};

// 타입별 최신 버전 조회
export const loadProvisionVersionAPI = async (tp: string) => {
    return axios.get("/api/st/provision/get-desc", {
        params: {
            schTp: tp,
        },
    });
};

// // // 권한 저장
export const registProvisionAPI = async (params: ProvisionRegistParamsType) => {
    return axios.post("/api/st/provision/regist", { ...params });
};