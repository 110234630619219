import globalCSS from "@/assets/ts/global-css";
import guideCSS from "@/assets/ts/guide-css";
import GuideLeft from "@/guide/components/sidebar";

export default function GuideText() {
  return (
    <div className="guide-area" css={guideCSS.guideWrap}>
      <GuideLeft />
      <div css={guideCSS.guideWrapper}>
        <div className="title-box">
          <h2 className="guide-h2">Text</h2>
        </div>
        <div className="guide-content">
          <div>
            <h3 className="guide-h3">
              메인 타이틀01 Weight700: css=&#123; globalCSS.h1 &#125;
            </h3>
            <h2 css={globalCSS.h1}>H1 KR</h2>
          </div>
          <hr className="guide-hr"></hr>
          <div>
            <h3 className="guide-h3">
              메인 타이틀02 Weight700: css=&#123; globalCSS.h2 &#125;
            </h3>
            <h2 css={globalCSS.h2}>H2 KR</h2>
          </div>
          <hr className="guide-hr"></hr>
          <div>
            <h3 className="guide-h3">
              메인 타이틀02 Weight600: css=&#123; globalCSS.h3 &#125;
            </h3>
            <h2 css={globalCSS.h3}>H3 KR</h2>
          </div>
          <hr className="guide-hr"></hr>
          <div>
            <h3 className="guide-h3">
              메인 타이틀04 Weight600: css=&#123; globalCSS.h4 &#125;
            </h3>
            <h2 css={globalCSS.h4}>H4 KR</h2>
          </div>
          <hr className="guide-hr" />
          <div>
            <h3 className="guide-h3">
              바디 타이틀 텍스트 01 css=&#123; globalCSS.bodyTitle &#125;
            </h3>
            <h2 css={globalCSS.bodyTitle}>Content KR / font-size: 16px</h2>
          </div>
          <hr className="guide-hr"></hr>
          <div>
            <h3 className="guide-h3">
              바디 텍스트 02 css=&#123; globalCSS.body &#125;
            </h3>
            <h2 css={globalCSS.body}>Content KR / font-size: 14px</h2>
          </div>
          <hr className="guide-hr"></hr>
          <div>
            <h3 className="guide-h3">
              바디 텍스트 02 Bold css=&#123; globalCSS.body2 &#125;
            </h3>
            <h2 css={globalCSS.body2}>
              Content KR / font-size: 14px / font-weight: 600
            </h2>
          </div>
          <hr className="guide-hr" />
          <div>
            <h3 className="guide-h3">
              바디 볼드 텍스트 css=&#123; globalCSS.body2Bold &#125;
            </h3>
            <h2 css={globalCSS.body2Bold}>Body Article KR / font-size: 16px</h2>
          </div>
          <hr className="guide-hr" />
          <div>
            <h3 className="guide-h3">
              바디 아티클 텍스트 css=&#123; globalCSS.body2Article &#125;
            </h3>
            <h2 css={globalCSS.body2Article}>
              Body Article KR / font-size: 16px
            </h2>
          </div>
          <hr className="guide-hr"></hr>
          <div>
            <h3 className="guide-h3">
              캡션 텍스트 css=&#123; globalCSS.caption &#125;
            </h3>
            <h2 css={globalCSS.caption}>Caption KR / font-size: 12px</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
