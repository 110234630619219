import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, InputLabel, TextField, css } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  XTDialog,
  XTDialogActions,
  XTDialogContent,
  XTDialogTitle,
} from "@/components/mui/xt-dialog";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTFormHelperText, XTInput } from "@/components/mui/xt-input";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import theme from "@/assets/ts/theme";
import { ProductRegistParamsType } from "@/types/product";
import { useEffect } from "react";
import { regOnlyNumber } from "@/assets/ts/common";

export default function ProductRegistDialog(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  regist: (data: ProductRegistParamsType) => void;
}) {
  const { open, setOpen, regist } = props;

  const schema = yup
    .object()
    .shape({
      nm: yup.string().trim().required("물품명을 입력해주세요."),
      goodsStock: yup.object().shape({
        qty: yup.string().trim().required("수량을 입력해주세요."),
      }),
    })
    .required();

  const {
    trigger,
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    watch,
    reset,
  } = useForm<ProductRegistParamsType>({
    defaultValues: {
      nm: "",
      goodsStock: {
        qty: "",
      },
    },
    shouldFocusError: false,
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    regist(getValues());
  };

  useEffect(() => {
    reset({
      nm: "",
      goodsStock: {
        qty: "",
      },
    });
  }, [open]);

  return (
    <>
      <XTDialog disablePortal className="sm" open={open} onClose={handleClose}>
        <XTDialogTitle className="flex-title">
          <span>물품 추가</span>
          <button type="button" className="close-btn" onClick={handleClose}>
            <span css={globalCSS.hidden}>닫기</span>
            <i css={globalCSS.icoClose} />
          </button>
        </XTDialogTitle>
        <XTDialogContent css={style.container}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="cont">
              <Grid style={{ marginBottom: "10px" }}>
                <Grid item>
                  <InputLabel required>물품명</InputLabel>
                </Grid>
                <Grid item>
                  <Controller
                    rules={{
                      required: true,
                    }}
                    name="nm"
                    control={control}
                    render={({ field }) => (
                      <XTInput
                        placeholder="물품명을 입력해주세요."
                        {...field}
                        sx={{
                          minWidth: 315,
                        }}
                        type="text"
                      />
                    )}
                  />
                  {errors.nm?.type === "required" && (
                    <XTFormHelperText className="error">
                      {errors.nm?.message}
                    </XTFormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid style={{ marginBottom: "10px" }}>
                <Grid item>
                  <InputLabel required>수량</InputLabel>
                </Grid>
                <Grid item>
                  <Controller
                    rules={{
                      required: true,
                    }}
                    name="goodsStock.qty"
                    control={control}
                    render={({ field }) => (
                      <Grid container>
                        <Grid item>
                          <XTButton
                            className="num-btn"
                            color="primary"
                            size="small"
                            onClick={() => {
                              if (
                                watch("goodsStock.qty") &&
                                parseInt(watch("goodsStock.qty")) > 0
                              ) {
                                let num = parseInt(watch("goodsStock.qty")) - 1;
                                setValue("goodsStock.qty", num.toString());
                              }
                              trigger("goodsStock.qty");
                            }}
                          >
                            <RemoveIcon />{" "}
                          </XTButton>
                        </Grid>
                        <Grid item>
                          <input
                            {...field}
                            className="num-input"
                            type="text"
                            placeholder="수량을 입력해주세요."
                            onChange={(e) => {
                              setValue(
                                "goodsStock.qty",
                                regOnlyNumber(e.target.value)
                              );
                              trigger("goodsStock.qty");
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <XTButton
                            className="num-btn"
                            color="primary"
                            size="small"
                            onClick={() => {
                              if (watch("goodsStock.qty") == "") {
                                setValue("goodsStock.qty", "1");
                              } else {
                                let num = parseInt(watch("goodsStock.qty")) + 1;
                                setValue("goodsStock.qty", num.toString());
                              }
                              trigger("goodsStock.qty");
                            }}
                          >
                            <AddIcon />{" "}
                          </XTButton>
                        </Grid>
                      </Grid>
                    )}
                  />
                  {errors.goodsStock?.qty?.type === "required" && (
                    <XTFormHelperText className="error">
                      {errors.goodsStock?.qty?.message}
                    </XTFormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid container justifyContent="end">
                <XTButton
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                >
                  등록
                </XTButton>
              </Grid>
            </div>
          </form>
        </XTDialogContent>
        <XTDialogActions className="flex-end"></XTDialogActions>
      </XTDialog>
    </>
  );
}

const style = {
  container: css`
    form {
      width: 100%;
      display: flex;
      justify-content: center;

      .num-btn {
        &:disabled {
          color: ${theme.palette.text_sub2};
        }
      }

      .num-input {
        padding: 10px;
        width: 12.5rem;
        height: 1.8125rem;
        border: 1px solid #ccceca;
        border-left: none;
        border-right: none;

        ::placeholder {
          font-size: 14px;
          color: #ccceca;
        }

        &:disabled {
          background: ${theme.palette.disabled};
          color: ${theme.palette.text_sub2};

          ::placeholder {
            color: ${theme.palette.text_sub2};
          }
        }
      }

      .MuiInputLabel-root {
        font-size: 14px;
        font-weight: 700;
        color: ${theme.palette.default};
        .MuiInputLabel-asterisk {
          color: ${theme.palette.error.main};
        }
      }
    }
  `,
};
