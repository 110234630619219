import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import {
  XTDialog,
  XTDialogActions,
  XTDialogContent,
  XTDialogTitle,
} from "@/components/mui/xt-dialog";
import {
  XTFormControl,
  XTFormHelperText,
  XTTextarea,
} from "@/components/mui/xt-input";
import { Grid, InputLabel } from "@mui/material";
import { css } from "@emotion/react";
import theme from "@/assets/ts/theme";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { alertDialogState } from "@/state";

export default function TemplateDialog(props: any) {
  const { open, setOpen, onChange } = props;
  const [alertDialog, setAlertDialog] = useRecoilState(alertDialogState);
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const schema = yup.object().shape({
    cont: yup.string().required("사유를 입력해 주세요."),
  });

  const {
    trigger,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    clearErrors,
    formState: { errors, submitCount },
  } = useForm({
    shouldFocusError: false,
    defaultValues: {
      cont: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = () => {
    setAlertDialog({
      open: true,
      text: "다운로드 하시겠습니까?",
      type: "confirm",
      title: "알림",
      handleOK: () => {
        onChange(getValues().cont);
      },
      handleClose: () => {
        setAlertDialog({
          ...alertDialog,
          open: false,
        });
      },
    });
  };

  useEffect(() => {
    reset({
      cont: "",
    });
  }, [open]);

  return (
    <XTDialog
      disablePortal
      className="sm"
      open={open}
      css={[globalCSS.detailContainer, style.cancel]}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <XTDialogTitle className="flex-title">
          <div>
            <span>다운로드 사유</span>
          </div>

          <button
            type="button"
            className="close-btn"
            onClick={() => {
              handleCloseDialog();
            }}
          >
            <span css={globalCSS.hidden}>닫기</span>
            <i css={globalCSS.icoClose} />
          </button>
        </XTDialogTitle>

        <XTDialogContent>
          <p className="noti">
            고객 개인정보를 다운로드하는 경우 사유 확인이 필요합니다.
          </p>
          <div className="cont-wrap">
            <div className="cont-inner">
              <table css={globalCSS.formTable}>
                <colgroup>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "80%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <InputLabel required>사유</InputLabel>
                    </th>
                    <td>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="cont"
                        control={control}
                        render={({ field }) => (
                          <XTFormControl>
                            <XTTextarea {...field} multiline />
                          </XTFormControl>
                        )}
                      />
                      {errors.cont?.type === "required" && (
                        <XTFormHelperText className="error">
                          {errors.cont?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </XTDialogContent>
        <XTDialogActions className="flex-end">
          <div className="bottom-wrap">
            <Grid container spacing={2} justifyContent="end">
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={() => {
                    handleCloseDialog();
                  }}
                >
                  취소
                </XTButton>
              </Grid>
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  type="submit"
                >
                  확인
                </XTButton>
              </Grid>
            </Grid>
          </div>
        </XTDialogActions>
      </form>
    </XTDialog>
  );
}

const style = {
  cancel: css`
    .noti {
      margin-bottom: ${theme.typography.pxToRem(15)};
    }
    .notetext {
      margin-top: 10px;
      font-size: 13px;
      color: #999999;
    }
  `,
};
