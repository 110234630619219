import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTCheckBox } from "@/components/mui/xt-checkbox";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import {
  XTDialog,
  XTDialogActions,
  XTDialogContent,
  XTDialogTitle,
} from "@/components/mui/xt-dialog";
import { XTInput } from "@/components/mui/xt-input";
import { Box, Checkbox, Grid, LinearProgress, Pagination } from "@mui/material";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import { useState } from "react";
import { useProvisionVersion } from "../_hook/use-provision";
import { ProvisionType } from "@/types/system/provision";

export default function VerDialog(props: {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
  tp: string;
  provisionType: ProvisionType;
}) {
  const { openDialog, setOpenDialog, tp, provisionType } = props;
  const {
    listData,
    schViewForm,
    handleChangeInput,
    handleChangePage,
    handleSearch,
    navigate,
  } = useProvisionVersion(tp);
  const [seq, setSeq] = useState("");
  const apiRef = useGridApiRef();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const columns: GridColDef[] = [
    {
      field: "seq",
      headerName: "선택",
      width: 40,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <XTCheckBox
            label=""
            control={
              <Checkbox
                checked={params.value == seq}
                onChange={() => setSeq(params.value)}
              />
            }
            {...props}
          />
        );
      },
    },
    {
      field: "ver",
      headerName: "버전명",
      headerAlign: "center",
      flex: 1,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <XTDialog
      disablePortal
      className="sm"
      open={openDialog}
      css={globalCSS.detailContainer}
    >
      <XTDialogTitle className="flex-title">
        <div>
          <span>버전 복사</span>
        </div>

        <button
          className="close-btn"
          onClick={() => {
            handleCloseDialog();
          }}
        >
          <i css={globalCSS.icoClose} />
        </button>
      </XTDialogTitle>

      <XTDialogContent>
        <p className="noti">{provisionType.ProvisionType[tp]}</p>
        <p className="noti">복사할 약관 버전을 선택해주세요.</p>
        <Grid
          container
          justifyContent="space-between"
          sx={{
            marginBottom: 4, // 하단 마진 값 설정
          }}
        >
          <Grid item xs={10}>
            <XTInput
              name="schTxt"
              value={schViewForm.schTxt}
              fullWidth
              placeholder="버전명을 입력해주세요."
              onChange={handleChangeInput}
            />
          </Grid>
          <Grid item>
            <XTButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                handleSearch();
              }}
            >
              조회
            </XTButton>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            marginBottom: 4, // 하단 마진 값 설정
          }}
        >
          <XTDataGrid
            className="clickable"
            columns={columns}
            rows={listData.provisionList.list}
            getRowId={(params) => params.id}
            apiRef={apiRef}
            experimentalFeatures={{
              ariaV7: true,
            }}
            slots={{
              noRowsOverlay: () => (
                <div className="no-data">검색 결과가 존재하지 않습니다.</div>
              ),
              noResultsOverlay: () => (
                <div className="no-data">검색 결과가 존재하지 않습니다.</div>
              ),
            }}
            rowHeight={38}
            columnHeaderHeight={38}
            hideFooter
            components={{
              LoadingOverlay: LinearProgress,
            }}
          />
        </Grid>
        <Grid container justifyContent="center">
          <Box>
            <Pagination
              page={listData.provisionList.page}
              count={listData.provisionList.totalPageSize}
              onChange={handleChangePage}
            />
          </Box>
        </Grid>
      </XTDialogContent>
      <XTDialogActions>
        <div className="bottom-wrap">
          <XTButton
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              handleCloseDialog();
              navigate(`${seq}`, { replace: true });
            }}
          >
            확인
          </XTButton>
        </div>
      </XTDialogActions>
    </XTDialog>
  );
}
