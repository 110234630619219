import globalCSS from "@/assets/ts/global-css";
import {
  XTFormControl,
  XTFormHelperText,
  XTInput,
} from "@/components/mui/xt-input";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { XTButton } from "@/components/mui/xt-button";
import theme from "@/assets/ts/theme";
import AlertDialog from "@/components/ui/alert-dialog";
import { useLoginManager } from "@/pages/login/_hook/use-login";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  clearAllLocalStorage,
  clearAllSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from "@/assets/ts/common";
import { useRecoilValue } from "recoil";
import { alertDialogState } from "@/state";
import { CommonHeaderProperties, DecodedTokenType } from "@/types";
import EmailDialog from "@/components/login/email-dialog";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";

export default function Login() {
  const [isPwdShow, setIsPwdShow] = useState(false);
  const queryClient = useQueryClient();

  const alertDialog = useRecoilValue(alertDialogState);
  const [alert, setAlert] = useState<{
    title: string;
    text: string;
    open: boolean;
    type: string;
    handleOK: null | (() => void);
  }>({
    title: "",
    text: "",
    open: false,
    type: "confirm",
    handleOK: null,
  });

  const [emailAlert, setEmailAlert] = useState<{
    open: boolean;
    seq: number;
    email: string;
    cd: string;
    handleClose: null | (() => void);
  }>({
    open: false,
    seq: 0,
    email: "",
    cd: "",
    handleClose: null,
  });

  const { login, resetUserInfo } = useLoginManager();

  const schema = yup
    .object()
    .shape({
      username: yup
        .string()
        .trim()
        .required("아이디를 입력해주세요.")
        .test(
          "username",
          "영소문자와 숫자로만 입력 가능합니다.",
          (value, context) => {
            const regex = /[^a-z0-9]/;

            if (!regex.test(value as string)) {
              return true;
            }

            return false;
          }
        ),
      password: yup.string().trim().required("비밀번호를 입력해주세요."),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm<{ username: string; password: string }>({
    defaultValues: {
      username: "",
      password: "",
    },
    shouldFocusError: false,
    resolver: yupResolver(schema),
  });

  const handleCloseConfirmDialog = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const onSubmit = async () => {
    const isMutating = queryClient.isMutating({
      mutationKey: ["login"],
    });

    if (isMutating > 0) return;

    try {
      const res = await login.mutateAsync({
        grantType: "password",
        username: getValues("username"),
        password: getValues("password"),
      });

      if (res) {
        let data = res.data.data;

        const decodedToken: DecodedTokenType = jwt_decode(data.accessToken);
        if (decodedToken)
          setSessionStorage(
            "X-AUTH-TOKEN-ADMIN",
            `${res.data.data.tokenType} ${res.data.data.accessToken}`
          );

        setSessionStorage(
          "X-REFRESH-TOKEN-ADMIN",
          `${res.data.data.refreshToken}`
        );

        axios.defaults.headers = {
          Authorization: `${res.data.data.tokenType} ${res.data.data.accessToken}`,
        } as CommonHeaderProperties;

        setEmailAlert({
          open: true,
          seq: decodedToken.seq || 0,
          cd: data.certiCd,
          email: data.email,
          handleClose: () => {
            setEmailAlert({
              ...emailAlert,
              open: false,
            });
          },
        });
        // setUserInfo(res.data.data);
      }
    } catch (error: any) {
      // MB004 계정 미존재 or 비밀번호 오류
      // MB006 계정 잠김
      // MB007 비밀번호 오류수 초과
      // MB008 계정 미사용
      // MB009 계정 사용 기간 아님
      // MB010 장기 미접속
      if (error.response) {
        let data = error.response.data;
        let obj = {
          title: "",
          text: "",
        };
        if (
          data.code === "MB004" ||
          data.code === "MB008" ||
          data.code === "MB009"
        ) {
          obj = {
            title: "로그인 오류",
            text: "로그인 권한이 없는 계정입니다. 관리자에게 문의하세요.",
          };
        } else if (data.code === "MB006") {
          obj = {
            title: "계정 잠김",
            text: "계정이 잠금 처리 되었습니다. 관리자에게 잠금 해제를 요청하세요.",
          };
        } else if (data.code === "MB007") {
          obj = {
            title: "비밀번호 오류수 초과",
            text: "로그인 5회 실패로 계정이 잠금 처리 되었습니다.<br />관리자에게 잠금 해제를 요청하세요.",
          };
        } else if (data.code === "MB010") {
          obj = {
            title: "장기 미접속",
            text: "장기 미로그인으로 계정이 잠금 처리 되었습니다.<br />관리자에게 잠금 해제를 요청하세요.",
          };
        }

        setAlert({
          title: obj.title,
          text: obj.text,
          open: true,
          type: "complete",
          handleOK: handleCloseConfirmDialog,
        });
      }
    }
  };

  useEffect(() => {
    clearAllSessionStorage();
    clearAllLocalStorage();
    resetUserInfo();
  }, []);

  return (
    <>
      <main id="login" css={style.login}>
        <div className="login-inner">
          <div className="inner-contents">
            <h1>
              {/* <img src="/images/icon/login-logo.svg" alt="아워홈 MAMS" /> */}
              노리터 관리자 시스템
            </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <XTFormControl>
                    <XTInput
                      {...field}
                      className="custom-round login-input"
                      placeholder="아이디를 입력해주세요."
                      type="text"
                      aria-describedby="error-id"
                      aria-label="아이디를 입력해주세요."
                      aria-invalid="true"
                      endAdornment={
                        <>
                          {getValues("username") ? (
                            <IconButton
                              onClick={() => setValue("username", "")}
                            >
                              <span css={globalCSS.hidden}>내용 삭제하기</span>
                              <i css={globalCSS.icoDelete} />
                            </IconButton>
                          ) : null}
                        </>
                      }
                    />
                    {errors.username?.message && (
                      <XTFormHelperText id="error-id" className="error">
                        {errors.username.message}
                      </XTFormHelperText>
                    )}
                  </XTFormControl>
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <XTFormControl>
                    <XTInput
                      {...field}
                      className="custom-round login-input"
                      placeholder="비밀번호를 입력해주세요."
                      type={isPwdShow ? "text" : "password"}
                      aria-describedby="error-pw"
                      aria-label="비밀번호를 입력해주세요."
                      aria-invalid="true"
                      endAdornment={
                        <>
                          {isPwdShow ? (
                            <IconButton
                              onClick={() => setIsPwdShow((prev) => !prev)}
                            >
                              <span css={globalCSS.hidden}>
                                비밀번호 보이기
                              </span>
                              <i css={globalCSS.icoHide} />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => setIsPwdShow((prev) => !prev)}
                            >
                              <span css={globalCSS.hidden}>
                                비밀번호 숨기기
                              </span>
                              <i css={globalCSS.icoShow} />
                            </IconButton>
                          )}
                        </>
                      }
                    />
                    {errors.password?.message && (
                      <XTFormHelperText id="error-id" className="error">
                        비밀번호를 입력해주세요.
                      </XTFormHelperText>
                    )}
                  </XTFormControl>
                )}
              />
              <XTButton
                className="btn-login"
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
              >
                로그인
              </XTButton>
            </form>
            <div className="login-desc" css={globalCSS.subTitle}>
              최초 비밀번호는 마스터 관리자에게 요청하세요.
              <br />
              개인정보 보호를 위해 30분 동안 화면 이동이 없을 경우 자동으로
              로그아웃됩니다.
            </div>
            <EmailDialog
              open={emailAlert.open}
              handleClose={emailAlert.handleClose}
              seq={emailAlert.seq}
              cd={emailAlert.cd}
              email={emailAlert.email}
              alert={alertDialog}
            />
            <AlertDialog
              title={alert.title}
              open={alert.open}
              text={alert.text}
              type={alert.type}
              handleOK={alert.handleOK}
              preventCloseEvent={true}
            />
          </div>
        </div>
      </main>
    </>
  );
}

const style = {
  login: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    min-height: 100svh;

    .login-inner {
      width: ${theme.typography.pxToRem(600)};
      padding: ${theme.typography.pxToRem(70)} ${theme.typography.pxToRem(30)};
      border: 1px solid ${theme.palette.bg_02};
      background-color: ${theme.palette.white};
      border-radius: 15px;

      .inner-contents {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        max-width: ${theme.typography.pxToRem(352)};
      }

      h1 {
        margin-bottom: ${theme.typography.pxToRem(30)};

        img {
          width: 100%;
          height: 100%;
        }
      }

      form {
        .MuiFormControl-root + .MuiFormControl-root {
          margin-top: ${theme.typography.pxToRem(15)};
        }
        .login-input {
          width: 100%;
        }

        .checkbox-id {
          .MuiTypography-root {
            margin-left: ${theme.typography.pxToRem(8)};
            ${globalCSS.bodyTitle}
          }
        }

        .btn-login {
          margin: ${theme.typography.pxToRem(30)} 0;
        }
      }
    }
  `,
};
function jwtDecode(accessToken: any): DecodedTokenType {
  throw new Error("Function not implemented.");
}
