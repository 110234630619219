import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loadIndicationListAPI } from "@/api/dashboard/indication";
import { IndicationListType } from "@/types/dashboard/indication";
import { SelectChangeEvent } from "@mui/material";

export const useIndication = () => {
  const navigate = useNavigate();

  const [schForm, setSchForm] = useState({
    schDgnsTp: "1",
  });

  const initialData: IndicationListType = {
    childDiagnosisList: [],
    ChildSexType: {
      "1": "남자",
      "2": "여자",
    },
    ChildDiagnosisType: {
      "1": "ISS",
      "2": "GHD",
      "3": "SGA",
      "4": "Turner",
      "5": "Noonan",
      "6": "CRD",
      "7": "AGHD",
      "8": "unknown",
    },
  };

  const {
    data: listData = initialData,
    isFetching,
    refetch,
  } = useQuery<
    AxiosResponse<{ data: IndicationListType }>,
    AxiosError,
    IndicationListType
  >({
    queryKey: ["loadIndicationList", schForm],
    queryFn: async () => await loadIndicationListAPI(schForm),
    select: (res) => {
      return res.data.data;
    },
  });

  const handleChangeInput = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<any>
  ) => {
    const { name, value } = e.target;

    setSchForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return {
    listData,
    schForm,
    setSchForm,
    navigate,
    handleChangeInput,
  };
};
