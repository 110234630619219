import axios from "axios";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "@/App";
import { RecoilRoot } from "recoil";
import { CssBaseline } from "@mui/material";
import theme from "@/assets/ts/theme";
import { ThemeProvider } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { getLocalStorage, getSessionStorage } from "@/assets/ts/common";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

axios.defaults.baseURL = process.env.REACT_APP_FETCH_URL;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

axios.defaults.headers = {
  Authorization: getSessionStorage("X-AUTH-TOKEN-ADMIN"),
} as any;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 0,
      gcTime: 0,
      placeholderData: (prev: any) => prev,
    },
  },
});

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </MuiThemeProvider>
    </RecoilRoot>
  </QueryClientProvider>
  // </React.StrictMode>
);
