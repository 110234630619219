import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { changeSubmitDate } from "@/assets/ts/common";
import moment from "moment";
import {
  ProvisionListType,
  ProvisionType,
  ProvisionDetailType,
  ProvisionRegistParamsType,
  ProvisionListSchFormType,
} from "@/types/system/provision";
import {
  loadProvisionListAPI,
  loadProvisionTypeAPI,
  loadProvisionDetailAPI,
  loadProvisionVersionAPI,
  registProvisionAPI,
} from "@/api/system/provision";
import { useQuery, useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";

export const useProvisionList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const initialSchFormData: ProvisionListSchFormType = {
    pg: params.get("pg") || "1",
    pgSz: params.get("pgSz") || "10",
    sordFld: params.get("sordFld") || "",
    sordMetd: params.get("sordMetd") || "DESC",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schFldYmd: params.get("schFldYmd") || "rgstYmd",
    schTp: params.get("schTp") || " ",
    schFld: params.get("sordFld") || " ",
    schTxt: params.get("schTxt") || "",
  };

  const [schForm, setSchForm] = useState({
    pg: params.get("pg") || "1",
    pgSz: params.get("pgSz") || "10",
    sordFld: params.get("sordFld") || "",
    sordMetd: params.get("sordMetd") || "DESC",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schFldYmd: params.get("schFldYmd") || "rgstYmd",
    schTp: params.get("schTp") || " ",
    schFld: params.get("sordFld") || " ",
    schTxt: params.get("schTxt") || "",
  });

  const [schViewForm, setSchViewForm] = useState({
    schFldYmd: params.get("schFldYmd") || "rgstYmd",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schTp: params.get("schTp") || " ",
    schTxt: params.get("schTxt") || "",
  });

  const initialData: ProvisionListType = {
    provisionList: {
      list: [],
      pageSize: 0,
      totalSize: 0,
      idx: 0,
      page: 0,
      number: 0,
      nextPageAvailable: false,
      previousPageAvailable: false,
      firstPage: false,
      pageIndex: 0,
      middlePage: false,
      lastPage: false,
      totalPageSize: 10,
      empty: false,
    },
    ProvisionType: {
      "1": "개인정보 수집 및 이용 동의",
      "2": "개인정보 제3자 제공 동의",
      "3": "개인정보 국외 이전 동의",
      "4": "개인정보 처리 방침",
    },
  };

  const {
    data: listData = initialData, // 쿼리로부터 반환된 데이터, 기본값은 initialData
    isFetching, // 쿼리가 현재 데이터를 가져오고 있는지 여부
    refetch, // 쿼리를 수동으로 다시 실행하는 함수
    error, // 쿼리 실행 중 발생한 오류
    isLoading, // 쿼리가 초기 로딩 중인지 여부
    isSuccess, // 쿼리가 성공적으로 완료되었는지 여부
    isError, // 쿼리 실행 중 오류가 발생했는지 여부
  } = useQuery<
    AxiosResponse<{ data: ProvisionListType }>,
    AxiosError,
    ProvisionListType
  >({
    queryKey: ["loadProvisionList", schForm],
    queryFn: async () => await loadProvisionListAPI(schForm),
    select: (res) => {
      let list = res.data.data.provisionList.list;

      list.map((item, i) => {
        item.id = item.seq;
        item.index = i;
      });
      return res.data.data;
    },
  });

  const handleResetInput = () => {
    let resetForm = {
      pg: "1",
      pgSz: "10",
      sordFld: "rgstYmd",
      sordMetd: "DESC",
      schStrtYmd: "",
      schEndYmd: "",
      schFldYmd: "rgstYmd",
      schTp: " ",
      schFld: " ",
      schTxt: "",
    };

    setSchForm(resetForm);
    setSchViewForm({
      schFldYmd: "rgstYmd",
      schStrtYmd: "",
      schEndYmd: "",
      schTp: " ",
      schTxt: "",
    });

    const queryStringResult = queryString.stringify(resetForm);
    navigate("/system/provision?" + queryStringResult);
  };

  const handleChangeInput = (event: any) => {
    const target = event.target;
    const name = target.name;

    setSchViewForm((prev) => ({
      ...prev,
      [name]: target.value,
    }));
  };

  const handleChangeDatePicker = (name: string) => (value: Date) => {
    setSchViewForm((prev) => ({
      ...prev,
      pg: "1",
      [name]: changeSubmitDate(value),
    }));
  };

  const handleClickDateButton = (month: string | null) => {
    let startDate = "";
    let endDate = "";

    if (month) {
      startDate = moment().subtract(month, "months").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    }

    setSchViewForm((prev) => ({
      ...prev,
      pg: "1",
      schStrtYmd: changeSubmitDate(startDate),
      schEndYmd: changeSubmitDate(endDate),
    }));
  };

  const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    let form = {
      ...schForm,
      pg: value.toString(),
    };
    setSchForm(form);

    const queryStringResult = queryString.stringify(form);
    navigate("/system/provision?" + queryStringResult);
  };

  const handleChangePageSz = (event: SelectChangeEvent<unknown>) => {
    const target = event.target;
    const name = target.name;

    let form = {
      ...schForm,
      pg: "1",
      [name]: target.value,
    };
    setSchForm(form);

    const queryStringResult = queryString.stringify(form);
    navigate("/system/provision?" + queryStringResult);
  };

  const handleChangeSort = (sordFld: string) => {
    let sordMetd = "";
    if (schForm.sordFld !== sordFld) {
      sordMetd = "ASC";
    } else {
      sordMetd = schForm.sordMetd === "DESC" ? "ASC" : "DESC";
    }

    let form = {
      ...schForm,
      sordMetd: sordMetd,
      sordFld: sordFld,
    };
    setSchForm(form);
    const queryStringResult = queryString.stringify(form);
    navigate("/system/provision?" + queryStringResult);
  };

  const handleSearch = () => {
    let form = {
      ...schForm,
      pg: "1",
      pgSz: "10",
      schStrtYmd: schViewForm.schStrtYmd,
      schEndYmd: schViewForm.schEndYmd,
      schTp: schViewForm.schTp,
      schTxt: schViewForm.schTxt,
      schFldYmd: schViewForm.schFldYmd,
    };

    setSchForm(form);
    const queryStringResult = queryString.stringify(form);
    navigate("/system/provision?" + queryStringResult);
  };

  useEffect(() => {
    setSchForm({
      ...initialSchFormData,
    });
    setSchViewForm({
      ...initialSchFormData,
    });
  }, [location.search]);

  return {
    listData,
    isFetching,
    schForm,
    schViewForm,
    handleChangeInput,
    handleChangePage,
    handleChangePageSz,
    handleClickDateButton,
    handleResetInput,
    handleChangeDatePicker,
    handleSearch,
    handleChangeSort,
    navigate,
  };
};

export const useProvisionDetail = () => {
  let { seq } = useParams();

  const { data: detailData } = useQuery<
    AxiosResponse<{
      data: ProvisionDetailType;
    }>,
    AxiosError,
    ProvisionDetailType
  >({
    queryKey: ["loadProvisionDetail", seq],
    queryFn: async () => await loadProvisionDetailAPI(seq!),
    select: (res) => {
      return res.data.data;
    },
    enabled: seq ? true : false,
  });

  const { data: provisionType } = useQuery<
    AxiosResponse<{ data: ProvisionType }>,
    AxiosError,
    ProvisionType
  >({
    queryKey: ["loadProvisionType"],
    queryFn: async () => await loadProvisionTypeAPI(),
    select: (res) => {
      return res.data.data;
    },
  });

  const verInfo = useMutation({
    mutationFn: (tp: string) => loadProvisionVersionAPI(tp),
  });

  const regist = useMutation({
    mutationFn: (form: ProvisionRegistParamsType) => registProvisionAPI(form),
  });

  return {
    seq,
    detailData,
    provisionType,
    verInfo,
    regist,
  };
};

export const useProvisionVersion = (tp: string) => {
  const navigate = useNavigate();

  const [schForm, setSchForm] = useState({
    pg: "1",
    pgSz: "5",
    sordFld: "",
    sordMetd: "DESC",
    schStrtYmd: "",
    schEndYmd: "",
    schFldYmd: "rgstYmd",
    schTp: "",
    schFld: "",
    schTxt: "",
  });

  const [schViewForm, setSchViewForm] = useState({
    schTxt: "",
  });

  const initialData: ProvisionListType = {
    provisionList: {
      list: [],
      pageSize: 0,
      totalSize: 0,
      idx: 0,
      page: 0,
      number: 0,
      nextPageAvailable: false,
      previousPageAvailable: false,
      firstPage: false,
      pageIndex: 0,
      middlePage: false,
      lastPage: false,
      totalPageSize: 0,
      empty: false,
    },
    ProvisionType: {
      "1": "개인정보 수집 및 이용약관",
      "2": "서비스 이용약관",
      "3": "개인정보 제3자 제공약관",
    },
  };

  const {
    data: listData = initialData,
    isFetching,
    refetch,
  } = useQuery<
    AxiosResponse<{ data: ProvisionListType }>,
    AxiosError,
    ProvisionListType
  >({
    queryKey: ["loadProvisionVersionList", schForm],
    queryFn: async () => await loadProvisionListAPI(schForm),
    select: (res) => {
      let list = res.data.data.provisionList.list;

      list.map((item, i) => {
        item.id = item.seq;
        item.index = i;
      });

      return res.data.data;
    },
  });

  const handleChangeInput = (event: any) => {
    const target = event.target;
    const name = target.name;

    setSchViewForm((prev) => ({
      ...prev,
      [name]: target.value,
    }));
  };

  const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    let form = {
      ...schForm,
      pg: value.toString(),
    };
    setSchForm(form);
  };

  const handleSearch = () => {
    let form = {
      ...schForm,
      schTxt: schViewForm.schTxt,
    };

    setSchForm(form);
  };

  useEffect(() => {
    let form = {
      ...schForm,
      schTp: tp,
    };
    setSchForm(form);
  }, [tp]);

  return {
    listData,
    schViewForm,
    handleChangeInput,
    handleChangePage,

    handleSearch,
    navigate,
  };
};
