import { PrivacyRegistParamsType, PrivacySchFormType } from "@/types/log/privacy";
import axios from "axios";

export const loadPrivacyListAPI = async (form: PrivacySchFormType) => {
  return axios.get("/api/lg/log-privacy-read/page-list", {
    params: { ...form },
  });
};

export const loadPrivacyTypeAPI = async () => {
  return axios.get("/api/lg/log-privacy-read/get-type");
};

//로그 수정
export const updateSelectPrivacyAPI = async (form: PrivacyRegistParamsType) => {
  return axios.post("/api/lg/log-privacy-read/update", { ...form });
};

export const updateAllPrivacyAPI = async () => {
  return axios.post("/api/lg/log-privacy-read/update-all", {});
};

export const loadPrivacyAPI = async (seq: string) => {
  return axios.get("/api/lg/log-privacy-read/get", {
    params: {
      seq: seq,
    },
  });
};
