import {
  LoginFormType,
  RefreshTokenType,
  UpadteCdFormType,
} from "@/types/login";
import axios from "axios";

export const loginAPI = (form: LoginFormType) => {
  return axios.post("/api/auth/token", form);
};

export const refreshTokenAPI = (form: RefreshTokenType) => {
  return axios.post(`/api/auth/token`, form);
};

export const logoutAPI = () => {
  return axios.post(`/api/mg/manager-token/update-logout`, {});
};

//인증-재발급
export const updateCertificateAPI = (mgSeq: string) => {
  return axios.post("/api/mg/manager-certificate/update", {
    mgSeq: mgSeq,
  });
};

//인증-인증
export const updateCdCertificateAPI = (form: UpadteCdFormType) => {
  return axios.post("/api/mg/manager-certificate/update-cd", form);
};

//비밀번호 변경
export const changePassWordAPI = (pwd: string) => {
  return axios.post("/api/mg/manager-password/update", { pwd: pwd });
};
