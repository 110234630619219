import { Autocomplete, Popper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const XTAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: "fit-content",
  minWidth: "200px",
  maxWidth: "100%",
  height: "29px",

  "&.MuiAutocomplete-fullWidth": {
    width: "100%",
  },

  "&.custom-round": {
    height: "40px",

    "& .MuiFormControl-root": {
      border: "none",
      borderRadius: "4px",

      "& input": {
        padding: "0 4px 0 20px !important",
      },

      ".end-wrap": {
        " .organization": {
          right: "35px",
        },

        " .MuiAutocomplete-endAdornment": {
          " .MuiAutocomplete-clearIndicator": {
            position: "absolute",
            right: "65px",
          },
        },
      },

      ".MuiAutocomplete-endAdornment": {
        right: 5,
      },
    },

    "&.MuiAutocomplete-hasClearIcon": {
      ".MuiInputBase-root": {
        "&.MuiOutlinedInput-root": {
          paddingRight: "65px",
        },
      },

      "&:has(.end-wrap)": {
        ".MuiInputBase-root": {
          "&.MuiOutlinedInput-root": {
            paddingRight: "88px",
          },
        },
      },
    },
  },

  "& .MuiFormControl-root": {
    backgroundColor: `${theme.palette.white}`,
    border: `1px solid ${theme.palette.line1};`,
    width: "100%",
    height: "100%",
  },

  "&.success": {
    ".MuiFormControl-root": {
      borderColor: `${theme.palette.success.main};`,
    },
  },

  "&.error": {
    ".MuiFormControl-root": {
      borderColor: `${theme.palette.error.main};`,
    },
  },

  ".MuiFormLabel-root": {
    borderRadius: 0,
  },

  ".MuiOutlinedInput-notchedOutline": {
    display: "none",
  },

  "&.MuiAutocomplete-hasClearIcon": {
    ".MuiInputBase-root": {
      "&.MuiOutlinedInput-root": {
        paddingRight: "60px",
      },
    },

    "&:has(.end-wrap)": {
      ".MuiInputBase-root": {
        "&.MuiOutlinedInput-root": {
          paddingRight: "80px",
        },
      },
    },
  },

  ".MuiInputBase-root": {
    padding: 0,
    height: "100%",
    borderRadius: 0,

    "&.MuiOutlinedInput-root": {
      paddingRight: "30px",
    },

    "&:has(.end-wrap)": {
      paddingRight: "60px",
    },

    "&.Mui-disabled": {
      background: theme.palette.disabled,

      input: {
        color: `${theme.palette.text_sub2}`,
        WebkitTextFillColor: `${theme.palette.text_sub2} !important`,
      },

      ".MuiAutocomplete-popupIndicator": {
        filter:
          "invert(59%) sepia(6%) saturate(160%) hue-rotate(177deg) brightness(93%) contrast(82%)",
      },

      "& .end-wrap": {
        " .organization": {
          "& i": {
            filter:
              "invert(59%) sepia(6%) saturate(160%) hue-rotate(177deg) brightness(93%) contrast(82%)",
          },
        },
      },
    },

    input: {
      color: `${theme.palette.default}`,
      fontWeight: 400,
      fontSize: `${theme.typography.pxToRem(14)}`,
      lineHeight: "29px",
      padding: "0 4px 0 12px !important",

      "&::placeholder": {
        color: `${theme.palette.placeholder}`,
        textOverflow: "ellipsis !important",
        fontWeight: 400,
        opacity: 1,
      },
    },

    ".end-wrap": {
      " .organization": {
        position: "absolute",
        right: "30px",
        top: "50%",
        transform: "translateY(-50%)",
        width: 30,
        height: 29,
        cursor: "pointer",

        "& i": {
          display: "block",
          width: "100%",
          height: "100%",
          background: "url(/images/icon/ico-organization.svg) no-repeat center",
        },
      },

      " .MuiAutocomplete-endAdornment": {
        " .MuiAutocomplete-clearIndicator": {
          position: "absolute",
          right: "60px",
          top: "50%",
          transform: "translateY(-50%)",
        },
      },
    },

    ".MuiAutocomplete-endAdornment": {
      right: 0,

      ".MuiAutocomplete-popupIndicator": {
        padding: 0,
        margin: 0,
        width: 30,
        height: 29,
        background: "url('/images/icon/ico-down.svg') no-repeat center / cover",
        transition: "0.2s",
        borderRadius: 0,

        "&.MuiAutocomplete-popupIndicatorOpen": {
          transform: "rotate(180deg)",
        },

        svg: {
          display: "none",
        },
      },

      ".MuiAutocomplete-clearIndicator": {
        width: `${theme.typography.pxToRem(24)}`,
        height: `${theme.typography.pxToRem(24)}`,
        background:
          'url("/images/icon/24/ico-delete.svg") no-repeat center / cover',
        svg: {
          display: "none",
        },
      },
    },
  },

  // 메인 화면 (대시보드용 오토컴플릿)

  "&.dashboard-autocomplete": {
    width: "100%",
    height: "auto",

    ".MuiFormControl-root": {
      borderColor: "transparent",
      borderRadius: "4px",
    },

    input: {
      padding: `
      ${theme.typography.pxToRem(20)}
      ${theme.typography.pxToRem(10)}
      ${theme.typography.pxToRem(20)}
      ${theme.typography.pxToRem(13)} !important`,
    },

    ".organization": {
      right: "40px !important",
    },

    ".MuiAutocomplete-endAdornment": {
      right: `${theme.typography.pxToRem(10)}`,
    },
  },
}));

export const XTPopper = styled(Popper)(({ theme }) => ({
  marginTop: "-1px",
  backgroundColor: `${theme.palette.white};`,
  border: `solid 1px ${theme.palette.line1}`,
  // TODO: 셀렉 박스와 인풋 사이 간격 여기에서 조정하면 됩니다!!!

  ".MuiPaper-root": {
    borderRadius: "0 !important",
    boxShadow: "none",
    padding: 0,
  },

  ".MuiAutocomplete-listbox": {
    padding: 0,
    backgroundColor: `${theme.palette.white};`,

    ".MuiAutocomplete-option": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: `${theme.palette.white}`,
      fontWeight: 400,
      fontSize: `${theme.typography.pxToRem(14)};`,
      lineHeight: 1,
      color: `${theme.palette.text_sub1};`,
      height: `${theme.typography.pxToRem(34)};`,
      padding: "8px 12px",

      '&[aria-selected="true"]': {
        backgroundColor: `${theme.palette.selected} !important;`,
        color: `${theme.palette.primary.main};`,
      },

      "&.Mui-focused": {
        backgroundColor: "transparent",
      },

      "&.Mui-focusVisible": {
        backgroundColor: `${theme.palette.selectHover}`,
        color: `${theme.palette.primary.main};`,
      },

      "&:hover": {
        backgroundColor: `${theme.palette.selectHover}`,
        color: `${theme.palette.primary.main};`,

        ".sub": {
          color: `${theme.palette.primary.main};`,
        },
      },

      ".sub": {
        fontSize: `${theme.typography.pxToRem(12)};`,
        lineHeight: `${theme.typography.pxToRem(20)};`,
        color: `${theme.palette.bg70} !important;`,
      },
    },
  },

  ".MuiAutocomplete-noOptions": {
    fontWeight: 400,
    fontSize: `${theme.typography.pxToRem(14)}`,
    lineHeight: 1,
    padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(10)};`,
  },
}));

export const XTDashboardPopper = styled(Popper)(({ theme }) => ({
  marginTop: "5px !important",
  backgroundColor: `${theme.palette.white};`,
  border: `none`,

  // // TODO: 셀렉 박스와 인풋 사이 간격 여기에서 조정하면 됩니다!!!

  ".MuiPaper-root": {
    borderRadius: "0 !important",
    boxShadow: "none",
    padding: 0,
  },

  ".MuiAutocomplete-listbox": {
    padding: 0,
    backgroundColor: `${theme.palette.white};`,

    ".MuiAutocomplete-option": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: `${theme.palette.white}`,
      fontWeight: 400,
      fontSize: `${theme.typography.pxToRem(14)};`,
      lineHeight: 1,
      color: `${theme.palette.text_sub1};`,
      // height: `${theme.typography.pxToRem(34)};`,
      padding: "13px 8px",

      '&[aria-selected="true"]': {
        backgroundColor: `${theme.palette.selected} !important;`,
        color: `${theme.palette.primary.main};`,
      },

      "&.Mui-focused": {
        backgroundColor: "transparent",
      },

      "&.Mui-focusVisible": {
        backgroundColor: `${theme.palette.selectHover}`,
        color: `${theme.palette.primary.main};`,
      },

      "&:hover": {
        backgroundColor: `${theme.palette.selectHover}`,
        color: `${theme.palette.primary.main};`,

        ".sub": {
          color: `${theme.palette.primary.main};`,
        },
      },

      ".sub": {
        fontSize: `${theme.typography.pxToRem(12)};`,
        lineHeight: `${theme.typography.pxToRem(20)};`,
        color: `${theme.palette.bg70} !important;`,
      },
    },
  },

  ".MuiAutocomplete-noOptions": {
    fontWeight: 400,
    fontSize: `${theme.typography.pxToRem(14)}`,
    lineHeight: 1,
    padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(10)};`,
  },
}));
