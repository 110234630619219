import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { styled } from "@mui/material/styles";

export const XTTreeView = styled(TreeView)(({ theme }) => ({}));

export const XTTreeItem = styled(TreeItem)(({ theme }) => ({
  marginTop: "3px",

  ".MuiTreeItem-content": {
    "&.Mui-focused": {
      background: `transparent !important`,
    },

    "&:hover": {
      background: `${theme.palette.selectHover} !important`,
    },

    "&.Mui-selected": {
      background: `${theme.palette.selected} !important`,
    },

    ".MuiTreeItem-label": {
      padding: "5px",
      fontSize: "14px",
    },
  },
}));
