import guideCSS from "@/assets/ts/guide-css";
import GuideLeft from "@/guide/components/sidebar";
import CodeMirror from "@uiw/react-codemirror";
import { XTButton } from "@/components/mui/xt-button";
import { XTTooltip } from "@/components/mui/xt-tooltip";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useState } from "react";
import { html } from "@codemirror/lang-html";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { XTTreeItem, XTTreeView } from "@/components/mui/xt-tree";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";

export default function GuideTree() {
  const [codemirror, setCodemirror] = useState<string | false>();
  const handleCodemirror =
    (panel: string) =>
      (event: React.SyntheticEvent, newcodemirror: boolean) => {
        setCodemirror(newcodemirror ? panel : false);
      };

  const menuList = [
    {
      menuCd: "AA", //메뉴 코드
      upperMenuCd: null, //상위 메뉴 코드
      depth: 1, //메뉴 뎁스
      menuNm: "회원", //메뉴 이름
      menuIconPath: "/images/icon/30/ico-user.svg", //메뉴 아이콘 URL
      ord: 1, //메뉴 순서
      readAuthYn: "Y", //공통버튼 조회 여부
      createAuthYn: "Y", //공통버튼 신규 여부
      saveAuthYn: "Y", //공통버튼 저장 여부
      deleteAuthYn: "Y", //공통버튼 삭제 여부
      printAuthYn: "Y", //공통버튼 인쇄 여부
      excelAuthYn: "Y", //공통버튼 엑셀 여부
      showYn: "Y", //노출 여부
      createDate: "2024-01-30 18:17:32", //등록 일자 일시
      updateDate: "2024-01-30 18:22:08",
      childMenus: [
        //하위 메뉴 목록
        {
          menuCd: "AAAA",
          upperMenuCd: "AA",
          depth: 2,
          menuNm: "회원 관리",
          menuIconPath: null,
          ord: 1,
          readAuthYn: "Y",
          createAuthYn: "Y",
          saveAuthYn: "Y",
          deleteAuthYn: "Y",
          printAuthYn: "Y",
          excelAuthYn: "Y",
          showYn: "Y",
          createDate: "2024-01-31 10:19:07",
          updateDate: "2024-02-07 14:04:42",
          childMenus: [
            {
              menuCd: "AAAAAA",
              upperMenuCd: "AAAA",
              depth: 3,
              menuNm: "회원 관리",
              menuIconPath: null,
              ord: 3,
              readAuthYn: "N",
              createAuthYn: "Y",
              saveAuthYn: "Y",
              deleteAuthYn: "Y",
              printAuthYn: "Y",
              excelAuthYn: "Y",
              showYn: "Y",
              createDate: "2024-01-31 10:34:03",
              updateDate: "2024-01-31 10:34:03",
            },
            {
              menuCd: "AAAABB",
              upperMenuCd: "AAAA",
              depth: 3,
              menuNm: "메뉴 관리",
              menuIconPath: null,
              ord: 3,
              readAuthYn: "N",
              createAuthYn: "Y",
              saveAuthYn: "Y",
              deleteAuthYn: "Y",
              printAuthYn: "Y",
              excelAuthYn: "Y",
              showYn: "Y",
              createDate: "2024-01-31 10:34:03",
              updateDate: "2024-01-31 10:34:03",
            },
            {
              menuCd: "AAAACC",
              upperMenuCd: "AAAA",
              depth: 3,
              menuNm: "점포 관리",
              menuIconPath: null,
              ord: 3,
              readAuthYn: "N",
              createAuthYn: "Y",
              saveAuthYn: "Y",
              deleteAuthYn: "Y",
              printAuthYn: "Y",
              excelAuthYn: "Y",
              showYn: "Y",
              createDate: "2024-01-31 10:34:03",
              updateDate: "2024-01-31 10:34:03",
            },
            {
              menuCd: "AAAADD",
              upperMenuCd: "AAAA",
              depth: 3,
              menuNm: "카테고리 관리",
              menuIconPath: null,
              ord: 3,
              readAuthYn: "N",
              createAuthYn: "Y",
              saveAuthYn: "Y",
              deleteAuthYn: "Y",
              printAuthYn: "Y",
              excelAuthYn: "Y",
              showYn: "Y",
              createDate: "2024-01-31 10:34:03",
              updateDate: "2024-01-31 10:34:03",
            },
          ],
        },
      ],
    },
    {
      menuCd: "BB", //메뉴 코드
      upperMenuCd: null, //상위 메뉴 코드
      depth: 1, //메뉴 뎁스
      menuNm: "KALIS lab", //메뉴 이름
      menuIconPath: "/images/icon/30/ico-kalis.svg", //메뉴 아이콘 URL
      ord: 1, //메뉴 순서
      readAuthYn: "Y", //공통버튼 조회 여부
      createAuthYn: "Y", //공통버튼 신규 여부
      saveAuthYn: "Y", //공통버튼 저장 여부
      deleteAuthYn: "Y", //공통버튼 삭제 여부
      printAuthYn: "Y", //공통버튼 인쇄 여부
      excelAuthYn: "Y", //공통버튼 엑셀 여부
      showYn: "Y", //노출 여부
      createDate: "2024-01-30 18:17:32", //등록 일자 일시
      updateDate: "2024-01-30 18:22:08",
      childMenus: [
        //하위 메뉴 목록
        {
          menuCd: "BBAA",
          upperMenuCd: "AA",
          depth: 2,
          menuNm: "구독관리",
          menuIconPath: null,
          ord: 1,
          readAuthYn: "Y",
          createAuthYn: "Y",
          saveAuthYn: "Y",
          deleteAuthYn: "Y",
          printAuthYn: "Y",
          excelAuthYn: "Y",
          showYn: "Y",
          createDate: "2024-01-31 10:19:07",
          updateDate: "2024-02-07 14:04:42",
          childMenus: [
            {
              menuCd: "BBAAAA",
              upperMenuCd: "BBAA",
              depth: 3,
              menuNm: "신청 관리",
              menuIconPath: null,
              ord: 3,
              readAuthYn: "N",
              createAuthYn: "Y",
              saveAuthYn: "Y",
              deleteAuthYn: "Y",
              printAuthYn: "Y",
              excelAuthYn: "Y",
              showYn: "Y",
              createDate: "2024-01-31 10:34:03",
              updateDate: "2024-01-31 10:34:03",
            },
            {
              menuCd: "BBAABB",
              upperMenuCd: "BBAA",
              depth: 3,
              menuNm: "환불 관리",
              menuIconPath: null,
              ord: 3,
              readAuthYn: "N",
              createAuthYn: "Y",
              saveAuthYn: "Y",
              deleteAuthYn: "Y",
              printAuthYn: "Y",
              excelAuthYn: "Y",
              showYn: "Y",
              createDate: "2024-01-31 10:34:03",
              updateDate: "2024-01-31 10:34:03",
            },
          ],
        },
      ],
    },
    {
      menuCd: "CC", //메뉴 코드
      upperMenuCd: null, //상위 메뉴 코드
      depth: 1, //메뉴 뎁스
      menuNm: "시스템", //메뉴 이름
      menuIconPath: "/images/icon/30/ico-setting.svg", //메뉴 아이콘 URL
      ord: 1, //메뉴 순서
      readAuthYn: "Y", //공통버튼 조회 여부
      createAuthYn: "Y", //공통버튼 신규 여부
      saveAuthYn: "Y", //공통버튼 저장 여부
      deleteAuthYn: "Y", //공통버튼 삭제 여부
      printAuthYn: "Y", //공통버튼 인쇄 여부
      excelAuthYn: "Y", //공통버튼 엑셀 여부
      showYn: "Y", //노출 여부
      createDate: "2024-01-30 18:17:32", //등록 일자 일시
      updateDate: "2024-01-30 18:22:08",
      childMenus: [
        //하위 메뉴 목록
        {
          menuCd: "CCAA",
          upperMenuCd: "CC",
          depth: 2,
          menuNm: "설정",
          menuIconPath: null,
          ord: 1,
          readAuthYn: "Y",
          createAuthYn: "Y",
          saveAuthYn: "Y",
          deleteAuthYn: "Y",
          printAuthYn: "Y",
          excelAuthYn: "Y",
          showYn: "Y",
          createDate: "2024-01-31 10:19:07",
          updateDate: "2024-02-07 14:04:42",
          childMenus: [
            {
              menuCd: "CCAAAA",
              upperMenuCd: "CCAA",
              depth: 3,
              menuNm: "메뉴 관리",
              menuIconPath: null,
              ord: 3,
              readAuthYn: "N",
              createAuthYn: "Y",
              saveAuthYn: "Y",
              deleteAuthYn: "Y",
              printAuthYn: "Y",
              excelAuthYn: "Y",
              showYn: "Y",
              createDate: "2024-01-31 10:34:03",
              updateDate: "2024-01-31 10:34:03",
            },
          ],
        },
      ],
    },
  ];

  return (
    <div className="guide-area" css={guideCSS.guideWrap}>
      <GuideLeft />
      <div css={guideCSS.guideWrapper}>
        <div className="title-box">
          <h2 className="guide-h2">Tree</h2>
        </div>
        <div className="guide-content">
          <div>
            <h3 className="guide-h3">Tree - Default</h3>
            <XTTreeView
              defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />}
              defaultExpandIcon={<AddBoxOutlinedIcon />}
            >
              <XTTreeItem nodeId="0" label="ROOT">
                {menuList.length > 0 &&
                  menuList.map((d1) => (
                    <XTTreeItem
                      key={d1.menuCd}
                      nodeId={d1.menuCd}
                      label={d1.menuNm}
                    >
                      {d1.childMenus &&
                        d1.childMenus.length > 0 &&
                        d1.childMenus.map((d2, i) => (
                          <XTTreeItem
                            key={d2.menuCd}
                            nodeId={d2.menuCd}
                            label={d2.menuNm}
                          >
                            {d2.childMenus &&
                              d2.childMenus.length > 0 &&
                              d2.childMenus.map((d3) => (
                                <XTTreeItem
                                  key={d3.menuCd}
                                  nodeId={d3.menuCd}
                                  label={d3.menuNm}
                                  icon={<ArrowRightIcon />}
                                />
                              ))}
                          </XTTreeItem>
                        ))}
                    </XTTreeItem>
                  ))}
              </XTTreeItem>
            </XTTreeView>
          </div>
          <br />
          <br />
          <Accordion
            sx={{
              margin: 0,
              p: 0,
              border: "none",
              outline: "none",
              boxShadow: "none",
              "&::before": {
                position: "relative",
              },
            }}
            expanded={codemirror === "treeDefault"}
            onChange={handleCodemirror("treeDefault")}
            className="accordion-codemirror"
          >
            <AccordionSummary>
              <p className="guide-codeMore">코드보기</p>
            </AccordionSummary>
            <AccordionDetails>
              <CodeMirror
                theme={vscodeDark}
                extensions={[html()]}
                value={`
import { XTTreeItem, XTTreeView } from "@/components/mui/xt-tree";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";

const menuList = [
  {
    menuCd: "AA", //메뉴 코드
    upperMenuCd: null, //상위 메뉴 코드
    depth: 1, //메뉴 뎁스
    menuNm: "회원", //메뉴 이름
    menuIconPath: "/images/icon/30/ico-user.svg", //메뉴 아이콘 URL
    ord: 1, //메뉴 순서
    readAuthYn: "Y", //공통버튼 조회 여부
    createAuthYn: "Y", //공통버튼 신규 여부
    saveAuthYn: "Y", //공통버튼 저장 여부
    deleteAuthYn: "Y", //공통버튼 삭제 여부
    printAuthYn: "Y", //공통버튼 인쇄 여부
    excelAuthYn: "Y", //공통버튼 엑셀 여부
    showYn: "Y", //노출 여부
    createDate: "2024-01-30 18:17:32", //등록 일자 일시
    updateDate: "2024-01-30 18:22:08",
    childMenus: [
      //하위 메뉴 목록
      {
        menuCd: "AAAA",
        upperMenuCd: "AA",
        depth: 2,
        menuNm: "회원 관리",
        menuIconPath: null,
        ord: 1,
        readAuthYn: "Y",
        createAuthYn: "Y",
        saveAuthYn: "Y",
        deleteAuthYn: "Y",
        printAuthYn: "Y",
        excelAuthYn: "Y",
        showYn: "Y",
        createDate: "2024-01-31 10:19:07",
        updateDate: "2024-02-07 14:04:42",
        childMenus: [
          {
            menuCd: "AAAAAA",
            upperMenuCd: "AAAA",
            depth: 3,
            menuNm: "회원 관리",
            menuIconPath: null,
            ord: 3,
            readAuthYn: "N",
            createAuthYn: "Y",
            saveAuthYn: "Y",
            deleteAuthYn: "Y",
            printAuthYn: "Y",
            excelAuthYn: "Y",
            showYn: "Y",
            createDate: "2024-01-31 10:34:03",
            updateDate: "2024-01-31 10:34:03",
          },
          {
            menuCd: "AAAABB",
            upperMenuCd: "AAAA",
            depth: 3,
            menuNm: "메뉴 관리",
            menuIconPath: null,
            ord: 3,
            readAuthYn: "N",
            createAuthYn: "Y",
            saveAuthYn: "Y",
            deleteAuthYn: "Y",
            printAuthYn: "Y",
            excelAuthYn: "Y",
            showYn: "Y",
            createDate: "2024-01-31 10:34:03",
            updateDate: "2024-01-31 10:34:03",
          },
          {
            menuCd: "AAAACC",
            upperMenuCd: "AAAA",
            depth: 3,
            menuNm: "점포 관리",
            menuIconPath: null,
            ord: 3,
            readAuthYn: "N",
            createAuthYn: "Y",
            saveAuthYn: "Y",
            deleteAuthYn: "Y",
            printAuthYn: "Y",
            excelAuthYn: "Y",
            showYn: "Y",
            createDate: "2024-01-31 10:34:03",
            updateDate: "2024-01-31 10:34:03",
          },
          {
            menuCd: "AAAADD",
            upperMenuCd: "AAAA",
            depth: 3,
            menuNm: "카테고리 관리",
            menuIconPath: null,
            ord: 3,
            readAuthYn: "N",
            createAuthYn: "Y",
            saveAuthYn: "Y",
            deleteAuthYn: "Y",
            printAuthYn: "Y",
            excelAuthYn: "Y",
            showYn: "Y",
            createDate: "2024-01-31 10:34:03",
            updateDate: "2024-01-31 10:34:03",
          },
        ],
      },
    ],
  },
  {
    menuCd: "BB", //메뉴 코드
    upperMenuCd: null, //상위 메뉴 코드
    depth: 1, //메뉴 뎁스
    menuNm: "KALIS lab", //메뉴 이름
    menuIconPath: "/images/icon/30/ico-kalis.svg", //메뉴 아이콘 URL
    ord: 1, //메뉴 순서
    readAuthYn: "Y", //공통버튼 조회 여부
    createAuthYn: "Y", //공통버튼 신규 여부
    saveAuthYn: "Y", //공통버튼 저장 여부
    deleteAuthYn: "Y", //공통버튼 삭제 여부
    printAuthYn: "Y", //공통버튼 인쇄 여부
    excelAuthYn: "Y", //공통버튼 엑셀 여부
    showYn: "Y", //노출 여부
    createDate: "2024-01-30 18:17:32", //등록 일자 일시
    updateDate: "2024-01-30 18:22:08",
    childMenus: [
      //하위 메뉴 목록
      {
        menuCd: "BBAA",
        upperMenuCd: "AA",
        depth: 2,
        menuNm: "구독관리",
        menuIconPath: null,
        ord: 1,
        readAuthYn: "Y",
        createAuthYn: "Y",
        saveAuthYn: "Y",
        deleteAuthYn: "Y",
        printAuthYn: "Y",
        excelAuthYn: "Y",
        showYn: "Y",
        createDate: "2024-01-31 10:19:07",
        updateDate: "2024-02-07 14:04:42",
        childMenus: [
          {
            menuCd: "BBAAAA",
            upperMenuCd: "BBAA",
            depth: 3,
            menuNm: "신청 관리",
            menuIconPath: null,
            ord: 3,
            readAuthYn: "N",
            createAuthYn: "Y",
            saveAuthYn: "Y",
            deleteAuthYn: "Y",
            printAuthYn: "Y",
            excelAuthYn: "Y",
            showYn: "Y",
            createDate: "2024-01-31 10:34:03",
            updateDate: "2024-01-31 10:34:03",
          },
          {
            menuCd: "BBAABB",
            upperMenuCd: "BBAA",
            depth: 3,
            menuNm: "환불 관리",
            menuIconPath: null,
            ord: 3,
            readAuthYn: "N",
            createAuthYn: "Y",
            saveAuthYn: "Y",
            deleteAuthYn: "Y",
            printAuthYn: "Y",
            excelAuthYn: "Y",
            showYn: "Y",
            createDate: "2024-01-31 10:34:03",
            updateDate: "2024-01-31 10:34:03",
          },
        ],
      },
    ],
  },
  {
    menuCd: "CC", //메뉴 코드
    upperMenuCd: null, //상위 메뉴 코드
    depth: 1, //메뉴 뎁스
    menuNm: "시스템", //메뉴 이름
    menuIconPath: "/images/icon/30/ico-setting.svg", //메뉴 아이콘 URL
    ord: 1, //메뉴 순서
    readAuthYn: "Y", //공통버튼 조회 여부
    createAuthYn: "Y", //공통버튼 신규 여부
    saveAuthYn: "Y", //공통버튼 저장 여부
    deleteAuthYn: "Y", //공통버튼 삭제 여부
    printAuthYn: "Y", //공통버튼 인쇄 여부
    excelAuthYn: "Y", //공통버튼 엑셀 여부
    showYn: "Y", //노출 여부
    createDate: "2024-01-30 18:17:32", //등록 일자 일시
    updateDate: "2024-01-30 18:22:08",
    childMenus: [
      //하위 메뉴 목록
      {
        menuCd: "CCAA",
        upperMenuCd: "CC",
        depth: 2,
        menuNm: "설정",
        menuIconPath: null,
        ord: 1,
        readAuthYn: "Y",
        createAuthYn: "Y",
        saveAuthYn: "Y",
        deleteAuthYn: "Y",
        printAuthYn: "Y",
        excelAuthYn: "Y",
        showYn: "Y",
        createDate: "2024-01-31 10:19:07",
        updateDate: "2024-02-07 14:04:42",
        childMenus: [
          {
            menuCd: "CCAAAA",
            upperMenuCd: "CCAA",
            depth: 3,
            menuNm: "메뉴 관리",
            menuIconPath: null,
            ord: 3,
            readAuthYn: "N",
            createAuthYn: "Y",
            saveAuthYn: "Y",
            deleteAuthYn: "Y",
            printAuthYn: "Y",
            excelAuthYn: "Y",
            showYn: "Y",
            createDate: "2024-01-31 10:34:03",
            updateDate: "2024-01-31 10:34:03",
          },
        ],
      },
    ],
  },
];

<XTTreeView
  defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />}
  defaultExpandIcon={<AddBoxOutlinedIcon />}
>
  <XTTreeItem nodeId="0" label="ROOT">
    {menuList.length > 0 &&
      menuList.map((d1) => (
        <XTTreeItem
          key={d1.menuCd}
          nodeId={d1.menuCd}
          label={d1.menuNm}
        >
          {d1.childMenus &&
            d1.childMenus.length > 0 &&
            d1.childMenus.map((d2, i) => (
              <XTTreeItem
                key={d2.menuCd}
                nodeId={d2.menuCd}
                label={d2.menuNm}
              >
                {d2.childMenus &&
                  d2.childMenus.length > 0 &&
                  d2.childMenus.map((d3) => (
                    <XTTreeItem
                      key={d3.menuCd}
                      nodeId={d3.menuCd}
                      label={d3.menuNm}
                      icon={<ArrowRightIcon />}
                    />
                  ))}
              </XTTreeItem>
            ))}
        </XTTreeItem>
      ))}
  </XTTreeItem>
</XTTreeView>
`}
              ></CodeMirror>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
