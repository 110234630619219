import theme from "@/assets/ts/theme";
import { Popper, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

export const XTTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  z-index: 9999999;

  .MuiTooltip-tooltip {
    padding: 9px 12px;
    margin-top: 8px !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    background: ${theme.palette.primary.main};
    border-radius: 30px;
  }

  .MuiTooltip-arrow {
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 6px solid ${theme.palette.primary.main};
    color: ${theme.palette.primary.main};
    top: 4px !important;

    &:before {
      display: none;
    }
  }
`;
