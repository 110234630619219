import globalCSS from "@/assets/ts/global-css";
import guideCSS from "@/assets/ts/guide-css";
import GuideLeft from "@/guide/components/sidebar";
import {
  XTDialog,
  XTDialogActions,
  XTDialogContent,
  XTDialogTitle,
} from "@/components/mui/xt-dialog";
import { useState } from "react";
import { XTButton, XTTextButton } from "@/components/mui/xt-button";
import { XTPopupFile } from "@/components/mui/xt-fileform";
import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import CodeMirror from "@uiw/react-codemirror";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { html } from "@codemirror/lang-html";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import AlertDialog from "@/components/ui/alert-dialog";

export default function GuideDialog() {
  const [alertDialog, setAlertDialog] = useState<{ open: boolean; tp: string }>(
    {
      open: false,
      tp: "1",
    }
  );

  const [openDialog, setOpenDialog] = useState<{ open: boolean; tp: string }>({
    open: false,
    tp: "1",
  });

  const [codemirror, setCodemirror] = useState<string | false>();

  const handleCodemirror =
    (panel: string) =>
      (event: React.SyntheticEvent, newcodemirror: boolean) => {
        setCodemirror(newcodemirror ? panel : false);
      };

  const handleCloseConfirmDialog = () => {
    setAlertDialog({
      ...alertDialog,
      open: false,
    });
  };

  const handleOKConfirmDialog = () => {
    switch (alertDialog.tp) {
      case "1":
        alert("Handle Confirm OK");
        break;

      case "2":
        alert("Handle Complete OK");
        break;
    }

    setAlertDialog({
      ...alertDialog,
      open: false,
    });
  };

  const handleOpenAlertDialog = (tp: string) => {
    setAlertDialog({
      open: true,
      tp,
    });
  };

  const handleOpenContDialog = (tp: string) => {
    setOpenDialog({
      open: true,
      tp,
    });
  };

  const handleCloseContDialog = () => {
    setOpenDialog({
      ...openDialog,
      open: false,
    });
  };

  const handleOKContDialog = () => {
    alert("handle ok cont dialog");
  };

  const getDialogText = () => {
    switch (alertDialog.tp) {
      case "1":
        return "등록하시겠습니까?";

      case "2":
        return "등록되었습니다.";
    }
  };

  return (
    <div className="guide-area" css={guideCSS.guideWrap}>
      <GuideLeft />
      <div css={guideCSS.guideWrapper}>
        <div className="title-box">
          <h2 className="guide-h2">Dialog</h2>
        </div>
        <div className="guide-content">
          <h3 className="guide-h3">Alert Dialog</h3>
          <XTButton
            onClick={() => {
              handleOpenAlertDialog("1");
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Confirm Dialog
          </XTButton>
          <XTButton
            sx={{ marginLeft: 5 }}
            onClick={() => {
              handleOpenAlertDialog("2");
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Complete Dialog
          </XTButton>
          <br />
          <br />
          <AlertDialog
            open={alertDialog.open} // open state값
            text={getDialogText()}
            type={alertDialog.tp === "1" ? "confirm" : "complete"} // dialog 타입 -> confirm: 취소/확인, complete: 확인
            handleClose={handleCloseConfirmDialog} // confirm일 경우 취소버튼이나 닫았을 떄 실행되는 function type:"complete"면 없어도 됨
            handleOK={handleOKConfirmDialog} // 확인 버튼 눌렀을 경우 실행되는 function
          />
          <Accordion
            sx={{
              margin: 0,
              p: 0,
              border: "none",
              outline: "none",
              boxShadow: "none",
              "&::before": {
                position: "relative",
              },
            }}
            expanded={codemirror === "file-popup"}
            onChange={handleCodemirror("file-popup")}
            className="accordion-codemirror"
          >
            <AccordionSummary>
              <p className="guide-codeMore">코드보기</p>
            </AccordionSummary>
            <AccordionDetails>
              <CodeMirror
                theme={vscodeDark}
                extensions={[html()]}
                value={`
const [alertDialog, setAlertDialog] = useState<{ open: boolean; tp: string }>(
  {
    open: false,
    tp: "1",
  }
);

const handleCloseConfirmDialog = () => {
  setAlertDialog({
    ...alertDialog,
    open: false,
  });
};

const handleOKConfirmDialog = () => {
  switch (alertDialog.tp) {
    case "1":
      alert("Handle Confirm OK");
      break;

    case "2":
      alert("Handle Complete OK");
      break;
  }

  setAlertDialog({
    ...alertDialog,
    open: false,
  });
};

const handleOpenAlertDialog = (tp: string) => {
  setAlertDialog({
    ...alertDialog,
    open: true,
    tp,
  });
};

const getDialogText = () => {
  switch (alertDialog.tp) {
    case "1":
      return "등록 <br /> 하시겠습니까?";

    case "2":
      return "등록되었습니다.";
  }
};
                
                
<AlertDialog
  open={alertDialog.open} // open state값
  text={getDialogText()}
  type={alertDialog.tp === "1" ? "confirm" : "complete"} // dialog 타입 -> confirm: 취소/확인, complete: 확인
  handleClose={handleCloseConfirmDialog} // confirm일 경우 취소버튼이나 닫았을 떄 실행되는 function type:"complete"면 없어도 됨
  handleOK={handleOKConfirmDialog} // 확인 버튼 눌렀을 경우 실행되는 function
/>`}
              ></CodeMirror>
            </AccordionDetails>
          </Accordion>
        </div>
        <hr className="guide-hr" />
        <div className="guide-content">
          <h3 className="guide-h3">Content Dialog</h3>
          <XTButton
            onClick={() => {
              handleOpenContDialog("1");
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Dialog className="md"
          </XTButton>
          <XTButton
            sx={{ marginLeft: 5 }}
            onClick={() => {
              handleOpenContDialog("2");
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Dialog className="lg"
          </XTButton>

          {/* className="md" */}
          <XTDialog
            disablePortal
            className="md"
            open={openDialog.open && openDialog.tp == "1"}
            onClose={handleCloseContDialog}
          >
            <XTDialogTitle className="flex-title">
              <span>옵션 선택</span>
              <button
                className="close-btn"
                onClick={() => {
                  handleCloseContDialog();
                }}
              >
                <i css={globalCSS.icoClose} />
              </button>
            </XTDialogTitle>
            <XTDialogContent>
              <div>옵션 선택 Contents....</div>
            </XTDialogContent>
            <XTDialogActions>
              <XTTextButton
                className="gray"
                onClick={() => {
                  handleCloseContDialog();
                }}
              >
                취소
              </XTTextButton>
              <XTTextButton color="primary" onClick={handleOKContDialog}>
                확인
              </XTTextButton>
            </XTDialogActions>
          </XTDialog>

          {/* className="lg" */}
          <XTDialog
            disablePortal
            className="lg"
            open={openDialog.open && openDialog.tp == "2"}
            onClose={handleCloseContDialog}
          >
            <XTDialogTitle className="flex-title">
              <span>옵션 선택</span>
              <button
                className="close-btn"
                onClick={() => {
                  handleCloseContDialog();
                }}
              >
                <i css={globalCSS.icoClose} />
              </button>
            </XTDialogTitle>
            <XTDialogContent>
              <div>옵션 선택 Contents....</div>
            </XTDialogContent>
            <XTDialogActions>
              <XTTextButton
                className="gray"
                onClick={() => {
                  handleCloseContDialog();
                }}
              >
                취소
              </XTTextButton>
              <XTTextButton color="primary" onClick={handleOKContDialog}>
                확인
              </XTTextButton>
            </XTDialogActions>
          </XTDialog>
          <br />
          <br />
          <Accordion
            sx={{
              margin: 0,
              p: 0,
              border: "none",
              outline: "none",
              boxShadow: "none",
              "&::before": {
                position: "relative",
              },
            }}
            expanded={codemirror === "file-popup"}
            onChange={handleCodemirror("file-popup")}
            className="accordion-codemirror"
          >
            <AccordionSummary>
              <p className="guide-codeMore">코드보기</p>
            </AccordionSummary>
            <AccordionDetails>
              <CodeMirror
                theme={vscodeDark}
                extensions={[html()]}
                value={`
const [alertDialog, setAlertDialog] = useState<{ open: boolean; tp: string }>(
  {
    open: false,
    tp: "1",
  }
);

const handleCloseConfirmDialog = () => {
  setAlertDialog({
    ...alertDialog,
    open: false,
  });
};

const handleOKConfirmDialog = () => {
  switch (alertDialog.tp) {
    case "1":
      alert("Handle Confirm OK");
      break;

    case "2":
      alert("Handle Complete OK");
      break;
  }

  setAlertDialog({
    ...alertDialog,
    open: false,
  });
};

const handleOpenAlertDialog = (tp: string) => {
  setAlertDialog({
    ...alertDialog,
    open: true,
    tp,
  });
};

const getDialogText = () => {
  switch (alertDialog.tp) {
    case "1":
      return "등록 <br /> 하시겠습니까?";

    case "2":
      return "등록되었습니다.";
  }
};
                
// className="md"
<XTDialog disablePortal className="md" open={openDialog.open}>
  <XTDialogTitle className="flex-title">
    <span>옵션 선택</span>
    <button
      className="close-btn"
      onClick={() => {
        handleCloseContDialog();
      }}
    >
      <i css={globalCSS.icoClose} />
    </button>
  </XTDialogTitle>
  <XTDialogContent>
    <div>옵션 선택 Contents....</div>
  </XTDialogContent>
  <XTDialogActions>
    <XTTextButton
      className="gray"
      onClick={() => {
        handleCloseContDialog();
      }}
    >
      취소
    </XTTextButton>
    <XTTextButton color="primary" onClick={handleOKContDialog}>
      확인
    </XTTextButton>
  </XTDialogActions>
</XTDialog>

// className="lg"
<XTDialog
  disablePortal
  className="lg"
  open={openDialog.open && openDialog.tp == "2"}
  onClose={handleCloseContDialog}
>
  <XTDialogTitle className="flex-title">
    <span>옵션 선택</span>
    <button
      className="close-btn"
      onClick={() => {
        handleCloseContDialog();
      }}
    >
      <i css={globalCSS.icoClose} />
    </button>
  </XTDialogTitle>
  <XTDialogContent>
    <div>옵션 선택 Contents....</div>
  </XTDialogContent>
  <XTDialogActions>
    <XTTextButton
      className="gray"
      onClick={() => {
        handleCloseContDialog();
      }}
    >
      취소
    </XTTextButton>
    <XTTextButton color="primary" onClick={handleOKContDialog}>
      확인
    </XTTextButton>
  </XTDialogActions>
</XTDialog>

`}
              ></CodeMirror>
            </AccordionDetails>
          </Accordion>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
