import { DestructionSchFormType } from "@/types/log/destruction";
import { PrivacyRegistParamsType } from "@/types/log/privacy";
import axios from "axios";

export const loadDestructionListAPI = async (form: DestructionSchFormType) => {
  return axios.get("/api/lg/log-privacy-delete/page-list", {
    params: { ...form },
  });
};

export const loadDestructionTypeAPI = async () => {
  return axios.get("/api/lg/log-privacy-delete/get-type");
};

export const updateSelectDestructionAPI = async (form: PrivacyRegistParamsType) => {
  return axios.post("/api/lg/log-privacy-delete/update", { ...form });
};

export const updateAllDestructionAPI = async () => {
  return axios.post("/api/lg/log-privacy-delete/update-all", {});
};

export const loadDestructionAPI = async (seq: string) => {
  return axios.get("/api/lg/log-privacy-delete/get", {
    params: {
      seq: seq,
    },
  });
};
