import globalCSS from "@/assets/ts/global-css";
import guideCSS from "@/assets/ts/guide-css";
import GuideLeft from "@/guide/components/sidebar";
import {
  XTButton,
  XTTextButton,
  XTTextUnderlineButton,
} from "@/components/mui/xt-button";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { html } from "@codemirror/lang-html";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import theme from "@/assets/ts/theme";
import { XTToggle } from "@/components/mui/xt-switch";
import { XTFileDownload, FileUpload } from "@/components/mui/xt-fileform";
import { css } from "@emotion/react";

export default function GuideButton() {
  const [codemirror, setCodemirror] = useState<string | false>();
  const handleCodemirror =
    (panel: string) =>
      (event: React.SyntheticEvent, newcodemirror: boolean) => {
        setCodemirror(newcodemirror ? panel : false);
      };

  const [checked, setChecked] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key == "Enter") setChecked((prev) => !prev);
  };

  // 다운로드 파일
  const fileRoute: string = "/images/icon/ico-delete.svg";

  // 다운로드 할 때 파일명
  const fileName: string = "이미지 파일";

  // 텍스트
  const fileTitle: string = "이미지 파일";

  // 확장자
  const fileExt: string = ".jpg";

  const totalSize = 10;

  // test

  const handleCody = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (e) { }
  };

  return (
    <>
      <div className="guide-area" css={guideCSS.guideWrap}>
        <GuideLeft />
        <div css={guideCSS.guideWrapper}>
          <div className="title-box">
            <h2 className="guide-h2">Button</h2>
          </div>
          <div className="guide-content">
            {/* ------------------------------------------------ type A */}
            <div>
              <h3 className="guide-h3">
                Type A (클릭 시 코드 복사가 가능합니다)
              </h3>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                }}
              >
                <XTButton
                  variant="contained"
                  color="primary"
                  size="large"
                  className="xl"
                  data-code='<XTButton variant="contained" color="primary" size="large" className="xl"> Contained Primary </XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Contained Primary
                </XTButton>
                <XTButton
                  variant="contained"
                  color="primary"
                  size="large"
                  className="xl info-btn"
                  data-code='<XTButton variant="contained" color="primary" size="large" className="xl info-btn"> 
                  <div className="name-area"><i css={globalCSS.icoProfile} /><span className="name">홍길동</span>님</div><div className="icon-area"><i css={globalCSS.icoLogout} /></div></XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  <div className="name-area">
                    <i css={globalCSS.icoProfile} />
                    <span className="name">홍길동</span>님
                  </div>
                  <div className="icon-area">
                    <i css={globalCSS.icoLogout} />
                  </div>
                </XTButton>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  padding: "10px",
                }}
              >
                <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column"
                  data-code='<XTButton variant="contained" color="secondary" size="large" className="box flex-column">  <img className="icon" src="/images/icon/30/ico-user.svg" /> <span> Default </span></XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  <img className="icon" src="/images/icon/30/ico-user.svg" />
                  <span>Default</span>
                </XTButton>
                <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column active"
                  data-code='<XTButton variant="contained" color="secondary" size="large" className="box flex-column active" startIcon={<i css={globalCSS.icoUser} />}> <img className="icon" src="/images/icon/30/ico-user.svg" /> <span>Active</span> </XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  <img className="icon" src="/images/icon/30/ico-user.svg" />
                  <span>Active</span>
                </XTButton>
                <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box active flex-column"
                  data-code='<XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box active flex-column"
                >
                  <img className="icon" src="/images/icon/30/ico-user.svg" />
                  <span>회원</span>
                </XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  <img className="icon" src="/images/icon/30/ico-user.svg" />
                  <span>회원</span>
                </XTButton>
                <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column"
                  data-code=' <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column"
                >
                  <img className="icon" src="/images/icon/30/ico-kalis.svg" />
                  <span>KALIS lab</span>
                </XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  <img className="icon" src="/images/icon/30/ico-kalis.svg" />
                  <span>KALIS lab</span>
                </XTButton>
                <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column"
                  data-code='<XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column"
                >
                  <img
                  className="icon"
                  src="/images/icon/30/ico-mealcare.svg"
                  />
                  <span>Meal Care</span>
                </XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  <img
                    className="icon"
                    src="/images/icon/30/ico-mealcare.svg"
                  />
                  <span>Meal Care</span>
                </XTButton>
                <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column"
                  data-code=' <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column"
                >
                  <img
                  className="icon"
                  src="/images/icon/30/ico-smartorder.svg"
                  />
                  <span>스마트오더</span>
                </XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  <img
                    className="icon"
                    src="/images/icon/30/ico-smartorder.svg"
                  />
                  <span>스마트오더</span>
                </XTButton>
                <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column"
                  data-code='  <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column"
                >
                  <img className="icon" src="/images/icon/30/ico-board.svg" />
                  <span>운영관리</span>
                </XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  <img className="icon" src="/images/icon/30/ico-board.svg" />
                  <span>운영관리</span>
                </XTButton>
                <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column"
                  data-code='  <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column"
                >
                  <img
                    className="icon"
                    src="/images/icon/30/ico-headphone.svg"
                  />
                  <span>고객센터</span>
                </XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  <img
                    className="icon"
                    src="/images/icon/30/ico-headphone.svg"
                  />
                  <span>고객센터</span>
                </XTButton>
                <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column"
                  data-code='  <XTButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="box flex-column"
                >
                  시스템
                </XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  <img className="icon" src="/images/icon/30/ico-setting.svg" />
                  <span>시스템</span>
                </XTButton>
                <XTButton
                  variant="contained"
                  color="primary"
                  size="large"
                  className="box icon"
                  startIcon={<i css={globalCSS.icoLogout} />}
                  data-code='<XTButton
                  variant="contained"
                  color="primary"
                  size="large"
                  className="box icon"
                  startIcon={<i css={globalCSS.icoLogout} />}
                ></XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                ></XTButton>
              </div>
              <br />
              <br />
              <Accordion
                sx={{
                  margin: 0,
                  p: 0,
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  "&::before": {
                    position: "relative",
                  },
                }}
                expanded={codemirror === "basicContainedButton"}
                onChange={handleCodemirror("basicContainedButton")}
                className="accordion-codemirror"
              >
                <AccordionSummary>
                  <p className="guide-codeMore">코드보기</p>
                </AccordionSummary>
                <AccordionDetails>
                  <CodeMirror
                    theme={vscodeDark}
                    extensions={[html()]}
                    value={`
import { XTButton } from "@/components/mui/xt-button";

/* Primary 버튼 */
<XTButton variant="contained" color="primary" size="large" className="xl">Button</XTButton>

/* Primary 버튼 icon + text + icon */
<XTButton variant="contained" color="primary" size="large" className="xl" startIcon={<i css={globalCSS.icoProfile} />} endIcon={<i css={globalCSS.icoLogout} />}> <div className="name-area"> <span className="name">홍길동</span>님 </div> </XTButton>

/* BOX형 아이콘 버튼 icon + text*/
<XTButton variant="contained" color="secondary" size="large" className="box" startIcon={<i css={globalCSS.icoUser} />} > 회원 </XTButton>

/* BOX형 아이콘 버튼 icon + text - active*/
<XTButton variant="contained" color="secondary" size="large" className="box active" startIcon={<i css={globalCSS.icoUser} />} > 회원 </XTButton>

/* BOX형 아이콘 버튼 icon만, primary color */
<XTButton variant="contained" color="primary" size="large" className="box icon" startIcon={<i css={globalCSS.icoLogout} />} ></XTButton>

`}
                  ></CodeMirror>
                </AccordionDetails>
              </Accordion>
            </div>
            <hr className="guide-hr" />
            {/* ------------------------------------------------ type B */}
            <div>
              <h3 className="guide-h3">
                Type B (클릭 시 코드 복사가 가능합니다)
              </h3>
              <div style={{ display: "flex", gap: "20px" }}>
                <XTButton
                  variant="contained"
                  color="primary"
                  size="large"
                  data-code='<XTButton variant="contained" color="primary" size="small">Contained Primary</XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Contained Primary
                </XTButton>
                <XTButton
                  disabled
                  variant="contained"
                  color="primary"
                  size="large"
                  data-code='<XTButton disabled variant="contained" color="primary" size="small">Contained disabled</XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Contained disabled
                </XTButton>
              </div>
              <br />
              <div style={{ display: "flex", gap: "20px" }}>
                <XTButton
                  variant="contained"
                  color="primary"
                  size="large"
                  data-code='<XTButton variant="contained" color="primary" size="small">Contained Primary</XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  로그인
                </XTButton>
              </div>
              <br />
              <br />
              <Accordion
                sx={{
                  margin: 0,
                  p: 0,
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  "&::before": {
                    position: "relative",
                  },
                }}
                expanded={codemirror === "ContainedPrimaryButton"}
                onChange={handleCodemirror("ContainedPrimaryButton")}
                className="accordion-codemirror"
              >
                <AccordionSummary>
                  <p className="guide-codeMore">코드보기</p>
                </AccordionSummary>
                <AccordionDetails>
                  <CodeMirror
                    theme={vscodeDark}
                    extensions={[html()]}
                    value={`
import { XTButton } from "@/components/mui/xt-button";

/* 기본 Primary 버튼 */
<XTButton variant="contained" color="primary" size="large">Button</XTButton>

/* 기본 Disabled 버튼 */
<XTButton disabled variant="contained" color="primary" size="large">Button</XTButton>
`}
                  ></CodeMirror>
                </AccordionDetails>
              </Accordion>
            </div>
            <hr className="guide-hr" />
            {/* ------------------------------------------------ type C */}
            <div>
              <h3 className="guide-h3">
                Type C (클릭 시 코드 복사가 가능합니다)
              </h3>
              <div style={{ display: "flex", gap: "20px" }}>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  data-code='<XTButton variant="outlined" color="primary" size="medium">Outlined Primary</XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Outlined Primary
                </XTButton>
                <XTButton
                  disabled
                  variant="outlined"
                  color="primary"
                  size="medium"
                  data-code='<XTButton disabled variant="outlined" color="info" size="medium">Outlined disabled</XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Outlined disabled
                </XTButton>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  data-code='<XTButton variant="outlined" color="primary" size="medium"> <i css={globalCSS.icoExcel} /></XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  조회
                </XTButton>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  data-code='<XTButton variant="outlined" color="primary" size="medium"> <i css={globalCSS.icoExcel} /></XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  <i css={globalCSS.icoExcel} />
                </XTButton>
                <XTButton
                  disabled
                  variant="outlined"
                  color="primary"
                  size="medium"
                  className="btn-excel"
                  data-code='<XTButton variant="outlined" color="primary" size="medium"> <i css={globalCSS.icoExcel} /></XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  <i css={globalCSS.icoExcel} />
                </XTButton>
              </div>
              <br />
              <div
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  padding: "10px",
                  display: "flex",
                  gap: "20px",
                }}
              >
                <XTButton
                  variant="contained"
                  color="secondary"
                  size="medium"
                  data-code='<XTButton variant="contained" color="secondary" size="medium">Outlined Primary</XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Contained Primary
                </XTButton>
                <XTButton
                  variant="contained"
                  color="secondary"
                  size="medium"
                  className="btn-push active"
                  data-code='<XTButton variant="contained" color="secondary" size="medium">Outlined Primary</XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  <i css={globalCSS.icoPush} />
                </XTButton>
              </div>
              <br />
              버튼과 스타일이 동일한 div : globalCSS.btnContained
              <div
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  padding: "10px",
                  display: "flex",
                  gap: "20px",
                }}
              >
                <div css={globalCSS.btnContained}>
                  <i css={globalCSS.icoCalendar} />
                  <span>2023-08-14</span>
                </div>
                <div css={globalCSS.btnContained}>
                  <i css={globalCSS.icoTime} />
                  <span>12:15</span>
                </div>
                {/* <XTButton
                  variant="contained"
                  color="secondary"
                  size="medium"
                  startIcon={<i css={globalCSS.icoCalendar} />}
                  data-code='<XTButton variant="contained" color="secondary" size="medium">Outlined Primary</XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  2023-08-14
                </XTButton>
                <XTButton
                  variant="contained"
                  color="secondary"
                  size="medium"
                  startIcon={<i css={globalCSS.icoTime} />}
                  data-code='<XTButton variant="contained" color="secondary" size="medium">Outlined Primary</XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  12:15
                </XTButton> */}
              </div>
              <br />
              <br />
              <Accordion
                sx={{
                  margin: 0,
                  p: 0,
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  "&::before": {
                    position: "relative",
                  },
                }}
                expanded={codemirror === "basic-button"}
                onChange={handleCodemirror("basic-button")}
                className="accordion-codemirror"
              >
                <AccordionSummary>
                  <p className="guide-codeMore">코드보기</p>
                </AccordionSummary>
                <AccordionDetails>
                  <CodeMirror
                    theme={vscodeDark}
                    extensions={[html()]}
                    value={`
import { XTButton } from "@/components/mui/xt-button";

/* Outlined Primary 버튼 */
<XTButton variant="outlined" color="primary" size="medium" > Outlined Primary </XTButton>

/* Outlined Disabled 버튼 */
<XTButton disabled variant="outlined" color="primary" size="medium" > Outlined Primary </XTButton>

/* Outlined excel 버튼 */
<XTButton variant="outlined" color="primary" size="medium" > <i css={globalCSS.icoExcel} /> </XTButton>


/* Contained secondary 버튼 */
<XTButton variant="contained" color="secondary" size="medium" > Contained Primary </XTButton>

/* Contained secondary 버튼 icon + text */
<XTButton variant="contained" color="secondary" size="medium" startIcon={<i css={globalCSS.icoCalendar} />} } > 2023.08.14 </XTButton>

/* Contained secondary 버튼 icon + badge btn-push */
<XTButton variant="contained" color="secondary" size="medium" className="btn-push active" > <i css={globalCSS.icoPush} /> </XTButton>
`}
                  ></CodeMirror>
                </AccordionDetails>
              </Accordion>
            </div>
            <hr className="guide-hr" />
            {/* ------------------------------------------------ type D */}
            <div>
              <h3 className="guide-h3">
                Type D (클릭 시 코드 복사가 가능합니다)
              </h3>
              <div style={{ display: "flex", gap: "20px" }}>
                <XTButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  data-code='<XTButton variant="outlined" color="secondary" size="small" className="xs">Button</XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Button
                </XTButton>
                <XTButton
                  disabled
                  variant="outlined"
                  color="secondary"
                  size="small"
                  data-code='<XTButton disabled variant="outlined" color="secondary" size="small" className="xs">Button</XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Disabled
                </XTButton>
                <XTButton
                  variant="contained"
                  color="primary"
                  size="small"
                  data-code='<XTButton variant="contained" color="primary" size="small"> Button </XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Button
                </XTButton>
                <XTButton
                  disabled
                  variant="contained"
                  color="primary"
                  size="small"
                  data-code='<XTButton disabled variant="contained" color="primary" size="small"> Disabled </XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Disabled
                </XTButton>
              </div>
              <br />
              <div style={{ display: "flex", gap: "20px" }}>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="btn-datepicker"
                  endIcon={<i css={globalCSS.icoCalendar} />}
                  data-code='<XTButton variant="outlined" color="primary" size="small">Button</XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  2023-09-25
                </XTButton>
                <XTButton
                  disabled
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="btn-datepicker"
                  endIcon={<i css={globalCSS.icoCalendar} />}
                  data-code='<XTButton variant="outlined" color="primary" size="small">Button</XTButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  2023-09-25
                </XTButton>
              </div>
              <br />
              <Accordion
                sx={{
                  margin: 0,
                  p: 0,
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  "&::before": {
                    position: "relative",
                  },
                }}
                expanded={codemirror === "typeDBtn"}
                onChange={handleCodemirror("typeDBtn")}
                className="accordion-codemirror"
              >
                <AccordionSummary>
                  <p className="guide-codeMore">코드보기</p>
                </AccordionSummary>
                <AccordionDetails>
                  <CodeMirror
                    theme={vscodeDark}
                    extensions={[html()]}
                    value={`
import { XTButton } from "@/components/mui/xt-button";

/* Outlined Primary 버튼 */
<XTButton variant="outlined" color="primary" size="small" > Button </XTButton>  

/* Outlined Primary 버튼 disabled */
<XTButton disabled variant="outlined" color="primary" size="small" > Disabled </XTButton>  

/* Outlined Primary 버튼 text + icon */
<XTButton variant="outlined" color="primary" size="small" className="btn-datepicker" endIcon={<i css={globalCSS.icoCalendar} />} > 2023-09-25 </XTButton> 

/* Outlined Primary 버튼 text + icon disabled */ 
<XTButton disabled variant="outlined" color="primary" size="small" className="btn-datepicker" endIcon={<i css={globalCSS.icoCalendar} />} > 2023-09-25 </XTButton>

/* Contained Primary 버튼 */
<XTButton variant="contained" color="primary" size="small"> Button </XTButton>

/* Contained Primary 버튼 disabled */
<XTButton disabled variant="contained" color="primary" size="small"> Disabled </XTButton>
`}
                  ></CodeMirror>
                </AccordionDetails>
              </Accordion>
            </div>
            <hr className="guide-hr" />
            <div>
              <h3 className="guide-h3">Text button</h3>
              <div style={{ display: "flex", gap: "20px" }}>
                <XTTextButton
                  color="primary"
                  data-code='<XTTextButton color="primary">Text button</XTTextButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Text button
                </XTTextButton>
                <XTTextButton
                  color="info"
                  data-code='<XTTextButton color="info">Text button</XTTextButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Text button
                </XTTextButton>
                <XTTextButton
                  color="primary"
                  disabled
                  data-code='<XTTextButton color="primary" disabled>Text button</XTTextButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Text button
                </XTTextButton>
                <br />
                <br />
              </div>
              <Accordion
                sx={{
                  margin: 0,
                  p: 0,
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  "&::before": {
                    position: "relative",
                  },
                }}
                expanded={codemirror === "textbutton"}
                onChange={handleCodemirror("textbutton")}
                className="accordion-codemirror"
              >
                <AccordionSummary>
                  <p className="guide-codeMore">코드보기</p>
                </AccordionSummary>
                <AccordionDetails>
                  <CodeMirror
                    theme={vscodeDark}
                    extensions={[html()]}
                    value={`
import { XTTextButton } from "@/components/mui/xt-button";


/* 기본 Primary Text 버튼 */
<XTTextButton color="primary">Text button</XTTextButton>

/* 기본 Info Text 버튼 */
<XTTextButton color="info">Text button</XTTextButton>

/* 기본 Disabled Text 버튼 */
<XTTextButton color="primary" disabled>Text button</XTTextButton>

`}
                  ></CodeMirror>
                </AccordionDetails>
              </Accordion>
            </div>
            <hr className="guide-hr" />
            <div>
              <h3 className="guide-h3">Text button - underline</h3>
              <div style={{ display: "flex", gap: "20px" }}>
                <XTTextUnderlineButton
                  color="primary"
                  data-code='<XTTextUnderlineButton color="primary">Text button</XTTextUnderlineButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Text button
                </XTTextUnderlineButton>
                <XTTextUnderlineButton
                  color="info"
                  data-code='<XTTextUnderlineButton color="info">Text button</XTTextUnderlineButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Text button
                </XTTextUnderlineButton>
                <XTTextUnderlineButton
                  color="primary"
                  disabled
                  data-code='<XTTextUnderlineButton disabled color="primary">Text button</XTTextUnderlineButton>'
                  onClick={(e) =>
                    handleCody(e.currentTarget.dataset.code ?? "")
                  }
                >
                  Text button
                </XTTextUnderlineButton>
                <br />
                <br />
              </div>
              <Accordion
                sx={{
                  margin: 0,
                  p: 0,
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  "&::before": {
                    position: "relative",
                  },
                }}
                expanded={codemirror === "textbutton-underline"}
                onChange={handleCodemirror("textbutton-underline")}
                className="accordion-codemirror"
              >
                <AccordionSummary>
                  <p className="guide-codeMore">코드보기</p>
                </AccordionSummary>
                <AccordionDetails>
                  <CodeMirror
                    theme={vscodeDark}
                    extensions={[html()]}
                    value={`
import { XTTextUnderlineButton } from "@/components/mui/xt-button";


/* 기본 Primary Text 버튼 */
<XTTextUnderlineButton color="primary">Text button</XTTextUnderlineButton>

/* 기본 Info Text 버튼 */
<XTTextUnderlineButton color="info">Text button</XTTextUnderlineButton>

/* 기본 Disabled Text 버튼 */
<XTTextUnderlineButton disabled color="primary">Text button</XTTextUnderlineButton>

`}
                  ></CodeMirror>
                </AccordionDetails>
              </Accordion>
            </div>
            <hr className="guide-hr" />
            <div>
              <h3 className="guide-h3">Toggle</h3>
              <div style={{ display: "flex", gap: "20px" }}>
                <XTToggle
                  checked={checked}
                  onChange={handleChange}
                  onKeyUp={handleEnter}
                  inputProps={{
                    "aria-label":
                      "XT 기본 토글 버튼. 엔터키 입력 시 상태값이 변경됩니다.",
                  }}
                />
              </div>
              <br />
              <Accordion
                sx={{
                  margin: 0,
                  p: 0,
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  "&::before": {
                    position: "relative",
                  },
                }}
                expanded={codemirror === "toggle"}
                onChange={handleCodemirror("toggle")}
                className="accordion-codemirror"
              >
                <AccordionSummary>
                  <p className="guide-codeMore">코드보기</p>
                </AccordionSummary>
                <AccordionDetails>
                  <CodeMirror
                    theme={vscodeDark}
                    extensions={[html()]}
                    value={`
import { XTToggle } from "@/components/mui/xt-switch";


const [checked, setChecked] = useState(false);

const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setChecked(event.target.checked);
};
  
// 엔터키를 눌렀을 때 checked 값 변경
const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key == "Enter") setChecked((prev) => !prev);
};

// aria-label은 img의 alt와 같은 기능을 합니다. 시그넷은 다국어를 지원하지 않으므로 가급적 한국어로 입력 부탁드립니다.

<XTToggle
checked={checked}
onChange={handleChange}
onKeyUp={handleEnter}
inputProps={{
"aria-label":
"XT 기본 토글 버튼. 엔터키 입력 시 상태값이 변경됩니다.",
}}
/>


`}
                  ></CodeMirror>
                </AccordionDetails>
              </Accordion>
            </div>
            <hr className="guide-hr" />
            <div>
              <h3 className="guide-h3">File</h3>
              <div>
                <XTFileDownload
                  fileRoute={fileRoute}
                  fileName={fileName}
                  fileTitle={fileTitle}
                  fileExt={fileExt}
                  onClick={() => {
                    alert("download");
                  }}
                />
              </div>
              <br />
              <Accordion
                sx={{
                  margin: 0,
                  p: 0,
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  "&::before": {
                    position: "relative",
                  },
                }}
                expanded={codemirror === "file-dl"}
                onChange={handleCodemirror("file-dl")}
                className="accordion-codemirror"
              >
                <AccordionSummary>
                  <p className="guide-codeMore">코드보기</p>
                </AccordionSummary>
                <AccordionDetails>
                  <CodeMirror
                    theme={vscodeDark}
                    extensions={[html()]}
                    value={`

import { XTFileDownload } from "@/components/ui/file";

<XTFileDownload
  fileRoute={fileRoute} // 파일 경로
  fileName={fileName} // 다운로드될 때 파일명
  fileTitle={fileTitle} // 보여지는 파일명
  fileExt={fileExt} // 확장자
/>
`}
                  ></CodeMirror>
                </AccordionDetails>
              </Accordion>
            </div>
            <br />
            <hr className="guide-hr" />
          </div>
        </div>
      </div>
    </>
  );
}
