import {
  changeDatepickerDate,
  changeSubmitDate,
  formatYmdHisDot,
  regComma,
} from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import XTDatePicker from "@/components/mui/xt-datepicker";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import {
  XTMenuItem,
  XTMenuItemWrap,
  XTSelect,
} from "@/components/mui/xt-select";
import AlertDialog from "@/components/ui/alert-dialog";
import { DestructionSchFormType } from "@/types/log/destruction";
import {
  Box,
  Checkbox,
  Grid,
  InputLabel,
  LinearProgress,
  Pagination,
  Radio,
} from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-premium";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  useDestructionManager,
  useDestructionTypeManager,
} from "@/pages/log/destruction/_hook/use-destruction";
import moment from "moment";
import { XTCheckBox } from "@/components/mui/xt-checkbox";

export default function DestructionList() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    schParams,
    destructionData,
    updateSelect,
    updateAll,
    resetQuery,
    isFetching,
  } = useDestructionManager();
  // const { destructionData } = useDestructionTypeManager();

  const [schForm, setSchForm] = useState<DestructionSchFormType>(schParams);

  // initialData와 schForm을 상태로 설정
  const initialData: DestructionSchFormType = {
    schEndYmd: "",
    schStrtYmd: "",
    schTp: "",
    schMthd: "",
    pg: "1",
    pgSz: "10",
    sordFld: "rgstYmd",
    sordMetd: "DESC",
  };

  const [alertDialog, setAlertDialog] = useState({
    open: false,
    title: "",
    text: "",
    type: "complete",
    handleOK: () => {},
    handleClose: () => {},
  });

  const handleClose = () => {
    setAlertDialog({
      ...alertDialog,
      open: false,
    });
  };

  useEffect(() => {
    // let init: DestructionSchFormType = {
    //   schStrtYmd: params.get("schStrtYmd") || "",
    //   schEndYmd: params.get("schEndYmd") || "",
    //   schTp: params.get("schTp") || "",
    //   schMthd: params.get("schMthd") || "",
    //   pg: params.get("pg") || "1",
    //   pgSz: params.get("pgSz") || "10",
    //   sordFld: params.get("sordFld") || "rgstYmd",
    //   sordMetd: params.get("sordMetd") || "DESC",
    // };

    reset(schParams);
    setSchForm(schParams);
  }, [location.search]);

  const goPage = (form: DestructionSchFormType) => {
    // setSchForm(form);
    // reset(form);

    const queryStringResult = queryString.stringify(form);
    navigate("/log/destruction?" + queryStringResult);
  };

  const {
    register,
    trigger,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, submitCount },
  } = useForm<DestructionSchFormType>({
    shouldFocusError: false,
    defaultValues: schParams,
  });

  const handleChangeInput = useCallback((event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const data = getValues();
    let form = {
      ...data,
      pg: 1,
      pgSz: Number(value),
    };

    goPage(form);
  }, []);

  const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    const data = getValues();
    let form = {
      ...data,
      pg: value,
    };

    goPage(form);
  };

  const columns: GridColDef[] = [
    {
      field: "no",
      headerName: "번호",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return destructionData!.logPrivacyDeleteList?.number - params.row.index;
      },
    },
    {
      field: "tp",
      headerName: "파기 구분",
      width: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return destructionData?.LogPrivacyDeleteType[params.value];
      },
    },
    {
      field: "mthd",
      headerName: "파기 방법",
      width: 120,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return destructionData?.LogPrivacyDeleteMethod[params.value];
      },
    },
    {
      field: "cnt",
      headerName: "파기건수",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "formatModYmdHis",
      headerName: "확인일시",
      width: 180,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return formatYmdHisDot(params.row.modYmd, params.row.modHis);
      },
    },
    {
      field: "formatRgstYmdHis",
      headerName: "파기일시",
      width: 180,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return formatYmdHisDot(params.row.rgstYmd, params.row.rgstHis);
      },
    },
  ];

  const [selection, setSelection] = useState<string[]>([]);
  const selectConfirm = async () => {
    try {
      const res = await updateSelect.mutateAsync({
        seqArray: selection,
      });

      if (res) {
        setAlertDialog({
          open: true,
          title: "알림",
          text: "선택한 항목 확인 처리되었습니다.",
          type: "complete",
          handleOK: () => {
            setSelection([]);
            resetQuery();
            handleClose();
          },
          handleClose: () => {
            setSelection([]);
            resetQuery();
            handleClose();
          },
        });
      }
    } catch (error: any) {}
  };

  const handleSelectConfirm = async () => {
    if (selection.length === 0) {
      setAlertDialog({
        ...alertDialog,
        type: "complete",
        text: "확인할 항목을 먼저 선택해주세요.",
        open: true,
        handleOK: () => handleClose(),
        handleClose: () => handleClose(),
      });
      return;
    }
    setAlertDialog({
      ...alertDialog,
      type: "confirm",
      text: "선택한 항목을 확인 처리하시겠습니까?",
      open: true,
      handleOK: () => selectConfirm(),
      handleClose: () => handleClose(),
    });
  };

  const allConfirm = async () => {
    try {
      const res = await updateAll.mutateAsync();

      if (res) {
        setAlertDialog({
          open: true,
          title: "알림",
          text: "전체 항목 확인 처리되었습니다.",
          type: "complete",
          handleOK: () => {
            resetQuery();
            handleClose();
          },
          handleClose: () => {
            resetQuery();
            handleClose();
          },
        });
      }
    } catch (error: any) {}
  };

  const handleAllConfirm = async () => {
    setAlertDialog({
      ...alertDialog,
      type: "confirm",
      text: "전체 항목을 확인 처리하시겠습니까?",
      open: true,
      handleOK: () => allConfirm(),
      handleClose: () => handleClose(),
    });
  };

  const handleClickDateButton = useCallback(
    (month: string | null) => {
      let startDate = "";
      let endDate = "";

      if (month) {
        startDate = moment().subtract(month, "months").format("YYYYMMDD");
        endDate = moment().format("YYYYMMDD");
      }

      reset({
        ...getValues(),
        pg: 1,
        schStrtYmd: startDate,
        schEndYmd: endDate,
      });
    },
    [getValues()]
  );

  const handleReset = () => {
    goPage(initialData);
  };

  const onSubmit = (data: DestructionSchFormType) => {
    let form = {
      ...data,
      pg: 1,
    };

    goPage(form);
  };

  const onError = (data) => {
    console.log("error", data);
  };

  return (
    <>
      <main id="" css={globalCSS.listContainer} className="only-list">
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>개인정보 파기 관리</h2>
            </div>
            <div className="b-sub">
              <span>홈</span>
              <span>개인정보 관리</span>
              <span>개인정보 파기 관리 목록</span>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="cont-wrap">
            <div className="search-wrap">
              <table css={globalCSS.formTable}>
                <colgroup>
                  <col style={{ width: "200px" }} />
                  <col />
                  <col style={{ width: "200px" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <InputLabel>파기일자</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Grid container spacing={1} className="form-grid">
                        <Grid item>
                          <Controller
                            name="schStrtYmd"
                            control={control}
                            render={({ field }) => (
                              <XTDatePicker
                                value={watch("schStrtYmd")}
                                onChange={(newDate) => {
                                  field.onChange(newDate);
                                }}
                                maxDate={watch("schEndYmd")}
                                placeholder="시작일을 선택하세요."
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <div className="divider">
                            <span>~</span>
                          </div>
                        </Grid>
                        <Grid item>
                          <Controller
                            name="schEndYmd"
                            control={control}
                            render={({ field }) => (
                              <XTDatePicker
                                value={watch("schEndYmd")}
                                minDate={watch("schStrtYmd")}
                                onChange={(newDate) => {
                                  field.onChange(newDate);
                                }}
                                placeholder="종료일을 선택하세요."
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <div className="date-btn-group">
                            <XTButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className="xs"
                              onClick={() => handleClickDateButton("1")}
                            >
                              1개월
                            </XTButton>
                            <XTButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className="xs"
                              onClick={() => handleClickDateButton("3")}
                            >
                              3개월
                            </XTButton>
                            <XTButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className="xs"
                              onClick={() => handleClickDateButton("12")}
                            >
                              1년
                            </XTButton>
                            <XTButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className="xs"
                              onClick={() => handleClickDateButton("")}
                            >
                              전체
                            </XTButton>
                          </div>
                        </Grid>
                      </Grid>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>파기 구분</InputLabel>
                    </th>
                    <td>
                      {destructionData && (
                        <Controller
                          name="schTp"
                          control={control}
                          render={({ field }) => (
                            <XTRadioGroup row defaultValue="" {...field}>
                              <XTRadio
                                value={""}
                                control={<Radio />}
                                label={"전체"}
                              />
                              {Object.keys(
                                destructionData!.LogPrivacyDeleteType
                              ).map((key: string) => (
                                <XTRadio
                                  key={key}
                                  value={key}
                                  control={<Radio />}
                                  label={
                                    destructionData!.LogPrivacyDeleteType[
                                      key.toString()
                                    ]
                                  }
                                />
                              ))}
                            </XTRadioGroup>
                          )}
                        />
                      )}
                    </td>
                    <th scope="row">
                      <InputLabel>파기 방법</InputLabel>
                    </th>
                    <td>
                      {destructionData && (
                        <Controller
                          name="schMthd"
                          control={control}
                          render={({ field }) => (
                            <XTRadioGroup row defaultValue="" {...field}>
                              <XTRadio
                                value={""}
                                control={<Radio />}
                                label={"전체"}
                              />
                              {Object.keys(
                                destructionData!.LogPrivacyDeleteMethod
                              ).map((key: string) => (
                                <XTRadio
                                  key={key}
                                  value={key}
                                  control={<Radio />}
                                  label={
                                    destructionData!.LogPrivacyDeleteMethod[
                                      key.toString()
                                    ]
                                  }
                                />
                              ))}
                            </XTRadioGroup>
                          )}
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="action-button-group">
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={handleReset}
                >
                  초기화
                </XTButton>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  type="submit"
                >
                  조회
                </XTButton>
              </div>
            </div>
            <div className="grid-wrap">
              <div className="total-wrap">
                <div className="total-cnt">
                  전체{" "}
                  <span>
                    {regComma(destructionData?.logPrivacyDeleteList.totalSize)}
                  </span>
                  건
                </div>
                <XTSelect
                  defaultValue={10}
                  value={schForm.pgSz}
                  onChange={handleChangeInput}
                  sx={{
                    minWidth: 150,
                  }}
                  MenuProps={{
                    MenuListProps: { sx: { padding: 0 } },
                    PaperProps: {
                      sx: {
                        ...XTMenuItemWrap,
                      },
                    },
                  }}
                >
                  <XTMenuItem value={10}>10개</XTMenuItem>
                  <XTMenuItem value={20}>20개</XTMenuItem>
                  <XTMenuItem value={30}>30개</XTMenuItem>
                  <XTMenuItem value={50}>50개</XTMenuItem>
                  <XTMenuItem value={100}>100개</XTMenuItem>
                </XTSelect>
              </div>
              <XTDataGrid
                className="clickable"
                loading={isFetching}
                columns={columns}
                rows={destructionData?.logPrivacyDeleteList?.list || []}
                experimentalFeatures={{
                  ariaV7: true,
                }}
                onRowClick={(params) => {
                  navigate(`/log/destruction/detail/${params.row.seq}`);
                }}
                getRowId={(params) => params.seq}
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={selection}
                onRowSelectionModelChange={(newSelection) => {
                  setSelection(newSelection as string[]);
                }}
                isRowSelectable={(params) =>
                  params.row.modYn === "N" ? true : false
                }
                slots={{
                  noRowsOverlay: () => (
                    <div className="no-data">등록된 내용이 없습니다.</div>
                  ),
                  noResultsOverlay: () => (
                    <div className="no-data">등록된 내용이 없습니다.</div>
                  ),
                  baseCheckbox: (props) => (
                    <XTCheckBox
                      control={
                        <Checkbox icon={<span className="checkbox-icon" />} />
                      }
                      {...props}
                    />
                  ),
                  loadingOverlay: LinearProgress,
                }}
                rowHeight={38}
                columnHeaderHeight={38}
                hideFooter
              />
            </div>
          </div>
          <Box css={globalCSS.bottomWrap}>
            <Pagination
              page={Number(schForm.pg)}
              count={destructionData?.logPrivacyDeleteList.totalPageSize}
              onChange={handleChangePage}
            />
            <Grid container justifyContent="end" spacing={2}>
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={handleSelectConfirm}
                >
                  선택 확인
                </XTButton>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={handleAllConfirm}
                >
                  전체 확인
                </XTButton>
              </Grid>
            </Grid>
          </Box>
          <AlertDialog
            open={alertDialog.open}
            text={alertDialog.text}
            type={alertDialog.type}
            handleOK={alertDialog.handleOK}
            handleClose={alertDialog.handleClose}
            preventCloseEvent={true}
          />
        </form>
      </main>
    </>
  );
}
