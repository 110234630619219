import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import { Switch } from "@mui/material";

export const XTSwitch = styled(Switch)(({ theme }) => ({
  padding: 1,
  border: `solid 1px ${theme.palette.primary.dark}`,
  width: theme.typography.pxToRem(60),
  height: theme.typography.pxToRem(30),
  borderRadius: theme.typography.pxToRem(18),

  "& .MuiSwitch-switchBase": {
    position: "relative",
    padding: 0,
    margin: 0,
    // transitionDuration: "300ms",
    transition: "0.2s",
    color: `${theme.palette.primary.dark} !important`,
    "&.Mui-checked": {
      transform: `translateX(${theme.typography.pxToRem(30)})`,
      "& + .MuiSwitch-track": {
        background: "transparent",
      },
    },

    "&.Mui-disabled .MuiSwitch-thumb": {},
    "&.Mui-disabled + .MuiSwitch-track": {},
  },
  "& .MuiSwitch-thumb": {
    width: theme.typography.pxToRem(26),
    height: theme.typography.pxToRem(26),
  },
  "& .MuiSwitch-track": {
    background: "transparent",
  },

  //   "& .MuiSwitch-switchBase": {
  //     padding: 0,
  //     // width: "100%",
  //     // height: "100%",
  //     background: "transparent",
  //   },
}));

export const XTToggle = styled(Switch)(({ theme }) => ({
  width: theme.typography.pxToRem(60),
  height: theme.typography.pxToRem(30),
  padding: 0,
  borderRadius: "30px",
  "& .Mui-focusVisible": {
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      outline: "solid #00f",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transitionDuration: "300ms",
    "&:hover": {
      backgroundColor: theme.palette.transparent,
    },
    "&.Mui-checked": {
      transform: "translateX(0px)  translateY(-50%)",
      color: theme.palette.white,
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.light_03,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
      "& .MuiSwitch-thumb": {
        transform: "translateX(30px)",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: theme.palette.primary.light_03,
      border: "none",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {},
    "&.Mui-disabled + .MuiSwitch-track": {},
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
    boxShadow: "none",
    transition: "transform 0.3s",
  },
  "& .MuiButtonBase-root": {
    top: "50%",
    transform: "translateY(-50%)",
    left: "3px",
  },
  "& .MuiSwitch-track": {
    borderRadius: "30px",
    backgroundColor: theme.palette.bg_05,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
  "& .MuiSwitch-input.PrivateSwitchBase-input": {
    left: "0",
  },
}));
