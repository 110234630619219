import {
  deleteFaqAPI,
  loadFaqAPI,
  loadFaqListAPI,
  loadFaqTypeAPI,
  registFaqAPI,
  updateFaqAPI,
} from "@/api/faq";
import {
  FaqListType,
  FaqSchFormType,
  FaqTypesType,
  FaqDetailType,
} from "@/types/faq";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const useFaqManager = () => {
  const queryClient = useQueryClient();
  const [faqData, setFaqData] = useState<FaqListType | null>(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let schParams: FaqSchFormType = {
    schFldYmd: params.get("schFldYmd") || "rgstYmd",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schUseYn: params.get("schUseYn") || "",
    schFld: params.get("schFld") || "",
    schTxt: params.get("schTxt") || "",
    pg: params.get("pg") || "1",
    pgSz: params.get("pgSz") || "10",
    sordFld: params.get("sordFld") || "rgstYmd",
    sordMetd: params.get("sordMetd") || "DESC",
  };

  const {
    data: faqList,
    isFetching,
    isSuccess,
  } = useQuery<
    AxiosResponse<{
      data: FaqListType;
    }>,
    AxiosError,
    FaqListType
  >({
    queryKey: ["loadFaqList", schParams],
    queryFn: async () => await loadFaqListAPI(schParams),
    select: (res) => {
      res.data.data?.faqList.list.map((item: any, i: number) => {
        item.index = i;
      });
      return res.data.data;
    },
  });

  const resetQuery = () => {
    queryClient.resetQueries({ queryKey: ["loadFaqList"] });
  };

  useEffect(() => {
    if (faqList) setFaqData(faqList);
  }, [isSuccess, faqList]);

  return {
    schParams,
    isFetching,
    isSuccess,
    faqData,
    resetQuery,
  };
};

export const useFaqDetailManager = () => {
  const params = useParams();
  const queryClient = useQueryClient();
  const {
    data: faqData,
    isFetching,
    isSuccess,
  } = useQuery<
    AxiosResponse<{
      data: FaqDetailType;
    }>,
    AxiosError,
    FaqDetailType
  >({
    queryKey: ["loadFaq", params.seq],
    queryFn: async () => await loadFaqAPI(params.seq!),
    enabled: !!params.seq,
    select: (res) => {
      return res.data.data;
    },
  });

  const registFaq = useMutation({
    mutationFn: (form: any) => registFaqAPI(form),
  });

  const updateFaq = useMutation({
    mutationFn: (form: any) => updateFaqAPI(form),
  });

  const deleteFaq = useMutation({
    mutationFn: (form: any) => deleteFaqAPI(form),
  });

  const resetQuery = () => {
    queryClient.resetQueries({ queryKey: ["loadFaq"] });
  };

  return {
    isFetching,
    isSuccess,
    faqData,
    registFaq,
    updateFaq,
    deleteFaq,
    resetQuery,
  };
};

export const useFaqTypeManager = () => {
  const { data: faqTypes } = useQuery<
    AxiosResponse<{
      data: FaqTypesType;
    }>,
    AxiosError,
    FaqTypesType
  >({
    queryKey: ["loadFaqTypes"],
    queryFn: async () => await loadFaqTypeAPI(),
    select: (res) => {
      return res.data.data;
    },
  });

  return { faqTypes };
};
