import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  PermissionListSchFormType,
  PermissionListType,
} from "@/types/system/permission";
import { loadPermissionListAPI } from "@/api/system/permission";
import { changeSubmitDate } from "@/assets/ts/common";
import moment from "moment";
import {
  loadPermissionDetailAPI,
  registPermissionAPI,
  removePermissionAPI,
  updatePermissionAPI,
} from "@/api/system/permission";
import {
  PermissionDetailType,
  PermissionRegistParamsType,
} from "@/types/system/permission";
import { useQuery, useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";

export const usePermissionList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const initialSchFormData: PermissionListSchFormType = {
    pg: params.get("pg") || "1",
    pgSz: params.get("pgSz") || "10",
    sordFld: params.get("sordFld") || "",
    sordMetd: params.get("sordMetd") || "DESC",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schUseYn: params.get("schUseYn") || "",
    schFld: params.get("schFld") || " ",
    schTxt: params.get("schTxt") || "",
  };

  const [schForm, setSchForm] = useState({
    pg: params.get("pg") || "1",
    pgSz: params.get("pgSz") || "10",
    sordFld: params.get("sordFld") || "",
    sordMetd: params.get("sordMetd") || "DESC",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schUseYn: params.get("schUseYn") || "",
    schFld: params.get("schFld") || " ",
    schTxt: params.get("schTxt") || "",
  });

  const [schViewForm, setSchViewForm] = useState({
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schUseYn: params.get("schUseYn") || "",
    schTxt: params.get("schTxt") || "",
  });

  const initialData: PermissionListType = {
    authList: {
      list: [],
      pageSize: 0,
      totalSize: 0,
      idx: 0,
      page: 0,
      number: 0,
      nextPageAvailable: false,
      previousPageAvailable: false,
      firstPage: false,
      pageIndex: 0,
      middlePage: false,
      lastPage: false,
      totalPageSize: 1,
      empty: false,
    },
  };

  const {
    data: listData = initialData,
    isFetching,
    refetch,
  } = useQuery<
    AxiosResponse<{
      data: PermissionListType;
    }>,
    AxiosError,
    PermissionListType
  >({
    queryKey: ["loadPermissionList", schForm],
    queryFn: async () => await loadPermissionListAPI(schForm),
    select: (res) => {
      let list = res.data.data.authList.list;

      list.map((item, i) => {
        item.id = item.seq;
        item.index = i;
      });

      return res.data.data;
    },
  });

  const handleResetInput = () => {
    let resetForm = {
      pg: "1",
      pgSz: "10",
      sordFld: "rgstYmd",
      sordMetd: "DESC",
      schStrtYmd: "",
      schEndYmd: "",
      schUseYn: "",
      schFld: " ",
      schTxt: "",
    };

    setSchForm(resetForm);
    setSchViewForm({
      schStrtYmd: "",
      schEndYmd: "",
      schUseYn: "",
      schTxt: "",
    });

    const queryStringResult = queryString.stringify(resetForm);
    navigate("/system/permission?" + queryStringResult);
  };

  const handleChangeInput = (event: any) => {
    const target = event.target;
    const name = target.name;

    setSchViewForm((prev) => ({
      ...prev,
      [name]: target.value,
    }));
  };

  const handleChangeDatePicker = (name: string) => (value: Date) => {
    setSchViewForm((prev) => ({
      ...prev,
      pg: "1",
      [name]: changeSubmitDate(value),
    }));
  };

  const handleClickDateButton = (month: string | null) => {
    let startDate = "";
    let endDate = "";

    if (month) {
      startDate = moment().subtract(month, "months").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    }

    setSchViewForm((prev) => ({
      ...prev,
      pg: "1",
      schStrtYmd: changeSubmitDate(startDate),
      schEndYmd: changeSubmitDate(endDate),
    }));
  };

  const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    let form = {
      ...schForm,
      pg: value.toString(),
    };
    setSchForm(form);

    const queryStringResult = queryString.stringify(form);
    navigate("/system/permission?" + queryStringResult);
  };

  const handleChangePageSz = (event: SelectChangeEvent<unknown>) => {
    const target = event.target;
    const name = target.name;

    if (typeof name === "string") {
      let form = {
        ...schForm,
        pg: "1",
        [name]: target.value,
      };
      setSchForm(form);

      const queryStringResult = queryString.stringify(form);
      navigate("/system/permission?" + queryStringResult);
    }
  };

  const handleSearch = () => {
    let form = {
      pg: "1",
      pgSz: "10",
      sordFld: "",
      sordMetd: "DESC",
      schFld: " ",
      schStrtYmd: schViewForm.schStrtYmd,
      schEndYmd: schViewForm.schEndYmd,
      schUseYn: schViewForm.schUseYn,
      schTxt: schViewForm.schTxt,
    };

    setSchForm(form);
    const queryStringResult = queryString.stringify(form);
    navigate("/system/permission?" + queryStringResult);
  };

  useEffect(() => {
    setSchForm({
      ...initialSchFormData,
    });
    setSchViewForm({
      ...initialSchFormData,
    });
  }, [location.search]);

  return {
    listData,
    isFetching,
    schForm,
    schViewForm,
    handleChangeInput,
    handleChangePage,
    handleChangePageSz,
    handleClickDateButton,
    handleResetInput,
    handleChangeDatePicker,
    handleSearch,
    navigate,
  };
};

export const usePermissionDetail = () => {
  let { seq } = useParams();

  const { data: detailData } = useQuery<
    AxiosResponse<{
      data: PermissionDetailType;
    }>,
    AxiosError,
    PermissionDetailType
  >({
    queryKey: ["loadPermissionDetail", seq],
    queryFn: async () => await loadPermissionDetailAPI(seq!),
    select: (res) => {
      return res.data.data;
    },
    enabled: seq ? true : false,
  });

  const regist = useMutation({
    mutationFn: (form: PermissionRegistParamsType) => registPermissionAPI(form),
  });

  const update = useMutation({
    mutationFn: (form: PermissionRegistParamsType) => updatePermissionAPI(form),
  });

  const deleted = useMutation({
    mutationFn: (seq: string) => removePermissionAPI(seq),
  });

  return {
    seq,
    detailData,
    regist,
    update,
    deleted,
  };
};
