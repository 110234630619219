import {
  changeDataSubmitDateTime,
  formatYmdHisDot,
  getSubmitFileList,
  maxLen,
  regComma,
} from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";

import { Grid, InputAdornment, InputLabel, Radio } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AlertDialog from "@/components/ui/alert-dialog";
import { useEffect, useState } from "react";
import {
  useNoticeDetailManager,
  useNoticeTypeManager,
} from "@/pages/notice/_hook/use-notice";
import { Controller, useForm } from "react-hook-form";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import { NoticeFormType } from "@/types/notice";
import XTDatePicker from "@/components/mui/xt-datepicker";
import {
  XTFormControl,
  XTFormHelperText,
  XTInput,
  XTTextarea,
} from "@/components/mui/xt-input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { XTFileUpload } from "@/components/mui/xt-fileform";
import moment from "moment";

export default function NoticeDetail() {
  const navigate = useNavigate();
  const { noticeData, registNotice, updateNotice, deleteNotice } =
    useNoticeDetailManager();
  const { noticeTypes } = useNoticeTypeManager();
  const params: any = useParams();

  const [alertDialog, setAlertDialog] = useState({
    open: false,
    title: "",
    text: "",
    type: "complete",
    handleOK: () => {},
    handleClose: () => handleCloseDialog(),
  });

  const handleCloseDialog = () => {
    setAlertDialog({
      ...alertDialog,
      open: false,
    });
  };

  const schema = yup
    .object()
    .shape({
      ttl: yup.string().trim().required("제목을 입력해 주세요."),
      cont: yup.string().trim().required("내용을 입력해 주세요."),
      usePrdStrtYmd: yup.mixed().when("usePrdYn", {
        is: "Y",
        then: yup.string().nullable().required("게시기간을 선택해주세요."),
        otherwise: yup.mixed(),
      }),
      usePrdEndYmd: yup.mixed().when("usePrdYn", {
        is: "Y",
        then: yup.string().nullable().required("게시기간을 선택해주세요."),
        otherwise: yup.mixed(),
      }),
      noticeNotification: yup.mixed().when("notfUseYn", {
        is: "Y",
        then: yup.object().shape({
          ttl: yup.mixed().when("ttlSameYn", {
            is: "N",
            then: yup
              .string()
              .trim()
              .required("알림함 노출내용을 입력해주세요."),
            otherwise: yup.mixed(),
          }),
          usePrdStrtYmd: yup.mixed().when("usePrdYn", {
            is: "Y",
            then: yup
              .string()
              .nullable()
              .required("알림함 노출 기간을 선택해주세요."),
            otherwise: yup.mixed(),
          }),
          usePrdEndYmd: yup.mixed().when("usePrdYn", {
            is: "Y",
            then: yup
              .string()
              .nullable()
              .required("알림함 노출 기간을 선택해주세요."),
            otherwise: yup.mixed(),
          }),
        }),
        otherwise: yup.object(),
      }),
    })
    .required();

  const {
    register,
    trigger,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, submitCount },
  } = useForm<NoticeFormType>({
    shouldFocusError: false,
    defaultValues: {
      seq: "",
      useYn: "Y",
      usePrdYn: "N",
      // 사용 기간 여부
      usePrdStrtYmd: "",
      // 사용 기간 시작 일자
      usePrdStrtHis: "",
      // 사용 기간 시작 시간
      usePrdEndYmd: "",
      // 사용 기간 종료 일자
      usePrdEndHis: "",
      // 사용 기간 종료 시간
      ttl: "",
      // 제목
      cont: "",
      notfUseYn: "N",
      noticeNotification: {
        tp: "2",
        usePrdYn: "N",
        usePrdStrtYmd: "",
        usePrdStrtHis: "",
        usePrdEndYmd: "",
        // 사용 기간 종료 일자
        usePrdEndHis: "",
        // 사용 기간 종료 시간
        ttl: "",
        ttlSameYn: "Y",
      },
      noticeFileList: [
        {
          seq: "",
          uri: "",
          orgNm: "",
          ext: "",
          capa: 0,
        },
      ],
      noticeImageList: [
        {
          seq: "",
          uri: "",
          orgNm: "",
          ext: "",
          capa: 0,
        },
      ],
    },
    resolver: yupResolver(schema),
  });

  const onDeleteNotice = async () => {
    try {
      let res = await deleteNotice.mutateAsync(params.seq);
      if (res) {
        setAlertDialog({
          ...alertDialog,
          open: true,
          type: "complete",
          text: "삭제되었습니다.",
          handleOK: () => {
            navigate(-1);
          },
          handleClose: () => {
            navigate(-1);
          },
        });
      }
    } catch (e) {
      setAlertDialog({
        ...alertDialog,
        open: true,
        type: "complete",
        text: `삭제를 실패했습니다.`,
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
      });
    }
  };

  const handleDelete = () => {
    setAlertDialog({
      ...alertDialog,
      open: true,
      type: "confirm",
      text: "삭제 하시겠습니까?",
      handleOK: () => {
        onDeleteNotice();
      },
      handleClose: () => {
        setAlertDialog({
          ...alertDialog,
          open: false,
        });
      },
    });
  };

  const handleChangeRadio = (e, type: number = 1) => {
    if (type === 1) {
      setValue("usePrdYn", e.target.value);
      if (e.target.value === "Y") {
        setValue("usePrdStrtYmd", watch("usePrdStrtYmd"));
        setValue("usePrdEndYmd", watch("usePrdEndYmd"));
      } else {
        setValue("usePrdStrtYmd", "");
        setValue("usePrdEndYmd", "");
      }

      if (submitCount > 0) {
        trigger(["usePrdStrtYmd", "usePrdEndYmd"]);
      }
    } else {
      setValue("noticeNotification.usePrdYn", e.target.value);
      if (e.target.value === "Y") {
        setValue(
          "noticeNotification.usePrdStrtYmd",
          watch("noticeNotification.usePrdStrtYmd")
        );
        setValue(
          "noticeNotification.usePrdEndYmd",
          watch("noticeNotification.usePrdEndYmd")
        );
      } else {
        setValue("noticeNotification.usePrdStrtYmd", "");
        setValue("noticeNotification.usePrdEndYmd", "");
      }

      if (submitCount > 0) {
        trigger([
          "noticeNotification.usePrdStrtYmd",
          "noticeNotification.usePrdEndYmd",
        ]);
      }
    }
  };
  const handleDataSubmit = async () => {
    let data = getValues();

    let a = data.usePrdStrtYmd.split(" ")[0];

    let form = {
      ...data,
      usePrdStrtYmd: changeDataSubmitDateTime(data.usePrdStrtYmd, "start")[0],
      usePrdStrtHis: changeDataSubmitDateTime(data.usePrdStrtYmd, "start")[1],
      usePrdEndYmd: changeDataSubmitDateTime(data.usePrdEndYmd, "end")[0],
      usePrdEndHis: changeDataSubmitDateTime(data.usePrdEndYmd, "end")[1],
      noticeNotification: {
        ...data.noticeNotification,
        ttl:
          data.noticeNotification.ttlSameYn === "Y"
            ? data.ttl
            : data.noticeNotification.ttl,
        usePrdStrtYmd:
          data.noticeNotification.usePrdYn === "Y"
            ? changeDataSubmitDateTime(
                data.noticeNotification.usePrdStrtYmd,
                "start"
              )[0]
            : "",
        usePrdStrtHis:
          data.noticeNotification.usePrdYn === "Y"
            ? changeDataSubmitDateTime(
                data.noticeNotification.usePrdStrtYmd,
                "start"
              )[1]
            : "",
        usePrdEndYmd:
          data.noticeNotification.usePrdYn === "Y"
            ? changeDataSubmitDateTime(
                data.noticeNotification.usePrdEndYmd,
                "end"
              )[0]
            : "",
        usePrdEndHis:
          data.noticeNotification.usePrdYn === "Y"
            ? changeDataSubmitDateTime(
                data.noticeNotification.usePrdEndYmd,
                "end"
              )[1]
            : "",
      },
      noticeFileList: getSubmitFileList("FILE", data.noticeFileList),
      noticeImageList: getSubmitFileList("IMAGE", data.noticeImageList),
    };

    try {
      let res = {};

      if (data.seq) {
        res = await updateNotice.mutateAsync(form);
      } else {
        res = await registNotice.mutateAsync(form);
      }

      if (res) {
        setAlertDialog({
          open: true,
          title: "알림",
          text: `${data.seq ? "수정" : "등록"} 되었습니다.`,
          type: "complete",
          handleOK: () => {
            navigate(-1);
          },
          handleClose: () => {
            navigate(-1);
          },
        });
      }
    } catch (error: any) {
      setAlertDialog({
        ...alertDialog,
        open: true,
        type: "complete",
        text: `${data.seq ? "수정" : "등록"}을 실패했습니다.`,
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
      });
    }
  };
  const onSubmit = (data: NoticeFormType) => {
    let seq = data.seq;

    const isStartBefore = moment(
      data.noticeNotification.usePrdStrtYmd
    ).isBefore(data.usePrdStrtYmd);

    const isEndBefore = moment(data.noticeNotification.usePrdEndYmd).isAfter(
      data.usePrdEndYmd
    );

    if (data.useYn === "N" && data.notfUseYn === "Y") {
      setAlertDialog({
        ...alertDialog,
        open: true,
        type: "complete",
        text: "게시하지 않는 경우 알림함에 노출할 수 없습니다.",
        handleOK: () => {
          handleCloseDialog();
        },
        handleClose: () => {
          handleCloseDialog();
        },
      });

      return;
    } else if (
      data.usePrdYn === "Y" &&
      data.notfUseYn === "Y" &&
      data.noticeNotification.usePrdYn === "Y" &&
      (isStartBefore || isEndBefore)
    ) {
      setAlertDialog({
        ...alertDialog,
        open: true,
        type: "complete",
        text: "알림함 노출 기간은 게시기간 내에서 설정 가능합니다.",
        handleOK: () => {
          handleCloseDialog();
        },
        handleClose: () => {
          handleCloseDialog();
        },
      });

      return;
    }

    setAlertDialog({
      ...alertDialog,
      open: true,
      type: "confirm",
      text: `${seq ? "수정" : "등록"} 하시겠습니까?`,
      handleOK: () => handleDataSubmit(),
      handleClose: () => {
        setAlertDialog({
          ...alertDialog,
          open: false,
        });
      },
    });
  };

  const handleCancel = (seq: string) => {
    if (seq) {
      navigate(-1);
    } else {
      setAlertDialog({
        ...alertDialog,
        type: "confirm",
        text: "취소하시겠습니까?",
        open: true,
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            type: "complete",
            text: "취소되었습니다.",
            open: true,
            handleOK: () => {
              navigate(-1);
            },
            handleClose: () => {
              navigate(-1);
            },
          });
        },
        handleClose: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
      });
    }
  };

  useEffect(() => {
    if (noticeData && noticeData.notice) {
      reset({
        seq: noticeData.notice.seq || "",
        useYn: noticeData.notice.useYn,
        usePrdYn: noticeData.notice.usePrdYn,
        usePrdStrtYmd: noticeData.notice.usePrdStrtYmd
          ? `${noticeData.notice.usePrdStrtYmd} ${noticeData.notice.usePrdStrtHis}`
          : "",
        usePrdStrtHis: noticeData.notice.usePrdStrtHis ?? "",
        usePrdEndYmd: noticeData.notice.usePrdEndYmd
          ? `${noticeData.notice.usePrdEndYmd} ${noticeData.notice.usePrdEndHis}`
          : "",
        usePrdEndHis: noticeData.notice.usePrdEndHis,
        ttl: noticeData.notice.ttl,
        cont: noticeData.notice.cont,
        notfUseYn: noticeData.notice.notfUseYn,
        noticeNotification:
          noticeData.notice.notfUseYn === "Y"
            ? {
                tp: noticeData.notice.noticeNotification?.tp,
                usePrdYn: noticeData.notice.noticeNotification?.usePrdYn,
                usePrdStrtYmd: noticeData.notice.noticeNotification
                  ?.usePrdStrtYmd
                  ? `${noticeData.notice.noticeNotification?.usePrdStrtYmd} ${noticeData.notice.noticeNotification?.usePrdStrtHis}`
                  : "",
                usePrdStrtHis:
                  noticeData.notice.noticeNotification?.usePrdStrtHis ?? "",
                usePrdEndYmd: noticeData.notice.noticeNotification?.usePrdEndYmd
                  ? `${noticeData.notice.noticeNotification?.usePrdEndYmd} ${noticeData.notice.noticeNotification?.usePrdEndHis}`
                  : "",
                usePrdEndHis:
                  noticeData.notice.noticeNotification?.usePrdEndHis ?? "",
                ttl: noticeData.notice.noticeNotification?.ttl,
                ttlSameYn: noticeData.notice.noticeNotification?.ttlSameYn,
              }
            : {
                tp: "2",
                usePrdYn: "N",
                usePrdStrtYmd: "",
                usePrdStrtHis: "",
                usePrdEndYmd: "",
                // 사용 기간 종료 일자
                usePrdEndHis: "",
                // 사용 기간 종료 시간
                ttl: "",
                ttlSameYn: "Y",
              },
        noticeFileList:
          noticeData.notice.noticeFileList.length > 0
            ? noticeData.notice.noticeFileList.map((file) => file.attachFile)
            : [
                {
                  seq: "",
                  uri: "",
                  orgNm: "",
                  ext: "",
                  capa: 0,
                },
              ],
        noticeImageList:
          noticeData.notice.noticeImageList.length > 0
            ? noticeData.notice.noticeImageList.map((file) => file.attachImage)
            : [
                {
                  seq: "",
                  uri: "",
                  orgNm: "",
                  ext: "",
                  capa: 0,
                },
              ],
      });
    }
  }, [noticeData]);
  // if (isFetching) return <></>;
  return (
    <main id="sample" css={globalCSS.listContainer}>
      <div className="title-wrap">
        <div className="breadcrumbs">
          <div className="b-main">
            <h2>공지사항</h2>
          </div>
          <div className="b-sub">
            <span>홈</span>
            <span>공지사항</span>
            <span>상세</span>
          </div>
        </div>
      </div>
      <div className="cont-wrap">
        <div className="cont-inner">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="sub-title">
              공지사항 정보(<span className="color-point">*</span> 필수항목)
            </h3>
            <table css={globalCSS.formTable}>
              <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "40%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "40%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputLabel required>제목</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Controller
                      name="ttl"
                      control={control}
                      render={({ field }) => (
                        <XTInput
                          {...field}
                          fullWidth
                          placeholder="제목을 입력해 주세요."
                          inputProps={{ maxLength: 100 }}
                          onChange={(e) => {
                            let value = maxLen(e.target.value, 100);
                            field.onChange(value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <span
                                className="count"
                                css={globalCSS.inputLength}
                              >
                                {watch("ttl").length}&nbsp;/&nbsp;100
                              </span>
                            </InputAdornment>
                          }
                        />
                      )}
                    />
                    {errors.ttl?.message && (
                      <XTFormHelperText className="error">
                        {errors.ttl?.message}
                      </XTFormHelperText>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel required>게시여부</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Controller
                      name="useYn"
                      control={control}
                      render={({ field }) => (
                        <XTRadioGroup row {...field}>
                          <XTRadio
                            value={"Y"}
                            control={<Radio />}
                            label={"Y"}
                          />
                          <XTRadio
                            value={"N"}
                            control={<Radio />}
                            label={"N"}
                          />
                        </XTRadioGroup>
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel required>게시기간</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container className="form-grid" spacing={2}>
                      <Grid item>
                        <Controller
                          name="usePrdYn"
                          control={control}
                          render={({ field }) => (
                            <XTRadioGroup
                              row
                              {...field}
                              onChange={(e) => handleChangeRadio(e, 1)}
                            >
                              <XTRadio
                                value="N"
                                control={<Radio />}
                                label={"상시"}
                              />
                              <XTRadio
                                value="Y"
                                control={<Radio />}
                                label={"기간 선택"}
                              />
                            </XTRadioGroup>
                          )}
                        />
                      </Grid>
                      {watch("usePrdYn") == "Y" && (
                        <>
                          <Grid item>
                            <Controller
                              name="usePrdStrtYmd"
                              control={control}
                              render={({ field }) => (
                                <XTDatePicker
                                  timePicker={true}
                                  value={watch("usePrdStrtYmd")}
                                  onChange={(newDate) => {
                                    field.onChange(newDate);
                                  }}
                                  maxDate={watch("usePrdEndYmd")}
                                  placeholder="시작일시를 선택하세요."
                                />
                              )}
                            />
                          </Grid>
                          <Grid item>
                            <div className="divider">
                              <span>~</span>
                            </div>
                          </Grid>
                          <Grid item>
                            <Controller
                              name="usePrdEndYmd"
                              control={control}
                              render={({ field }) => (
                                <XTDatePicker
                                  timePicker={true}
                                  value={watch("usePrdEndYmd")}
                                  minDate={watch("usePrdStrtYmd")}
                                  onChange={(newDate) => {
                                    field.onChange(newDate);
                                  }}
                                  placeholder="종료일시를 선택하세요."
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    {(errors.usePrdStrtYmd || errors.usePrdEndYmd) && (
                      <XTFormHelperText className="error">
                        {errors?.usePrdStrtYmd?.message ||
                          errors?.usePrdEndYmd?.message}
                      </XTFormHelperText>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>이미지</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTFileUpload
                      file={watch(`noticeImageList`)}
                      data={{
                        domain: "notice",
                        type: "IMAGE",
                        fileType: ["jpg", "jpeg", "png"],
                        limitMB: 10,
                        setValue,
                        setKey: "noticeImageList",
                        submitCount,
                        multiple: true,
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel required>내용</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Controller
                      name="cont"
                      control={control}
                      render={({ field }) => (
                        <XTFormControl>
                          <XTTextarea
                            {...field}
                            multiline
                            placeholder="내용을 입력해 주세요."
                            type="text"
                            onChange={(e) => {
                              let value = maxLen(e.target.value, 2000);
                              field.onChange(value);
                            }}
                            className={watch("cont") && "input-filled"}
                            inputProps={{ maxLength: 2000 }}
                          ></XTTextarea>
                          <span css={globalCSS.textareaLength}>
                            {watch("cont").length}
                            &nbsp;/&nbsp;
                            {regComma(2000)}
                          </span>
                        </XTFormControl>
                      )}
                    />
                    {errors.cont?.message && (
                      <XTFormHelperText className="error">
                        {errors.cont?.message}
                      </XTFormHelperText>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>첨부파일</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTFileUpload
                      file={watch(`noticeFileList`)}
                      data={{
                        domain: "notice",
                        type: "FILE",
                        maxLength: 3,
                        fileType: [
                          "ppt",
                          "pptx",
                          "xls",
                          "xlsx",
                          "pdf",
                          "doc",
                          "docx",
                          "hwp",
                          "zip",
                          "rar",
                          "alz",
                        ],
                        setValue,
                        setKey: "noticeFileList",
                        submitCount,
                        multiple: true,
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel required>알림함 노출</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Controller
                      name="notfUseYn"
                      control={control}
                      render={({ field }) => (
                        <XTRadioGroup row defaultValue="" {...field}>
                          <XTRadio
                            value={"Y"}
                            control={<Radio />}
                            label={"Y"}
                          />
                          <XTRadio
                            value={"N"}
                            control={<Radio />}
                            label={"N"}
                          />
                        </XTRadioGroup>
                      )}
                    />
                  </td>
                </tr>
                {watch("notfUseYn") === "Y" ? (
                  <>
                    <tr>
                      <th scope="row">
                        <InputLabel required>중요알림</InputLabel>
                      </th>
                      <td colSpan={3}>
                        {noticeTypes && (
                          <Controller
                            name="noticeNotification.tp"
                            control={control}
                            render={({ field }) => (
                              <XTRadioGroup row defaultValue="2" {...field}>
                                {Object.keys(
                                  noticeTypes!.NoticeNotificationType
                                ).map((key: string) => (
                                  <XTRadio
                                    value={key}
                                    control={<Radio />}
                                    label={
                                      noticeTypes!.NoticeNotificationType[
                                        key.toString()
                                      ]
                                    }
                                  />
                                ))}
                              </XTRadioGroup>
                            )}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <InputLabel required>알림함 노출 기간</InputLabel>
                      </th>
                      <td colSpan={3}>
                        <Grid container spacing={1} className="form-grid">
                          <Grid item>
                            <Controller
                              name="noticeNotification.usePrdYn"
                              control={control}
                              render={({ field }) => (
                                <XTRadioGroup
                                  row
                                  defaultValue="N"
                                  {...field}
                                  onChange={(e) => handleChangeRadio(e, 2)}
                                >
                                  <XTRadio
                                    value={"N"}
                                    control={<Radio />}
                                    label={"상시"}
                                  />
                                  <XTRadio
                                    value={"Y"}
                                    control={<Radio />}
                                    label={"기간선택"}
                                  />
                                </XTRadioGroup>
                              )}
                            />
                          </Grid>
                          {watch("noticeNotification.usePrdYn") == "Y" && (
                            <>
                              <Grid item>
                                <Controller
                                  name="noticeNotification.usePrdStrtYmd"
                                  control={control}
                                  render={({ field }) => (
                                    <XTDatePicker
                                      timePicker={true}
                                      value={watch(
                                        "noticeNotification.usePrdStrtYmd"
                                      )}
                                      onChange={(newDate) => {
                                        field.onChange(newDate);
                                      }}
                                      maxDate={watch(
                                        "noticeNotification.usePrdEndYmd"
                                      )}
                                      placeholder="시작일시를 선택하세요."
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item>
                                <div className="divider">
                                  <span>~</span>
                                </div>
                              </Grid>
                              <Grid item>
                                <Controller
                                  name="noticeNotification.usePrdEndYmd"
                                  control={control}
                                  render={({ field }) => (
                                    <XTDatePicker
                                      timePicker={true}
                                      value={watch(
                                        "noticeNotification.usePrdEndYmd"
                                      )}
                                      minDate={watch(
                                        "noticeNotification.usePrdStrtYmd"
                                      )}
                                      onChange={(newDate) => {
                                        field.onChange(newDate);
                                      }}
                                      placeholder="종료일시를 선택하세요."
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                        {(errors.noticeNotification?.usePrdStrtYmd ||
                          errors.noticeNotification?.usePrdEndYmd) && (
                          <XTFormHelperText className="error">
                            {errors.noticeNotification?.usePrdStrtYmd
                              ?.message ||
                              errors.noticeNotification?.usePrdEndYmd?.message}
                          </XTFormHelperText>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <InputLabel required>알림함 노출 내용</InputLabel>
                      </th>
                      <td colSpan={3}>
                        <Grid container spacing={1} className="form-grid">
                          <Grid item>
                            <Controller
                              name="noticeNotification.ttlSameYn"
                              control={control}
                              render={({ field }) => (
                                <XTRadioGroup row defaultValue="N" {...field}>
                                  <XTRadio
                                    value={"Y"}
                                    control={<Radio />}
                                    label={"제목과 동일"}
                                  />
                                  <XTRadio
                                    value={"N"}
                                    control={<Radio />}
                                    label={"직접입력"}
                                  />
                                </XTRadioGroup>
                              )}
                            />
                          </Grid>
                          {watch("noticeNotification.ttlSameYn") == "N" && (
                            <Grid item>
                              <Controller
                                name="noticeNotification.ttl"
                                control={control}
                                render={({ field }) => (
                                  <XTInput
                                    {...field}
                                    fullWidth
                                    placeholder="제목을 입력해 주세요."
                                    inputProps={{ maxLength: 50 }}
                                    onChange={(e) => {
                                      let value = maxLen(e.target.value, 50);
                                      field.onChange(value);
                                    }}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <span
                                          className="count"
                                          css={globalCSS.inputLength}
                                        >
                                          {
                                            watch("noticeNotification.ttl")
                                              ?.length
                                          }
                                          &nbsp;/&nbsp;50
                                        </span>
                                      </InputAdornment>
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          )}
                        </Grid>
                        {errors.noticeNotification?.ttl?.message && (
                          <XTFormHelperText className="error">
                            {errors.noticeNotification?.ttl?.message}
                          </XTFormHelperText>
                        )}
                      </td>
                    </tr>
                  </>
                ) : (
                  <></>
                )}

                {noticeData?.notice.seq && (
                  <>
                    <tr>
                      <th scope="row">
                        <InputLabel>등록자</InputLabel>
                      </th>
                      <td>
                        {noticeData?.notice?.rgstrManagerAccount?.nm
                          ? `${noticeData?.notice?.rgstrManagerAccount?.nm}(${noticeData?.notice?.rgstrManagerAccount?.id})`
                          : "-"}
                      </td>
                      <th scope="row">
                        <InputLabel>등록일시</InputLabel>
                      </th>
                      <td>
                        {formatYmdHisDot(
                          noticeData?.notice?.rgstYmd,
                          noticeData?.notice?.rgstHis
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <InputLabel>수정자</InputLabel>
                      </th>
                      <td>
                        {noticeData?.notice?.modrManagerAccount?.nm
                          ? `${noticeData?.notice?.modrManagerAccount?.nm}(${noticeData?.notice?.modrManagerAccount?.id})`
                          : "-"}
                      </td>
                      <th scope="row">
                        <InputLabel>수정일시</InputLabel>
                      </th>
                      <td>
                        {formatYmdHisDot(
                          noticeData?.notice?.modYmd,
                          noticeData?.notice?.modHis
                        )}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            <div css={globalCSS.bottomWrap}>
              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  <XTButton
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick={() => handleCancel(noticeData?.notice?.seq || "")}
                  >
                    {noticeData?.notice?.seq ? "목록" : "취소"}
                  </XTButton>
                </Grid>
                <Grid item>
                  {noticeData?.notice?.seq && (
                    <XTButton
                      variant="outlined"
                      color="primary"
                      size="medium"
                      onClick={handleDelete}
                    >
                      삭제
                    </XTButton>
                  )}
                  <XTButton
                    variant="outlined"
                    color="primary"
                    size="medium"
                    type="submit"
                  >
                    {noticeData?.notice?.seq ? "수정" : "등록"}
                  </XTButton>
                </Grid>
              </Grid>
              <AlertDialog
                open={alertDialog.open}
                text={alertDialog.text}
                type={alertDialog.type}
                handleOK={alertDialog.handleOK}
                handleClose={alertDialog.handleClose}
                preventCloseEvent={true}
              />
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
