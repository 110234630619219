import {
  loadPrivacyAPI,
  loadPrivacyListAPI,
  loadPrivacyTypeAPI,
  updateAllPrivacyAPI,
  updateSelectPrivacyAPI,
} from "@/api/log/privacy";
import {
  PrivacyDetailType,
  PrivacyListType,
  PrivacyRegistParamsType,
  PrivacySchFormType,
  PrivacyTypesType,
} from "@/types/log/privacy";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const usePrivacyManager = () => {
  const queryClient = useQueryClient();
  const [privacyData, setPrivacyData] = useState<PrivacyListType | null>(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let schParams: PrivacySchFormType = {
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schTp: params.get("schTp") || "",
    schFld: params.get("schFld") || "",
    schTxt: params.get("schTxt") || "",
    pg: params.get("pg") || "1",
    pgSz: params.get("pgSz") || "10",
    sordFld: params.get("sordFld") || "rgstYmd",
    sordMetd: params.get("sordMetd") || "DESC",
  };

  const {
    data: privacyList,
    isFetching,
    isSuccess,
  } = useQuery<
    AxiosResponse<{
      data: PrivacyListType;
    }>,
    AxiosError,
    PrivacyListType
  >({
    queryKey: ["loadPrivacyList", schParams],
    queryFn: async () => await loadPrivacyListAPI(schParams),
    select: (res) => {
      res.data.data?.logPrivacyReadList.list.map((item: any, i: number) => {
        item.index = i;
      });
      return res.data.data;
    },
  });

  const updateSelect = useMutation({
    mutationFn: (form: any) => updateSelectPrivacyAPI(form),
  });

  const updateAll = useMutation({
    mutationFn: () => updateAllPrivacyAPI(),
  });

  const resetQuery = () => {
    queryClient.invalidateQueries({ queryKey: ["loadPrivacyList"] });
  };

  useEffect(() => {
    if (privacyList) setPrivacyData(privacyList);
  }, [isSuccess, privacyList]);

  return {
    schParams,
    isFetching,
    isSuccess,
    privacyData,
    updateSelect,
    updateAll,
    resetQuery,
  };
};

export const usePrivacyDetailManager = () => {
  const params = useParams();
  const queryClient = useQueryClient();
  const {
    data: privacyData,
    isFetching,
    isFetched,
    isSuccess,
  } = useQuery<
    AxiosResponse<{
      data: PrivacyDetailType;
    }>,
    AxiosError,
    PrivacyDetailType
  >({
    queryKey: ["loadPrivacy", params.seq],
    queryFn: async () => await loadPrivacyAPI(params.seq!),
    // enabled: params.seq,
    select: (res) => {
      return res.data.data;
    },
  });

  const updateSelect = useMutation({
    mutationFn: (form: PrivacyRegistParamsType) => updateSelectPrivacyAPI(form),
  });

  const resetQuery = () => {
    queryClient.resetQueries({ queryKey: ["loadPrivacy"] });
  };

  return {
    isFetching,
    isSuccess,
    privacyData,
    updateSelect,
    resetQuery,
  };
};

export const usePrivacyTypeManager = () => {
  const { data: privacyTypes } = useQuery<
    AxiosResponse<{
      data: PrivacyTypesType;
    }>,
    AxiosError,
    PrivacyTypesType
  >({
    queryKey: ["loadPrivacyTypes"],
    queryFn: async () => await loadPrivacyTypeAPI(),
    select: (res) => {
      return res.data.data;
    },
  });

  return { privacyTypes };
};
