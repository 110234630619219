import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ProductListType,
  ProductRegistParamsType,
  ProductStockType,
  StockRegistParamsType,
} from "@/types/product";
import {
  deleteProductAPI,
  loadProductListAPI,
  loadProductTypesAPI,
  registProductAPI,
  registStockAPI,
} from "@/api/product";

export const useProductType = () => {
  const initialData = {
    GoodsStockType: {
      "1": "입고",
      "2": "출고",
      "3": "폐기",
    },
  };

  const { data: productStockTypes = initialData } = useQuery<
    AxiosResponse<{
      data: ProductStockType;
    }>,
    AxiosError,
    ProductStockType
  >({
    queryKey: ["loadProductTypes"],
    queryFn: async () => await loadProductTypesAPI(),
    select: (res) => {
      return res.data.data;
    },
  });

  return { productStockTypes };
};

export const useProduct = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [product, setProduct] = useState<number[]>([]);
  const initialData: ProductListType = {
    goodsList: [],
  };

  const {
    data: productListData = initialData,
    isFetching,
    refetch,
  } = useQuery<
    AxiosResponse<{ data: ProductListType }>,
    AxiosError,
    ProductListType
  >({
    queryKey: ["loadProductList"],
    queryFn: async () => await loadProductListAPI(),
    select: (res) => {
      let list = res.data.data.goodsList;

      list.map((item, i) => {
        item.id = item.seq;
        item.index = i;
      });
      return res.data.data;
    },
  });

  const regist = useMutation({
    mutationFn: (form: ProductRegistParamsType) => registProductAPI(form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["loadStockList"] });
    },
  });

  const deleted = useMutation({
    mutationFn: (form: any) => deleteProductAPI(form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["loadStockList"] });
    },
  });

  const stockUpdate = useMutation({
    mutationFn: (form: StockRegistParamsType) => registStockAPI(form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["loadStockList"] });
    },
  });

  return {
    productListData,
    product,
    setProduct,
    regist,
    deleted,
    stockUpdate,
    navigate,
    refetch,
  };
};
