import { AlertType, TabListType, UserInfoType } from "@/types/index";
import { atom } from "recoil";
import { LoginPayload, AccessToken, DecodedTokenType } from "@/types";
import { Cookies } from "react-cookie";

const cookies = new Cookies();
let isLight = cookies.get("isLight")
  ? cookies.get("isLight")
  : cookies.set("isLight", "false", { path: "/" });

export const accessiblePages = atom<string[]>({
  key: "accssiblePages",
  default: ["/login", "/guide", "reset-password"],
});

export const cacheState = atom<boolean>({
  key: "CacheState",
  default: false,
});

export const isDetailState = atom<boolean>({
  key: "IsDetailState",
  default: false,
});

export const reloadMenu = atom<boolean>({
  key: "reloadMenu",
  default: false,
});

export const loginData = atom<LoginPayload>({
  key: "loginData",
  default: {
    username: "",
    password: "",
  },
});

export const accessTokenState = atom<AccessToken>({
  key: "accessTokenState",
  default: {
    accessToken: "",
    expiresIn: 0,
    refreshToken: "",
    scope: "",
    tokenKey: "",
    tokenType: "",
    tmpPwdYn: "",
    email: "",
    certiCd: "",
  },
});

export const decodedTokenState = atom<DecodedTokenType>({
  key: "decodedTokenState",
  default: {
    certiAdmsYn: "",
    certiNatDelYn: "",
    certiOprYn: "",
    cph: "",
    email: "",
    exp: null,
    fstNm: "",
    id: "",
    lstNm: "",
    mclSeq: null,
    mrSeq: null,
    nm: "",
    pwdUpdtYn: "",
    seq: null,
    tel: "",
    tp: "",
    whleMgrYn: "",
  },
});

export const themeChangeState = atom({
  key: "themeChangeState",
  default: isLight,
});

export const loadingState = atom({
  key: "loadingState",
  default: false,
});

export const alertDialogState = atom<AlertType>({
  key: "AlertState",
  default: {
    open: false,
    title: "",
    text: "",
    type: "confirm",
    handleOK: () => {},
    handleClose: () => {},
  },
});

export const expAlertOpenState = atom<boolean>({
  key: "expAlertOpenState",
  default: false,
});

export const sessionTabListState = atom<TabListType[]>({
  key: "sessionTabListState",
  default: [],
});

export const UserInfoState = atom<UserInfoType>({
  key: "userInfoState",
  default: {
    managerStatus: {
      seq: 0,
      rgstrId: "",
      rgstrNm: "",
      rgstYmd: "",
      rgstHis: "",
      modrId: "",
      modrNm: "",
      modYmd: "",
      modHis: "",
      useYn: "Y",
      lckdYn: "N",
      tmpPwdYn: "Y",
      usePrdYn: "Y",
      usePrdStrtYmd: "",
      usePrdEndYmd: "",
      pwdMtkCnt: 0,
      lstLoginYmd: "",
      lstLoginHis: "",
      mclSeq: 0,
      mrSeq: null,
      maskYn: "N",
      tp: 0,
      saSeq: "",
      managerAccount: {
        seq: "",
        mrSeq: 0,
        id: "",
        nm: "",
        email: "",
        dept: "",
        jbps: "",
      },
      formatRgstYmd: "",
      formatRgstHis: "",
    },
  },
});
