import { formatYmdHisDot, regComma } from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import { XTInput } from "@/components/mui/xt-input";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import {
  XTMenuItem,
  XTMenuItemWrap,
  XTSelect,
} from "@/components/mui/xt-select";
import {
  useManagerList,
  useManagerType,
} from "@/pages/system/manager/_hook/use-manager";
import { alertDialogState } from "@/state";

import {
  Box,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Radio,
} from "@mui/material";
import {
  GridColDef,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useRecoilState } from "recoil";

export default function Admin() {
  const {
    listData,
    permissionListData,
    isFetching,
    schForm,
    schViewForm,
    handleChangeInput,
    handleChangePage,
    handleChangePageSz,
    handleResetInput,
    navigate,
    search,
    userInfo,
  } = useManagerList();
  const [alertDialog, setAlertDialog] = useRecoilState(alertDialogState);
  const apiRef = useGridApiRef();

  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "번호",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return listData!.managerStatusList?.number - params.row.index;
      },
    },
    {
      field: "tp",
      headerName: "구분",
      width: 150,
      headerAlign: "center",
      align: "center",
      valueGetter: (params: GridValueGetterParams) => {
        return listData.ManagerStatusType &&
          listData.ManagerStatusType[params.value]
          ? listData.ManagerStatusType[params.value]
          : "-";
      },
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "nm",
      headerName: "이름(아이디)",
      headerAlign: "center",
      width: 300,
      align: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.managerAccount.nm}(${params.row.managerAccount.id})`,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "dept",
      headerName: "부서",
      headerAlign: "center",
      flex: 1,
      width: 500,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.managerAccount.dept}`,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "useYn",
      headerName: "사용여부",
      headerAlign: "center",
      width: 80,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "formatRgstYmdHis",
      headerName: "등록일시",
      headerAlign: "center",
      width: 160,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        formatYmdHisDot(params.row.rgstYmd, params.row.rgstHis),
    },
  ];

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      search();
    }
  };

  return (
    <>
      <main id="sample" css={globalCSS.listContainer} className="only-list">
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>관리자 계정 관리</h2>
            </div>
            <div className="b-sub">
              <span>시스템 관리</span>
              <span>관리자 계정 관리</span>
            </div>
          </div>
        </div>
        <div className="cont-wrap">
          <div className="search-wrap">
            <table css={globalCSS.formTable}>
              <colgroup>
                <col style={{ width: "200px" }} />
                <col />
                <col style={{ width: "200px" }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputLabel>구분</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <XTRadioGroup
                          name="schTp"
                          row
                          value={schViewForm.schTp}
                          onChange={handleChangeInput}
                        >
                          <XTRadio
                            value=""
                            control={<Radio />}
                            label={"전체"}
                          />
                          {Object.keys(listData.ManagerStatusType).map(
                            (key) => (
                              <XTRadio
                                key={key}
                                value={key}
                                control={<Radio />}
                                label={listData.ManagerStatusType[key]}
                              />
                            )
                          )}
                        </XTRadioGroup>
                      </Grid>
                      {schViewForm.schTp == "4" && (
                        <Grid item>
                          <XTSelect
                            name="schSaSeq"
                            value={schViewForm.schSaSeq}
                            onChange={handleChangeInput}
                            sx={{
                              minWidth: 150,
                            }}
                            MenuProps={{
                              MenuListProps: { sx: { padding: 0 } },
                              PaperProps: {
                                sx: {
                                  ...XTMenuItemWrap,
                                },
                              },
                            }}
                          >
                            <XTMenuItem value={" "}>전체</XTMenuItem>
                            {permissionListData!.authList?.map((item, i) => (
                              <XTMenuItem key={i} value={item.seq}>
                                {item.ttl}
                              </XTMenuItem>
                            ))}
                          </XTSelect>
                        </Grid>
                      )}
                    </Grid>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>사용여부</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTRadioGroup
                      name="schUseYn"
                      row
                      value={schViewForm.schUseYn}
                      onChange={handleChangeInput}
                    >
                      <XTRadio value="" control={<Radio />} label={"전체"} />
                      <XTRadio value="Y" control={<Radio />} label={"Y"} />
                      <XTRadio value="N" control={<Radio />} label={"N"} />
                    </XTRadioGroup>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>검색</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTSelect
                          name="schFld"
                          value={schViewForm.schFld}
                          onChange={handleChangeInput}
                          sx={{
                            minWidth: 150,
                          }}
                          MenuProps={{
                            MenuListProps: { sx: { padding: 0 } },
                            PaperProps: {
                              sx: {
                                ...XTMenuItemWrap,
                              },
                            },
                          }}
                        >
                          <XTMenuItem value=" ">전체</XTMenuItem>
                          <XTMenuItem value="nm">이름</XTMenuItem>
                          <XTMenuItem value="id">아이디</XTMenuItem>
                          <XTMenuItem value="dept">부서</XTMenuItem>
                        </XTSelect>
                      </Grid>
                      <Grid item>
                        <XTInput
                          name="schTxt"
                          value={schViewForm.schTxt}
                          fullWidth
                          placeholder="검색어를 입력해주세요."
                          onKeyDown={handleKeyPress}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="action-button-group">
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => {
                  handleResetInput();
                }}
              >
                초기화
              </XTButton>
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={(e) => {
                  search();
                }}
              >
                조회
              </XTButton>
            </div>
          </div>
          <div className="grid-wrap">
            <div className="total-wrap">
              <div className="total-cnt">
                전체{" "}
                <span>{regComma(listData?.managerStatusList.totalSize)}</span>건
              </div>
              <XTSelect
                name="pgSz"
                value={schForm.pgSz}
                onChange={handleChangePageSz}
                sx={{
                  minWidth: 150,
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 50,
                    horizontal: -10,
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
              >
                <XTMenuItem value={10}>10개 씩</XTMenuItem>
                <XTMenuItem value={20}>20개 씩</XTMenuItem>
                <XTMenuItem value={30}>30개 씩</XTMenuItem>
                <XTMenuItem value={50}>50개 씩</XTMenuItem>
                <XTMenuItem value={100}>100개 씩</XTMenuItem>
              </XTSelect>
            </div>

            <XTDataGrid
              loading={isFetching}
              className="clickable"
              columns={columns}
              rows={listData.managerStatusList.list}
              getRowId={(params) => params.id}
              apiRef={apiRef}
              experimentalFeatures={{
                ariaV7: true,
              }}
              onRowClick={(params) => {
                if (userInfo.managerStatus.tp != "1" && params.row.tp == "1") {
                  setAlertDialog({
                    ...alertDialog,
                    open: true,
                    type: "complete",
                    text: "최고관리자 계정 조회 권한이 없습니다.",
                    handleOK: () => {
                      setAlertDialog({
                        ...alertDialog,
                        open: false,
                      });
                    },
                  });
                } else {
                  navigate(`detail/${params.row.seq}`);
                }
              }}
              slots={{
                noRowsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
                noResultsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
                loadingOverlay: LinearProgress,
              }}
              rowHeight={38}
              columnHeaderHeight={38}
              hideFooter
            />
          </div>
          <Box css={globalCSS.bottomWrap}>
            <Pagination
              page={listData.managerStatusList.page}
              count={listData.managerStatusList.totalPageSize}
              onChange={handleChangePage}
            />
            <Grid container justifyContent="end" spacing={2}>
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={(e) => {
                    //링크 이동 시키기
                    navigate(`detail`);
                  }}
                >
                  등록
                </XTButton>
              </Grid>
            </Grid>
          </Box>
        </div>
      </main>
    </>
  );
}
