import { loadCommonMenuListAPI } from "@/api/system/menu";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Main() {
  const navigate = useNavigate();

  const moveFirstMenu = async () => {
    const result = await loadCommonMenuListAPI("Y");
    const firstMenuUri =
      result.data.data?.menuList[0]?.menuList?.[0]?.uri ||
      result.data.data?.menuList[0]?.uri;

    if (firstMenuUri) {
      navigate(firstMenuUri, { replace: true });
    } else {
      window.location.href = "/login";
    }
  };

  useEffect(() => {
    moveFirstMenu();
  }, []);

  return <></>;
}
