import globalCSS from "@/assets/ts/global-css";
import { FileType, IFile } from "@/types";
import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import { XTButton } from "./xt-button";
import { css } from "@emotion/react";
import theme from "@/assets/ts/theme";
import { convertFileSize, fileUpload, getImage } from "@/assets/ts/common";
import axios from "axios";
import AlertDialog from "@/components/ui/alert-dialog";
import { FileUploader } from "react-drag-drop-files";
import { XTInput } from "@/components/mui/xt-input";
import { IconButton } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

export function XTFIleUploadArea(props: any) {
  // api와 실 데이터 연동 필요
  // props 처리 안되어있음

  const inputRef: any = useRef(null);

  const fileTypes = ["JPG", "PNG", "GIF"];
  const { file, data } = props;

  const [files, setFiles] = useState();

  const handleChange = (file) => {
    setFiles(file);
  };

  const [openDialog, setOpenDialog] = useState<{
    open: boolean;
    type: string | null;
  }>({ open: false, type: null });

  const handleOpenDialog = (type: string) => {
    setOpenDialog({ open: true, type: type });
  };

  const handleCloseDialog = (type: string) => {
    setOpenDialog({ ...openDialog, open: false });
  };

  const handleUploadFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number = -1
  ) => {
    if (!e.target.files) return;

    if (data.fileType) {
      let fileNm = e.target.files[0].name;
      let ext = fileNm.substring(fileNm.lastIndexOf(".") + 1, fileNm.length);
      let fileSize = e.target.files[0].size;
      let capa = (fileSize / (1024 * 1024)).toFixed(2);

      data.fileType = data.fileType.map((item: string) => {
        return item.toLowerCase();
      });

      if (data.fileType.includes(ext.toLowerCase()) === false) {
        handleOpenDialog("rejectFileType");
        return;
      }
    }

    if (data.limitMB) {
      const currentMB =
        Math.round((e.target.files[0].size / Math.pow(1024, 2)) * 100) / 100;

      if (currentMB > data.limitMB) {
        handleOpenDialog("rejectCapacity");
        return;
      }
    }

    if (index >= 0) {
      fileUpload(e, data, index);
    } else {
      fileUpload(e, data);
    }
  };

  const removeFile = (index) => {
    const updatedFiles = Array.from(files).filter((_, idx) => idx !== index);
    setFiles(updatedFiles);
  };

  return (
    <>
      <div css={style.fileArea}>
        <XTButton
          variant="outlined"
          color="primary"
          size="small"
          className="file-button"
          // disabled={true}
          onClick={() => {
            inputRef.current.click();
          }}
        >
          파일 선택
          <input
            ref={inputRef}
            hidden
            value=""
            type="file"
            // className="file-input"
            onChange={(e) => handleUploadFile(e)}
          />
        </XTButton>
        <FileUploader
          multiple
          classes="file-upload-area"
          handleChange={handleChange}
          types={fileTypes}
          hoverTitle=""
          children={
            <>
              <div className="file-upload-wrap">
                <span>파일을 드래그 해주세요.</span>
              </div>
            </>
          }
        />
        {files && (
          <Box className="file-list">
            <div className="file-list-header">파일명</div>
            {files.length > 0 &&
              Array.from(files).map((item, i) => {
                return (
                  <Box key={i} className="file-list-item">
                    <span>
                      {" "}
                      {item.name} / {convertFileSize(item.size)}
                    </span>
                    <XTButton
                      variant="contained"
                      color="primary"
                      size="small"
                      data-code='<XTButton variant="outlined" color="primary" size="small" className="xs">Button</XTButton>'
                      onClick={(e) => removeFile(i)}
                    >
                      삭제
                    </XTButton>
                  </Box>
                );
              })}
          </Box>
        )}
      </div>
    </>
  );
}

export function XTFileDownload({ fileTitle, fileExt, onClick }: IFile) {
  const getFileNm = () => {
    if (!fileTitle) return "";
    let strIdx = fileTitle.lastIndexOf(".");

    const result = fileTitle.substr(0, strIdx);

    return result;
  };

  return (
    <Link
      to={"/"}
      target="_parent"
      css={globalCSS.fileDownload}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <i css={globalCSS.icoFileFilledSm} className="ico" />
      <span className="text">{getFileNm()}</span>
      <span className="ext">.{fileExt}</span>
    </Link>
  );
}

export function XTFileUpload(props: any) {
  const inputRef: any = useRef(null);
  const inputMultipleRef: any = useRef([]);

  const { file, data } = props;

  const [openDialog, setOpenDialog] = useState<{
    open: boolean;
    text: string;
  }>({ open: false, text: "" });

  const handleOpenDialog = (text: string) => {
    setOpenDialog({ open: true, text });
  };

  const handleCloseDialog = () => {
    setOpenDialog({ ...openDialog, open: false });
  };

  const handleFileAdd = () => {
    if (data.maxLength <= file.length) {
      handleOpenDialog(`첨부파일은 ${data.maxLength}개만 첨부 가능합니다.`);
      return;
    }

    data.setValue(
      `${data.setKey}.${file.length}`,
      {
        filePath: "",
        fileName: "",
        ext: "",
        fileSize: 0,
      },
      {
        shouldValidate: data.submitCount && data.submitCount > 0 ? true : false,
        shouldDirty: true,
      }
    );
  };

  const handleFileRemove = (index: number) => {
    const updatedFiles = file.filter((_: any, i: number) => i !== index);

    data.setValue(data.setKey, updatedFiles);
  };

  const handleFileDelete = (index: number = -1) => {
    if (index >= 0) {
      data.setValue(
        `${data.setKey}.${index}`,
        {
          seq: "",
          uri: "",
          orgNm: "",
          ext: "",
          capa: 0,
        },
        {
          shouldValidate:
            data.submitCount && data.submitCount > 0 ? true : false,
          shouldDirty: true,
        }
      );
    } else {
      data.setValue(
        data.setKey,
        {
          seq: "",
          uri: "",
          orgNm: "",
          ext: "",
          capa: 0,
        },
        {
          shouldValidate:
            data.submitCount && data.submitCount > 0 ? true : false,
          shouldDirty: true,
        }
      );
    }
  };

  const handleUploadFile = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number = -1
  ) => {
    if (!e.target.files) return;

    if (data.maxLength && data.maxLength <= fileUpload.length) {
      handleOpenDialog(`첨부파일은 ${data.maxLength}개만 첨부 가능합니다.`);
      return;
    }

    if (data.fileType) {
      let fileNm = e.target.files[0].name;
      let ext = fileNm.substring(fileNm.lastIndexOf(".") + 1, fileNm.length);

      data.fileType = data.fileType.map((item: string) => {
        return item.toLowerCase();
      });

      if (data.fileType.includes(ext.toLowerCase()) === false) {
        handleOpenDialog(`첨부파일은 ${
          data.fileType && data.fileType.join(", ")
        }형식만 첨부
        가능합니다.`);
        return;
      }
    }

    if (data.limitMB) {
      const currentMB =
        Math.round((e.target.files[0].size / Math.pow(1024, 2)) * 100) / 100;

      if (currentMB > data.limitMB) {
        handleOpenDialog(`첨부파일은 ${data.limitMB}MB까지만 첨부 가능합니다.`);
        return;
      }
    }

    let errorCd = "";

    if (index >= 0) {
      const res = await fileUpload(e, data, index);
      errorCd = res.errorCd;
    } else {
      const res = await fileUpload(e, data);
      errorCd = res.errorCd;
    }

    if (errorCd) {
      handleOpenDialog(`파일 업로드에 실패했습니다.`);
      return;
    }
  };

  return (
    <>
      {data.multiple ? (
        file.map((item: FileType, index: number) => {
          return (
            <div css={style.file}>
              {data.type === "IMAGE" && item && item.seq && (
                <div className="img-wrap">
                  <img className="img" src={getImage(item.seq)} />
                </div>
              )}
              <div key={index} className="file-wrap">
                <XTInput
                  disabled
                  type="text"
                  value={item && item.orgNm ? item.orgNm : ""}
                  endAdornment={
                    <>
                      {item && item.orgNm && (
                        <IconButton onClick={() => handleFileDelete(index)}>
                          <i css={globalCSS.icoDelete} className="gray" />
                        </IconButton>
                      )}
                    </>
                  }
                />
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="file-button"
                  onClick={() => {
                    inputMultipleRef.current[index].click();
                  }}
                >
                  파일 선택
                  <input
                    ref={(el) => (inputMultipleRef.current[index] = el)}
                    hidden
                    value=""
                    type="file"
                    onChange={(e) => handleUploadFile(e, index)}
                  />
                </XTButton>
                {index == 0 ? (
                  data.multiple && (
                    <XTButton
                      disabled={data.maxLength == file.length}
                      variant="outlined"
                      color="primary"
                      size="small"
                      className="file-button"
                      onClick={handleFileAdd}
                    >
                      추가
                    </XTButton>
                  )
                ) : (
                  <XTButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    className="file-button"
                    onClick={() => handleFileRemove(index)}
                  >
                    삭제
                  </XTButton>
                )}
              </div>
              <p>
                ※
                {data.fileType
                  ? `업로드 가능 확장자 ${data.fileType
                      .join(", ")
                      .toUpperCase()}`
                  : ""}
                {data.maxLength
                  ? `${data.fileType ? " / " : ""}업로드 개수 최대 ${
                      data.maxLength
                    }개`
                  : ""}
                {data.type === "FILE" && data.limitMB
                  ? `${
                      data.fileType || data.maxLength ? " / " : ""
                    }업로드 가능 용량 총 ${data.limitMB}MB`
                  : ""}
              </p>
            </div>
          );
        })
      ) : (
        <>
          <div css={style.file}>
            {data.type === "IMAGE" && file && file.seq && (
              <div className="img-wrap">
                <img className="img" src={getImage(file.seq)} alt="" />
              </div>
            )}
            <div className="file-wrap">
              <XTInput
                disabled
                type="text"
                value={file && file.orgNm ? file.orgNm : ""}
                endAdornment={
                  <>
                    {file && file.orgNm && (
                      <IconButton onClick={() => handleFileDelete()}>
                        <i css={globalCSS.icoDelete} className="gray" />
                      </IconButton>
                    )}
                  </>
                }
              />
              <XTButton
                variant="outlined"
                color="primary"
                size="small"
                className="file-button"
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                파일 선택
                <input
                  ref={inputRef}
                  hidden
                  value=""
                  type="file"
                  onChange={(e) => {
                    handleUploadFile(e);
                  }}
                />
              </XTButton>
            </div>
          </div>
          <div>
            <p>
              ※
              {data.fileType
                ? `업로드 가능 확장자 ${data.fileType.join(", ").toUpperCase()}`
                : ""}
              {data.maxLength
                ? `${data.fileType ? " / " : ""}업로드 개수 최대 ${
                    data.maxLength
                  }개`
                : ""}
              {data.type === "FILE" && data.limitMB
                ? `${
                    data.fileType || data.maxLength ? " / " : ""
                  }업로드 가능 용량 총 ${data.limitMB}MB`
                : ""}
            </p>
          </div>
        </>
      )}
      <AlertDialog
        open={openDialog.open}
        text={openDialog.text}
        type={"complete"}
        handleOK={handleCloseDialog}
        preventCloseEvent={true}
      />
    </>
  );
}

export function XTFileUploadButton(props: any) {
  const inputRef: any = useRef(null);

  const { file, data } = props;

  const [openDialog, setOpenDialog] = useState<{
    open: boolean;
    text: string;
  }>({ open: false, text: "" });

  const handleOpenDialog = (text: string) => {
    setOpenDialog({ open: true, text });
  };

  const handleCloseDialog = () => {
    setOpenDialog({ ...openDialog, open: false });
  };

  const handleFileDelete = (index: number = -1) => {
    if (index >= 0) {
      data.setValue(
        `${data.setKey}.${index}`,
        {
          filePath: "",
          fileName: "",
          ext: "",
          fileSize: 0,
        },
        {
          shouldValidate:
            data.submitCount && data.submitCount > 0 ? true : false,
          shouldDirty: true,
        }
      );
    } else {
      data.setValue(
        data.setKey,
        {
          filePath: "",
          fileName: "",
          ext: "",
          fileSize: 0,
        },
        {
          shouldValidate:
            data.submitCount && data.submitCount > 0 ? true : false,
          shouldDirty: true,
        }
      );
    }
  };

  const handleUploadFile = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number = -1
  ) => {
    if (!e.target.files) return;

    if (data.maxLength) {
    }

    if (data.fileType) {
      let fileNm = e.target.files[0].name;
      let ext = fileNm.substring(fileNm.lastIndexOf(".") + 1, fileNm.length);

      data.fileType = data.fileType.map((item: string) => {
        return item.toLowerCase();
      });

      if (data.fileType.includes(ext.toLowerCase()) === false) {
        handleOpenDialog(`첨부파일은 ${
          data.fileType && data.fileType.join(", ")
        }형식만 첨부
        가능합니다.`);
        return;
      }
    }

    if (data.limitMB) {
      const currentMB =
        Math.round((e.target.files[0].size / Math.pow(1024, 2)) * 100) / 100;

      if (currentMB > data.limitMB) {
        handleOpenDialog(`첨부파일은 ${data.limitMB}MB까지만 첨부 가능합니다.`);
        return;
      }
    }

    if (index >= 0) {
      await fileUpload(e, data, index);
    } else {
      await fileUpload(e, data);
    }
  };

  return (
    <>
      <div className="file-btn" css={style.file}>
        <XTButton
          variant="contained"
          color="primary"
          size="small"
          startIcon={<CameraAltIcon />}
          onClick={() => {
            inputRef.current.click();
          }}
        >
          업로드
          <input
            ref={inputRef}
            hidden
            value=""
            type="file"
            onChange={(e) => handleUploadFile(e)}
          />
        </XTButton>
      </div>
      <AlertDialog
        open={openDialog.open}
        text={openDialog.text}
        type={"complete"}
        handleOK={handleCloseDialog}
        preventCloseEvent={true}
      />
    </>
  );
}

export function XTPopupFile(props: any) {
  const inputRef: any = useRef(null);

  const { file, data } = props;

  const [openDialog, setOpenDialog] = useState<{
    open: boolean;
    type: string | null;
    cont?: string | null;
  }>({ open: false, type: null, cont: null });

  const handleOpenDialog = (type: string) => {
    setOpenDialog({ open: true, type: type });
  };

  const handleCloseDialog = (type: string) => {
    setOpenDialog({ ...openDialog, open: false });
  };

  const handleFileDelete = (index: number = -1) => {
    data.setValue("");
  };

  const handleUploadFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number = -1
  ) => {
    if (!e.target.files) return;

    if (data.fileType) {
      let fileNm = e.target.files[0].name;
      let ext = fileNm.substring(fileNm.lastIndexOf(".") + 1, fileNm.length);
      let fileSize = e.target.files[0].size;
      let capa = (fileSize / (1024 * 1024)).toFixed(2);

      data.fileType = data.fileType.map((item: string) => {
        return item.toLowerCase();
      });

      if (data.fileType.includes(ext.toLowerCase()) === false) {
        handleOpenDialog("rejectFileType");
        return;
      }
    }

    // if (data.limitMB) {
    //   const currentMB =
    //     Math.round((e.target.files[0].size / Math.pow(1024, 2)) * 100) / 100;

    //   if (currentMB > data.limitMB) {
    //     handleOpenDialog("rejectCapacity");
    //     return;
    //   }
    // }
    data.setValue(e.target.files[0]);
    //fileExcelUpload(e, data);
  };

  const getAlertDialogText = () => {
    if (openDialog.type === "rejectFileType") {
      return `첨부파일은 ${data.fileType && data.fileType.join(", ")}형식만 첨부
      가능합니다.`;
    } else if (openDialog.type === "rejectCapacity") {
      return `첨부파일은 ${data.limitMB}MB까지만 첨부 가능합니다.`;
    } else if (openDialog.type === "failUpload") {
      return openDialog.cont;
    }
  };

  const fileExcelUpload = (e: any, data: any, i: number | null = null) => {
    const { domain, setValue, setKey, submitCount, type, info } = data;

    const file = e.target.files[0];

    let formData = new FormData();
    let url: string = ``;

    switch (type) {
      case "EXCEL":
        formData.append("file", file);
        url = `/api/ap/apply/update-ivc-no-upload`;
        break;
    }

    axios.post(url, formData).then((res) => {
      if (res.data.data.resultCode === "200") {
        const fileResponse = res.data.data;
        let resFile = {};

        if (type === "EXCEL") {
          resFile = {
            orgNm: file.name,
            ...fileResponse,
          };
        }

        if (i != null) {
          setValue(
            `${setKey}.${i}`,
            { ...resFile },
            {
              shouldValidate: submitCount && submitCount > 0 ? true : false,
            }
          );
        } else {
          setValue(
            setKey,
            { ...resFile },
            {
              shouldValidate: submitCount && submitCount > 0 ? true : false,
            }
          );
        }
      } else {
        errAlert(res.data.data.resultCode);
      }
    });
  };

  const errAlert = (resultCode: string) => {
    let cont = "";
    if (resultCode === "300") {
      cont = "엑셀 형식이 맞지 않습니다.<br/>다시 확인해 주세요.";
    } else if (resultCode === "301") {
      cont = "중복되는 개소명이 있습니다.<br/>다시 확인해 주세요.";
    } else if (resultCode === "302") {
      cont = "필수 입력 컬럼을 다시 확인해 주세요.";
    }

    setOpenDialog({
      open: true,
      type: "failUpload",
      cont: cont,
    });
  };

  return (
    <>
      <div css={style.fileExcel}>
        <div className="file-upload">
          <XTButton
            variant="outlined"
            color="primary"
            size="small"
            className="xs black file-button"
            onClick={() => {
              inputRef.current.click();
            }}
          >
            첨부파일 추가
            <input
              ref={inputRef}
              hidden
              value=""
              type="file"
              // className="file-input"
              onChange={(e) => handleUploadFile(e)}
            />
          </XTButton>

          <em className="info-msg">업로드 가능 확장자 xlsx</em>
        </div>
        {!!file && (
          <div className="file-item">
            <i css={globalCSS.icoFileFilledSm} className="ico" />
            <span className="nm">{file.name}</span>
            <button onClick={(e) => handleFileDelete(e)}>
              <i className="delete" css={globalCSS.icoDelete} />
            </button>
          </div>
        )}
      </div>
      <AlertDialog
        open={openDialog.open}
        text={getAlertDialogText()}
        textType={"text"}
        type={"complete"}
        handleOK={handleCloseDialog}
        preventCloseEvent={true}
      />
    </>
  );
}

const style = {
  fileArea: css`
    .file-upload-area {
      display: block;
      width: 100%;
      height: ${theme.typography.pxToRem(200)};
      border: 1px dashed ${theme.palette.line1};
      margin-top: ${theme.typography.pxToRem(10)};

      .file-upload-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: ${theme.typography.pxToRem(200)};
        padding: ${theme.typography.pxToRem(30)};
      }
    }

    .file-list {
      margin-top: ${theme.typography.pxToRem(10)};

      .file-list-header {
        padding: ${theme.typography.pxToRem(7)} ${theme.typography.pxToRem(10)};
        background-color: ${theme.palette.disabled};
        text-align: center;
      }

      .file-list-item {
        display: flex;
        justify-content: space-between;
        padding: ${theme.typography.pxToRem(7)} ${theme.typography.pxToRem(10)};
        border: 1px solid ${theme.palette.disabled};

        & + .file-list-item {
          margin-top: -1px;
        }
      }
    }
  `,

  file: css`
    &:not(:first-of-type) {
      margin-top: ${theme.typography.pxToRem(10)};
    }
    .img-wrap {
      width: 100%;

      .img {
        max-width: 100%;
      }
    }

    .file-wrap {
      display: flex;
      align-items: flex-start;
      margin-top: 5px;

      .file-button {
        margin-left: 5px;
        width: fit-content;

        .file-input {
          /* position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        color: transparent;
        background: transparent;
        opacity: 0;
        /* width: ${theme.typography.pxToRem(180)};
        height: ${theme.typography.pxToRem(36)}; */
          cursor: pointer;
        }
      }

      .file-list {
        margin-left: ${theme.typography.pxToRem(20)};
        margin-top: ${theme.typography.pxToRem(6)};
        max-width: ${theme.typography.pxToRem(780)};

        .info-msg {
          font-size: 14px;
          color: ${theme.palette.bg52};
        }

        .file-item {
          display: flex;
          align-items: center;
          margin-top: ${theme.typography.pxToRem(10)};

          .ico {
            margin-right: ${theme.typography.pxToRem(10)};
            flex: none;
            /* flex-basis: 18px; */
          }

          .nm {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: ${theme.palette.bg52};
            min-width: 0;
            max-width: ${theme.typography.pxToRem(300)};
          }

          .ext {
            color: ${theme.palette.bg52};
          }

          .volumn {
            color: ${theme.palette.bg66};
            margin: 0 ${theme.typography.pxToRem(11)} 0
              ${theme.typography.pxToRem(17)};
          }

          button {
          }
        }
      }
    }
  `,

  fileExcel: css`
    display: flex;
    flex-direction: column;

    .file-upload {
      display: flex;
      align-items: center;

      .info-msg {
        color: ${theme.palette.bg52};
        margin-left: ${theme.typography.pxToRem(10)};
      }
    }

    .file-item {
      margin-left: ${theme.typography.pxToRem(122.5)};
      display: flex;
      align-items: center;

      .ico {
        margin-right: ${theme.typography.pxToRem(10)};
        flex: none;
      }

      .nm {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: ${theme.palette.bg52};
        min-width: 0;
        max-width: ${theme.typography.pxToRem(250)};
      }

      .ext {
        color: ${theme.palette.bg52};
      }

      .volumn {
        color: ${theme.palette.bg66};
        margin: 0 ${theme.typography.pxToRem(5)} 0
          ${theme.typography.pxToRem(10)};
      }
    }
  `,
};
