import {
  changeDataSubmitDateTime,
  formatYmdHisDot,
  getSubmitFileList,
  maxLen,
  regComma,
} from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";

import {
  Grid,
  InputAdornment,
  InputLabel,
  Radio,
  SelectChangeEvent,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AlertDialog from "@/components/ui/alert-dialog";
import { useEffect, useState } from "react";
import { useFaqDetailManager } from "@/pages/faq/_hook/use-faq";
import { Controller, useForm } from "react-hook-form";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import { FaqFormType } from "@/types/faq";
import XTDatePicker from "@/components/mui/xt-datepicker";
import {
  XTFormControl,
  XTFormHelperText,
  XTInput,
  XTTextarea,
} from "@/components/mui/xt-input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { XTFileUpload } from "@/components/mui/xt-fileform";

export default function FaqDetail() {
  const navigate = useNavigate();
  const { faqData, registFaq, updateFaq, deleteFaq } = useFaqDetailManager();
  const params: any = useParams();

  const [alertDialog, setAlertDialog] = useState({
    open: false,
    title: "",
    text: "",
    type: "complete",
    handleOK: () => {},
    handleClose: () => handleCloseDialog(),
  });

  const handleCloseDialog = () => {
    setAlertDialog({
      ...alertDialog,
      open: false,
    });
  };

  const schema = yup
    .object()
    .shape({
      ttl: yup.string().trim().required("제목을 입력해 주세요."),
      cont: yup.string().trim().required("내용을 입력해 주세요."),
      usePrdStrtYmd: yup.mixed().when("usePrdYn", {
        is: "Y",
        then: yup.string().nullable().required("게시기간을 선택해주세요."),
        otherwise: yup.mixed(),
      }),
      usePrdEndYmd: yup.mixed().when("usePrdYn", {
        is: "Y",
        then: yup.string().nullable().required("게시기간을 선택해주세요."),
        otherwise: yup.mixed(),
      }),
    })
    .required();

  const {
    register,
    trigger,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, submitCount },
  } = useForm<FaqFormType>({
    shouldFocusError: false,
    defaultValues: {
      seq: "",
      useYn: "Y",
      usePrdYn: "N",
      // 사용 기간 여부
      usePrdStrtYmd: "",
      // 사용 기간 시작 일자
      usePrdStrtHis: "",
      // 사용 기간 시작 시간
      usePrdEndYmd: "",
      // 사용 기간 종료 일자
      usePrdEndHis: "",
      // 사용 기간 종료 시간
      ttl: "",
      // 제목
      cont: "",
      faqImageList: [
        {
          seq: "",
          uri: "",
          orgNm: "",
          ext: "",
          capa: 0,
        },
      ],
    },
    resolver: yupResolver(schema),
  });

  const onDeleteFaq = async () => {
    try {
      let res = await deleteFaq.mutateAsync(params.seq);
      if (res) {
        setAlertDialog({
          ...alertDialog,
          open: true,
          type: "complete",
          text: "삭제되었습니다.",
          handleOK: () => {
            navigate(-1);
          },
        });
      }
    } catch (e) {
      setAlertDialog({
        ...alertDialog,
        open: true,
        type: "complete",
        text: "삭제를 실패했습니다.",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
      });
    }
  };

  const handleDelete = () => {
    setAlertDialog({
      ...alertDialog,
      open: true,
      type: "confirm",
      text: "삭제 하시겠습니까?",
      handleOK: () => {
        onDeleteFaq();
      },
      handleClose: () => {
        setAlertDialog({
          ...alertDialog,
          open: false,
        });
      },
    });
  };

  const handleChangeRadio = (e: any) => {
    setValue("usePrdYn", e.target.value);
    if (e.target.value === "Y") {
      setValue("usePrdStrtYmd", watch("usePrdStrtYmd"));
      setValue("usePrdEndYmd", watch("usePrdEndYmd"));
    } else {
      setValue("usePrdStrtYmd", "");
      setValue("usePrdEndYmd", "");
    }

    if (submitCount > 0) {
      trigger(["usePrdStrtYmd", "usePrdEndYmd"]);
    }
  };
  const handleDataSubmit = async () => {
    let data = getValues();

    let form = {
      ...data,
      usePrdStrtYmd: changeDataSubmitDateTime(data.usePrdStrtYmd, "start")[0],
      usePrdStrtHis: changeDataSubmitDateTime(data.usePrdStrtYmd, "start")[1],
      usePrdEndYmd: changeDataSubmitDateTime(data.usePrdEndYmd, "end")[0],
      usePrdEndHis: changeDataSubmitDateTime(data.usePrdEndYmd, "end")[1],
      faqImageList: getSubmitFileList("IMAGE", data.faqImageList),
    };

    try {
      let res = {};
      if (data.seq) {
        res = await updateFaq.mutateAsync(form);
      } else {
        res = await registFaq.mutateAsync(form);
      }

      if (res) {
        setAlertDialog({
          ...alertDialog,
          open: true,
          title: "알림",
          text: `${data.seq ? "수정" : "등록"} 되었습니다.`,
          type: "complete",
          handleOK: () => {
            navigate(-1);
          },
        });
      }
    } catch (error: any) {
      setAlertDialog({
        ...alertDialog,
        open: true,
        type: "complete",
        text: `${data.seq ? "수정" : "등록"}을 실패했습니다.`,
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
      });
    }
  };
  const onSubmit = (data: FaqFormType) => {
    let seq = data.seq;
    setAlertDialog({
      ...alertDialog,
      open: true,
      type: "confirm",
      text: `${seq ? "수정" : "등록"} 하시겠습니까?`,
      handleOK: () => handleDataSubmit(),
      handleClose: () => {
        setAlertDialog({
          ...alertDialog,
          open: false,
        });
      },
    });
  };

  const handleCancel = (seq: string) => {
    if (seq) {
      navigate(-1);
    } else {
      setAlertDialog({
        ...alertDialog,
        type: "confirm",
        text: "취소하시겠습니까?",
        open: true,
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            type: "complete",
            text: "취소되었습니다.",
            open: true,
            handleOK: () => {
              navigate(-1);
            },
            handleClose: () => {
              navigate(-1);
            },
          });
        },
        handleClose: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
      });
    }
  };

  useEffect(() => {
    if (faqData && faqData.faq) {
      reset({
        seq: faqData.faq.seq || "",
        useYn: faqData.faq.useYn,
        usePrdYn: faqData.faq.usePrdYn,
        usePrdStrtYmd: faqData.faq.usePrdStrtYmd
          ? `${faqData.faq.usePrdStrtYmd} ${faqData.faq.usePrdStrtHis}`
          : "",
        usePrdStrtHis: faqData.faq.usePrdStrtHis ?? "",
        usePrdEndYmd: faqData.faq.usePrdStrtYmd
          ? `${faqData.faq.usePrdEndYmd} ${faqData.faq.usePrdEndHis}`
          : "",
        usePrdEndHis: faqData.faq.usePrdEndHis ?? "",
        ttl: faqData.faq.ttl,
        cont: faqData.faq.cont,
        faqImageList:
          faqData.faq.faqImageList.length > 0
            ? faqData.faq.faqImageList.map((file) => file.attachImage)
            : [
                {
                  seq: "",
                  uri: "",
                  orgNm: "",
                  ext: "",
                  capa: 0,
                },
              ],
      });
    }
  }, [faqData]);

  return (
    <main id="sample" css={globalCSS.listContainer}>
      <div className="title-wrap">
        <div className="breadcrumbs">
          <div className="b-main">
            <h2>고객센터</h2>
          </div>
          <div className="b-sub">
            <span>홈</span>
            <span>고객센터</span>
            <span>상세</span>
          </div>
        </div>
      </div>
      <div className="cont-wrap">
        <div className="cont-inner">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="sub-title">
              고객센터 정보(<span className="color-point">*</span> 필수항목)
            </h3>
            <table css={globalCSS.formTable}>
              <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "40%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "40%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputLabel required>제목</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Controller
                      name="ttl"
                      control={control}
                      render={({ field }) => (
                        <XTInput
                          {...field}
                          fullWidth
                          placeholder="제목을 입력해 주세요."
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => {
                            let value = maxLen(e.target.value, 50);
                            field.onChange(value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <span
                                className="count"
                                css={globalCSS.inputLength}
                              >
                                {watch("ttl").length}&nbsp;/&nbsp;50
                              </span>
                            </InputAdornment>
                          }
                        />
                      )}
                    />
                    {errors.ttl?.message && (
                      <XTFormHelperText className="error">
                        {errors.ttl?.message}
                      </XTFormHelperText>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel required>게시여부</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Controller
                      name="useYn"
                      control={control}
                      render={({ field }) => (
                        <XTRadioGroup row {...field}>
                          <XTRadio
                            value={"Y"}
                            control={<Radio />}
                            label={"Y"}
                          />
                          <XTRadio
                            value={"N"}
                            control={<Radio />}
                            label={"N"}
                          />
                        </XTRadioGroup>
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel required>게시기간</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container className="form-grid" spacing={2}>
                      <Grid item>
                        <Controller
                          name="usePrdYn"
                          control={control}
                          render={({ field }) => (
                            <XTRadioGroup
                              row
                              {...field}
                              onChange={handleChangeRadio}
                            >
                              <XTRadio
                                value="N"
                                control={<Radio />}
                                label={"상시"}
                              />
                              <XTRadio
                                value="Y"
                                control={<Radio />}
                                label={"기간 선택"}
                              />
                            </XTRadioGroup>
                          )}
                        />
                      </Grid>
                      {watch("usePrdYn") == "Y" && (
                        <>
                          <Grid item>
                            <Controller
                              name="usePrdStrtYmd"
                              control={control}
                              render={({ field }) => (
                                <XTDatePicker
                                  timePicker={true}
                                  value={watch("usePrdStrtYmd")}
                                  onChange={(newDate) => {
                                    field.onChange(newDate);
                                  }}
                                  maxDate={watch("usePrdEndYmd")}
                                  placeholder="시작일시를 선택하세요."
                                />
                              )}
                            />
                          </Grid>
                          <Grid item>
                            <div className="divider">
                              <span>~</span>
                            </div>
                          </Grid>
                          <Grid item>
                            <Controller
                              name="usePrdEndYmd"
                              control={control}
                              render={({ field }) => (
                                <XTDatePicker
                                  timePicker={true}
                                  value={watch("usePrdEndYmd")}
                                  minDate={watch("usePrdStrtYmd")}
                                  onChange={(newDate) => {
                                    field.onChange(newDate);
                                  }}
                                  placeholder="종료일시를 선택하세요."
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    {(errors.usePrdStrtYmd || errors.usePrdEndYmd) && (
                      <XTFormHelperText className="error">
                        {errors?.usePrdStrtYmd?.message ||
                          errors?.usePrdEndYmd?.message}
                      </XTFormHelperText>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>이미지</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTFileUpload
                      file={watch(`faqImageList`)}
                      data={{
                        domain: "faq",
                        type: "IMAGE",
                        fileType: ["jpg", "jpeg", "png"],
                        limitMB: 10,
                        setValue,
                        setKey: "faqImageList",
                        submitCount,
                        multiple: true,
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel required>내용</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Controller
                      name="cont"
                      control={control}
                      render={({ field }) => (
                        <XTFormControl>
                          <XTTextarea
                            {...field}
                            multiline
                            placeholder="내용을 입력해 주세요."
                            type="text"
                            onChange={(e) => {
                              let value = maxLen(e.target.value, 2000);
                              field.onChange(value);
                            }}
                            className={watch("cont") && "input-filled"}
                            inputProps={{ maxLength: 2000 }}
                          ></XTTextarea>
                          <span css={globalCSS.textareaLength}>
                            {watch("cont").length}
                            &nbsp;/&nbsp;
                            {regComma(2000)}
                          </span>
                        </XTFormControl>
                      )}
                    />
                    {errors.cont?.message && (
                      <XTFormHelperText className="error">
                        {errors.cont?.message}
                      </XTFormHelperText>
                    )}
                  </td>
                </tr>

                {faqData?.faq?.seq && (
                  <>
                    <tr>
                      <th scope="row">
                        <InputLabel>등록자</InputLabel>
                      </th>
                      <td>
                        {faqData?.faq?.rgstrManagerAccount?.nm
                          ? `${faqData?.faq?.rgstrManagerAccount?.nm}(${faqData?.faq?.rgstrManagerAccount?.id})`
                          : "-"}
                      </td>
                      <th scope="row">
                        <InputLabel>등록일시</InputLabel>
                      </th>
                      <td>
                        {formatYmdHisDot(
                          faqData?.faq?.rgstYmd,
                          faqData?.faq?.rgstHis
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <InputLabel>수정자</InputLabel>
                      </th>
                      <td>
                        {faqData?.faq?.modrManagerAccount?.nm
                          ? `${faqData?.faq?.modrManagerAccount?.nm}(${faqData?.faq?.modrManagerAccount?.id})`
                          : "-"}
                      </td>
                      <th scope="row">
                        <InputLabel>수정일시</InputLabel>
                      </th>
                      <td>
                        {formatYmdHisDot(
                          faqData?.faq?.modYmd,
                          faqData?.faq?.modHis
                        )}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            <div css={globalCSS.bottomWrap}>
              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  <XTButton
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick={() => {
                      handleCancel(faqData?.faq?.seq || "");
                    }}
                  >
                    {faqData?.faq?.seq ? "목록" : "취소"}
                  </XTButton>
                </Grid>
                <Grid item>
                  {faqData?.faq?.seq && (
                    <XTButton
                      variant="outlined"
                      color="primary"
                      size="medium"
                      onClick={handleDelete}
                    >
                      삭제
                    </XTButton>
                  )}
                  <XTButton
                    variant="outlined"
                    color="primary"
                    size="medium"
                    type="submit"
                  >
                    {faqData?.faq?.seq ? "수정" : "등록"}
                  </XTButton>
                </Grid>
              </Grid>
              <AlertDialog
                open={alertDialog.open}
                text={alertDialog.text}
                type={alertDialog.type}
                handleOK={alertDialog.handleOK}
                handleClose={alertDialog.handleClose}
                preventCloseEvent={true}
              />
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
