import { styled } from "@mui/material/styles";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Input,
  InputLabel,
  TextareaAutosize,
  TextField,
} from "@mui/material";

export const XTCheckBoxGroup = styled(FormGroup)(({ theme }) => ({
  "&.MuiFormGroup-row": {
    "& .MuiFormControlLabel-root": {
      "& + .MuiFormControlLabel-root": {
        marginLeft: "30px",
      },
    },
  },

  "&.MuiFormGroup-root": {
    "&:not(.MuiFormGroup-row)": {
      "& .MuiFormControlLabel-root": {
        "& + .MuiFormControlLabel-root": {
          marginTop: "5px",
        },
      },
    },
  },
}));

export const XTCheckBox = styled(FormControlLabel)(({ theme }) => ({
  boxSizing: "border-box",
  padding: 0,
  margin: 0,

  "&.MuiFormControlLabel-root": {
    display: "flex",
    alignItems: "center",
  },

  "& .MuiButtonBase-root": {
    width: "18px",
    height: "18px",
    padding: 0,
    border: `1px solid ${theme.palette.line1}`,
    borderRadius: "4px",
    boxSizing: "border-box",
    backgroundColor: "transparent",

    "&:hover": {
      backgroundColor: `${theme.palette.white}`,
    },

    "&.Mui-checked": {
      background: "url(/images/icon/ico-checked.svg) no-repeat center",
      backgroundColor: `${theme.palette.primary.main};`,
      borderColor: `${theme.palette.primary.main};`,
    },

    "& input": {
      borderRadius: "4px",
    },

    "& svg": {
      display: "none",
    },

    "&.Mui-disabled": {
      background: ` ${theme.palette.disabled}`,
      border: `1px solid ${theme.palette.disabled}`,

      "&.Mui-checked": {
        background: "url(/images/icon/ico-checked.svg) no-repeat center",
        backgroundColor: `${theme.palette.disabled};`,
      },
    },
  },

  "& .PrivateSwitchBase-input": {
    "&:hover": {
      backgroundColor: `${theme.palette.white}`,
    },
  },

  "& .MuiTypography-root": {
    marginLeft: `${theme.typography.pxToRem(6)}`,
    fontWeight: 400,
    fontSize: `${theme.typography.pxToRem(14)}`,
    lineHeight: `${theme.typography.pxToRem(24)}`,
    color: `${theme.palette.info.main}`,
  },
}));
