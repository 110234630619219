import {
  updateCertificateAPI,
  updateCdCertificateAPI,
  loginAPI,
  changePassWordAPI,
  refreshTokenAPI,
  logoutAPI,
} from "@/api/login";
import { loadCommonAdminDetailAPI } from "@/api/system/manager";
import { UserInfoState } from "@/state";
import { AccessToken } from "@/types";
import {
  LoginFormType,
  RefreshTokenType,
  UpadteCdFormType,
} from "@/types/login";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";

export function useLoginManager() {
  const navigate = useNavigate();
  const setUserInfo = useSetRecoilState(UserInfoState);
  const resetUserInfo = useResetRecoilState(UserInfoState);

  const login = useMutation<AxiosResponse, AxiosError, LoginFormType>({
    mutationFn: (form: LoginFormType) => loginAPI(form),
    mutationKey: ["login"],
  });

  const logout = useMutation<AxiosResponse, AxiosError>({
    mutationFn: () => logoutAPI(),
  });

  const refreshToken = useMutation<
    AxiosResponse<{ data: AccessToken }>,
    AxiosError,
    RefreshTokenType
  >({
    mutationFn: (form: RefreshTokenType) => refreshTokenAPI(form),
  });

  const modify = useMutation({
    mutationFn: (mgSeq: string) => updateCertificateAPI(mgSeq),
  });

  const modifyCd = useMutation({
    mutationFn: (form: UpadteCdFormType) => updateCdCertificateAPI(form),
  });

  const userInfoMutation = useMutation({
    mutationFn: (seq: string) => loadCommonAdminDetailAPI(seq),
  });

  const changePwMutation = useMutation({
    mutationFn: (pwd: string) => changePassWordAPI(pwd),
  });

  const handleSetUserInfo = async (seq: string) => {
    try {
      const res = await userInfoMutation.mutateAsync(seq);
      if (res.status === 200) {
        setUserInfo(res.data.data);
      }

      return res;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response;
      }
    }
  };

  return {
    login,
    logout,
    refreshToken,
    modify,
    modifyCd,
    changePwMutation,
    handleSetUserInfo,
    resetUserInfo,
  };
}
