/** @format */

import { lsToPx } from "./common";
import { css, Theme } from "@emotion/react";
import theme from "./theme";

const globalCSS = {
  /**************************************************************************************************
  layout
  **************************************************************************************************/
  layout: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background: ${theme.palette.bg_main};
    overflow: hidden;

    .layout-inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;

      #inner-contents {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 30px 30px 50px;
        height: 100%;
        overflow: auto;

        > main,
        > div {
          min-width: 1280px;

          /* min-height: 774px; */
        }
      }
    }
  `,

  main: css``,
  header: css``,
  footer: css`
    width: 100%;
  `,

  gridWrap: css`
    .total-wrap {
      display: flex;
      letter-spacing: -0.4px;
      font-weight: 500;
      color: ${theme.palette.text_sub2};

      span {
        margin-left: 12px;
        margin-right: 2px;
        font-weight: 700;
        color: ${theme.palette.default};
      }

      + .MuiDataGrid-root {
        margin-top: 20px;
      }
    }
  `,

  listContainer: css`
    display: flex;
    flex-direction: column;

    &.h-100 {
      height: 100%;
    }

    &.only-list {
      height: 100%;
      overflow: hidden;

      .title-wrap {
        + form {
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow: hidden;
        }
      }
    }

    .title-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      left: 0;
      top: -30px;
      z-index: 100;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-top: -30px;
      background: ${theme.palette.bg_main};
    }

    .breadcrumbs {
      display: flex;
      flex-direction: row;
      align-items: center;

      .b-main {
        position: relative;
        padding-right: 40px;

        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 14px;
          background: ${theme.palette.line1};
        }

        h2 {
          font-size: 20px;
          font-weight: 700;
        }
      }

      .b-sub {
        display: flex;
        flex-direction: row;

        span {
          position: relative;
          font-size: 14px;
          font-weight: 500;
          color: ${theme.palette.text_sub2};

          &:not(:last-of-type) {
            padding-right: 22px;

            &:after {
              content: "";
              position: absolute;
              right: 6px;
              top: 50%;
              transform: translateY(-50%);
              display: block;
              width: 7px;
              height: 12px;
              background: url("/images/icon/ico-arrow-right-grey.svg") no-repeat
                center;
              background-size: 100%;
            }
          }
        }
      }
    }

    .cont-wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 0;
      overflow: hidden;
      /* height: 100%; */
      .cont-inner {
        + .cont-inner {
          margin-top: 30px;
        }
      }

      .sub-title {
        position: relative;
        padding-left: 20px;
        font-size: 16px;
        font-weight: 500;

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: solid 1px ${theme.palette.default};
        }

        .color-point {
          color: ${theme.palette.error.main};
        }
        + table {
          margin-top: 15px;
        }
      }

      .btn-wrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        + table {
          margin-top: 10px;
        }
      }
      .search-wrap {
        + .grid-wrap {
          margin-top: 30px;
        }

        .action-button-group {
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
          .MuiButtonBase-root {
            + .MuiButtonBase-root {
              margin-left: 6px;
            }
          }
        }
      }

      .helper-text {
        padding: 10px 0;
        font-size: 14px;
        color: ${theme.palette.text_sub1};
      }

      .grid-wrap {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .total-wrap {
        display: flex;
        justify-content: space-between;
        .total-cnt {
          display: flex;
          letter-spacing: -0.4px;
          font-weight: 500;
          color: ${theme.palette.text_sub2};

          span {
            margin-left: 12px;
            margin-right: 2px;
            font-weight: 700;
            color: ${theme.palette.default};
          }
        }

        + .MuiDataGrid-root {
          margin-top: 20px;
        }
      }
    }
  `,

  detailContainer: css`
    .MuiDialogTitle-root {
    }

    .MuiDialogContent-root {
      .cont-wrap {
        .cont-inner {
          + .cont-inner {
            margin-top: 30px;
          }
        }

        .sub-title {
          position: relative;
          padding-left: 20px;
          font-size: 16px;
          font-weight: 500;

          &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: solid 1px ${theme.palette.default};
          }

          + table {
            margin-top: 15px;
          }
        }

        .btn-wrap {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          + table {
            margin-top: 10px;
          }
        }
      }
    }

    .MuiDialogActions-root {
      .bottom-wrap {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        .MuiButton-root {
          flex: 1;
          min-width: initial;
          max-width: 200px;
        }
      }
    }
  `,

  formTable: css`
    width: 100%;
    table-layout: fixed;

    tr {
      th,
      td {
        height: 38px;
        font-size: 14px;
        border-top: solid 1px ${theme.palette.line1};
        border-bottom: solid 1px ${theme.palette.line1};
      }

      th {
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        color: ${theme.palette.default};
        background: ${theme.palette.bg_02};

        .MuiInputLabel-root {
          font-size: 14px;
          font-weight: 700;
          color: ${theme.palette.default};
          .MuiInputLabel-asterisk {
            color: ${theme.palette.error.main};
          }
        }
      }

      td {
        padding: 3px 30px;
        color: ${theme.palette.default};
        background: ${theme.palette.white};
      }
    }

    .form-grid {
      margin-top: 0;

      .MuiGrid-item {
        padding-top: 0;

        .divider {
          margin: 3px 5px 0px;
          font-weight: 500;
        }
      }
    }

    .date-btn-group {
      display: flex;
      flex-direction: row;

      .MuiButton-root {
        + .MuiButton-root {
          margin-left: 4px;
        }
      }
    }
  `,

  customTable: css`
    width: 100%;
    table-layout: fixed;

    thead {
      tr {
        th {
          padding: 3px 30px;
          height: 38px;
          background-color: ${theme.palette.bg_02};
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          color: ${theme.palette.default};
          border-top: solid 1px ${theme.palette.line1};
          border-bottom: solid 1px ${theme.palette.line1};

          &:not(:last-of-type) {
            border-right: solid 1px ${theme.palette.line1};
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 3px 30px;
          height: 38px;
          background-color: ${theme.palette.white};
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: ${theme.palette.default};
          border-bottom: solid 1px ${theme.palette.line1};

          &:not(:last-of-type) {
            border-right: solid 1px ${theme.palette.line1};
          }

          .MuiFormGroup-root {
            justify-content: center;
          }
        }
      }
    }
  `,

  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  flexSpaceBetween: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  flexFlexStart: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `,

  hiddenPc: css`
    display: none;
    @media (max-width: ${theme.breakpoints.values.xs}px) {
      display: block !important;
    }
  `,

  hiddenMobile: css`
    display: block;
    @media (max-width: ${theme.breakpoints.values.xs}px) {
      display: none !important;
    }
  `,

  /**************************************************************************************************
  Typography
  **************************************************************************************************/
  h1: css`
    font-size: ${theme.typography.pxToRem(45)};
    font-weight: 700;
    line-height: ${theme.typography.pxToRem(60)};
    color: ${theme.palette.default};
    letter-spacing: ${lsToPx(45, 4)};
  `,
  h2: css`
    font-size: ${theme.typography.pxToRem(20)};
    font-weight: 700;
    line-height: ${theme.typography.pxToRem(20)};
    color: ${theme.palette.default};
    letter-spacing: ${lsToPx(45, 4)};
  `,
  h3: css`
    font-size: ${theme.typography.pxToRem(16)};
    font-weight: 700;
    line-height: ${theme.typography.pxToRem(16)};
    color: ${theme.palette.default};
    letter-spacing: ${lsToPx(16, 4)};
  `,

  subTitle: css`
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(14)};
    line-height: ${theme.typography.pxToRem(21)};
    color: ${theme.palette.text_sub2};
    letter-spacing: ${lsToPx(14, 4)};
  `,

  bodyTitle: css`
    font-size: ${theme.typography.pxToRem(16)};
    line-height: ${theme.typography.pxToRem(16)};
    color: ${theme.palette.default};
    letter-spacing: ${lsToPx(16, 4)};
  `,

  body: css`
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(14)};
    line-height: ${theme.typography.pxToRem(14)};
    color: ${theme.palette.default};
    letter-spacing: ${lsToPx(14, 4)};
  `,

  bodyBold: css`
    font-weight: 700;
    font-size: ${theme.typography.pxToRem(14)};
    line-height: ${theme.typography.pxToRem(14)};
    color: ${theme.palette.default};
    letter-spacing: ${lsToPx(14, 4)};
  `,

  article1: css`
    font-weight: 500;
    font-size: ${theme.typography.pxToRem(40)};
    line-height: 1;
    letter-spacing: ${lsToPx(40, 4)};
  `,

  ellipsis: css`
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  MultiEllipsis: css`
    max-height: 2.6;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  hidden: css`
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px 0 0 -1px;
    padding: 0;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;
    opacity: 0;
    position: absolute;
  `,
  lineHeight1: css`
    line-height: 1 !important;
  `,
  lineHeight2: css`
    line-height: 1.3 !important;
  `,
  weight400: css`
    font-weight: 400 !important;
  `,
  weight500: css`
    font-weight: 500 !important;
  `,
  weight600: css`
    font-weight: 600 !important;
  `,
  weight700: css`
    font-weight: 700 !important;
  `,
  weight900: css`
    font-weight: 900 !important;
  `,
  preLine: css`
    white-space: pre-line;
  `,

  /**************************************************************************************************
  sns links
  **************************************************************************************************/

  sns: css``,
  /**************************************************************************************************
  Icons
  **************************************************************************************************/

  // Logo

  logo: css`
    display: block;
    width: ${theme.typography.pxToRem(121)};
    height: ${theme.typography.pxToRem(33)};
    background: url("/images/icon/logo.svg") no-repeat center / cover;
  `,
  footerLogo: css`
    width: ${theme.typography.pxToRem(111)};
    height: ${theme.typography.pxToRem(29)};
    background: url("/images/icon/footer-logo.svg") no-repeat center / cover;
  `,

  icoDownMenu: css`
    display: block;
    width: ${theme.typography.pxToRem(14)};
    height: ${theme.typography.pxToRem(14)};
    background: url("/images/icon/ico-down-menu.svg") no-repeat center / cover;
  `,

  // ------------------------ icons 20X20
  icoLogout: css`
    display: block;
    width: ${theme.typography.pxToRem(18)};
    height: ${theme.typography.pxToRem(17)};
    background: url("/images/icon/ico-logout.svg") no-repeat center / cover;
  `,

  icoExcel: css`
    display: block;
    width: ${theme.typography.pxToRem(23)};
    height: ${theme.typography.pxToRem(20)};
    background: url("/images/icon/ico-excel.png") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoTime: css`
    display: block;
    width: ${theme.typography.pxToRem(20)};
    height: ${theme.typography.pxToRem(20)};
    background: url("/images/icon/20/ico-time.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoCalendar: css`
    display: block;
    width: ${theme.typography.pxToRem(20)};
    height: ${theme.typography.pxToRem(20)};
    background: url("/images/icon/20/ico-calendar.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoPush: css`
    display: block;
    width: ${theme.typography.pxToRem(20)};
    height: ${theme.typography.pxToRem(20)};
    background: url("/images/icon/20/ico-push.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  // ------------------------ icons 30X30
  icoUser: css`
    display: block;
    width: ${theme.typography.pxToRem(30)};
    height: ${theme.typography.pxToRem(30)};
    background: url("/images/icon/30/ico-user.svg") no-repeat center / cover;
  `,

  icoKalis: css`
    display: block;
    width: ${theme.typography.pxToRem(30)};
    height: ${theme.typography.pxToRem(30)};
    background: url("/images/icon/30/ico-kalis.svg") no-repeat center / cover;
  `,

  icoMealcare: css`
    display: block;
    width: ${theme.typography.pxToRem(30)};
    height: ${theme.typography.pxToRem(30)};
    background: url("/images/icon/30/ico-mealcare.svg") no-repeat center / cover;
  `,

  icoSmartorder: css`
    display: block;
    width: ${theme.typography.pxToRem(30)};
    height: ${theme.typography.pxToRem(30)};
    background: url("/images/icon/30/ico-smartorder.svg") no-repeat center /
      cover;
  `,

  icoBoard: css`
    display: block;
    width: ${theme.typography.pxToRem(30)};
    height: ${theme.typography.pxToRem(30)};
    background: url("/images/icon/30/ico-board.svg") no-repeat center / cover;
  `,

  icoHeadphone: css`
    display: block;
    width: ${theme.typography.pxToRem(30)};
    height: ${theme.typography.pxToRem(30)};
    background: url("/images/icon/30/ico-headphone.svg") no-repeat center /
      cover;
  `,

  icoSetting: css`
    display: block;
    width: ${theme.typography.pxToRem(30)};
    height: ${theme.typography.pxToRem(30)};
    background: url("/images/icon/30/ico-setting.svg") no-repeat center / cover;
  `,
  // ------------------------ icons 40X40

  icoProfile: css`
    display: block;
    width: ${theme.typography.pxToRem(40)};
    height: ${theme.typography.pxToRem(40)};
    background: url("/images/icon/40/ico-profile.svg") no-repeat center / cover;
  `,

  // main

  icoMainOrder: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/main/ico-order.svg") no-repeat center / cover;
  `,
  icoMainWarehousing: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/main/ico-warehousing.svg") no-repeat center /
      cover;
  `,
  icoMainTakingGoods: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/main/ico-taking-goods.svg") no-repeat center /
      cover;
  `,

  // 16x16

  icoCalSm: css`
    display: inline-block;
    width: ${theme.typography.pxToRem(16)};
    height: ${theme.typography.pxToRem(16)};
    background: url("/images/icon/ico-calender-sm.svg") no-repeat center / cover;
  `,

  icoDownloadSm: css`
    display: inline-block;
    width: ${theme.typography.pxToRem(16)};
    height: ${theme.typography.pxToRem(16)};
    background: url("/images/icon/ico-download-sm.svg") no-repeat center / cover;
  `,

  // 18x18

  icoFileFilledSm: css`
    display: inline-block;
    width: ${theme.typography.pxToRem(18)};
    height: ${theme.typography.pxToRem(18)};
    background: url("/images/icon/18/ico-file-filled.svg") no-repeat center /
      cover;
  `,

  //24x24

  icoChatFilled: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-chat_filled.svg") no-repeat center /
      cover;
  `,

  icoDelete: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-delete.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoShow: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-show.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoHide: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-hide.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoCalender: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-calender.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }

    &.startIco {
      background: url("/images/icon/ico-calendar-gray.svg") no-repeat center /
        cover;
    }

    &.focusIco {
      background: url("/images/icon/ico-calendar-gray2.svg") no-repeat center /
        cover;
    }
  `,

  icoChat: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-chat.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoCheck: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-check.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }

    &.primary {
      filter: invert(45%) sepia(56%) saturate(933%) hue-rotate(119deg)
        brightness(102%) contrast(103%);
    }
  `,

  icoClose: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-close.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }

    &.primary {
      filter: invert(45%) sepia(56%) saturate(933%) hue-rotate(119deg)
        brightness(102%) contrast(103%);
    }
  `,

  icoConstruction: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-construction.svg") no-repeat center /
      cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoDown: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-down.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoError: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-error.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoFileFilled: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-file_filled.svg") no-repeat center /
      cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,
  icoFileFilledGray: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-file_filled_gray.svg") no-repeat center /
      cover;
  `,

  icoFile: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-file.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoHome: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-home.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoHome28: css`
    display: block;
    width: ${theme.typography.pxToRem(28)};
    height: ${theme.typography.pxToRem(28)};
    background: url("/images/icon/28/ico-home.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoLink: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-link.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoLock: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-lock.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }

    &.error {
      filter: invert(20%) sepia(70%) saturate(5659%) hue-rotate(354deg)
        brightness(94%) contrast(127%);
    }
  `,
  icoNext: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-next.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }

    &.error {
      filter: invert(20%) sepia(70%) saturate(5659%) hue-rotate(354deg)
        brightness(94%) contrast(127%);
    }
  `,
  icoNotice: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-notice.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,
  icoOrder: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-order.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,
  icoPartner: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-partner.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,
  icoPrev: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-prev.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,
  icoProducts: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-products.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoProducts28: css`
    display: block;
    width: ${theme.typography.pxToRem(28)};
    height: ${theme.typography.pxToRem(28)};
    background: url("/images/icon/28/ico-products.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,
  icoReset: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-reset.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,
  icoReturn: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-return.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,
  icoSearch: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-search.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,
  icoSell: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-sell.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,
  icoUnlock: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-unlock.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoUp: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-up.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoArrowLeft: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-arrow-left.svg") no-repeat center /
      cover;
  `,

  icoArrowRight: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-arrow-right.svg") no-repeat center /
      cover;
  `,

  //36x36

  icoFilter: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/36/ico-filter.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoJoin: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/36/ico-join.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoLogin: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/36/ico-login.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoMemberEdit: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/36/ico-member_edit.svg") no-repeat center /
      cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoMenu: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/36/ico-menu.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoMypage: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/36/ico-mypage.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoMypage24: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/24/ico-mypage.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoMypage28: css`
    display: block;
    width: ${theme.typography.pxToRem(28)};
    height: ${theme.typography.pxToRem(28)};
    background: url("/images/icon/28/ico-mypage.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoProduct: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/36/ico-products.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoWrite: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/36/ico-write.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoBack: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/36/ico-back.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoSearch36: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/36/ico-search.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoClose36: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/36/ico-close.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  //48x48

  icoComplete: css`
    display: block;
    width: ${theme.typography.pxToRem(48)};
    height: ${theme.typography.pxToRem(48)};
    background: url("/images/icon/48/ico-complete.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoMail: css`
    display: block;
    width: ${theme.typography.pxToRem(48)};
    height: ${theme.typography.pxToRem(48)};
    background: url("/images/icon/48/ico-mail.svg") no-repeat center / cover;

    &.gray {
      filter: invert(79%) sepia(1%) saturate(1407%) hue-rotate(7deg)
        brightness(86%) contrast(88%);
    }
  `,

  icoErrorBig: css`
    display: block;
    width: ${theme.typography.pxToRem(48)};
    height: ${theme.typography.pxToRem(48)};
    background: url("/images/icon/48/ico-error.svg") no-repeat center / cover;
  `,

  // mobile

  icoConstructionMo: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/mobile/ico-construction.svg") no-repeat center /
      cover;
  `,

  icoCloseMo: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/mobile/ico-close.svg") no-repeat center /
      cover;
  `,

  icoMypageMo: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/mobile/ico-mypage.svg") no-repeat center /
      cover;
  `,

  icoOrderMo: css`
    display: block;
    width: ${theme.typography.pxToRem(32)};
    height: ${theme.typography.pxToRem(32)};
    background: url("/images/icon/mobile/ico-order.svg") no-repeat center /
      cover;
  `,

  icoPartnerMo: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/mobile/ico-partner.svg") no-repeat center /
      cover;
  `,

  icoProductsMo: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/mobile/ico-products.svg") no-repeat center /
      cover;
  `,

  icoResetMo: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/mobile/ico-reset.svg") no-repeat center /
      cover;
  `,

  icoReturnMo: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/mobile/ico-return.svg") no-repeat center /
      cover;
  `,

  icoSellMo: css`
    display: block;
    width: ${theme.typography.pxToRem(36)};
    height: ${theme.typography.pxToRem(36)};
    background: url("/images/icon/mobile/ico-sell.svg") no-repeat center / cover;
  `,

  /**************************************************************************************************
  Badge
  **************************************************************************************************/
  badge: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    width: fit-content;
    min-width: 40px;
    height: 22px;
    line-height: 22px;
    min-height: auto;
    color: ${theme.palette.white};
    font-size: 10px;
    font-weight: 700;
    background-color: ${theme.palette.primary.main};
    border-radius: 4px;

    &.outline {
      background-color: ${theme.palette.white};
      border: 1px solid ${theme.palette.primary.main};
      color: ${theme.palette.primary.main};
    }

    &.outline-transparent {
      background-color: transparent;
      border: 1px solid ${theme.palette.primary.main};
      color: ${theme.palette.primary.main};
    }

    &.outlined-error {
      background-color: ${theme.palette.white};
      border: 1px solid ${theme.palette.error.main};
      color: ${theme.palette.error.main};
    }

    &.outline-transparent {
      background-color: ${theme.palette.transparent};
      color: ${theme.palette.primary.main};
      border: 1px solid ${theme.palette.primary.main};
    }

    &.disabled {
      background-color: ${theme.palette.bg90};
      color: ${theme.palette.bg66};
    }

    &.black {
      background-color: ${theme.palette.black};
      color: ${theme.palette.white};
    }
  `,
  /**************************************************************************************************
  etc
  **************************************************************************************************/
  mobileHidden: css``,

  inputLength: css`
    /* position: absolute;
    bottom: ${theme.typography.pxToRem(10)};
    right: ${theme.typography.pxToRem(10)}; */
    /* bottom: 50%;
    transform: translateY(-50%); */
    transform: translateX(-0.625rem);
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(12)};
    line-height: ${theme.typography.pxToRem(20)};
    color: ${theme.palette.bg66};
  `,

  textareaLength: css`
    position: absolute;
    bottom: ${theme.typography.pxToRem(10)};
    right: ${theme.typography.pxToRem(10)};
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(12)};
    line-height: ${theme.typography.pxToRem(12)};
    color: ${theme.palette.bg66};
  `,

  /**************************************************************************************************
  file
  **************************************************************************************************/

  fileDownload: css`
    display: flex;
    align-items: center;
    text-decoration: none !important;
    max-width: fit-content;
    @media (max-width: ${theme.breakpoints.values.xs}px) {
      /* width: 100%;
      max-width: 100%; */
    }

    & + & {
      margin-top: ${theme.typography.pxToRem(8)};
    }

    .ico {
      margin-right: ${theme.typography.pxToRem(4)};
      flex: none;
      @media (max-width: ${theme.breakpoints.values.xs}px) {
        /* flex: none; */
        margin-right: ${theme.typography.pxToRem(5)};
        width: ${theme.typography.pxToRem(24)};
        height: ${theme.typography.pxToRem(24)};
      }
    }

    .text {
      flex: 1;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      /* max-width: ${theme.typography.pxToRem(400)}; */
      word-spacing: normal;
      font-weight: 400;
      font-size: ${theme.typography.pxToRem(14)};
      line-height: ${theme.typography.pxToRem(24)};
      color: ${theme.palette.bg52};
      @media (max-width: ${theme.breakpoints.values.xs}px) {
      }
    }

    .ext {
      font-weight: 400;
      font-size: ${theme.typography.pxToRem(14)};
      line-height: ${theme.typography.pxToRem(24)};
      color: ${theme.palette.bg52};
      text-transform: uppercase !important;
    }

    .title-box {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: ${theme.typography.pxToRem(14)};
      line-height: ${theme.typography.pxToRem(24)};
      color: ${theme.palette.bg52};
      width: calc(100% - ${theme.typography.pxToRem(37)});
      max-width: fit-content;
      @media (max-width: ${theme.breakpoints.values.xs}px) {
        width: calc(100% - 2.3125rem);
      }
    }
  `,

  /**************************************************************************************************
  etc
  **************************************************************************************************/
  btnContained: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(16)};
    font-size: ${theme.typography.pxToRem(14)};
    font-weight: 400;
    line-height: 0.8;
    min-width: ${theme.typography.pxToRem(54)};
    min-height: ${theme.typography.pxToRem(40)};
    border-radius: 77.8px;
    background-color: ${theme.palette.white};

    i {
      margin-right: 8px;
    }
  `,

  colorPrimary: css`
    color: ${theme.palette.primary.main};
  `,

  inputBtnWrap: css`
    position: relative;
    display: flex;
    gap: ${theme.typography.pxToRem(10)};
    margin-right: ${theme.typography.pxToRem(10)};

    .MuiButtonBase-root {
      position: relative;
      top: auto;
      right: auto;
      transform: none;
    }
  `,

  noData: css`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: ${theme.typography.pxToRem(60)} 0;
    /* border-bottom: 1px solid ${theme.palette.bg90}; */

    &.no-line {
      border: none;
    }

    @media (max-width: ${theme.breakpoints.values.xs}px) {
      /* padding-top: 0; */
      padding: 0;
      justify-content: center;
      /* height: calc(100vh - ${theme.typography.pxToRem(112)}); */
      padding-top: ${theme.typography.pxToRem(180)};
      border-bottom: none;
      /* height: 35vh; */
    }

    i {
      margin-bottom: ${theme.typography.pxToRem(4)};
    }

    span {
      font-weight: 600;
      font-size: ${theme.typography.pxToRem(16)};
      line-height: ${theme.typography.pxToRem(26)};
      color: ${theme.palette.bg52};
    }

    button {
      margin-top: ${theme.typography.pxToRem(20)};
    }
  `,

  navigator: css`
    position: sticky;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: ${theme.typography.pxToRem(52)};
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.palette.white};
    padding: ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(10)}
      ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(20)};
    z-index: 10;

    @media (max-width: ${theme.breakpoints.values.xs}px) {
      display: flex;
    }

    .page-info-box {
      display: flex;
      align-items: center;
      .current-page {
        font-weight: 600;
        font-size: ${theme.typography.pxToRem(16)};
        line-height: ${theme.typography.pxToRem(26)};
        color: ${theme.palette.default};
        /* margin-left: ${theme.typography.pxToRem(4)}; */
      }
    }
    .toggle-btn-box {
      height: ${theme.typography.pxToRem(36)};
      button {
        + button {
          margin-left: ${theme.typography.pxToRem(10)};
        }
      }
    }
  `,

  writeBtn: css`
    margin-top: ${theme.typography.pxToRem(16)};
    margin-left: auto;

    .link-write {
      width: ${theme.typography.pxToRem(90)};
      height: ${theme.typography.pxToRem(38)};
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      border: 1px solid ${theme.palette.bg20};
      text-decoration: none;
      i {
        width: ${theme.typography.pxToRem(24)};
        height: ${theme.typography.pxToRem(24)};
        margin-right: ${theme.typography.pxToRem(6)};
      }

      span {
      }
    }
  `,
  bottomWrap: css`
    position: relative;
    margin-top: ${theme.typography.pxToRem(10)};
    min-height: ${theme.typography.pxToRem(32)};
    .MuiGrid-root {
    }
    .MuiPagination-root {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .MuiButtonBase-root {
      + .MuiButtonBase-root {
        margin-left: 6px;
      }
    }
  `,
};

export default globalCSS;
