import React, { useState } from "react";

import "./App.css";
import { css } from "@emotion/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RoutesData from "@/routes/Routes";
import Layout from "@/components/layout";
import { useRecoilValue } from "recoil";
import { alertDialogState } from "@/state";
import AlertDialog from "@/components/ui/alert-dialog";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import InterceptorsContainer from "./components/interceptor-container";
import NotFound from "@/components/not-found";

function App() {
  const alertDialog = useRecoilValue(alertDialogState);

  return (
    <BrowserRouter>
      <Routes>
        {RoutesData.map((route) => {
          let Component = route.component;
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <>
                  <Layout>
                    <Component />
                    <AlertDialog
                      open={alertDialog.open}
                      text={alertDialog.text}
                      type={alertDialog.type}
                      handleOK={alertDialog.handleOK}
                      handleClose={alertDialog.handleClose}
                      preventCloseEvent={true}
                    />
                    <InterceptorsContainer />
                  </Layout>
                </>
              }
            />
          );
        })}
        <Route
          path="*"
          element={
            <>
              <NotFound />
              <InterceptorsContainer />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

const style = {
  container: css`
    display: none;
  `,
};

export default App;
