import globalCSS from "@/assets/ts/global-css";
import guideCSS from "@/assets/ts/guide-css";
import theme from "@/assets/ts/theme";
import GuideLeft from "@/guide/components/sidebar";
import { css } from "@emotion/react";

function ColorBox({ name, color }: { name: string; color: string }) {
  return (
    <div className="guide-color-box">
      <div
        className="color-box"
        css={{ background: color }}></div>
      <div className="text-box">
        <em>{name}</em>
        <span>{color}</span>
      </div>
    </div>
  );
}

export default function GuidePalette() {
  function getColorArray(startsWithText: string) {
    const arr = Object.keys(theme.palette)
      .filter((key) => key.startsWith(startsWithText))
      .map((key) => [key, theme.palette[key]]);

    return arr;
  }

  const textSubColors = getColorArray("text_sub");
  const lineColors = getColorArray("line");
  const bgColors = getColorArray("bg_");

  return (
    <>
      <div
        className="guide-area"
        css={guideCSS.guideWrap}>
        <GuideLeft />
        <div css={[guideCSS.guideWrapper, style.guide]}>
          <div className="title-box">
            <h2 className="guide-h2">Palette</h2>
          </div>

          <div className="guide-content">
            <div>
              <h3 className="guide-h3">Primary</h3>
              <div className="guide-color-flex">
                {Object.entries(theme.palette.primary)
                  .slice(0, 6)
                  .map((color, index) => (
                    <ColorBox
                      key={index}
                      name={color[0]}
                      color={color[1]}
                    />
                  ))}
              </div>
            </div>
            <div>
              <h3 className="guide-h3">Secondary</h3>
              <div className="guide-color-flex">
                {Object.entries(theme.palette.secondary)
                  .slice(0, 6)
                  .map((color, index) => (
                    <ColorBox
                      key={index}
                      name={color[0]}
                      color={color[1]}
                    />
                  ))}
              </div>
            </div>
            <div>
              <h3 className="guide-h3">Error</h3>
              <div className="guide-color-flex">
                {Object.entries(theme.palette.error)
                  .slice(0, 6)
                  .map((color, index) => (
                    <ColorBox
                      key={index}
                      name={color[0]}
                      color={color[1]}
                    />
                  ))}
              </div>
            </div>
            <div>
              <h3 className="guide-h3">Info</h3>
              <div className="guide-color-flex">
                {Object.entries(theme.palette.info)
                  .slice(0, 6)
                  .map((color, index) => (
                    <ColorBox
                      key={index}
                      name={color[0]}
                      color={color[1]}
                    />
                  ))}
              </div>
            </div>
            <div>
              <h3 className="guide-h3">Success</h3>
              <div className="guide-color-flex">
                {Object.entries(theme.palette.success)
                  .slice(0, 1)
                  .map((color, index) => (
                    <ColorBox
                      key={index}
                      name={color[0]}
                      color={color[1]}
                    />
                  ))}
              </div>
            </div>
            <div>
              <h3 className="guide-h3">Default</h3>
              <div className="guide-color-flex">
                <ColorBox
                  name={"default"}
                  color={"#222"}
                />
              </div>
            </div>
            <div>
              <h3 className="guide-h3">Disabled</h3>
              <div className="guide-color-flex">
                <ColorBox
                  name={"disabled"}
                  color={"#e6e6e6"}
                />
              </div>
            </div>
            <div>
              <h3 className="guide-h3">Text sub</h3>
              <div className="guide-color-flex">
                {textSubColors.map((color, index) => (
                  <ColorBox
                    key={index}
                    name={color[0]}
                    color={color[1]}
                  />
                ))}
              </div>
            </div>
            <div>
              <h3 className="guide-h3">Line</h3>
              <div className="guide-color-flex">
                {lineColors.map((color, index) => (
                  <ColorBox
                    key={index}
                    name={color[0]}
                    color={color[1]}
                  />
                ))}
              </div>
            </div>
            <div>
              <h3 className="guide-h3">Background</h3>
              <div className="guide-color-flex">
                {bgColors.map((color, index) => (
                  <ColorBox
                    key={index}
                    name={color[0]}
                    color={color[1]}
                  />
                ))}
              </div>
            </div>
            <div>
              <h3 className="guide-h3">Etc.</h3>
              <div className="guide-color-flex">
                <ColorBox
                  name={"white"}
                  color={"#fff"}
                />
                <ColorBox
                  name={"transparent"}
                  color={"transparent"}
                />
                <ColorBox
                  name={"placeholder"}
                  color={"#CCCECA"}
                />
                <ColorBox
                  name={"selected"}
                  color={"#FFE5E5"}
                />
                <ColorBox
                  name={"selectHover"}
                  color={"#rgba(255, 229, 229, 0.5)"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const style = {
  guide: css`
    .guide-content {
      > div {
        + div {
          margin-top: 30px;
        }
      }
    }
  `,
};
