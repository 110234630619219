import guideCSS from "@/assets/ts/guide-css";
import GuideLeft from "@/guide/components/sidebar";
import CodeMirror from "@uiw/react-codemirror";
import { XTButton } from "@/components/mui/xt-button";
import { XTTooltip } from "@/components/mui/xt-tooltip";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useState } from "react";
import { html } from "@codemirror/lang-html";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";

export default function GuideTooltip() {
  const [codemirror, setCodemirror] = useState<string | false>();
  const handleCodemirror =
    (panel: string) =>
      (event: React.SyntheticEvent, newcodemirror: boolean) => {
        setCodemirror(newcodemirror ? panel : false);
      };

  return (
    <div className="guide-area" css={guideCSS.guideWrap}>
      <GuideLeft />
      <div css={guideCSS.guideWrapper}>
        <div className="title-box">
          <h2 className="guide-h2">Tooltip</h2>
        </div>
        <div className="guide-content">
          <div>
            <h3 className="guide-h3">Tooltip - Default</h3>
            <XTTooltip title="모두 닫기" arrow>
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                data-code='<XTButton variant="outlined" color="primary" size="medium">Outlined Primary</XTButton>'
              >
                Mouse Hover
              </XTButton>
            </XTTooltip>
          </div>
          <br />
          <br />
          <Accordion
            sx={{
              margin: 0,
              p: 0,
              border: "none",
              outline: "none",
              boxShadow: "none",
              "&::before": {
                position: "relative",
              },
            }}
            expanded={codemirror === "tooltipDefault"}
            onChange={handleCodemirror("tooltipDefault")}
            className="accordion-codemirror"
          >
            <AccordionSummary>
              <p className="guide-codeMore">코드보기</p>
            </AccordionSummary>
            <AccordionDetails>
              <CodeMirror
                theme={vscodeDark}
                extensions={[html()]}
                value={`
import { XTTooltip } from "@/components/mui/xt-tooltip";
import { XTButton } from "@/components/mui/xt-button";

<XTTooltip title="모두 닫기" arrow>
    <XTButton
        variant="outlined"
        color="primary"
        size="medium"
        data-code='<XTButton variant="outlined" color="primary" size="medium">Outlined Primary</XTButton>'
        >
        Mouse Hover
    </XTButton>
</XTTooltip>
`}
              ></CodeMirror>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
