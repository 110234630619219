import { styled } from "@mui/material/styles";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";

export const XTDataGrid = styled(DataGrid)<DataGridProps>(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  maxHeight: "100%",
  height: "100%",
  border: "none",
  borderRadius: 0,
  overflow: "auto",

  "@media print": {
    fontFamily: "initial",
    overflow: "visible !important",

    "& .print-description": {
      display: "block !important",
      marginTop: "10px",
      marginBottom: "10px",
      fontSize: "20px",
      fontWeight: 500,
    },

    "& .print-width-100": {
      width: "100px !important",
      minWidth: "100px !important",
      maxWidth: "100px !important",
    },

    "& .print-width-250": {
      width: "250px !important",
      minWidth: "250px !important",
      maxWidth: "250px !important",
    },

    "& .MuiDataGrid-columnHeaders": {
      borderTop: "none !important",
      borderBottom: "none !important",

      "& .MuiDataGrid-columnHeader": {
        borderTop: `solid 1px ${theme.palette.line1} !important`,
        borderBottom: `solid 1px ${theme.palette.line1} !important`,
      },
    },

    "& .MuiDataGrid-row": {
      "& .MuiDataGrid-cell": {
        background: "transparent !important",
      },
    },

    "*": {
      fontFamily: "initial !important",
      textDecoration: "none !important",
    },

    "& .MuiDataGrid-virtualScroller": {
      "&:has(.no-data)": {
        height: "300px !important",
      },
    },
  },

  "*": {
    outline: "none !important",
  },

  "& .print-description": {
    display: "none",
  },

  ".MuiDataGrid-columnHeader ": {
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      "& .MuiDataGrid-columnHeaderTitle, button": {
        fontSize: "14px",
        fontWeight: 700,
      },
    },

    "&.ma0": {
      ".MuiDataGrid-columnHeaderDraggableContainer": {
        ".MuiDataGrid-columnHeaderTitleContainer": {
          margin: "0 !important",
        },
      },
    },

    ".MuiInputLabel-root": {
      fontSize: "14px",
      fontWeight: 700,
      color: theme.palette.default,
      textAlign: "center",

      ".MuiInputLabel-asterisk": {
        color: theme.palette.error.main,
      },
    },
  },

  "&.selectable": {
    "& .Mui-selected": {
      "& .MuiDataGrid-cell": {
        backgroundColor: `${theme.palette.selected} !important`,
      },
    },

    "& .Mui-hovered": {
      "& .MuiDataGrid-cell": {
        background: `${theme.palette.bg_main}`,
      },
    },
  },

  "&.clickable": {
    "&.clickable-overflow": {
      ".MuiDataGrid-main": {
        overflow: "unset",
      },

      ".MuiDataGrid-virtualScroller": {
        overflow: "unset",
        minHeight: "none",
      },
    },

    "&.clickable-overflow2": {
      ".MuiDataGrid-virtualScroller": {
        minHeight: "unset !important",
        // maxHeight: "370px",
        height: "370px",
      },
    },
    "& .MuiDataGrid-row": {
      cursor: "pointer",

      "&.Mui-selected": {
        "& .MuiDataGrid-cell": {
          backgroundColor: `${theme.palette.selected} !important`,
        },
      },

      "&.selected-row": {
        "& .MuiDataGrid-cell": {
          backgroundColor: `${theme.palette.bg90} !important`,
        },
      },

      "&.Mui-hovered": {
        "& .MuiDataGrid-cell": {
          background: `${theme.palette.bg_main}`,
        },
      },
    },
  },

  "& .MuiDataGrid-columnHeaders": {
    borderTop: `1px solid ${theme.palette.line1}`,
    borderBottom: `1px solid ${theme.palette.line1}`,
    background: theme.palette.bg_02,
    borderRadius: 0,
    width: "100%",

    ".MuiDataGrid-columnHeaderReorder": {
      position: "relative",
      "&::after": {
        content: "'순서'",
        display: "block",
        position: "absolute",
        top: 0,
        left: "12px",
        fontSize: "14px",
        fontWeight: 700,
      },
    },

    ".MuiDataGrid-columnHeadersInner": {
      width: "100%",

      ">div": {
        width: "100%",
      },
    },

    "& .MuiDataGrid-pinnedColumnHeaders": {
      background: theme.palette.bg_02,
    },

    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: "14px",
      fontWeight: 700,
    },

    "& .MuiDataGrid-columnHeader": {
      "&.required": {
        ".MuiDataGrid-columnHeaderTitle": {
          "&::after, &:after": {
            content: "'*'",
            position: "relative",
            display: "inline-block",
            color: theme.palette.error.main,
            marginLeft: "2px",
          },
        },
      },

      "&:not(:last-of-type)": {
        borderRight: `1px solid ${theme.palette.line1}`,
      },

      "&.MuiDataGrid-columnHeader--alignCenter": {
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          marginLeft: "-6px",
        },
        "&.MuiDataGrid-columnHeaderCheckbox": {
          outline: "none !important",
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            marginLeft: "0",
          },
        },
      },

      "& .MuiDataGrid-columnSeparator": {
        opacity: "0 !important",
      },

      "&:focus": {
        outline: "none !important",
      },
    },
  },

  "& .MuiDataGrid-virtualScroller": {
    width: "100%",
    // minHeight: "4px !important",
    "&:has(.no-data)": {
      minHeight: "200px",
      height: "100%",
      overflow: "hidden",

      ".MuiDataGrid-overlayWrapper": {
        width: "100% !important",
        height: "100% !important",

        "& .MuiDataGrid-overlayWrapperInner": {
          width: "100% !important",
          height: "100% !important",
        },
      },

      "& .MuiDataGrid-virtualScrollerContent": {
        width: " 100% !important",
      },
    },

    "& .MuiDataGrid-virtualScrollerContent": {},

    ".MuiDataGrid-overlayWrapper": {
      position: "absolute",
      width: "100% !important",
      height: "4px",

      "& .MuiDataGrid-overlayWrapperInner": {
        width: "100% !important",
      },
    },

    "& .MuiDataGrid-row": {
      "& .MuiDataGrid-withBorderColor": {
        borderColor: theme.palette.line1,
      },

      "& .MuiDataGrid-cell": {
        padding: "8.5px 10px",
        background: theme.palette.white,

        "&:not(:last-of-type)": {
          "&:has(+div[role])": {
            borderRight: `1px solid ${theme.palette.line1}`,
          },
        },

        "&:focus": {
          outline: "none",
        },

        ".MuiInput-root": {
          minWidth: "auto",
        },

        ".xt-timepicker": {
          ".btn-datepicker": {
            minWidth: "100px",
          },
        },

        ".MuiOutlinedInput-root": {
          width: "100%",
        },
      },

      "& .MuiDataGrid-cellCheckbox": {
        outline: "none",
      },
    },
  },

  "& .MuiDataGrid-overlayWrapper": {
    "& .no-data": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      // height: "300px",
      height: "100%",
      background: theme.palette.white,
    },
  },

  "& .MuiCheckbox-root": {
    width: "18px",
    height: "18px",
    padding: 0,
    border: `1px solid ${theme.palette.line1}`,
    borderRadius: "4px",
    boxSizing: "border-box",
    backgroundColor: "transparent",

    "&:hover": {
      backgroundColor: `${theme.palette.white}`,
    },

    "&.Mui-checked": {
      background: "url(/images/icon/ico-checked.svg) no-repeat center",
      backgroundColor: `${theme.palette.primary.main};`,
      borderColor: `${theme.palette.primary.main};`,
    },

    "& input": {
      borderRadius: "4px",
    },

    "& svg": {
      display: "none",
    },

    "&.Mui-disabled": {
      background: ` ${theme.palette.disabled}`,
      border: `1px solid ${theme.palette.disabled}`,

      "&.Mui-checked": {
        background: "url(/images/icon/ico-checked.svg) no-repeat center",
        backgroundColor: `${theme.palette.disabled};`,
      },
    },

    "& .PrivateSwitchBase-input": {
      "&:hover": {
        backgroundColor: `${theme.palette.white}`,
      },
    },

    "& .MuiTypography-root": {
      marginLeft: `${theme.typography.pxToRem(6)}`,
      fontWeight: 400,
      fontSize: `${theme.typography.pxToRem(14)}`,
      lineHeight: `${theme.typography.pxToRem(24)}`,
      color: `${theme.palette.info.main}`,
    },
  },
}));
