// select

import {
  Checkbox,
  ListSubheader,
  makeStyles,
  MenuItem,
  MenuList,
  NativeSelect,
  Pagination,
  Select,
  SelectProps,
} from "@mui/material";
import theme from "@/assets/ts/theme";
import { styled } from "@mui/material/styles";

export const XTSelect = styled(Select)(({ theme }) => ({
  fontWeight: 400,
  maxWidth: "100%",
  fontSize: `${theme.typography.pxToRem(14)}`,
  color: `${theme.palette.default}`,
  backgroundColor: `${theme.palette.white}`,
  borderColor: theme.palette.primary.main,
  height: "29px",
  lineHeight: "29px",

  "&.MuiInputBase-root": {
    borderRadius: "0",
  },

  "& svg": {
    display: "none",
  },

  "& .MuiSelect-select": {
    display: "block",
    alignItems: "center",
    padding: "0px 12px",
    height: "auto !important",
    minHeight: "auto !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    minWidth: 0,

    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "50%",
      right: 0,
      width: 30,
      height: 29,
      background: "url('/images/icon/ico-down.svg') no-repeat center / cover",
      transform: "translateY(-50%) rotate(0deg)",
      transition: "transform 0.2s ease",
    },
    "&[aria-expanded=true]": {
      "&:after": {
        transform: "translateY(-50%) rotate(180deg)",
      },
    },
  },

  "& fieldset": {
    border: `1px solid ${theme.palette.line1}`,
  },

  "&:hover": {
    "&& fieldset": {
      border: `1px solid ${theme.palette.line1}`,
    },
  },

  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
  },

  "& .placeholder": {
    fontWeight: 400,
    fontSize: "14px",
    color: `${theme.palette.placeholder}`,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },

  "&.Mui-disabled": {
    background: `${theme.palette.disabled}`,
    color: `${theme.palette.disabled}`,
    "& span": {
      opacity: 1,
      webkitTextFillColor: `${theme.palette.disabled}`,
      color: `${theme.palette.disabled}`,
    },

    "& fieldset": {
      borderColor: `${theme.palette.disabled} !important`,
    },

    "& .MuiSelect-select": {
      "&:after": {
        filter:
          "invert(59%) sepia(6%) saturate(160%) hue-rotate(177deg) brightness(93%) contrast(82%)",
      },
    },
  },

  "& .MuiPopover-root": {
    position: "absolute",

    "& .MuiBackdrop-root": {
      background: "transparent !important",
      cursor: "initial",
    },

    "& .MuiPaper-root": {
      top: "30px !important",
      left: "0 !important",
      maxHeight: "500px !important",
      overflow: "auto",
      marginTop: "-1px",
      border: `solid 1px ${theme.palette.line1}`,
      backgroundColor: `${theme.palette.white}`,
      borderRadius: "0 !important",
      boxShadow: `none`,
    },
  },
}));

export const XTMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(12)};`,
  color: `${theme.palette.text_sub1}`,
  fontSize: `${theme.typography.pxToRem(14)}`,
  lineHeight: `${theme.typography.pxToRem(14)}`,
  fontWeight: 400,

  "&.Mui-disabled": {
    display: "none",
  },
  "&:hover": {
    backgroundColor: `${theme.palette.selectHover} !important`,
    color: `${theme.palette.primary.main}`,
  },

  "&.Mui-focusVisible": {
    color: `${theme.palette.text_sub1}`,
    backgroundColor: "transparent",
  },

  "&.Mui-selected": {
    backgroundColor: `${theme.palette.selected} !important`,
    color: `${theme.palette.primary.main}`,
    "&:hover": {
      color: `${theme.palette.primary.main}`,
    },
  },
  "&:selected": {
    backgroundColor: `${theme.palette.selected}`,
    color: `${theme.palette.default}`,
  },
}));

export const XTMenuItemWrap = {
  marginTop: "-1px",
  border: `solid 1px ${theme.palette.line1}`,
  backgroundColor: `${theme.palette.white}`,
  borderRadius: "0 !important",
  boxShadow: `none`,
};

export const XTMenuProps = {
  position: "absolute",

  "& .MuiBackdrop-root": {
    background: "transparent !important",
    cursor: "initial",
  },

  "& .MuiPaper-root": {
    top: "30px !important",
    left: "0 !important",
    maxHeight: "500px !important",
    overflow: "auto",
  },
};
