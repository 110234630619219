import { useState } from "react";
import { useEffect } from "react";
import {
  loadDestructionAPI,
  loadDestructionListAPI,
  loadDestructionTypeAPI,
  updateAllDestructionAPI,
  updateSelectDestructionAPI,
} from "@/api/log/destruction";
import {
  DestructionDetailType,
  DestructionListType,
  DestructionSchFormType,
  DestructionTypesType,
} from "@/types/log/destruction";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useLocation, useParams } from "react-router-dom";

export const useDestructionManager = () => {
  const queryClient = useQueryClient();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let schParams: DestructionSchFormType = {
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schTp: params.get("schTp") || "",
    schMthd: params.get("schMthd") || "",
    pg: params.get("pg") || "1",
    pgSz: params.get("pgSz") || "10",
    sordFld: params.get("sordFld") || "rgstYmd",
    sordMetd: params.get("sordMetd") || "DESC",
  };

  const [destructionData, setDestructionData] =
    useState<DestructionListType | null>(null);

  const {
    data: destructionList,
    isFetching,
    isSuccess,
  } = useQuery<
    AxiosResponse<{
      data: DestructionListType;
    }>,
    AxiosError,
    DestructionListType
  >({
    queryKey: ["loadDestructionList", schParams],
    queryFn: async () => await loadDestructionListAPI(schParams),
    select: (res) => {
      res.data.data?.logPrivacyDeleteList.list.map((item: any, i: number) => {
        item.index = i;
      });
      return res.data.data;
    },
  });

  const updateSelect = useMutation({
    mutationFn: (form: any) => updateSelectDestructionAPI(form),
  });

  const updateAll = useMutation({
    mutationFn: () => updateAllDestructionAPI(),
  });

  const resetQuery = () => {
    queryClient.invalidateQueries({ queryKey: ["loadDestructionList"] });
  };

  useEffect(() => {
    if (destructionList) setDestructionData(destructionList);
  }, [isSuccess, destructionList]);

  return {
    schParams,
    isFetching,
    isSuccess,
    destructionData,
    updateSelect,
    updateAll,
    resetQuery,
  };
};

export const useDestructionDetailManager = () => {
  const params = useParams();

  const queryClient = useQueryClient();
  const {
    data: destructionData,
    isFetching,
    isFetched,
    isSuccess,
  } = useQuery<
    AxiosResponse<{
      data: DestructionDetailType;
    }>,
    AxiosError,
    DestructionDetailType
  >({
    queryKey: ["loadDestruction", params.seq],
    queryFn: async () => await loadDestructionAPI(params.seq!),
    enabled: !!params.seq,
    select: (res) => {
      return res.data.data;
    },
  });

  const updateSelect = useMutation({
    mutationFn: (form: any) => updateSelectDestructionAPI(form),
  });

  const resetQuery = () => {
    queryClient.resetQueries({ queryKey: ["loadDestruction"] });
  };

  return {
    isFetching,
    isSuccess,
    destructionData,
    updateSelect,
    resetQuery,
  };
};

export const useDestructionTypeManager = () => {
  const { data: destructionTypes } = useQuery<
    AxiosResponse<{
      data: DestructionTypesType;
    }>,
    AxiosError,
    DestructionTypesType
  >({
    queryKey: ["loadDestructionTypes"],
    queryFn: async () => await loadDestructionTypeAPI(),
    select: (res) => {
      return res.data.data;
    },
  });

  return { destructionTypes };
};
