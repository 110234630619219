import globalCSS from "@/assets/ts/global-css";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useHeight } from "./_hook/use-height";
import { Box, Grid, InputLabel, TextField } from "@mui/material";
import { XTAutocomplete, XTPopper } from "@/components/mui/xt-autocomplete";
import { css, Theme } from "@emotion/react";
import { useEffect, useState } from "react";
import { filter } from "lodash";
import theme from "@/assets/ts/theme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels
);

export default function Height() {
  const { listData, schForm, handleChangeInput } = useHeight();

  const list = Array.from({ length: 19 }, (_, i) => ({ label: `${i}` }));
  const [filteredOptions, setFilteredOptions] = useState<{ label: string }[]>(
    []
  );
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);

  const createDataset = (sexTp: number, color: string) => ({
    labels: Array.from({ length: 12 }, (_, m) => `${m}개월`),
    datasets: [
      {
        label: sexTp === 1 ? "남 환아 평균키" : "여 환아 평균키",
        data: Array.from({ length: 12 }, (_, m) => {
          const item = listData.childHeightList.find(
            (item) => item.sexTp === sexTp && item.m === m
          );
          return item ? Number(item.hgt) : "";
        }),
        fill: false,
        backgroundColor: color,
        borderColor: `${color}`, // 20% 투명도
      },
      {
        label: "표준 평균",
        data: Array.from({ length: 12 }, (_, m) => {
          const item = listData.heightList.find(
            (item) => item.sexTp === sexTp && item.m === m
          );
          return item ? Number(item.hgt) : 0;
        }),
        fill: false,
        backgroundColor: "rgb(201, 203, 207)",
        borderColor: "rgba(201, 203, 207, 0.2)",
      },
    ],
  });

  const options = {
    plugins: {
      legend: { display: true, position: "top", paddingBelowLegends: true },
      tooltip: { enabled: true },
      datalabels: {
        display: true,
        align: "bottom",
        anchor: "end",
        color: "#555",
        formatter: (value: number) => `${value}`,
      },
    },
    responsive: true,
  };

  return (
    <>
      <main id="sample" css={[globalCSS.listContainer, style.container]}>
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>평균키</h2>
            </div>
            <div className="b-sub">
              <span>환아 평균키 조회</span>
            </div>
          </div>
        </div>
        <div className="cont-wrap">
          <div className="search-wrap">
            <table css={globalCSS.formTable}>
              <colgroup>
                <col style={{ width: "200px" }} />
                <col />
                <col style={{ width: "200px" }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputLabel>만나이</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTAutocomplete
                      disablePortal
                      autoHighlight={true}
                      value={schForm.schY}
                      options={list}
                      getOptionLabel={(option: any) => {
                        return option.label ?? option;
                      }}
                      onChange={(e, value: unknown) => {
                        e.preventDefault();
                        e.stopPropagation();

                        const stringValue = value
                          ? (value as { label: string }).label
                          : null;

                        if (stringValue) {
                          handleChangeInput(stringValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="선택 또는 입력해 주세요."
                        />
                      )}
                      renderOption={(props, option: any) => {
                        return <li {...props}>{option.label}</li>;
                      }}
                      isOptionEqualToValue={(option: any, value: any) => {
                        return option.label === value;
                      }}
                      sx={{ width: "250px" }}
                      PopperComponent={XTPopper}
                      noOptionsText="검색 결과가 없습니다."
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {schForm.schY ? (
            <div className="chart-wrap">
              <div className="chart-inner">
                <p>{schForm.schY}세 남 환아 평균키</p>
                <Line
                  data={createDataset(1, "rgb(54, 162, 235)")}
                  options={options as any}
                />
              </div>
              <div className="chart-inner">
                <p>{schForm.schY}세 여 환아 평균키</p>
                <Line
                  data={createDataset(2, "rgb(255, 99, 132)")}
                  options={options as any}
                />
              </div>
            </div>
          ) : (
            <Box
              sx={{
                marginTop: "30px",
                width: "100%",
                height: "100%",
                fontSize: "14px",
              }}
            >
              만나이를 선택 또는 입력해주세요.
            </Box>
          )}
        </div>
      </main>
    </>
  );
}

const style = {
  container: css`
    .chart-wrap {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      width: 50%;
      height: 100%;
      overflow: hidden;

      .chart-inner {
        flex: 1;
        display: flex;
        flex-direction: column;

        canvas {
          flex: 1;
          width: 100% !important;
          height: 100% !important;
        }

        + .chart-inner {
          margin-top: 20px;
        }
      }
    }
  `,
};
