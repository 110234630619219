import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  useMediaQuery,
} from "@mui/material";
import { html } from "@codemirror/lang-html";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import globalCSS from "@/assets/ts/global-css";
import guideCSS from "@/assets/ts/guide-css";
import theme from "@/assets/ts/theme";
import { Link, useNavigate } from "react-router-dom";
import SectTitle from "@/components/ui/sect-title";
import DatePicker from "react-multi-date-picker";
import { XTButton } from "@/components/mui/xt-button";
import XTDatePicker from "@/components/mui/xt-datepicker";
import moment, { updateLocale } from "moment";
import XTTimePicker from "../../components/mui/xt-timepicker";

export default function GuideDatePicker() {
  const [codemirror, setCodemirror] = useState<string | false>();
  const handleCodemirror =
    (panel: string) =>
    (event: React.SyntheticEvent, newcodemirror: boolean) => {
      setCodemirror(newcodemirror ? panel : false);
    };

  const isMobile = useMediaQuery(
    `(max-width:${theme.breakpoints.values.xs}px)`
  );
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState<Date | string>("20240223");

  const [selectedDateTime, setSelectedDateTime] = useState<Date | string>(
    "20240223143015"
  );

  const [selectedTime, setSelectedTime] = useState<Date | string>("1000");

  const changeDate = (e: Date) => {
    setSelectedDate(e);
  };

  const changeDateTime = (e: Date) => {
    setSelectedDateTime(e);
  };

  const changeTime = (e: Date) => {
    setSelectedTime(e);
  };

  return (
    <>
      <div className="guide-area" css={guideCSS.guideWrap}>
        <div css={guideCSS.guideWrapper}>
          <div className="title-box">
            <h2 className="guide-h2">DatePicker</h2>
          </div>
          <div className="guide-content">
            <div>
              <h3 className="guide-h3">DatePicker</h3>
              <div
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  padding: "10px",
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <XTDatePicker value={selectedDate} onChange={changeDate} />
              </div>
            </div>
            <hr className="guide-hr"></hr>
            <div>
              <h3 className="guide-h3">DatePicker + TimePicker</h3>
              <div
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  padding: "10px",
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <XTDatePicker
                  value={selectedDateTime}
                  onChange={changeDateTime}
                  timePicker={true}
                />
              </div>
            </div>{" "}
            <hr className="guide-hr"></hr>
            <div>
              <h3 className="guide-h3">TimePicker(HH:mm)</h3>
              <div
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  padding: "10px",
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <XTTimePicker value={selectedTime} onChange={changeTime} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
