import {
  changeDatepickerDate,
  formatYmdHisDot,
  regComma,
  regPhoneNumber,
} from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import XTDatePicker from "@/components/mui/xt-datepicker";
import { XTInput } from "@/components/mui/xt-input";
import { XTMenuItem, XTSelect } from "@/components/mui/xt-select";
import {
  Box,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
} from "@mui/material";
import {
  GridColDef,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useParentList } from "./_hook/use-parent";

export default function Parent() {
  const {
    listData,
    isFetching,
    schForm,
    schViewForm,
    handleChangeInput,
    handleResetInput,
    handleChangeDatePicker,
    handleClickDateButton,
    navigate,
    search,
    handleChangePage,
    handleChangePageSz,
  } = useParentList();
  const apiRef = useGridApiRef();

  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "번호",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return listData!.innoBoostParentList?.number - params.row.index;
      },
    },
    {
      field: "prntRgstYmd",
      headerName: "최초로그인 일시",
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return formatYmdHisDot(params.value, params.row.prntRgstHis);
      },
    },
    {
      field: "prntNm",
      headerName: "보호자이름",
      headerAlign: "center",
      width: 200,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value ?? "-";
      },
    },
    {
      field: "chldrList",
      headerName: "자녀이름",
      headerAlign: "center",
      flex: 1,
      width: 150,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value
          .map((item: { chldrSeq: number; chldrNm: string }) => item.chldrNm)
          .join(",")}`,

      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "prntCph",
      headerName: "휴대폰번호",
      headerAlign: "center",
      width: 200,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? regPhoneNumber(params.value) : "-",
    },
  ];

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      search();
    }
  };

  return (
    <>
      <main id="sample" css={globalCSS.listContainer} className="only-list">
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>보호자관리</h2>
            </div>
            <div className="b-sub">
              <span>회원관리</span>
              <span>보호자관리</span>
            </div>
          </div>
        </div>
        <div className="cont-wrap">
          <div className="search-wrap">
            <table css={globalCSS.formTable}>
              <colgroup>
                <col style={{ width: "200px" }} />
                <col />
                <col style={{ width: "200px" }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputLabel>최초로그인 일자</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(schViewForm.schStrtYmd)}
                          onChange={handleChangeDatePicker("schStrtYmd")}
                          maxDate={changeDatepickerDate(schViewForm.schEndYmd)}
                          placeholder="시작일을 선택하세요."
                        />
                      </Grid>
                      <Grid item>
                        <div className="divider">
                          <span>~</span>
                        </div>
                      </Grid>
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(schViewForm.schEndYmd)}
                          minDate={changeDatepickerDate(schViewForm.schStrtYmd)}
                          onChange={handleChangeDatePicker("schEndYmd")}
                          placeholder="종료일을 선택하세요."
                        />
                      </Grid>
                      <Grid item>
                        <div className="date-btn-group">
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("1")}
                          >
                            1개월
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("3")}
                          >
                            3개월
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("12")}
                          >
                            1년
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("")}
                          >
                            전체
                          </XTButton>
                        </div>
                      </Grid>
                    </Grid>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>검색</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTInput
                          name="schTxt"
                          value={schViewForm.schTxt}
                          fullWidth
                          placeholder="보호자명을 입력해주세요."
                          onChange={handleChangeInput}
                          onKeyDown={handleKeyPress}
                        />
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="action-button-group">
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => {
                  handleResetInput();
                }}
              >
                초기화
              </XTButton>
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={(e) => {
                  search();
                }}
              >
                조회
              </XTButton>
            </div>
          </div>
          <span className="helper-text">
            ※ 해당 메뉴는 하이케어 관리자와 연동되는 부분입니다. 데이터가
            정상적으로 노출되지 않을 시, 새로고침을 해주시기 바랍니다.
          </span>
          <div className="grid-wrap">
            <div className="total-wrap">
              <div className="total-cnt">
                전체{" "}
                <span>{regComma(listData?.innoBoostParentList.totalSize)}</span>
                건
              </div>
              <XTSelect
                name="pgSz"
                value={schForm.pgSz}
                onChange={handleChangePageSz}
                sx={{
                  minWidth: 150,
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 50,
                    horizontal: -10,
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
              >
                <XTMenuItem value={10}>10개 씩</XTMenuItem>
                <XTMenuItem value={20}>20개 씩</XTMenuItem>
                <XTMenuItem value={30}>30개 씩</XTMenuItem>
                <XTMenuItem value={50}>50개 씩</XTMenuItem>
                <XTMenuItem value={100}>100개 씩</XTMenuItem>
              </XTSelect>
            </div>

            <XTDataGrid
              loading={isFetching}
              columns={columns}
              rows={listData.innoBoostParentList.list}
              getRowId={(params) => params.index}
              apiRef={apiRef}
              experimentalFeatures={{
                ariaV7: true,
              }}
              onRowClick={(params) => {}}
              slots={{
                noRowsOverlay: () => (
                  <div className="no-data">등록된 회원이 없습니다.</div>
                ),
                noResultsOverlay: () => (
                  <div className="no-data">등록된 회원이 없습니다.</div>
                ),
                loadingOverlay: LinearProgress,
              }}
              rowHeight={38}
              columnHeaderHeight={38}
              hideFooter
            />
          </div>
          <Box css={globalCSS.bottomWrap}>
            <Pagination
              page={listData.innoBoostParentList.page}
              count={listData.innoBoostParentList.totalPageSize}
              onChange={handleChangePage}
            />
          </Box>
        </div>
      </main>
    </>
  );
}
