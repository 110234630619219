import { css } from "@emotion/react";
import theme from "./theme";
import { debounce } from "lodash";

const guideCSS = {
  guideWrapper: css`
    padding: 40px 30px 100px 150px;
    width: 100%;
    background-color: transparent !important;
  `,

  guideWrap: css`
    // font-size: 14px;
    .guide-box {
      padding: 20px;
      background-color: #c8c9cb;

      .point-primary {
        color: ${theme.palette.primary.main};
      }

      .point-error {
        color: ${theme.palette.error.main};
      }
    }

    .title-box {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }

    .h-group {
      margin-bottom: 30px;
      text-align: center;
    }

    .guide-h2 {
      font-size: 32px;
    }

    .guide-h3 {
      margin-bottom: 20px;
      font-size: 20px;
      color: ${theme.palette.text_sub1};
    }

    .sub-title {
      display: inline-block;
      font-size: 16px;
      margin-bottom: 20px;
    }

    .guide-hr {
      margin: 50px 0;
      border-color: #e1e1e1;
      border-style: solid;
    }

    .guide-flexWrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: ${theme.typography.pxToRem(20)};
    }

    .guide-subTitle {
      display: block;
      font-size: 14px;
      color: ${theme.palette.default};
      margin-bottom: 10px;
    }

    .MuiAccordion-root {
      margin: 0;
    }

    .MuiAccordionSummary-root {
      display: inline-flex;
      min-height: auto;
      padding: 0;

      .MuiAccordionSummary-content {
        margin: 0;
        line-height: 1;

        &.Mui-expanded {
          margin: 0;
          min-height: auto;
        }
      }

      &.Mui-expanded {
        margin: 0;
        min-height: auto;
      }
    }

    .MuiAccordionDetails-root {
      padding: 0;
    }

    .guide-typeface {
      .MuiGrid-item {
        .item {
          padding: 10px;
          min-width: 150px;
          border: 1px solid ${theme.palette.line1};
          border-radius: 10px;
          .font {
            font-size: 50px;
            line-height: 1.3;
          }
          .info {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }

    .guide-box-line {
      border: solid 1px ${theme.palette.line1};
    }

    .box-type-2 {
      padding: 20px;
    }

    .list-dot {
      > li {
        position: relative;
        padding-left: 8px;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 14px;
          width: 2px;
          height: 2px;
          background: #000;
        }
      }
    }

    .MuiChip-root {
      width: 100px;
      height: 100px;
      border-radius: 6px;
      padding: 0 6px;
      font-size: 16px;
    }

    .guide-color-box {
      width: ${theme.typography.pxToRem(200)};
      height: ${theme.typography.pxToRem(250)};
      border-radius: 12px;
      box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .color-box {
        height: 60%;
      }

      .text-box {
        display: flex;
        flex-direction: column;
        padding: ${theme.typography.pxToRem(20)};
        em {
          display: inline-block;
          font-weight: bold;
          font-size: 18px;
          line-height: 1;
          margin-bottom: ${theme.typography.pxToRem(6)};
        }

        span {
          font-weight: 400;
          font-size: ${theme.typography.pxToRem(14)};
          line-height: 1.5;
          color: #222;
        }
      }
    }

    .guide-color-flex {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
    }

    .guide-typo-wrapper {
      + .guide-typo-wrapper {
        margin-top: ${theme.typography.pxToRem(80)};
      }

      .category {
        display: block;
        font-weight: 400;
        font-size: ${theme.typography.pxToRem(20)};
        letter-spacing: -0.04em;
        color: ${theme.palette.default};
        margin-bottom: ${theme.typography.pxToRem(30)};
      }

      .guide-typo-content {
        display: flex;
        justify-content: space-between;
      }

      .guide-typo-sample {
        position: sticky;
        top: 20px;
        width: ${theme.typography.pxToRem(450)};
        height: ${theme.typography.pxToRem(450)};
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: ${theme.typography.pxToRem(60)};
        border-radius: 15px;
        display: flex;
        flex-direction: column;

        span {
          color: ${theme.palette.default};
          font-weight: bold;
          &:nth-of-type(1) {
            font-size: ${theme.typography.pxToRem(22)};
            margin-bottom: ${theme.typography.pxToRem(35)};
          }

          &:nth-of-type(2) {
            /* font-size: ${theme.typography.pxToRem(130)};
            line-height: 1; */
          }
        }
      }

      .guide-typo-table {
        flex: 1;
        margin-left: ${theme.typography.pxToRem(60)};

        th {
          text-align: left;
          font-size: ${theme.typography.pxToRem(18)};
          padding-bottom: ${theme.typography.pxToRem(20)};
        }

        td {
          font-weight: 400;
          font-size: ${theme.typography.pxToRem(18)};
          color: ${theme.palette.default};
          padding: ${theme.typography.pxToRem(8)} 0;
          transition: all 0.2s;
        }

        tbody {
          tr {
            cursor: pointer;

            &:hover {
              td {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  `,
};

export default guideCSS;
