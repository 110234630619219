import {
  MenuDetailFormType,
  MenuSordFormType,
} from "@/types/system/menu";
import axios from "axios";

export const loadMenuListAPI = async () => {
  return axios.get("/api/st/menu/full-list");
};

export const updateMenuAPI = async (form: MenuDetailFormType) => {
  return axios.post("/api/st/menu/update", { ...form });
};

export const updateSordAPI = async (form: MenuSordFormType) => {
  return axios.post("/api/st/menu/update-sord", { ...form });
};

export const loadCommonMenuListAPI = async (schMgYn: string) => {
  return axios.get("/api/st/menu/common-full-list", {
    params: {
      schMgYn: schMgYn ?? "N",
    },
  });
};

export const loadMenuTypeAPI = async () => {
  return axios.get("/api/st/menu/get-type");
};
