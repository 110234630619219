import { clearSessionStorage } from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTAccordionDetail } from "@/components/mui/xt-accordion";
import { XTButton } from "@/components/mui/xt-button";
import { UserInfoState } from "@/state";
import { UserInfoType } from "@/types";
import { css } from "@emotion/react";
import { Theme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { useMenuTreeManager } from "@/pages/system/menu/_hook/use-menu";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import AppSettingsAltOutlinedIcon from "@mui/icons-material/AppSettingsAltOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import PersonIcon from "@mui/icons-material/Person";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import DashboardIcon from "@mui/icons-material/Dashboard";
import RoutesData from "@/routes/Routes";
import { useLoginManager } from "@/pages/login/_hook/use-login";
export const activeMenuState = atom<boolean>({
  key: "activeMenuStateKey",
  default: false,
});

export default function Sidebar() {
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState);
  const [selectedDepth1, setSelectedDepth1] = useState<any>({});
  const [selectedDepth2, setSelectedDepth2] = useState<any>({});
  const [isSelectMenu, setIsSelectMenu] = useState(false);
  const [user, setUser] = useState<UserInfoType>();
  const userInfo = useRecoilValue(UserInfoState);

  const { menuList } = useMenuTreeManager("Y");
  const { logout } = useLoginManager();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleLogOut = async () => {
    try {
      await logout.mutateAsync();
      clearSessionStorage("tabs");
      navigate("/login", { replace: true });
    } catch (error) {
      clearSessionStorage("tabs");
      navigate("/login", { replace: true });
    }
  };

  const getDepth1BtnClassName = (d1: any) => {
    return pathname.includes(d1.uri)
      ? "box flex-column active"
      : "box flex-column";
  };

  const getDepth2BtnClassName = (d2: any) => {
    return pathname === d2.uri ? "dot-btn active" : "dot-btn";
  };

  useEffect(() => {
    let sidebar = document.querySelector("#sidebar");

    if (activeMenu) {
      sidebar?.classList.add("active");
    } else {
      sidebar?.classList.remove("active");
    }
  }, [activeMenu]);

  useEffect(() => {
    if (userInfo) setUser(userInfo);
  }, [userInfo]);

  useEffect(() => {
    if (menuList) {
      menuList.menuList.map((menu, i) => {
        if (pathname.includes(menu.uri)) {
          setSelectedDepth1(menu);

          if (menu.menuList.length > 0) {
            setActiveMenu(true);
            setIsSelectMenu(true);
          } else {
            setActiveMenu(false);
            setIsSelectMenu(false);
          }
        }
      });

      if (
        !RoutesData.some((menu) => menu.path === pathname) &&
        !pathname.includes("/detail") &&
        pathname !== "/"
      ) {
        navigate("/login");
      }
    }
  }, [menuList]);

  function Icons(props: any) {
    const menuCd = props.menuCd;
    switch (menuCd) {
      case "AA":
        return <SettingsOutlinedIcon />;
      case "AB":
        return <PrivacyTipOutlinedIcon />;
      case "AC":
        return <SupportAgentOutlinedIcon />;
      case "AD":
        return <InfoOutlinedIcon />;
      case "AE":
        return <AppSettingsAltOutlinedIcon />;
      case "AF":
        return <PersonIcon />;
      case "AG":
        return <InventoryIcon />;
      case "AH":
        return <LocalShippingIcon />;
      case "AI":
        return <VaccinesIcon />;
      case "AJ":
        return <DashboardIcon />;
    }
  }
  return (
    <>
      <div
        id="sidebar"
        css={style.sidebar}
        onMouseLeave={() => {
          if (!isSelectMenu && activeMenu) setActiveMenu(false);
          // let currentTab = sessionTabList.find((item) => item.isActive);
          // if (!currentTab) return;
          // if (currentTab.menuCd === selectedDepth3 || !selectedDepth3) {
          //   setActiveMenu(false);
          // }
          // if (!selectedDepth1.menuCd) setActiveMenu(false);
        }}
      >
        <div className="sidebar-top">
          <div className="logo-wrap">
            <h1 className="logo">
              <span css={globalCSS.hidden}>로고</span>
              <img src="/images/image/logo.svg" alt="로고" />
            </h1>
            <button
              type="button"
              className="action-btn"
              onClick={(e) => {
                e.stopPropagation();
                if (!activeMenu) {
                  setActiveMenu(true);
                  setIsSelectMenu(true);
                } else {
                  setActiveMenu(false);
                  setIsSelectMenu(false);
                }
              }}
            >
              <span css={globalCSS.hidden}>사이드바 버튼</span>
              <i className="action-icon" />
            </button>
          </div>
          <div className="info-wrap">
            <XTButton
              variant="contained"
              color="primary"
              size="large"
              className="xl info-btn"
              onClick={handleLogOut}
            >
              <div className="name-area">
                <i css={globalCSS.icoProfile} />
                {user && (
                  <span className="name">
                    {user?.managerStatus?.managerAccount?.nm ?? "홍길동"}
                  </span>
                )}
                님
              </div>
              <div className="icon-area">
                <i css={globalCSS.icoLogout} />
              </div>
            </XTButton>
          </div>
        </div>
        <div className="sidebar-bottom">
          <div className="depth1-wrap">
            {menuList?.menuList.map((d1: any, i: number) => (
              <XTButton
                key={i}
                variant="contained"
                color="secondary"
                size="large"
                className={getDepth1BtnClassName(d1)}
                onMouseEnter={() => {
                  setSelectedDepth1(d1);
                  if (d1.menuList.length > 0) {
                    setActiveMenu(true);
                    setIsSelectMenu(true);
                  } else {
                    setActiveMenu(false);
                    setIsSelectMenu(false);
                  }
                }}
                onClick={() => {
                  if (d1.menuList.length > 0) {
                    navigate(d1.menuList[0].uri);
                  } else {
                    navigate(d1.uri);
                  }
                }}
              >
                {/* <img className="icon" src={d1.menuIconPath} /> */}
                <Icons menuCd={d1.cd} />
                <span>{d1.nm}</span>
              </XTButton>
            ))}
          </div>
          <div className="depth2-wrap">
            {selectedDepth1 &&
              selectedDepth1.menuList &&
              selectedDepth1.menuList.length > 0 && (
                <>
                  <span className="title">{selectedDepth1.nm}</span>
                  {selectedDepth1.menuList &&
                    selectedDepth1.menuList.length > 0 &&
                    selectedDepth1.menuList.map((d2: any, i: number) => (
                      <XTAccordionDetail key={i}>
                        <button
                          className={getDepth2BtnClassName(d2)}
                          onClick={() => {
                            setSelectedDepth2(d2);
                            navigate(d2.uri);
                          }}
                        >
                          {d2.nm}
                        </button>
                      </XTAccordionDetail>
                    ))}
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
}

const style = {
  sidebar: (theme: Theme) => css`
    flex: none;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 9999;
    width: 120px;
    height: 100%;
    min-height: 100%;
    box-shadow: 0 10px 15px 2px rgba(68, 69, 68, 0.05);
    transition: width 0.4s ${theme.transitions.easing.inout2};

    .sidebar-top {
      flex: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 185px;
      padding: 40px 30px 30px;
      background: ${theme.palette.white};
      border-bottom: solid 1px ${theme.palette.bg_02};
      overflow: hidden;

      .logo-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 30px;

        .logo {
          flex: 1;
          width: 100%;
          height: 100%;
          max-width: 0;
          opacity: 0;
          visibility: hidden;
          transition: all 0.4s ${theme.transitions.easing.inout2};

          img {
            display: block;
            width: 117px;
            height: 100%;
          }
        }

        .action-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          max-width: initial;
          flex: 1;
          width: 100%;
          height: 20px;
          cursor: pointer;
          transition: all 0.4s ${theme.transitions.easing.inout2};

          .action-icon {
            display: block;
            width: 20px;
            height: 20px;
            background: url("/images/icon/ico-arrow-double.svg") no-repeat
              center;
          }
        }
      }

      .info-wrap {
        width: 100%;

        .info-btn.MuiButton-root {
          padding: 8px 10px;
          width: 60px;
          max-width: initial;
          transition: width 0.4s ${theme.transitions.easing.inout2},
            padding 0.4s ${theme.transitions.easing.inout2};

          .name-area {
            opacity: 0;
            overflow: hidden;
            transition: opacity 0.4s ${theme.transitions.easing.inout2};
          }

          .icon-area {
            right: 50%;
            transform: translate(50%, -50%);
            transition: all 0.4s ${theme.transitions.easing.inout2};
          }
        }
      }
    }

    .sidebar-bottom {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: auto;

      .depth1-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding: 30px 5px;
        width: 120px;
        height: 100%;
        background: ${theme.palette.bg_02};
        z-index: 1;
        overflow: auto;

        .box {
          + .box {
            margin-top: 30px;
          }
        }
      }

      .depth2-wrap {
        position: absolute;
        left: -90px;
        padding: 30px 0;
        width: 210px;
        height: 100%;
        background: ${theme.palette.white};
        z-index: 0;
        transition: all 0.4s ${theme.transitions.easing.inout2};
        overflow: auto;

        .title {
          display: block;
          padding: 0 30px 20px;
          font-size: 16px;
          font-weight: 700;
          line-height: 1;
          overflow: hidden;
        }
      }
    }

    /* active sidebar */
    &.active {
      width: 330px;

      .logo-wrap {
        .logo {
          max-width: 100%;

          opacity: 1;
          visibility: visible;
          img {
            width: 200px;
            height: 33px;
          }
        }

        .action-btn {
          max-width: 20px;

          .action-icon {
            transform: rotate(180deg);
          }
        }
      }

      .info-wrap {
        .info-btn.MuiButton-root {
          width: 100%;
          padding: 8px 30px 10px;

          .name-area {
            opacity: 1;
            overflow: hidden;
            transition-duration: 0.5s;
          }

          .icon-area {
            right: 30px;
            transform: translateY(-50%);
          }
        }
      }

      .depth2-wrap {
        left: 120px;
      }
    }
  `,
  customDialog: (theme: Theme) => css`
    z-index: 9999999 !important;
  `,
};
