import { css } from "@emotion/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import theme from "../../assets/ts/theme";

const style = {
  sidebar: css`
    position: fixed;
    left: 0;
    top: 0;
    background: #f8f8f9;
    width: 254px;
    height: 100%;
    z-index: 5;
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      width: 100%;
      transform: translateX(-100%);
      &.open {
        transform: translateX(0);
      }
    }
  `,
  sidebarCont: css`
    width: 100%;
    padding: 30px;
    .h-group {
      margin-bottom: 30px;
      text-align: center;
      h2 {
        font-size: 21px;
      }
    }
  `,
  menuList: css`
    & a {
      color: ${theme.palette.text_sub1};
      text-decoration: none;
    }

    & .MuiAccordion-root {
      background-color: transparent;
      background-image: none;
      box-shadow: none;
      border: 0;
      &:before {
        display: none;
      }
      & + .MuiAccordion-root {
        margin-top: 20px;
      }
    }

    & .MuiAccordionSummary-root {
      min-height: auto !important;
      padding: 0;
      & .MuiAccordionSummary-content {
        margin: 0;
        font-size: 16px;
        font-weight: 700;
        color: ${theme.palette.text_sub1};
        &.Mui-expanded {
          color: #000;
        }
      }
    }

    & .MuiCollapse-root {
      transition: none;
      & .MuiAccordionDetails-root {
        padding: 0;
      }
    }

    & .depth-02 {
      position: relative;
      margin-top: 10px;
      padding-left: 15px;
      list-style: none;
      & > li {
        font-size: 15px;
        & .active {
          color: #000;
          font-weight: 500;
        }
        & + li {
          margin-top: 10px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        background-color: #adbdcc;
      }
    }
  `,
};

export default function GuideLeft() {
  return (
    <div className="sidebar-wrap" css={style.sidebar}>
      <div css={style.sidebarCont}>
        <div className="h-group">
          <h2>UI Guide</h2>
        </div>
        <div css={style.menuList}>
          <Accordion disableGutters>
            <AccordionSummary>01. UI Components</AccordionSummary>
            <AccordionDetails>
              <ul className="depth-02">
                <li>
                  <NavLink to="/guide/button">Button</NavLink>
                </li>
                <li>
                  <NavLink to="/guide/form">Form</NavLink>
                </li>
                <li>
                  <NavLink to="/guide/datepicker">DatePicker</NavLink>
                </li>
                <li>
                  <NavLink to="/guide/text">Text</NavLink>
                </li>
                <li>
                  <NavLink to="/guide/icon">Icon</NavLink>
                </li>
                <li>
                  <NavLink to="/guide/badge">Badge</NavLink>
                </li>
                <li>
                  <NavLink to="/guide/dialog">Dialog</NavLink>
                </li>
                <li>
                  <NavLink to="/guide/datagrid">DataGrid</NavLink>
                </li>
                <li>
                  <NavLink to="/guide/tooltip">Tooltip</NavLink>
                </li>
                <li>
                  <NavLink to="/guide/tree">Tree</NavLink>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion disableGutters>
            <AccordionSummary>
              <NavLink to="/guide/palette">02. Palette</NavLink>
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>
          <Accordion disableGutters>
            <AccordionSummary>
              <NavLink to="/guide/typography">03. Typography</NavLink>
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>
          <Accordion disableGutters>
            <AccordionSummary>
              <NavLink to="/sample">04. Sample</NavLink>
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>
          {/*      <Accordion disableGutters>
            <AccordionSummary>03. Typography</AccordionSummary>
            <AccordionDetails>
              <ul className="depth-02">
                <li>
                  <Link
                    className={
                      router.pathname === "/guide/typography" ? "active" : ""
                    }
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      router.push("/guide/typography");
                    }}
                  >
                    Typography
                  </Link>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters>
            <AccordionSummary>04. Template</AccordionSummary>
            <AccordionDetails>
              <ul className="depth-02">
                <li>
                  <Link
                    className={
                      router.pathname === "/guide/template/type1"
                        ? "active"
                        : ""
                    }
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      router.push("/guide/template/type1");
                    }}
                  >
                    Type1
                  </Link>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters>
            <AccordionSummary>
              <Link
                className={
                  router.pathname === "/guide/web-accessibility" ? "active" : ""
                }
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  router.push("/guide/web-accessibility");
                }}
              >
                05. Web accessibility
              </Link>
            </AccordionSummary>

          </Accordion> */}
        </div>
      </div>
    </div>
  );
}
