import React, { useState, useEffect } from "react";

const Timer = ({ resetTimer, setOpen }) => {
  const [time, setTime] = useState(0); // 초 단위로 시간을 관리
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval: string | number | NodeJS.Timer | undefined;
    if (isActive && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (isActive && time == 0) {
      setOpen();
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive, time]);

  useEffect(() => {
    if (resetTimer !== 0) {
      setIsActive(true);
      setTime(300);
    }
  }, [resetTimer]);

  const formatTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return <div>({formatTime()})</div>;
};

export default Timer;
