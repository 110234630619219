import globalCSS from "@/assets/ts/global-css";
import guideCSS from "@/assets/ts/guide-css";
import GuideLeft from "@/guide/components/sidebar";
import CodeMirror from "@uiw/react-codemirror";
import {
  GridColDef,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { html } from "@codemirror/lang-html";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { useDemoData } from "@mui/x-data-grid-generator";
import { useEffect, useState } from "react";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  LinearProgress,
} from "@mui/material";
import { regComma } from "@/assets/ts/common";
import { XTCheckBox } from "@/components/mui/xt-checkbox";
import { XTFormHelperText } from "@/components/mui/xt-input";
import { CheckBox } from "@mui/icons-material";

export default function GuideDataGrid() {
  const [codemirror, setCodemirror] = useState<string | false>();
  const handleCodemirror =
    (panel: string) =>
    (event: React.SyntheticEvent, newcodemirror: boolean) => {
      setCodemirror(newcodemirror ? panel : false);
    };

  const apiRef = useGridApiRef();

  const [selectedList, setSelectedList] = useState([]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "아이디",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 150,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 150,
      editable: true,
    },
    {
      field: "age",
      headerName: "Age",
      type: "",
      width: 150,
      editable: true,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      flex: 1,
      sortable: false,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "hobby",
      headerName: "Hobby",
      description: "This column has a value getter and is not sortable.",
      flex: 1,
      sortable: false,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => params.value,
    },
  ];

  const rows = [
    {
      seq: 1,
      id: 122,
      lastName: "Snow",
      firstName: "Jon",
      age: 14,
      hobby: "asddqwdqw",
    },
    {
      seq: 2,
      id: 2,
      lastName: "Lannister",
      firstName: "Cersei",
      age: 31,
      hobby: "etst1t",
    },
    {
      seq: 3,
      id: 3,
      lastName: "Lannister",
      firstName: "Jaime",
      age: 31,
      hobby: "test23t",
    },
    {
      seq: 4,
      id: 4,
      lastName: "Stark",
      firstName: "Arya",
      age: 11,
      hobby: "testest",
    },
    {
      seq: 5,
      id: 5,
      lastName: "Targaryen",
      firstName: "Daenerys",
      age: null,
      hobby: "boxing",
    },
    {
      seq: 6,
      id: 6,
      lastName: "Melisandre",
      firstName: null,
      age: 150,
      hobby: "backend develop",
    },
    {
      seq: 7,
      id: 7,
      lastName: "Clifford",
      firstName: "Ferrara",
      age: 44,
      hobby: "frontend develop",
    },
    {
      seq: 8,
      id: 8,
      lastName: "Frances",
      firstName: "Rossini",
      age: 36,
      hobby: "pc gaming",
    },
    {
      seq: 9,
      id: 9,
      lastName: "Roxie",
      firstName: "Harvey",
      age: 65,
      hobby: "reading book",
    },
  ];

  return (
    <div className="guide-area" css={guideCSS.guideWrap}>
      <GuideLeft />
      <div css={guideCSS.guideWrapper}>
        <div className="title-box">
          <h2 className="guide-h2">Data Grid</h2>
        </div>
        <div className="guide-content">
          <div>
            <h3 className="guide-h3">Data Grid - Default</h3>
            <div>
              <XTDataGrid
                columns={columns}
                rows={rows}
                getRowId={(params) => params.id}
                apiRef={apiRef}
                experimentalFeatures={{
                  ariaV7: true,
                }}
                disableRowSelectionOnClick
                slots={{
                  //   toolbar: GridToolbar,
                  noRowsOverlay: () => (
                    <div className="no-data">
                      검색 결과가 존재하지 않습니다.
                    </div>
                  ),
                  noResultsOverlay: () => (
                    <div className="no-data">
                      검색 결과가 존재하지 않습니다.
                    </div>
                  ),
                }}
                rowHeight={38}
                columnHeaderHeight={38}
                hideFooter
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </div>
          </div>
          <br />
          <br />
          <Accordion
            sx={{
              margin: 0,
              p: 0,
              border: "none",
              outline: "none",
              boxShadow: "none",
              "&::before": {
                position: "relative",
              },
            }}
            expanded={codemirror === "datagrid1"}
            onChange={handleCodemirror("datagrid1")}
            className="accordion-codemirror"
          >
            <AccordionSummary>
              <p className="guide-codeMore">코드보기</p>
            </AccordionSummary>
            <AccordionDetails>
              <CodeMirror
                theme={vscodeDark}
                extensions={[html()]}
                value={`
import {
    GridColDef,
    GridValueGetterParams,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import { XTDataGrid } from "@/components/mui/xt-datagrid";

const apiRef = useGridApiRef();

const columns: GridColDef[] = [
{
    field: "id",
    headerName: "아이디",
    width: 80,
    headerAlign: "center",
    align: "center",
},
{
    field: "firstName",
    headerName: "First name",
    width: 150,
    headerAlign: "center",
    align: "center",
    editable: true,
},
{
    field: "lastName",
    headerName: "Last name",
    width: 150,
    editable: true,
},
{
    field: "age",
    headerName: "Age",
    type: "",
    width: 150,
    editable: true,
},
{
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    flex: 1,
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
    params.row.firstName || "" params.row.lastName || "",
},
{
    field: "hobby",
    headerName: "Hobby",
    description: "This column has a value getter and is not sortable.",
    flex: 1,
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) => params.value,
},
];

const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 14, hobby: "asddqwdqw" },
    {
    id: 2,
    lastName: "Lannister",
    firstName: "Cersei",
    age: 31,
    hobby: "etst1t",
    },
    {
    id: 3,
    lastName: "Lannister",
    firstName: "Jaime",
    age: 31,
    hobby: "test23t",
    },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 11, hobby: "testest" },
{
    id: 5,
    lastName: "Targaryen",
    firstName: "Daenerys",
    age: null,
    hobby: "boxing",
    },
    {
    id: 6,
    lastName: "Melisandre",
    firstName: null,
    age: 150,
    hobby: "backend develop",
    },
    {
    id: 7,
    lastName: "Clifford",
    firstName: "Ferrara",
    age: 44,
    hobby: "frontend develop",
    },
    {
    id: 8,
    lastName: "Frances",
    firstName: "Rossini",
    age: 36,
    hobby: "pc gaming",
    },
    {
    id: 9,
    lastName: "Roxie",
    firstName: "Harvey",
    age: 65,
    hobby: "reading book",
    },
];



<XTDataGrid
    columns={columns}
    rows={rows}
    getRowId={(params) => params.id}
    apiRef={apiRef}
    experimentalFeatures={{
        ariaV7: true,
    }}
    disableRowSelectionOnClick
    slots={{
        noRowsOverlay: () => (
            <div className="no-data">
              검색 결과가 존재하지 않습니다.
            </div>
          ),
        noResultsOverlay: () => (
        <div className="no-data">
            검색 결과가 존재하지 않습니다.
        </div>
        ),
    }}
    rowHeight={38}
    columnHeaderHeight={38}
    hideFooter
    components={{
        LoadingOverlay: LinearProgress,
    }}
/>
                `}
              ></CodeMirror>
            </AccordionDetails>
          </Accordion>
          <br />
          <br />
          <div>
            <h3 className="guide-h3">Data Grid - No Data</h3>
            <div>
              <div>
                <XTDataGrid
                  columns={columns}
                  rows={[]}
                  getRowId={(params) => params.id}
                  apiRef={apiRef}
                  experimentalFeatures={{
                    ariaV7: true,
                  }}
                  disableRowSelectionOnClick
                  slots={{
                    noRowsOverlay: () => (
                      <div className="no-data">
                        검색 결과가 존재하지 않습니다.
                      </div>
                    ),
                    noResultsOverlay: () => (
                      <div className="no-data">
                        검색 결과가 존재하지 않습니다.
                      </div>
                    ),
                  }}
                  rowHeight={38}
                  columnHeaderHeight={38}
                  hideFooter
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </div>
            </div>
            <br />
            <br />
            <Accordion
              sx={{
                margin: 0,
                p: 0,
                border: "none",
                outline: "none",
                boxShadow: "none",
                "&::before": {
                  position: "relative",
                },
              }}
              expanded={codemirror === "datagrid2"}
              onChange={handleCodemirror("datagrid2")}
              className="accordion-codemirror"
            >
              <AccordionSummary>
                <p className="guide-codeMore">코드보기</p>
              </AccordionSummary>
              <AccordionDetails>
                <CodeMirror
                  theme={vscodeDark}
                  extensions={[html()]}
                  value={`
import {
    GridColDef,
    GridValueGetterParams,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import { XTDataGrid } from "@/components/mui/xt-datagrid";

const apiRef = useGridApiRef();

const rows = [];

<XTDataGrid
    columns={columns}
    rows={rows}
    apiRef={apiRef}
    getRowId={(params) => params.id}
    experimentalFeatures={{
        ariaV7: true,
    }}
    disableRowSelectionOnClick
    slots={{
        noRowsOverlay: () => (
            <div className="no-data">
              검색 결과가 존재하지 않습니다.
            </div>
        ),
        noResultsOverlay: () => (
        <div className="no-data">
            검색 결과가 존재하지 않습니다.
        </div>
        ),
    }}
    rowHeight={38}
    columnHeaderHeight={38}
    hideFooter
    components={{
        LoadingOverlay: LinearProgress,
    }}
/>
                `}
                ></CodeMirror>
              </AccordionDetails>
            </Accordion>
          </div>
          <br />
          <br />
          <div>
            <h3 className="guide-h3">
              Data Grid - Left pinned (예시를 위해 width, height 고정)
            </h3>
            <div>
              <XTDataGrid
                sx={{
                  width: 500,
                  height: 300,
                }}
                initialState={{ pinnedColumns: { left: ["id"] } }}
                columns={columns}
                rows={rows}
                getRowId={(params) => params.id}
                apiRef={apiRef}
                experimentalFeatures={{
                  ariaV7: true,
                }}
                disableRowSelectionOnClick
                slots={{
                  noRowsOverlay: () => (
                    <div className="no-data">
                      검색 결과가 존재하지 않습니다.
                    </div>
                  ),
                  noResultsOverlay: () => (
                    <div className="no-data">
                      검색 결과가 존재하지 않습니다.
                    </div>
                  ),
                }}
                rowHeight={38}
                columnHeaderHeight={38}
                hideFooter
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </div>
            <br />
            <br />
            <Accordion
              sx={{
                margin: 0,
                p: 0,
                border: "none",
                outline: "none",
                boxShadow: "none",
                "&::before": {
                  position: "relative",
                },
              }}
              expanded={codemirror === "datagrid3"}
              onChange={handleCodemirror("datagrid3")}
              className="accordion-codemirror"
            >
              <AccordionSummary>
                <p className="guide-codeMore">코드보기</p>
              </AccordionSummary>
              <AccordionDetails>
                <CodeMirror
                  theme={vscodeDark}
                  extensions={[html()]}
                  value={`
import {
    GridColDef,
    GridValueGetterParams,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import { XTDataGrid } from "@/components/mui/xt-datagrid";

const apiRef = useGridApiRef();

const columns: GridColDef[] = [
{
    field: "id",
    headerName: "아이디",
    width: 80,
    headerAlign: "center",
    align: "center",
},
{
    field: "firstName",
    headerName: "First name",
    width: 150,
    headerAlign: "center",
    align: "center",
    editable: true,
},
{
    field: "lastName",
    headerName: "Last name",
    width: 150,
    editable: true,
},
{
    field: "age",
    headerName: "Age",
    type: "",
    width: 150,
    editable: true,
},
{
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    flex: 1,
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
    params.row.firstName || "" params.row.lastName || "",
},
{
    field: "hobby",
    headerName: "Hobby",
    description: "This column has a value getter and is not sortable.",
    flex: 1,
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) => params.value,
},
];

const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 14, hobby: "asddqwdqw" },
    {
    id: 2,
    lastName: "Lannister",
    firstName: "Cersei",
    age: 31,
    hobby: "etst1t",
    },
    {
    id: 3,
    lastName: "Lannister",
    firstName: "Jaime",
    age: 31,
    hobby: "test23t",
    },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 11, hobby: "testest" },
{
    id: 5,
    lastName: "Targaryen",
    firstName: "Daenerys",
    age: null,
    hobby: "boxing",
    },
    {
    id: 6,
    lastName: "Melisandre",
    firstName: null,
    age: 150,
    hobby: "backend develop",
    },
    {
    id: 7,
    lastName: "Clifford",
    firstName: "Ferrara",
    age: 44,
    hobby: "frontend develop",
    },
    {
    id: 8,
    lastName: "Frances",
    firstName: "Rossini",
    age: 36,
    hobby: "pc gaming",
    },
    {
    id: 9,
    lastName: "Roxie",
    firstName: "Harvey",
    age: 65,
    hobby: "reading book",
    },
];

<XTDataGrid
    columns={columns}
    rows={rows}
    apiRef={apiRef}
    getRowId={(params) => params.id}
    initialState={{ pinnedColumns: { left: ["id"] } }}
    experimentalFeatures={{
        ariaV7: true,
    }}
    disableRowSelectionOnClick
    slots={{
        noRowsOverlay: () => (
            <div className="no-data">
              검색 결과가 존재하지 않습니다.
            </div>
          ),
        noResultsOverlay: () => (
        <div className="no-data">
            검색 결과가 존재하지 않습니다.
        </div>
        ),
    }}
    rowHeight={38}
    columnHeaderHeight={38}
    hideFooter
    components={{
        LoadingOverlay: LinearProgress,
    }}
/>
                `}
                ></CodeMirror>
              </AccordionDetails>
            </Accordion>
          </div>
          <br />
          <br />
          <div>
            <h3 className="guide-h3">
              Data Grid - Row Clickable className="clickable"
            </h3>
            <div>
              <XTDataGrid
                className="clickable"
                columns={columns}
                rows={rows}
                getRowId={(params) => params.id}
                apiRef={apiRef}
                experimentalFeatures={{
                  ariaV7: true,
                }}
                disableRowSelectionOnClick
                slots={{
                  noRowsOverlay: () => (
                    <div className="no-data">
                      검색 결과가 존재하지 않습니다.
                    </div>
                  ),
                  noResultsOverlay: () => (
                    <div className="no-data">
                      검색 결과가 존재하지 않습니다.
                    </div>
                  ),
                }}
                onRowClick={(params) => {
                  alert(`${params.id} Row Click!`);
                }}
                rowHeight={38}
                columnHeaderHeight={38}
                hideFooter
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </div>
            <br />
            <br />
            <Accordion
              sx={{
                margin: 0,
                p: 0,
                border: "none",
                outline: "none",
                boxShadow: "none",
                "&::before": {
                  position: "relative",
                },
              }}
              expanded={codemirror === "datagrid4"}
              onChange={handleCodemirror("datagrid4")}
              className="accordion-codemirror"
            >
              <AccordionSummary>
                <p className="guide-codeMore">코드보기</p>
              </AccordionSummary>
              <AccordionDetails>
                <CodeMirror
                  theme={vscodeDark}
                  extensions={[html()]}
                  value={`
import {
    GridColDef,
    GridValueGetterParams,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import { XTDataGrid } from "@/components/mui/xt-datagrid";

const apiRef = useGridApiRef();

const columns: GridColDef[] = [
{
    field: "id",
    headerName: "아이디",
    width: 80,
    headerAlign: "center",
    align: "center",
},
{
    field: "firstName",
    headerName: "First name",
    width: 150,
    headerAlign: "center",
    align: "center",
    editable: true,
},
{
    field: "lastName",
    headerName: "Last name",
    width: 150,
    editable: true,
},
{
    field: "age",
    headerName: "Age",
    type: "",
    width: 150,
    editable: true,
},
{
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    flex: 1,
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
    params.row.firstName || "" params.row.lastName || "",
},
{
    field: "hobby",
    headerName: "Hobby",
    description: "This column has a value getter and is not sortable.",
    flex: 1,
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) => params.value,
},
];

const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 14, hobby: "asddqwdqw" },
    {
    id: 2,
    lastName: "Lannister",
    firstName: "Cersei",
    age: 31,
    hobby: "etst1t",
    },
    {
    id: 3,
    lastName: "Lannister",
    firstName: "Jaime",
    age: 31,
    hobby: "test23t",
    },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 11, hobby: "testest" },
{
    id: 5,
    lastName: "Targaryen",
    firstName: "Daenerys",
    age: null,
    hobby: "boxing",
    },
    {
    id: 6,
    lastName: "Melisandre",
    firstName: null,
    age: 150,
    hobby: "backend develop",
    },
    {
    id: 7,
    lastName: "Clifford",
    firstName: "Ferrara",
    age: 44,
    hobby: "frontend develop",
    },
    {
    id: 8,
    lastName: "Frances",
    firstName: "Rossini",
    age: 36,
    hobby: "pc gaming",
    },
    {
    id: 9,
    lastName: "Roxie",
    firstName: "Harvey",
    age: 65,
    hobby: "reading book",
    },
];

<XTDataGrid
    className="clickable"
    columns={columns}
    rows={rows}
    apiRef={apiRef}
    getRowId={(params) => params.id}
    experimentalFeatures={{
        ariaV7: true,
    }}
    disableRowSelectionOnClick
    onRowClick={(params) => {
      alert('Row Click!');
    }}
    slots={{
        noRowsOverlay: () => (
            <div className="no-data">
              검색 결과가 존재하지 않습니다.
            </div>
          ),
        noResultsOverlay: () => (
        <div className="no-data">
            검색 결과가 존재하지 않습니다.
        </div>
        ),
    }}
    rowHeight={38}
    columnHeaderHeight={38}
    hideFooter
    components={{
        LoadingOverlay: LinearProgress,
    }}
/>
                `}
                ></CodeMirror>
              </AccordionDetails>
            </Accordion>
          </div>
          <br />
          <br />
          <div>
            <h3 className="guide-h3">Data Grid - With Checkbox</h3>
            <XTFormHelperText
              sx={{
                mb: 2,
              }}
            >
              getRowId 로 설정한 id 값이 배열로 담김
            </XTFormHelperText>
            <div>
              <XTDataGrid
                columns={columns}
                rows={rows}
                apiRef={apiRef}
                experimentalFeatures={{
                  ariaV7: true,
                }}
                getRowId={(params) => params.id}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelection) => {}}
                slots={{
                  noRowsOverlay: () => (
                    <div className="no-data">
                      검색 결과가 존재하지 않습니다.
                    </div>
                  ),
                  noResultsOverlay: () => (
                    <div className="no-data">
                      검색 결과가 존재하지 않습니다.
                    </div>
                  ),
                  baseCheckbox: (props) => (
                    <XTCheckBox
                      control={
                        <Checkbox icon={<span className="checkbox-icon" />} />
                      }
                      {...props}
                    />
                  ),
                }}
                rowHeight={38}
                columnHeaderHeight={38}
                hideFooter
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </div>
            <br />
            <br />
            <Accordion
              sx={{
                margin: 0,
                p: 0,
                border: "none",
                outline: "none",
                boxShadow: "none",
                "&::before": {
                  position: "relative",
                },
              }}
              expanded={codemirror === "datagrid5"}
              onChange={handleCodemirror("datagrid5")}
              className="accordion-codemirror"
            >
              <AccordionSummary>
                <p className="guide-codeMore">코드보기</p>
              </AccordionSummary>
              <AccordionDetails>
                <CodeMirror
                  theme={vscodeDark}
                  extensions={[html()]}
                  value={`
import {
    GridColDef,
    GridValueGetterParams,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import { XTDataGrid } from "@/components/mui/xt-datagrid";

const apiRef = useGridApiRef();

const columns: GridColDef[] = [
{
    field: "id",
    headerName: "아이디",
    width: 80,
    headerAlign: "center",
    align: "center",
},
{
    field: "firstName",
    headerName: "First name",
    width: 150,
    headerAlign: "center",
    align: "center",
    editable: true,
},
{
    field: "lastName",
    headerName: "Last name",
    width: 150,
    editable: true,
},
{
    field: "age",
    headerName: "Age",
    type: "",
    width: 150,
    editable: true,
},
{
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    flex: 1,
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
    params.row.firstName || "" params.row.lastName || "",
},
{
    field: "hobby",
    headerName: "Hobby",
    description: "This column has a value getter and is not sortable.",
    flex: 1,
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) => params.value,
},
];

const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 14, hobby: "asddqwdqw" },
    {
    id: 2,
    lastName: "Lannister",
    firstName: "Cersei",
    age: 31,
    hobby: "etst1t",
    },
    {
    id: 3,
    lastName: "Lannister",
    firstName: "Jaime",
    age: 31,
    hobby: "test23t",
    },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 11, hobby: "testest" },
{
    id: 5,
    lastName: "Targaryen",
    firstName: "Daenerys",
    age: null,
    hobby: "boxing",
    },
    {
    id: 6,
    lastName: "Melisandre",
    firstName: null,
    age: 150,
    hobby: "backend develop",
    },
    {
    id: 7,
    lastName: "Clifford",
    firstName: "Ferrara",
    age: 44,
    hobby: "frontend develop",
    },
    {
    id: 8,
    lastName: "Frances",
    firstName: "Rossini",
    age: 36,
    hobby: "pc gaming",
    },
    {
    id: 9,
    lastName: "Roxie",
    firstName: "Harvey",
    age: 65,
    hobby: "reading book",
    },
];

<XTDataGrid
  columns={columns}
  rows={rows}
  apiRef={apiRef}
  experimentalFeatures={{
    ariaV7: true,
  }}
  getRowId={(params) => params.id}
  checkboxSelection
  disableRowSelectionOnClick
  onRowSelectionModelChange={(newSelection) => {
    console.log("newSelection", newSelection);
  }}
  slots={{
    noRowsOverlay: () => (
      <div className="no-data">
        검색 결과가 존재하지 않습니다.
      </div>
    ),
    noResultsOverlay: () => (
      <div className="no-data">
        검색 결과가 존재하지 않습니다.
      </div>
    ),
    baseCheckbox: (props) => (
      <XTCheckBox
        control={
          <Checkbox icon={<span className="checkbox-icon" />} />
        }
        {...props}
      />
    ),
  }}
  rowHeight={38}
  columnHeaderHeight={38}
  hideFooter
  components={{
    LoadingOverlay: LinearProgress,
  }}
/>
                `}
                ></CodeMirror>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}
