import globalCSS from "@/assets/ts/global-css";
import {
  XTFormControl,
  XTFormHelperText,
  XTInput,
} from "@/components/mui/xt-input";
import { css } from "@emotion/react";
import { useState } from "react";
import { Grid, IconButton } from "@mui/material";
import { XTButton } from "@/components/mui/xt-button";
import theme from "@/assets/ts/theme";
import AlertDialog from "@/components/ui/alert-dialog";
import { useLoginManager } from "@/pages/login/_hook/use-login";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { loadCommonMenuListAPI } from "@/api/system/menu";

export default function ResetPassWord() {
  const navigate = useNavigate();
  const [isPwdShow, setIsPwdShow] = useState(false);
  const [alert, setAlert] = useState<{
    title: string;
    text: string;
    open: boolean;
    type: string;
    handleOK: null | (() => void);
  }>({
    title: "",
    text: "",
    open: false,
    type: "complete",
    handleOK: null,
  });

  const { changePwMutation } = useLoginManager();

  const schema = yup
    .object()
    .shape({
      pwd: yup
        .string()
        .trim()
        .required("새 비밀번호를 입력해주세요.")
        .test("password", "비밀번호 생성 규칙을 확인해주세요.", (value) => {
          if (!value) return false;
          return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{14,}$/.test(
            value
          );
        }),
      pwdConfirm: yup
        .string()
        .trim()
        .required("새 비밀번호 확인을 입력해주세요.")
        .oneOf(
          [yup.ref("pwd"), null],
          "새 비밀번호 확인에 입력한 비밀번호가\n새 비밀번호와 일치해야 합니다."
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      pwd: "",
      pwdConfirm: "",
    },
    shouldFocusError: false,
    resolver: yupResolver(schema),
  });

  const onSubmit = async () => {
    try {
      await changePwMutation.mutateAsync(getValues("pwd"));
      setAlert({
        title: "Success",
        text: "비밀번호가 정상적으로 변경 되었습니다.",
        open: true,
        type: "complete",
        handleOK: async () => {
          const result = await loadCommonMenuListAPI("Y");
          const firstMenuUri =
            result.data.data?.menuList[0]?.menuList?.[0]?.uri ||
            result.data.data?.menuList[0]?.uri;

          if (firstMenuUri) {
            navigate(firstMenuUri);
          } else {
            navigate("/");
          }
        },
      });
    } catch (error: any) {}
  };

  return (
    <>
      <main id="login" css={style.login}>
        <div className="login-inner">
          <h1>
            {/* <img src="/images/icon/login-logo.svg" alt="아워홈 MAMS" /> */}
            비밀번호 재설정
          </h1>
          <p className="noti">변경하실 비밀번호를 입력해주세요.</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginBottom: "10px" }}
            >
              <Grid item>
                <p className="text">새 비밀번호</p>
              </Grid>
              <Grid item>
                <Controller
                  name="pwd"
                  control={control}
                  render={({ field }) => (
                    <XTFormControl>
                      <XTInput
                        style={{ width: "300px" }}
                        {...field}
                        className="custom-round login-input"
                        placeholder="새 비밀번호"
                        type={isPwdShow ? "text" : "password"}
                        aria-describedby="error-pw"
                        aria-label="비밀번호를 입력해주세요."
                        aria-invalid="true"
                        endAdornment={
                          <>
                            {isPwdShow ? (
                              <IconButton
                                onClick={() => setIsPwdShow((prev) => !prev)}
                              >
                                <span css={globalCSS.hidden}>
                                  비밀번호 보이기
                                </span>
                                <i css={globalCSS.icoHide} />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => setIsPwdShow((prev) => !prev)}
                              >
                                <span css={globalCSS.hidden}>
                                  비밀번호 숨기기
                                </span>
                                <i css={globalCSS.icoShow} />
                              </IconButton>
                            )}
                          </>
                        }
                      />

                      {/* <p className="text">14자 이상(영문, 숫자, 기호 모두 포함)</p> */}
                    </XTFormControl>
                  )}
                />
              </Grid>
            </Grid>
            {errors.pwd?.message && (
              <XTFormHelperText id="error-id" className="error">
                {errors.pwd?.message}
              </XTFormHelperText>
            )}
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <p className="text">새 비밀번호 확인</p>
              </Grid>
              <Grid item>
                <Controller
                  name="pwdConfirm"
                  control={control}
                  render={({ field }) => (
                    <XTFormControl>
                      <XTInput
                        style={{ width: "300px" }}
                        {...field}
                        className="custom-round login-input"
                        placeholder="새 비밀번호 확인"
                        type={isPwdShow ? "text" : "password"}
                        aria-describedby="error-pw"
                        aria-label="비밀번호를 입력해주세요."
                        aria-invalid="true"
                        endAdornment={
                          <>
                            {isPwdShow ? (
                              <IconButton
                                onClick={() => setIsPwdShow((prev) => !prev)}
                              >
                                <span css={globalCSS.hidden}>
                                  비밀번호 보이기
                                </span>
                                <i css={globalCSS.icoHide} />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => setIsPwdShow((prev) => !prev)}
                              >
                                <span css={globalCSS.hidden}>
                                  비밀번호 숨기기
                                </span>
                                <i css={globalCSS.icoShow} />
                              </IconButton>
                            )}
                          </>
                        }
                      />
                    </XTFormControl>
                  )}
                />
              </Grid>
            </Grid>
            {errors.pwdConfirm?.message && (
              <XTFormHelperText id="error-id" className="error">
                {errors.pwdConfirm?.message}
              </XTFormHelperText>
            )}

            <p className="notetext">※ 14자 이상(영문, 숫자, 기호 모두 포함)</p>
            <XTButton
              className="btn-login"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth
            >
              재설정
            </XTButton>
          </form>
          <AlertDialog
            title={alert.title}
            open={alert.open}
            text={alert.text}
            type={alert.type}
            handleOK={alert.handleOK}
            preventCloseEvent={true}
          />
        </div>
      </main>
    </>
  );
}

const style = {
  login: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    min-height: 100svh;

    .login-inner {
      width: ${theme.typography.pxToRem(600)};
      padding: ${theme.typography.pxToRem(70)} ${theme.typography.pxToRem(124)};
      border: 1px solid ${theme.palette.bg_02};
      background-color: ${theme.palette.white};
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        /* width: ${theme.typography.pxToRem(164.35)}; */
        aspect-ratio: 164.35 / 30;
        margin-bottom: ${theme.typography.pxToRem(10)};
      }
      .noti {
        margin-bottom: ${theme.typography.pxToRem(20)};
      }
      .notetext {
        margin-top: 20px;
        font-size: 13px;
        color: #999999;
      }

      form {
        width: 450px;
        .MuiFormControl-root + .MuiFormControl-root {
          margin-top: ${theme.typography.pxToRem(15)};
        }

        .error {
          margin-left: 150px;
          margin-bottom: 10px;
        }
        .btn-login {
          margin: ${theme.typography.pxToRem(30)} 0;
        }
      }
    }
  `,
};
