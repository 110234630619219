import {
  ExcelDownloadParamsType,
  OrderCancelParamsType,
  OrderDownloadParamsType,
  OrderListSchFormType,
  OrderNumberParamsType,
  OrderRegistParamsType,
} from "@/types/order";
import axios from "axios";

//물품신청관리 리스트 조회
export const loadOrderListAPI = async (form: OrderListSchFormType) => {
  return axios.get("/api/ap/apply/page-list", {
    params: { ...form },
  });
};

// 물품신청 타입 조회
export const loadOrderTypesAPI = async () => {
  return axios.get("/api/ap/apply/get-type");
};

//물품취소
export const cancelOrderAPI = async (form: OrderCancelParamsType) => {
  return axios.post("/api/ap/apply/update-canc", { ...form });
};

//물품출고
export const registOrderAPI = async (form: OrderRegistParamsType) => {
  return axios.post("/api/ap/apply/update-tagds", { ...form });
};

//운송장 번호 저장
export const registOrderNumberAPI = async (form: OrderNumberParamsType) => {
  return axios.post("/api/ap/apply/update-ivc-no", { ...form });
};

//운송장 업로드
export const uploadOrderAPI = async (file: any) => {
  let formData = new FormData();
  formData.append("file", file);
  return axios.post("/api/ap/apply/update-ivc-no-upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
  });
};

//운송장 템플릿 다운로드
export const downloadOrderAPI = async (form: OrderDownloadParamsType) => {
  return axios.post(
    "/api/ap/apply/get-ivc-excel",
    { ...form },
    {
      responseType: "blob",
    }
  );
};

//엑셀 다운로드
export const downloadExcelOrderAPI = async (form: ExcelDownloadParamsType) => {
  return axios.post(
    "/api/ap/apply/get-full-excel",
    { ...form },
    {
      responseType: "blob",
    }
  );
};

//물품신청관리 상세내역
export const orderDetailAPI = async (seq: string) => {
  return axios.get("/api/ap/apply/get", {
    params: {
      seq: seq,
    },
  });
};
