import { PopupFormType, PopupSchFormType } from "@/types/popup";
import axios from "axios";

export const loadPopupListAPI = async (form: PopupSchFormType) => {
  return axios.get("/api/cs/popup/page-list", {
    params: { ...form },
  });
};

export const loadPopupAPI = async (seq: string) => {
  return axios.get("/api/cs/popup/get", {
    params: {
      seq: seq,
    },
  });
};

export const registPopupAPI = async (form: PopupFormType) => {
  return axios.post("/api/cs/popup/regist", { ...form });
};

export const updatePopupAPI = async (form: PopupFormType) => {
  return axios.post("/api/cs/popup/update", { ...form });
};

export const deletePopupAPI = async (seq: string) => {
  return axios.post("/api/cs/popup/delete", { seq: seq });
};

export const loadPopupTypeAPI = async () => {
  return axios.get("/api/cs/popup/get-type");
};
