import { FaqFormType, FaqListType, FaqSchFormType, FaqType } from "@/types/faq";
import axios from "axios";

export const loadFaqListAPI = async (form: FaqSchFormType) => {
  return axios.get("/api/cs/faq/page-list", {
    params: { ...form },
  });
};

export const loadFaqAPI = async (seq: string) => {
  return axios.get("/api/cs/faq/get", {
    params: {
      seq: seq,
    },
  });
};

export const registFaqAPI = async (form: FaqFormType) => {
  return axios.post("/api/cs/faq/regist", { ...form });
};

export const updateFaqAPI = async (form: FaqFormType) => {
  return axios.post("/api/cs/faq/update", { ...form });
};

export const deleteFaqAPI = async (seq: string) => {
  return axios.post("/api/cs/faq/delete", { seq: seq });
};

export const loadFaqTypeAPI = async () => {
  return axios.get("/api/cs/faq/get-type");
};
