import { changeDatepickerDate, regComma } from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTAutocomplete, XTPopper } from "@/components/mui/xt-autocomplete";
import { XTButton, XTTextButton } from "@/components/mui/xt-button";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import XTDatePicker from "@/components/mui/xt-datepicker";
import {
  XTDialog,
  XTDialogActions,
  XTDialogContent,
  XTDialogTitle,
} from "@/components/mui/xt-dialog";
import { XTFormControl, XTInput, XTTextarea } from "@/components/mui/xt-input";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import {
  XTMenuItem,
  XTMenuItemWrap,
  XTSelect,
} from "@/components/mui/xt-select";
import ActionButtonGroup from "@/components/ui/action-button-group";
import AlertDialog from "@/components/ui/alert-dialog";
import {
  Box,
  Grid,
  InputLabel,
  LinearProgress,
  Radio,
  TextField,
} from "@mui/material";
import {
  GridColDef,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useState } from "react";

export default function GuideSample() {
  const apiRef = useGridApiRef();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const list1 = [
    {
      label: "FA00G(LS용산타워점)",
    },
    {
      label: "FA00J(LS군포R&D연구센터점)",
    },
    {
      label: "FA1MX(마곡식품연구센터점(카페테리아))",
    },
    {
      label: "FA1MY(마곡식품연구센터점(카페))",
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "아이디",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 150,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 150,
      editable: true,
    },
    {
      field: "age",
      headerName: "Age",
      type: "",
      width: 150,
      editable: true,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      flex: 1,
      sortable: false,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "hobby",
      headerName: "Hobby",
      description: "This column has a value getter and is not sortable.",
      flex: 1,
      sortable: false,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => params.value,
    },
  ];

  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 14, hobby: "asddqwdqw" },
    {
      id: 2,
      lastName: "Lannister",
      firstName: "Cersei",
      age: 31,
      hobby: "etst1t",
    },
    {
      id: 3,
      lastName: "Lannister",
      firstName: "Jaime",
      age: 31,
      hobby: "test23t",
    },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 11, hobby: "testest" },
    {
      id: 5,
      lastName: "Targaryen",
      firstName: "Daenerys",
      age: null,
      hobby: "boxing",
    },
    {
      id: 6,
      lastName: "Melisandre",
      firstName: null,
      age: 150,
      hobby: "backend develop",
    },
    {
      id: 7,
      lastName: "Clifford",
      firstName: "Ferrara",
      age: 44,
      hobby: "frontend develop",
    },
    {
      id: 8,
      lastName: "Frances",
      firstName: "Rossini",
      age: 36,
      hobby: "pc gaming",
    },
    {
      id: 9,
      lastName: "Roxie",
      firstName: "Harvey",
      age: 65,
      hobby: "reading book",
    },
  ];

  const handleClickAction = () => {
    alert("action!!!");
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <main id="sample" css={globalCSS.listContainer}>
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>회원관리</h2>
            </div>
            <div className="b-sub">
              <span>홈</span>
              <span>회원</span>
              <span>회원관리</span>
            </div>
          </div>
          <ActionButtonGroup
            handleClickInquiry={handleClickAction}
            handleClickRegist={null}
            handleClickSave={handleClickAction}
            handleClickDelete={null}
            handleClickPrint={handleClickAction}
            handleClickExcel={handleClickAction}
          />
        </div>
        <div className="cont-wrap">
          <div className="search-wrap">
            <table css={globalCSS.formTable}>
              <colgroup>
                <col style={{ width: "200px" }} />
                <col style={{ width: "770px" }} />
                <col style={{ width: "200px" }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputLabel>서비스 구분</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTRadioGroup row defaultValue="1">
                      <XTRadio value="1" control={<Radio />} label={"B2B"} />
                      <XTRadio value="2" control={<Radio />} label={"B2C"} />
                    </XTRadioGroup>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>점포/업장</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTAutocomplete
                      sx={{
                        width: "340px",
                      }}
                      options={list1}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="선택 또는 입력해 주세요."
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <div className="end-wrap">
                                <button
                                  className="organization"
                                  onClick={(e) => {
                                    alert("open organization!");
                                  }}
                                >
                                  <i />
                                </button>
                                {params.InputProps.endAdornment}
                              </div>
                            ),
                          }}
                        />
                      )}
                      renderOption={(props, option: any) => (
                        <li {...props}>{option.label}</li>
                      )}
                      PopperComponent={XTPopper}
                      noOptionsText="검색 결과가 없습니다."
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>가입일</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(startDate)}
                          onChange={(newValue) => {
                            setStartDate(newValue);
                          }}
                          maxDate={changeDatepickerDate(endDate)}
                          placeholder="test"
                        />
                      </Grid>
                      <Grid item>
                        <div className="divider">
                          <span>~</span>
                        </div>
                      </Grid>
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(endDate)}
                          minDate={changeDatepickerDate(startDate)}
                          onChange={(newValue) => {
                            setEndDate(newValue);
                          }}
                          placeholder="test"
                        />
                      </Grid>
                      <Grid item>
                        <div className="date-btn-group">
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                          >
                            전체
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                          >
                            오늘
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                          >
                            어제
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                          >
                            1주일
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                          >
                            1개월
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                          >
                            1년
                          </XTButton>
                        </div>
                      </Grid>
                    </Grid>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>회원구분</InputLabel>
                  </th>
                  <td>
                    <XTSelect
                      defaultValue={"placeholder"}
                      sx={{
                        minWidth: 150,
                      }}
                      MenuProps={{
                        MenuListProps: { sx: { padding: 0 } },
                        PaperProps: {
                          sx: {
                            ...XTMenuItemWrap,
                          },
                        },
                      }}
                    >
                      <XTMenuItem value="placeholder" selected disabled hidden>
                        <span className="placeholder">선택</span>
                      </XTMenuItem>
                      <XTMenuItem value={0}>리스트 1</XTMenuItem>
                      <XTMenuItem value={1}>리스트 2</XTMenuItem>
                      <XTMenuItem value={2}>리스트 3</XTMenuItem>
                      <XTMenuItem value={3}>리스트 4</XTMenuItem>
                    </XTSelect>
                  </td>
                  <th scope="row">
                    <InputLabel>KALIS 구독여부</InputLabel>
                  </th>
                  <td>
                    <XTSelect
                      defaultValue={"placeholder"}
                      sx={{
                        minWidth: 150,
                      }}
                      MenuProps={{
                        MenuListProps: { sx: { padding: 0 } },
                        PaperProps: {
                          sx: {
                            ...XTMenuItemWrap,
                          },
                        },
                      }}
                    >
                      <XTMenuItem value="placeholder" selected disabled hidden>
                        <span className="placeholder">선택</span>
                      </XTMenuItem>
                      <XTMenuItem value={0}>리스트 1</XTMenuItem>
                      <XTMenuItem value={1}>리스트 2</XTMenuItem>
                      <XTMenuItem value={2}>리스트 3</XTMenuItem>
                      <XTMenuItem value={3}>리스트 4</XTMenuItem>
                    </XTSelect>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>SMS 수신동의</InputLabel>
                  </th>
                  <td>
                    <XTSelect
                      defaultValue={"placeholder"}
                      sx={{
                        minWidth: 150,
                      }}
                      MenuProps={{
                        MenuListProps: { sx: { padding: 0 } },
                        PaperProps: {
                          sx: {
                            ...XTMenuItemWrap,
                          },
                        },
                      }}
                    >
                      <XTMenuItem value="placeholder" selected disabled hidden>
                        <span className="placeholder">선택</span>
                      </XTMenuItem>
                      <XTMenuItem value={0}>리스트 1</XTMenuItem>
                      <XTMenuItem value={1}>리스트 2</XTMenuItem>
                      <XTMenuItem value={2}>리스트 3</XTMenuItem>
                      <XTMenuItem value={3}>리스트 4</XTMenuItem>
                    </XTSelect>
                  </td>
                  <th scope="row">
                    <InputLabel>E-mail 수신동의</InputLabel>
                  </th>
                  <td>
                    <XTSelect
                      defaultValue={"placeholder"}
                      sx={{
                        minWidth: 150,
                      }}
                      MenuProps={{
                        MenuListProps: { sx: { padding: 0 } },
                        PaperProps: {
                          sx: {
                            ...XTMenuItemWrap,
                          },
                        },
                      }}
                    >
                      <XTMenuItem value="placeholder" selected disabled hidden>
                        <span className="placeholder">선택</span>
                      </XTMenuItem>
                      <XTMenuItem value={0}>리스트 1</XTMenuItem>
                      <XTMenuItem value={1}>리스트 2</XTMenuItem>
                      <XTMenuItem value={2}>리스트 3</XTMenuItem>
                      <XTMenuItem value={3}>리스트 4</XTMenuItem>
                    </XTSelect>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>검색</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTSelect
                          defaultValue={"placeholder"}
                          sx={{
                            minWidth: 150,
                          }}
                          MenuProps={{
                            MenuListProps: { sx: { padding: 0 } },
                            PaperProps: {
                              sx: {
                                ...XTMenuItemWrap,
                              },
                            },
                          }}
                        >
                          <XTMenuItem
                            value="placeholder"
                            selected
                            disabled
                            hidden
                          >
                            <span className="placeholder">선택</span>
                          </XTMenuItem>
                          <XTMenuItem value={0}>리스트 1</XTMenuItem>
                          <XTMenuItem value={1}>리스트 2</XTMenuItem>
                          <XTMenuItem value={2}>리스트 3</XTMenuItem>
                          <XTMenuItem value={3}>리스트 4</XTMenuItem>
                        </XTSelect>
                      </Grid>
                      <Grid item>
                        <XTInput placeholder="검색어를 입력해주세요." />
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="grid-wrap">
            <span className="total-cnt">
              전체 <span>{regComma(100000)}</span>건
            </span>
            <XTDataGrid
              className="clickable"
              columns={columns}
              rows={rows}
              apiRef={apiRef}
              experimentalFeatures={{
                ariaV7: true,
              }}
              onRowClick={() => {
                setOpenDialog(true);
              }}
              slots={{
                noRowsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
                noResultsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
              }}
              rowHeight={38}
              columnHeaderHeight={38}
              hideFooter
              components={{
                LoadingOverlay: LinearProgress,
              }}
            />
          </div>
        </div>
      </main>
      <XTDialog
        disablePortal
        className="lg"
        open={openDialog}
        css={globalCSS.detailContainer}
      >
        <XTDialogTitle className="flex-title">
          <span>회원 관리 상세</span>
          <button
            className="close-btn"
            onClick={() => {
              handleCloseDialog();
            }}
          >
            <i css={globalCSS.icoClose} />
          </button>
        </XTDialogTitle>
        <XTDialogContent>
          <div className="cont-wrap">
            <div className="cont-inner">
              <h3 className="sub-title">소제목1</h3>
              <table css={globalCSS.formTable}>
                <colgroup>
                  <col style={{ width: "200px" }} />
                  <col />
                  <col style={{ width: "200px" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <InputLabel required>Field (required)</InputLabel>
                    </th>
                    <td>Value</td>
                    <th scope="row">
                      <InputLabel required>Field (required)</InputLabel>
                    </th>
                    <td>Value</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>Field</InputLabel>
                    </th>
                    <td>Value</td>
                    <th scope="row">
                      <InputLabel>Field</InputLabel>
                    </th>
                    <td>Value</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>Field</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <XTFormControl>
                        <XTTextarea
                          name="textarea"
                          id="textarea"
                          multiline
                          placeholder="Textarea"
                        />
                      </XTFormControl>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="cont-inner">
              <h3 className="sub-title">소제목2</h3>
              <table css={globalCSS.formTable}>
                <colgroup>
                  <col style={{ width: "200px" }} />
                  <col />
                  <col style={{ width: "200px" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <InputLabel required>Field (required)</InputLabel>
                    </th>
                    <td>Value</td>
                    <th scope="row">
                      <InputLabel required>Field (required)</InputLabel>
                    </th>
                    <td>Value</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>Field</InputLabel>
                    </th>
                    <td>Value</td>
                    <th scope="row">
                      <InputLabel>Field</InputLabel>
                    </th>
                    <td>Value</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>Field</InputLabel>
                    </th>
                    <td>
                      <XTInput />
                    </td>
                    <th scope="row">
                      <InputLabel>Field</InputLabel>
                    </th>
                    <td>
                      <XTSelect
                        defaultValue={"placeholder"}
                        sx={{
                          minWidth: 150,
                        }}
                        MenuProps={{
                          MenuListProps: { sx: { padding: 0 } },
                          PaperProps: {
                            sx: {
                              ...XTMenuItemWrap,
                            },
                          },
                        }}
                      >
                        <XTMenuItem
                          value="placeholder"
                          selected
                          disabled
                          hidden
                        >
                          <span className="placeholder">선택</span>
                        </XTMenuItem>
                        <XTMenuItem value={0}>리스트 1</XTMenuItem>
                        <XTMenuItem value={1}>리스트 2</XTMenuItem>
                        <XTMenuItem value={2}>리스트 3</XTMenuItem>
                        <XTMenuItem value={3}>리스트 4</XTMenuItem>
                      </XTSelect>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="cont-inner">
              <div className="btn-wrap">
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={(e) => {}}
                >
                  추가
                </XTButton>
              </div>
              <table css={globalCSS.customTable}>
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col width="150px" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">Field</th>
                    <th scope="col">Field</th>
                    <th scope="col">Field</th>
                    <th scope="col">Field</th>
                    <th scope="col">Field</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="row">
                      <XTInput fullWidth placeholder="Placeholder" />
                    </td>
                    <td>
                      <XTInput fullWidth placeholder="Placeholder" />
                    </td>
                    <td>
                      <XTInput fullWidth placeholder="Placeholder" />
                    </td>
                    <td>
                      <XTRadioGroup row defaultValue="1" sx={{ width: "100%" }}>
                        <XTRadio value="1" control={<Radio />} label={"B2B"} />
                        <XTRadio value="2" control={<Radio />} label={"B2C"} />
                      </XTRadioGroup>
                    </td>
                    <td>
                      <XTButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={(e) => {}}
                      >
                        삭제
                      </XTButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </XTDialogContent>
        <XTDialogActions>
          <div className="bottom-wrap">
            <XTButton
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                alert("handle save");
              }}
            >
              저장
            </XTButton>
            <XTButton
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => {
                handleCloseDialog();
              }}
            >
              닫기
            </XTButton>
          </div>
        </XTDialogActions>
      </XTDialog>
    </>
  );
}
