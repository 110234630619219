import {
  changeDatepickerDate,
  changeSubmitDate,
  formatYmdHisDot,
  getSessionStorage,
  regDeleteSpecial,
  setSessionStorage,
} from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTCheckBox } from "@/components/mui/xt-checkbox";
import XTDatePicker from "@/components/mui/xt-datepicker";
import { XTFormHelperText, XTInput } from "@/components/mui/xt-input";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import { AdminRegistParamsType } from "@/types/system/manager";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Box, Checkbox, Grid, InputLabel, Radio } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { alertDialogState, UserInfoState } from "@/state";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  useManagerDetail,
  useManagerList,
  useManagerType,
} from "./_hook/use-manager";
import { XTMenuItem, XTSelect } from "@/components/mui/xt-select";
import { useLoginManager } from "@/pages/login/_hook/use-login";
import { RefreshTokenType } from "@/types/login";
import axios from "axios";
import { CommonHeaderProperties } from "@/types";
import { useMenuTreeManager } from "@/pages/system/menu/_hook/use-menu";

export default function AdminDetail() {
  const navigate = useNavigate();
  const [checkId, setCheckId] = useState("");
  const [alertDialog, setAlertDialog] = useRecoilState(alertDialogState);
  const { userInfo, permissionListData } = useManagerList();
  const { managerTypes } = useManagerType();
  const {
    detailData,
    refetch,
    seq,
    regist,
    update,
    deleted,
    unlock,
    dormant,
    adminDupCheck,
    adminTempPsw,
  } = useManagerDetail();
  const { refetchMenu } = useMenuTreeManager("Y");
  const { refreshToken } = useLoginManager();

  const schema = yup.object().shape({
    managerAccount: yup.object().shape({
      nm: yup.string().required("이름을 입력해주세요."),
      id: yup
        .string()
        .required("아이디를 입력해 주세요.")
        .test(
          "regex-check",
          "영소문자와 숫자로만 입력 가능합니다.",
          (value, context) => {
            const regex = /[^a-z0-9]/;

            if (!regex.test(value as string)) {
              return true;
            }

            return false;
          }
        )
        .test("duplicate-check", "아이디 중복확인이 필요합니다.", () => {
          if (checkId === "NotDuplicateID" || checkId === "") {
            return false;
          } else {
            return true;
          }
        }),
      dept: yup.string().required("부서명을 입력해주세요."),
      jbrl: yup.string().required("직급을 입력해주세요."),
      email: yup
        .string()
        .required("이메일을 입력해주세요.")
        .matches(
          /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
          "올바른 이메일 형식이 아닙니다."
        )
        .matches(
          /^[0-9a-z]([-_.]?[0-9a-z])*@[0-9a-z]([-_.]?[0-9a-z])*.[a-z]*[^A-Z]{2,3}$/,
          "영소문자와 숫자로만 입력 가능합니다."
        ),
    }),
    saSeq: yup.string().when("tp", (tp) => {
      if (tp === "4") {
        return yup.string().trim().required("일반관리자 권한을 선택해주세요.");
      }
      return yup.string();
    }),
    usePrdYn: yup.string().required(),
    usePrdStrtYmd: yup.lazy((value) => {
      return yup
        .string()
        .test(
          "usePrdStrtYmd",
          "사용기간을 선택해주세요.",
          (value: any, context: any) => {
            return context.from[0].value.usePrdYn === "N" || value;
          }
        );
    }),
    usePrdEndYmd: yup.lazy((value) => {
      return yup
        .string()
        .test(
          "usePrdEndYmd",
          "사용기간을 선택해주세요.",
          (value: any, context: any) => {
            return context.from[0].value.usePrdYn === "N" || value;
          }
        );
    }),
  });

  const {
    register,
    trigger,
    control,
    watch,
    clearErrors,
    setError,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, submitCount },
  } = useForm<AdminRegistParamsType>({
    shouldFocusError: false,
    defaultValues: {
      saSeq: " ",
      tp: "1",
      useYn: "Y",
      usePrdYn: "N",
      usePrdStrtYmd: "",
      usePrdEndYmd: "",
      maskYn: "Y",
      managerAccount: {
        id: "",
        nm: "",
        email: "",
        dept: "",
        jbrl: "",
      },
    },
    resolver: yupResolver(schema),
  });

  const adminDelete = async (seq: string) => {
    try {
      await deleted.mutateAsync(seq!);
      setAlertDialog({
        title: "",
        text: "삭제되었습니다.",
        open: true,
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          navigate(-1);
        },
        handleClose: null,
      });
      // Handle success
    } catch (error) {
      console.error("Mutation error:", error);
      // Handle error
    }
  };

  const dupCheck = async (id: string) => {
    const params = {
      schId: id!,
    };
    try {
      const result = await adminDupCheck.mutateAsync(params);

      if (result.data.status === 200) {
        if (result.data.data.cnt === 0) {
          setCheckId("duplicateID");
        } else {
          setCheckId("NotDuplicateID");
        }
      }

      // Handle success
    } catch (error) {
      setError("managerAccount.id", {
        type: "custom",
        message: "영소문자와 숫자로만 입력 가능합니다.",
      });
      // Handle error
    }
  };

  const handleRefreshUser = async () => {
    const form: RefreshTokenType = {
      grantType: "refresh_token",
      refreshToken: getSessionStorage("X-REFRESH-TOKEN-ADMIN"),
    };

    try {
      const newToken = await refreshToken.mutateAsync(form);

      if (newToken && newToken.data.data.accessToken) {
        setSessionStorage(
          "X-AUTH-TOKEN-ADMIN",
          `${newToken.data.data.tokenType} ${newToken.data.data.accessToken}`
        );

        setSessionStorage(
          "X-REFRESH-TOKEN-ADMIN",
          `${newToken.data.data.refreshToken}`
        );

        axios.defaults.headers = {
          Authorization: `${newToken.data.data.tokenType} ${newToken.data.data.accessToken}`,
        } as CommonHeaderProperties;

        await refetchMenu();
      }
    } catch {
      return;
    }
  };

  const managerRegist = async () => {
    const data = getValues();

    const newData = {
      ...data, // 기존 데이터 복사
      usePrdStrtYmd:
        getValues("usePrdYn") === "Y" ? watch("usePrdStrtYmd") : "",
      usePrdEndYmd: getValues("usePrdYn") === "Y" ? watch("usePrdEndYmd") : "",
      saSeq: getValues("tp") !== "4" ? "" : getValues("saSeq"),
    };

    try {
      const result = await regist.mutateAsync(newData);
      setAlertDialog({
        title: "",
        text: "등록되었습니다.",
        open: true,
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          navigate(-1);
        },
        handleClose: null,
      });
      // Handle success
    } catch (error) {
      setAlertDialog({
        title: "",
        text: "데이터가 중복되었습니다.",
        open: true,
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
        handleClose: null,
      });
      console.error("Mutation error:", error);
      // Handle error
    }
  };

  const managerUpdate = async () => {
    const data = getValues();

    const newData = {
      ...data, // 기존 데이터 복사
      usePrdStrtYmd:
        getValues("usePrdYn") === "Y"
          ? changeSubmitDate(watch("usePrdStrtYmd"))
          : "",
      usePrdEndYmd:
        getValues("usePrdYn") === "Y"
          ? changeSubmitDate(watch("usePrdEndYmd"))
          : "",
      saSeq: getValues("tp") !== "4" ? "" : getValues("saSeq"),
    };
    if (seq) {
      try {
        const result = await update.mutateAsync({ seq, newData });
        setAlertDialog({
          title: "",
          text: "수정되었습니다.",
          open: true,
          type: "complete",
          handleOK: async () => {
            if (userInfo.managerStatus.seq === detailData?.managerStatus.seq) {
              await handleRefreshUser();
            }

            setAlertDialog({
              ...alertDialog,
              open: false,
            });

            navigate(-1);
          },
          handleClose: null,
        });
        // Handle success
      } catch (error) {
        console.error("Mutation error:", error);
        // Handle error
      }
    }
  };

  const handleTempPsw = async () => {
    const params = {
      mgSeq: seq!,
    };
    try {
      const result = await adminTempPsw.mutateAsync(params);
      setAlertDialog({
        title: "",
        text: "임시 비밀번호가 발송 되었습니다.",
        open: true,
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
        handleClose: null,
      });

      // Handle success
    } catch (error) {
      console.error("Mutation error:", error);
      // Handle error
    }
  };

  const handleUnlockManager = async () => {
    const params = {
      mgSeq: seq!,
    };
    try {
      const result = await unlock.mutateAsync(params);
      setAlertDialog({
        title: "",
        text: "해당 관리자 계정 잠금 해제 처리되었습니다.",
        open: true,
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          refetch();
        },
        handleClose: null,
      });

      // Handle success
    } catch (error) {
      console.error("Mutation error:", error);
      // Handle error
    }
  };

  const handleDormantManager = async () => {
    const params = {
      mgSeq: seq!,
    };
    try {
      const result = await dormant.mutateAsync(params);
      setAlertDialog({
        title: "",
        text: "해당 관리자 휴면 해제 처리되었습니다.",
        open: true,
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          refetch();
        },
        handleClose: null,
      });

      // Handle success
    } catch (error) {
      console.error("Mutation error:", error);
      // Handle error
    }
  };

  const onSubmit = async () => {
    setAlertDialog({
      title: "",
      text: !seq ? "등록하시겠습니까?" : "수정하시겠습니까?",
      open: true,
      type: "confirm",
      handleOK: () => {
        if (!seq) {
          managerRegist();
        } else {
          managerUpdate();
        }
      },
      handleClose: () => {
        setAlertDialog({
          ...alertDialog,
          open: false,
        });
      },
    });
  };

  const onError = (err: any) => {
    console.log(err);
  };

  useEffect(() => {
    if (detailData && userInfo.managerStatus.seq && seq) {
      if (
        userInfo.managerStatus.tp !== 1 &&
        detailData?.managerStatus?.tp === 1
      ) {
        setAlertDialog({
          ...alertDialog,
          open: true,
          type: "complete",
          text: "최고관리자 계정 조회 권한이 없습니다.",
          handleOK: () => {
            setAlertDialog({
              ...alertDialog,
              open: false,
            });
            navigate(-1);
          },
        });
      } else {
        reset({
          saSeq:
            detailData?.managerStatus?.saSeq !== 0
              ? String(detailData?.managerStatus?.saSeq)
              : " ",
          tp: String(detailData?.managerStatus?.tp),
          useYn: detailData?.managerStatus?.useYn,
          usePrdYn: detailData?.managerStatus?.usePrdYn,
          usePrdStrtYmd: changeSubmitDate(
            detailData?.managerStatus?.usePrdStrtYmd
          ),
          usePrdEndYmd: changeSubmitDate(
            detailData?.managerStatus?.usePrdEndYmd
          ),
          maskYn: detailData?.managerStatus?.maskYn,
          managerAccount: {
            id: detailData?.managerStatus?.managerAccount.id,
            nm: detailData?.managerStatus?.managerAccount.nm,
            email: detailData?.managerStatus?.managerAccount.email,
            dept: detailData?.managerStatus?.managerAccount.dept,
            jbrl: detailData?.managerStatus?.managerAccount.jbrl,
          },
        });
      }
    } else {
      reset({
        saSeq: " ",
        tp: userInfo.managerStatus.tp === 2 ? "2" : "1",
        useYn: "Y",
        usePrdYn: "N",
        usePrdStrtYmd: "",
        usePrdEndYmd: "",
        maskYn: "Y",
        managerAccount: {
          id: "",
          nm: "",
          email: "",
          dept: "",
          jbrl: "",
        },
      });
    }
  }, [detailData, userInfo]);

  useEffect(() => {
    if (seq) {
      setCheckId("duplicateID");
    } else {
      setCheckId("");
    }
  }, [watch("managerAccount.id")]);

  return (
    <>
      <main id="sample" css={globalCSS.listContainer}>
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>관리자 계정 관리</h2>
            </div>
            <div className="b-sub">
              <span>시스템 관리</span>
              <span>관리자 계정 관리 상세</span>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="cont-wrap">
            <div className="cont-inner">
              <h3 className="sub-title">
                관리자 정보(<span className="color-point">*</span> 필수항목)
              </h3>
              <table css={globalCSS.formTable}>
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "40%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <InputLabel required>이름</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="managerAccount.nm"
                        control={control}
                        render={({ field }) => (
                          <div className="input-length">
                            <XTInput
                              {...field}
                              placeholder="이름을 입력해 주세요"
                              onChange={(e) => {
                                field.onChange(
                                  regDeleteSpecial(e.target.value)
                                );
                              }}
                            />
                          </div>
                        )}
                      />
                      {errors.managerAccount?.nm?.type === "required" && (
                        <XTFormHelperText className="error">
                          {errors.managerAccount?.nm?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel required>아이디</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Controller
                            rules={{
                              required: true,
                            }}
                            name="managerAccount.id"
                            control={control}
                            render={({ field }) => (
                              <div className="input-length">
                                <XTInput
                                  disabled={seq ? true : false}
                                  {...field}
                                  placeholder="아이디를 입력해 주세요"
                                  onChange={(e) => {
                                    clearErrors("managerAccount.id");

                                    field.onChange(
                                      regDeleteSpecial(e.target.value)
                                    );
                                  }}
                                />
                              </div>
                            )}
                          />
                        </Grid>
                        {!seq && (
                          <Grid item>
                            <XTButton
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                dupCheck(watch("managerAccount.id"));
                              }}
                              disabled={
                                !watch("managerAccount.id") ? true : false
                              }
                            >
                              중복확인
                            </XTButton>
                          </Grid>
                        )}

                        {!seq && checkId === "duplicateID" && (
                          <Grid item>
                            <XTFormHelperText className="success">
                              사용가능한 아이디 입니다
                            </XTFormHelperText>
                          </Grid>
                        )}
                        {!seq && checkId === "NotDuplicateID" && (
                          <Grid item>
                            <XTFormHelperText className="error">
                              이미 사용중인 아이디입니다
                            </XTFormHelperText>
                          </Grid>
                        )}
                      </Grid>

                      {(checkId === "NotDuplicateID" || checkId === "") &&
                        errors.managerAccount?.id?.message && (
                          <XTFormHelperText className="error">
                            {errors.managerAccount?.id?.message}
                          </XTFormHelperText>
                        )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel required>부서명</InputLabel>
                    </th>
                    <td>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="managerAccount.dept"
                        control={control}
                        render={({ field }) => (
                          <div className="input-length">
                            <XTInput
                              {...field}
                              placeholder="부서명을 입력해 주세요"
                            />
                          </div>
                        )}
                      />
                      {errors.managerAccount?.dept?.type === "required" && (
                        <XTFormHelperText className="error">
                          {errors.managerAccount?.dept?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                    <th scope="row">
                      <InputLabel required>직급</InputLabel>
                    </th>
                    <td>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="managerAccount.jbrl"
                        control={control}
                        render={({ field }) => (
                          <div className="input-length">
                            <XTInput
                              {...field}
                              placeholder="직급을 입력해 주세요"
                            />
                          </div>
                        )}
                      />
                      {errors.managerAccount?.jbrl?.type === "required" && (
                        <XTFormHelperText className="error">
                          {errors.managerAccount?.jbrl?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel required>이메일</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="managerAccount.email"
                        control={control}
                        render={({ field }) => (
                          <div className="input-length">
                            <XTInput
                              {...field}
                              fullWidth
                              placeholder="이메일 주소를 입력해 주세요"
                              sx={{ maxWidth: "40%" }}
                            />
                          </div>
                        )}
                      />
                      {errors.managerAccount?.email?.type === "required" && (
                        <XTFormHelperText className="error">
                          {errors.managerAccount?.email?.message}
                        </XTFormHelperText>
                      )}
                      {errors.managerAccount?.email?.type === "matches" && (
                        <XTFormHelperText className="error">
                          {errors.managerAccount?.email?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <InputLabel required>구분</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Controller
                            rules={{
                              required: true,
                            }}
                            name="tp"
                            control={control}
                            render={({ field }) => (
                              <XTRadioGroup row {...field}>
                                {Object.keys(
                                  managerTypes.ManagerStatusType
                                ).map((key) => (
                                  <React.Fragment key={key}>
                                    {userInfo.managerStatus.tp === 1 ? (
                                      <XTRadio
                                        value={key}
                                        control={<Radio />}
                                        label={
                                          managerTypes.ManagerStatusType[key]
                                        }
                                      />
                                    ) : (
                                      <React.Fragment>
                                        {key !== "1" && (
                                          <XTRadio
                                            value={key}
                                            control={<Radio />}
                                            label={
                                              managerTypes.ManagerStatusType[
                                                key
                                              ]
                                            }
                                          />
                                        )}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                ))}
                              </XTRadioGroup>
                            )}
                          />
                        </Grid>
                        {watch("tp") === "4" && (
                          <Grid item>
                            <Controller
                              rules={{
                                required: true,
                              }}
                              name="saSeq"
                              control={control}
                              render={({ field }) => (
                                <XTSelect
                                  {...field}
                                  sx={{
                                    minWidth: 150,
                                  }}
                                  MenuProps={{
                                    MenuListProps: { sx: { padding: 0 } },
                                  }}
                                >
                                  <XTMenuItem
                                    value=" "
                                    selected
                                    disabled
                                    hidden
                                  >
                                    <span className="placeholder">
                                      선택해주세요
                                    </span>
                                  </XTMenuItem>
                                  {permissionListData!.authList?.map(
                                    (item, i) => (
                                      <XTMenuItem key={i} value={item.seq}>
                                        {item.ttl}
                                      </XTMenuItem>
                                    )
                                  )}
                                </XTSelect>
                              )}
                            />
                          </Grid>
                        )}
                      </Grid>

                      {watch("tp") != "1" && (
                        <XTCheckBox
                          control={
                            <Checkbox
                              checked={watch("maskYn") === "N"}
                              onChange={() =>
                                setValue(
                                  "maskYn",
                                  watch("maskYn") === "Y" ? "N" : "Y"
                                )
                              }
                            />
                          }
                          label="개인정보 마스킹 처리 해제"
                        />
                      )}
                      {errors.saSeq?.type === "required" && (
                        <XTFormHelperText className="error">
                          {errors.saSeq?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <InputLabel required>사용여부</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="useYn"
                        control={control}
                        render={({ field }) => (
                          <XTRadioGroup row {...field}>
                            <XTRadio
                              value="Y"
                              control={<Radio />}
                              label={"Y"}
                            />
                            <XTRadio
                              value="N"
                              control={<Radio />}
                              label={"N"}
                            />
                          </XTRadioGroup>
                        )}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel required>사용기간</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Grid container className="form-grid" spacing={2}>
                        <Grid item>
                          <Controller
                            rules={{
                              required: true,
                            }}
                            name="usePrdYn"
                            control={control}
                            render={({ field }) => (
                              <XTRadioGroup row {...field}>
                                <XTRadio
                                  value="N"
                                  control={<Radio />}
                                  label={"상시"}
                                />
                                <XTRadio
                                  value="Y"
                                  control={<Radio />}
                                  label={"기간 선택"}
                                />
                              </XTRadioGroup>
                            )}
                          />
                        </Grid>
                        {watch("usePrdYn") === "Y" && (
                          <>
                            <Grid item>
                              <Controller
                                name="usePrdStrtYmd"
                                control={control}
                                render={({ field }) => (
                                  <XTDatePicker
                                    value={changeDatepickerDate(
                                      watch("usePrdStrtYmd")
                                    )}
                                    onChange={(newDate) => {
                                      field.onChange(newDate);
                                    }}
                                    maxDate={changeDatepickerDate(
                                      watch("usePrdEndYmd")
                                    )}
                                    placeholder="시작일을 선택하세요."
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item>
                              <div className="divider">
                                <span>~</span>
                              </div>
                            </Grid>
                            <Grid item>
                              <Controller
                                name="usePrdEndYmd"
                                control={control}
                                render={({ field }) => (
                                  <XTDatePicker
                                    value={changeDatepickerDate(
                                      watch("usePrdEndYmd")
                                    )}
                                    minDate={changeDatepickerDate(
                                      watch("usePrdStrtYmd")
                                    )}
                                    onChange={(newDate) => {
                                      field.onChange(newDate);
                                    }}
                                    placeholder="종료일을 선택하세요."
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                      {watch("usePrdYn") === "Y" &&
                        (errors.usePrdStrtYmd?.message ||
                          errors.usePrdEndYmd?.message) && (
                          <XTFormHelperText className="error">
                            사용기간을 선택해주세요.
                          </XTFormHelperText>
                        )}
                    </td>
                  </tr>
                  {seq && (
                    <>
                      <tr>
                        <th scope="row">
                          <InputLabel>최종 로그인 일시</InputLabel>
                        </th>
                        <td colSpan={3}>
                          {formatYmdHisDot(
                            detailData?.managerStatus?.loginYmd!,
                            detailData?.managerStatus?.loginHis!
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <InputLabel>임시 비밀번호 발송</InputLabel>
                        </th>
                        <td colSpan={3}>
                          <XTButton
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              setAlertDialog({
                                title: "",
                                text: "임시 비밀번호를 발송하시겠습니까?",
                                open: true,
                                type: "confirm",
                                handleOK: () => {
                                  setAlertDialog({
                                    ...alertDialog,
                                    open: false,
                                  });
                                  handleTempPsw();
                                },
                                handleClose: () => {
                                  setAlertDialog({
                                    ...alertDialog,
                                    open: false,
                                  });
                                },
                              });
                            }}
                          >
                            임시 비밀번호 발송
                          </XTButton>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <InputLabel>등록자</InputLabel>
                        </th>
                        <td>
                          {detailData?.managerStatus?.rgstrManagerAccount?.nm
                            ? `${detailData?.managerStatus?.rgstrManagerAccount?.nm}(${detailData?.managerStatus?.rgstrManagerAccount?.id})`
                            : "-"}
                        </td>
                        <th scope="row">
                          <InputLabel>등록일시</InputLabel>
                        </th>
                        <td>
                          {formatYmdHisDot(
                            detailData?.managerStatus?.rgstYmd,
                            detailData?.managerStatus?.rgstHis
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <InputLabel>수정자</InputLabel>
                        </th>
                        <td>
                          {detailData?.managerStatus?.modrManagerAccount?.nm
                            ? `${detailData?.managerStatus?.modrManagerAccount?.nm}(${detailData?.managerStatus?.modrManagerAccount?.id})`
                            : "-"}
                        </td>
                        <th scope="row">
                          <InputLabel>수정일시</InputLabel>
                        </th>
                        <td>
                          {formatYmdHisDot(
                            detailData?.managerStatus?.modYmd,
                            detailData?.managerStatus?.modHis
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
              <Box css={globalCSS.bottomWrap} marginTop={4}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <XTButton
                      variant="outlined"
                      color="primary"
                      size="medium"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      {seq ? "목록" : "취소"}
                    </XTButton>
                  </Grid>
                  <Grid item>
                    {seq && (
                      <>
                        {detailData?.managerStatus.lckdYn === "Y" && (
                          <XTButton
                            variant="outlined"
                            color="primary"
                            size="medium"
                            onClick={() => {
                              handleUnlockManager();
                            }}
                          >
                            계정 잠금 해제
                          </XTButton>
                        )}
                        {detailData?.managerStatus.lstLoginChkCnt === 0 && (
                          <XTButton
                            variant="outlined"
                            color="primary"
                            size="medium"
                            onClick={() => {
                              handleDormantManager();
                            }}
                          >
                            휴면 해제
                          </XTButton>
                        )}

                        <XTButton
                          variant="outlined"
                          color="primary"
                          size="medium"
                          onClick={() => {
                            setAlertDialog({
                              title: "",
                              text: "삭제하시겠습니까?",
                              open: true,
                              type: "confirm",
                              handleOK: () => {
                                adminDelete(seq);
                              },
                              handleClose: () => {
                                setAlertDialog({
                                  ...alertDialog,
                                  open: false,
                                });
                              },
                            });
                          }}
                        >
                          삭제
                        </XTButton>
                      </>
                    )}
                    <XTButton
                      variant="outlined"
                      color="primary"
                      size="medium"
                      type="submit"
                    >
                      {seq ? "수정" : "등록"}
                    </XTButton>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
        </form>
      </main>
    </>
  );
}
