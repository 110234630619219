import moment from "moment";
import React, { useEffect, useRef } from "react";
import DatePicker, { DateObject, DatePickerRef } from "react-multi-date-picker";
import highlightWeekends from "react-multi-date-picker/plugins/highlight_weekends";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import customLocaleKo from "@/customLocaleKo";
import { Theme, css } from "@emotion/react";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "./xt-button";
import { changeSubmitTime, changeTimepickerDate } from "@/assets/ts/common";

interface XTDatePickerType {
  value: Date | string;
  onChange: (value: any) => void;
  minDate?: Date | string | null;
  maxDate?: Date | string | null;
  placeholder?: string;
  disabled?: boolean;
  disabledInput?: boolean;
  render?: React.ReactElement;
}

export default function XTTimePicker(props: XTDatePickerType) {
  const {
    value,
    onChange,
    minDate = null,
    maxDate = null,
    placeholder,
    disabled = false,
    disabledInput = false,
    render,
  } = props;

  const datepickerRef = useRef<DatePickerRef>();

  const closeDatePicker = (event: any) => {
    if (!datepickerRef || (event.keyCode !== 27 && event.keyCode !== 13))
      return;
    datepickerRef.current?.closeCalendar();
  };

  useEffect(() => {
    window.addEventListener("keydown", closeDatePicker);

    return () => {
      window.removeEventListener("keydown", closeDatePicker);
    };
  }, []);

  function CustomButton({ openCalendar, handleValueChange, ...props }: any) {
    return (
      <XTButton
        {...props}
        variant="outlined"
        color="primary"
        size="small"
        className="btn-datepicker"
        endIcon={<i css={globalCSS.icoTime} />}
      >
        {value ? (
          <span>
            {/* {timePicker
              ? formatYmd(value.toString(), true)
              : formatYmd(value.toString())} */}
            {renderInputDateValue(value)}
          </span>
        ) : (
          <span className="datepicker-placeholder">
            {placeholder ? placeholder : "HH:mm"}
          </span>
        )}
      </XTButton>
    );
  }

  const renderInputDateValue = (value: Date | string) => {
    let date = moment(changeTimepickerDate(value)).format("HH:mm");

    return date;
  };

  return (
    <>
      <DatePicker
        disableDayPicker
        containerClassName="xt-datepicker-input-container xt-timepicker"
        className="xt-datepicker"
        css={style.datepicker}
        ref={datepickerRef}
        render={<CustomButton />}
        format={"HH:mm"}
        formatMonth={(month, year) => {
          return month;
        }}
        formatYear={(year, month) => {
          return year + "년";
        }}
        onChange={(value: DateObject | DateObject[] | string | null) => {
          let formatValue;

          formatValue = value
            ? changeSubmitTime(
                moment((value as DateObject).format("HH:mm"), "HH:mm").toDate()
              )
            : "";

          onChange(formatValue);
        }}
        disabled={disabled}
        placeholder={placeholder}
        value={changeTimepickerDate(value)}
        // minDate={changeTimepickerDate(minDate as Date)}
        // maxDate={changeTimepickerDate(maxDate as Date)}
        arrow={false}
        shadow={true}
        locale={customLocaleKo}
        onOpenPickNewDate={false}
        highlightToday={false}
        plugins={[
          highlightWeekends(),
          <TimePicker position="right" header={false} hideSeconds />,
        ]}
        weekDays={
          [
            <span className="weekend">일</span>,
            "월",
            "화",
            "수",
            "목",
            "금",
            <span className="weekend">토</span>,
          ] as string[]
        }
      />
    </>
  );
}

const style = {
  datepicker: (theme: Theme) => css`
    .rmdp-arrow {
      border-color: ${theme.palette.primary.main};
    }

    .rmdp-top-class {
      .rmdp-calendar {
        .rmdp-header {
          .rmdp-arrow-container {
            &:hover {
              box-shadow: none;
              background-color: ${theme.palette.selected};

              .rmdp-arrow {
                border-color: ${theme.palette.default};
              }
            }
          }
          .rmdp-header-values {
            font-weight: 600;
          }
        }
      }
    }

    .rmdp-year-picker {
      .rmdp-ym {
        .rmdp-selected {
          span:not(.highlight) {
            box-shadow: none;
            background-color: ${theme.palette.selected};
            color: ${theme.palette.default};
          }
        }
        &:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
          box-shadow: none;
          background-color: ${theme.palette.selected};
          color: ${theme.palette.default};
        }
      }
    }

    .rmdp-month-picker {
      .rmdp-day {
        &.rmdp-selected span:not(.highlight) {
          box-shadow: none;
          background-color: ${theme.palette.selected};
          color: ${theme.palette.default};
        }
        &:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
          box-shadow: none;
          background-color: ${theme.palette.selected};
          color: ${theme.palette.default};
        }
      }
    }

    .rmdp-day-picker {
      .rmdp-week-day {
        color: ${theme.palette.text_sub2};
      }

      .rmdp-day {
        &.rmdp-selected span:not(.highlight) {
          box-shadow: none;
          background-color: ${theme.palette.selected};
          color: ${theme.palette.default};
        }

        span {
          color: ${theme.palette.text_sub1};
        }
        &:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
          background-color: ${theme.palette.selected};
        }

        &.rmdp-disabled {
          span {
            color: ${theme.palette.disabled};
          }
        }
      }
    }

    .rmdp-time-picker {
      .rmdp-arrow-container:hover {
        box-shadow: none;
        background-color: ${theme.palette.selected};
        .rmdp-arrow {
          border-color: ${theme.palette.default};
        }
      }
    }
  `,
};
