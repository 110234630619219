import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { Editor } from "@tinymce/tinymce-react";
import { XTFormHelperText, XTInput } from "@/components/mui/xt-input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import { Box, Grid, InputLabel } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProvisionRegistParamsType } from "@/types/system/provision";
import XTDatePicker from "@/components/mui/xt-datepicker";
import { XTMenuItem, XTSelect } from "@/components/mui/xt-select";
import VerDialog from "@/pages/system/provision/_component/ver-dialog";
import { alertDialogState } from "@/state";
import { useRecoilState } from "recoil";
import { useProvisionDetail } from "./_hook/use-provision";
import { changeDatepickerDate, formatYmdHisDot } from "@/assets/ts/common";

export default function ProvisionDetail() {
  const [ver, setVer] = useState("");
  const [minDate, setMinDate] = useState("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { seq, detailData, verInfo, regist, provisionType } =
    useProvisionDetail();
  const navigate = useNavigate();
  const [alertDialog, setAlertDialog] = useRecoilState(alertDialogState);

  const schema = yup.object().shape({
    tp: yup.string().required("약관을 선택해주세요."),
    cont: yup.string().required("약관내용을 입력해주세요."),
    strtYmd: yup.string().required("적용일을 선택해주세요."),
  });

  const {
    trigger,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    clearErrors,
    formState: { errors, submitCount },
  } = useForm<ProvisionRegistParamsType>({
    shouldFocusError: false,
    defaultValues: {
      tp: "",
      cont: "",
      strtYmd: "",
    },
    resolver: yupResolver(schema),
  });

  const handleVersion = async (tp: string) => {
    try {
      const result = await verInfo.mutateAsync(tp!);
      const provision = result.data.data.provision;
      setMinDate(moment(provision.strtYmd).add(1, "day").format("YYYYMMDD"));
      setValue("strtYmd", "");
      if (provision.ver == "0.1") {
        setVer(provision.ver);
      } else {
        setVer((parseFloat(provision.ver) + 0.1).toFixed(1));
      }
      // Handle success
    } catch (error) {
      console.error("Mutation error:", error);
      // Handle error
    }
  };

  const provisionRegist = async () => {
    try {
      const result = await regist.mutateAsync(getValues()!);
      setAlertDialog({
        title: "",
        text: "등록되었습니다.",
        open: true,
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          navigate(-1);
        },
        handleClose: null,
      });
      // Handle success
    } catch (error: any) {
      let data = error.response.data;
      if (data.code === "CM008") {
        setAlertDialog({
          title: "",
          text: "이미 등록된 적용일 입니다.",
          open: true,
          type: "complete",
          handleOK: () => {
            setAlertDialog({
              ...alertDialog,
              open: false,
            });
          },
          handleClose: null,
        });
      } else {
        setAlertDialog({
          title: "",
          text: "약관 등록을 실패했습니다.",
          open: true,
          type: "complete",
          handleOK: () => {
            setAlertDialog({
              ...alertDialog,
              open: false,
            });
          },
          handleClose: null,
        });
      }

      // Handle error
    }
  };

  const onSubmit = async () => {
    setAlertDialog({
      title: "",
      text: "약관은 등록 후 수정 또는 삭제가 불가능합니다.<br />입력하신 내용으로 등록 하시겠습니까?",
      open: true,
      type: "confirm",
      handleOK: () => {
        provisionRegist();
      },
      handleClose: () => {
        setAlertDialog({
          ...alertDialog,
          open: false,
        });
      },
    });
  };

  const onError = () => {};

  useEffect(() => {
    if (detailData) {
      reset({
        tp: detailData.provision.tp,
        cont: detailData.provision.cont,
        strtYmd: detailData.provision.strtYmd,
      });
      setVer(detailData.provision.ver);
    }
  }, [detailData]);

  return (
    <>
      <main id="sample" css={globalCSS.listContainer}>
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>약관관리</h2>
            </div>
            <div className="b-sub">
              <span>시스템 관리</span>
              <span>약관관리 상세</span>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="cont-wrap">
            <div className="cont-inner">
              <h3 className="sub-title">
                약관정보(<span className="color-point">*</span> 필수항목)
              </h3>
              <table css={globalCSS.formTable}>
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "40%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <InputLabel required>약관</InputLabel>
                    </th>
                    <td colSpan={3}>
                      {provisionType && (
                        <XTSelect
                          disabled={!!seq}
                          value={watch("tp")}
                          onChange={(e) => {
                            setValue("tp", e.target.value as string);
                            trigger("tp");
                            handleVersion(e.target.value as string);
                          }}
                          sx={{
                            minWidth: 200,
                          }}
                          MenuProps={{
                            MenuListProps: { sx: { padding: 0 } },
                          }}
                        >
                          {Object.entries(provisionType.ProvisionType).map(
                            ([id, text]) => (
                              <XTMenuItem key={id} value={id}>
                                {text}
                              </XTMenuItem>
                            )
                          )}
                        </XTSelect>
                      )}

                      {errors.tp?.type === "required" && (
                        <XTFormHelperText className="error">
                          {errors.tp?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>버전</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <XTInput value={ver} disabled />
                        </Grid>
                        {seq && (
                          <Grid item>
                            <XTButton
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                navigate("/system/provision/detail", {
                                  replace: true,
                                });
                                handleVersion(watch("tp"));
                              }}
                            >
                              이 버전으로 신규작성
                            </XTButton>
                          </Grid>
                        )}

                        {!seq && watch("tp") != "" && ver != "1.0" && (
                          <Grid item>
                            <XTButton
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                setOpenDialog(true);
                              }}
                            >
                              이전 버전 불러오기
                            </XTButton>
                          </Grid>
                        )}
                      </Grid>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel required>약관내용</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Controller
                        defaultValue={getValues("cont")}
                        rules={{
                          required: true,
                        }}
                        name="cont"
                        control={control}
                        render={({ field }) => (
                          <Editor
                            id="cont"
                            disabled={!!seq}
                            apiKey={process.env.REACT_APP_TINY_API_KEY}
                            onEditorChange={(newText) => {
                              setValue("cont", newText);
                              trigger("cont");
                            }}
                            value={getValues("cont")}
                            init={{
                              menubar: true,
                              placeholder: "내용을 입력해주세요.",
                              relative_urls: false,
                              remove_script_host: false,
                              plugins:
                                "autolink lists link charmap insertdatetime table",
                              toolbar:
                                "undo redo | styles | lineheight fontsize | " +
                                "bold italic forecolor backcolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | " +
                                "removeformat | help | link",
                              automatic_uploads: true,
                              highlight_on_focus: true,
                              // content_css: `${process.env.PUBLIC_URL}/editor.css`,
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              extended_valid_elements:
                                "button[class|name|href|title|onclick|rel|id|style], a[class|name|href|target|title|onclick|rel],script[type|src], style,link[href|rel], iframe[src|style|width|height|scrolling|marginwidth|marginheight|frameborder],img[class|src|border=0|alt|title|hspace|vspace|width|height|align|onmouseover|onmouseout|name]",
                              custom_elements: "style,link,~link",
                              resize: true,
                            }}
                          />
                        )}
                      />
                      {errors.cont?.type === "required" && (
                        <XTFormHelperText className="error">
                          {errors.cont?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel required>적용일</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Grid container>
                        <Grid item>
                          <Controller
                            name="strtYmd"
                            control={control}
                            render={({ field }) => (
                              <XTDatePicker
                                {...field}
                                minDate={changeDatepickerDate(minDate)}
                                disabled={!!seq}
                                placeholder="적용시작일 선택"
                              />
                            )}
                          />
                        </Grid>
                        {detailData && detailData!.provision!.endYmd && (
                          <>
                            <Grid item>
                              <div className="divider">
                                <span>~</span>
                              </div>
                            </Grid>
                            <Grid item>
                              <XTDatePicker
                                onChange={() => {}}
                                value={detailData!.provision.endYmd}
                                disabled={!!seq}
                                placeholder="적용종료일 선택"
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>

                      {errors.strtYmd?.type === "required" && (
                        <XTFormHelperText className="error">
                          {errors.strtYmd?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>
                  {seq && (
                    <tr>
                      <th scope="row">
                        <InputLabel>등록자</InputLabel>
                      </th>
                      <td>
                        {detailData?.provision?.rgstrManagerAccount?.nm
                          ? `${detailData?.provision?.rgstrManagerAccount?.nm}(${detailData?.provision?.rgstrManagerAccount?.id})`
                          : "-"}
                      </td>
                      <th scope="row">
                        <InputLabel>등록일시</InputLabel>
                      </th>
                      <td>
                        {formatYmdHisDot(
                          detailData?.provision?.rgstYmd,
                          detailData?.provision?.rgstHis
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Box css={globalCSS.bottomWrap} marginTop={4}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <XTButton
                      variant="outlined"
                      color="primary"
                      size="medium"
                      onClick={() => {
                        if (seq) {
                          navigate(-1);
                        } else {
                          setAlertDialog({
                            title: "",
                            text: "취소하시겠습니까?",
                            open: true,
                            type: "confirm",
                            handleOK: () => {
                              setAlertDialog({
                                title: "",
                                text: "취소되었습니다",
                                open: true,
                                type: "complete",
                                handleOK: () => {
                                  setAlertDialog({
                                    ...alertDialog,
                                    open: false,
                                  });
                                  navigate(-1);
                                },
                                handleClose: null,
                              });
                            },
                            handleClose: () => {
                              setAlertDialog({
                                ...alertDialog,
                                open: false,
                              });
                            },
                          });
                        }
                      }}
                    >
                      {seq ? "목록" : "취소"}
                    </XTButton>
                  </Grid>
                  {!seq && (
                    <Grid item>
                      <XTButton
                        variant="outlined"
                        color="primary"
                        size="medium"
                        type="submit"
                      >
                        등록
                      </XTButton>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </div>
          </div>
        </form>
        {provisionType && (
          <VerDialog
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            tp={watch("tp")}
            provisionType={provisionType}
          />
        )}
      </main>
    </>
  );
}
