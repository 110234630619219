import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import {
  ProductRegistParamsType,
  StockListSchFormType,
  StockListType,
} from "@/types/product";
import { loadStockListAPI, registProductAPI } from "@/api/product";
import { changeSubmitDate } from "@/assets/ts/common";
import moment from "moment";

export const useStock = () => {
  const initialData: StockListType = {
    goodsStockList: {
      list: [],
      pageSize: 0,
      totalSize: 1,
      idx: 0,
      page: 0,
      number: 0,
      nextPageAvailable: false,
      previousPageAvailable: false,
      firstPage: false,
      pageIndex: 0,
      middlePage: false,
      lastPage: false,
      totalPageSize: 0,
      empty: false,
    },
    GoodsStockType: {
      "1": "입고",
      "2": "출고",
      "3": "폐기",
    },
  };

  const initialSchFormData: StockListSchFormType = {
    pg: "1",
    pgSz: "10",
    sordFld: "",
    sordMetd: "DESC",
    schGgSeq: "",
    schTpArr: ["1", "2", "3"],
    schStrtYmd: "",
    schEndYmd: "",
  };
  const [schForm, setSchForm] = useState(initialSchFormData);

  const [schViewForm, setSchViewForm] = useState({
    schTpArr: ["1", "2", "3"],
    schStrtYmd: "",
    schEndYmd: "",
  });

  const { data: stockListData = initialData, isFetching } = useQuery<
    AxiosResponse<{ data: StockListType }>,
    AxiosError,
    StockListType
  >({
    queryKey: ["loadStockList", schForm],
    queryFn: async () => await loadStockListAPI(schForm),
    select: (res) => {
      let list = res.data.data.goodsStockList.list;

      list.map((item, i) => {
        item.id = item.seq;
        item.index = i;
      });
      return res.data.data;
    },
    enabled: !!schForm.schGgSeq,
  });

  const handleResetInput = (seq: string) => {
    let resetForm = {
      pg: "1",
      pgSz: "10",
      sordFld: "",
      sordMetd: "DESC",
      schGgSeq: seq,
      schTpArr: ["1", "2", "3"],
      schStrtYmd: "",
      schEndYmd: "",
    };

    setSchForm(resetForm);
    setSchViewForm({
      schTpArr: ["1", "2", "3"],
      schStrtYmd: "",
      schEndYmd: "",
    });
  };

  const handleChangeDatePicker = (name: string) => (value: Date) => {
    setSchViewForm((prev) => ({
      ...prev,
      pg: "1",
      [name]: changeSubmitDate(value),
    }));
  };

  const handleClickDateButton = (month: string | null) => {
    let startDate = "";
    let endDate = "";

    if (month) {
      startDate = moment().subtract(month, "months").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    }

    setSchViewForm((prev) => ({
      ...prev,
      pg: "1",
      schStrtYmd: changeSubmitDate(startDate),
      schEndYmd: changeSubmitDate(endDate),
    }));
  };

  const handleChangeInput = (event: any) => {
    const target = event.target;
    const name = target.name;

    setSchViewForm((prev) => ({
      ...prev,
      [name]: target.value,
    }));
  };

  const handleCheckBox = (val: string) => {
    if (val != "all") {
      if (schViewForm.schTpArr.includes(val)) {
        setSchViewForm((prev) => ({
          ...prev,
          schTpArr: schViewForm.schTpArr.filter((item) => item != val),
        }));
      } else {
        const array: string[] = [];
        schViewForm.schTpArr.map((item) => {
          array.push(item);
        });
        array.push(val);
        setSchViewForm((prev) => ({
          ...prev,
          schTpArr: array,
        }));
      }
    } else {
      if (
        ["1", "2", "3"].every((value) => schViewForm.schTpArr.includes(value))
      ) {
        setSchViewForm((prev) => ({
          ...prev,
          schTpArr: [],
        }));
      } else {
        setSchViewForm((prev) => ({
          ...prev,
          schTpArr: ["1", "2", "3"],
        }));
      }
    }
  };

  const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    let form = {
      ...schForm,
      pg: value.toString(),
    };
    setSchForm(form);
  };

  const handleSearch = () => {
    let form = {
      ...schForm,
      schTpArr: schViewForm.schTpArr,
      schStrtYmd: schViewForm.schStrtYmd,
      schEndYmd: schViewForm.schEndYmd,
      pg: "1",
      pgSz: "10",
    };

    setSchForm(form);
  };

  return {
    stockListData,
    isFetching,
    schViewForm,
    handleChangeDatePicker,
    handleClickDateButton,
    handleChangeInput,
    handleChangePage,
    handleResetInput,
    handleSearch,
    handleCheckBox,
  };
};
