import { createTheme, PaletteOptions, Palette } from "@mui/material";
import { createBreakpoints } from "@mui/system";
import { string } from "yup";

declare module "@mui/material/styles" {
  export interface BreakpointOverrides {
    xxs: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }

  export interface PaletteColor {
    lighter?: string;
    light_01?: string;
    light_02?: string;
    light_03?: string;
    a80: string;
    a60: string;
    a40: string;
    a20: string;
    a05: string;
    dark: string;
    dark_01?: string;
    dark_02?: string;
    dark_03?: string;
  }

  export interface SimplePaletteColorOptions {
    lighter?: string;
    light_01?: string;
    light_02?: string;
    light_03?: string;
    dark_01?: string;
    dark_02?: string;
    dark_03?: string;
    a80?: string;
    a60?: string;
    a40?: string;
    a20?: string;
    a05?: string;
  }

  export interface Palette {
    default: string;
    disabled: string;
    text_sub1: string;
    text_sub2: string;
    text_sub3: string;
    text_sub4: string;
    text_sub5: string;
    text_sub6: string;
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    line5: string;
    bg_main: string;
    bg_02: string;
    bg_03: string;
    bg_04: string;
    bg_05: string;
    bg_06: string;
    bg0: string;
    bg20: string;
    bg52: string;
    bg66: string;
    bg70: string;
    bg84: string;
    bg90: string;
    bg96: string;
    black: string;
    white: string;
    transparent: string;
    placeholder: string;
    selected: string;
    selectHover: string;
  }

  export interface PaletteOptions {
    default: string;
    disabled: string;
    text_sub1: string;
    text_sub2: string;
    text_sub3: string;
    text_sub4: string;
    text_sub5: string;
    text_sub6: string;
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    line5: string;
    bg_main: string;
    bg_02: string;
    bg_03: string;
    bg_04: string;
    bg_05: string;
    bg_06: string;
    bg0: string;
    bg20: string;
    bg52: string;
    bg66: string;
    bg70: string;
    bg84: string;
    bg90: string;
    bg96: string;
    black: string;
    white: string;
    transparent: string;
    placeholder: string;
    selected: string;
    selectHover: string;
  }

  export interface Easing {
    inout1: string;
    inout2: string;
    inout3: string;
    inout4: string;
    inout5: string;
  }
}

const customBreakpointValues = {
  values: {
    xxs: 320,
    xs: 750, // mobile
    sm: 1024, // tablet
    md: 1280,
    lg: 1444,
    xl: 1720,
    xxl: 1920,
  },
};

const breakpoints = createBreakpoints({ ...customBreakpointValues });

const theme = createTheme({
  spacing: 5,
  breakpoints: {
    ...customBreakpointValues,
  },
  palette: {
    primary: {
      main: "#2A2A2A",
      a80: "rgba(42,42,42, 0.8)",
      a60: "rgba(42,42,42, 0.6)",
      a40: "rgba(42,42,42, 0.4)",
      a20: "rgba(42,42,42, 0.2)",
      a05: "rgba(42,42,42, 0.05)",
    },
    secondary: {
      main: "#FFF",
      a80: "rgba(255,255, 255, 0.8)",
      a60: "rgba(255,255, 255, 0.6)",
      a40: "rgba(255,255, 255, 0.4)",
      a20: "rgba(255,255, 255, 0.2)",
      a05: "rgba(255,255, 255, 0.05)",
    },
    error: {
      main: "#F00",
      a80: "rgba(255,0, 0, 0.8)",
      a60: "rgba(255,0, 0, 0.6)",
      a40: "rgba(255,0, 0, 0.4)",
      a20: "rgba(255,0, 0, 0.2)",
      a05: "rgba(255,0, 0, 0.05)",
    },
    info: {
      main: "#000",
      a80: "rgba(0, 0, 0, 0.8)",
      a60: "rgba(0, 0, 0, 0.6)",
      a40: "rgba(0, 0, 0, 0.4)",
      a20: "rgba(0, 0, 0, 0.2)",
      a05: "rgba(0, 0, 0, 0.05)",
    },
    success: {
      main: "#00B780",
    },
    default: "#222222",
    disabled: "#CCCECA",
    text_sub1: "#666666",
    text_sub2: "#999999",
    text_sub3: "#FFFFFF",
    text_sub4: "#C8CACE",
    text_sub5: "#8A8C8F",
    text_sub6: "#a0a0a0",
    line1: "#CCCECA",
    line2: "#a0a0a0",
    line3: "#C8CACE",
    line4: "#D3D5DA",
    line5: "#CECECE",
    bg_main: "#F4F5F3",
    bg_02: "#ECEEEB",
    bg_03: "#848484",
    bg_04: "#a0a0a0",
    bg_05: "#dedede",
    bg_06: "#f5f5f5",
    bg0: "#000",
    bg20: "#333",
    bg52: "#848484",
    bg66: "#a8a8a8",
    bg70: "#b3b3b3",
    bg84: "#d6d6d6",
    bg90: "#e6e6e6",
    bg96: "#f5f5f5",
    black: "#000000",
    white: "#FFFFFF",
    transparent: "transparent",
    placeholder: "#CCCECA",
    selected: "#FFE5E5",
    selectHover: "rgba(255, 229, 229, 0.5)",
  },
  typography: {
    htmlFontSize: 16,
    fontFamily:
      "'Noto Sans KR', 'Malgun gothic', '맑은고딕', 'Dotum', '돋움', 'arial'",
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiMenu: {
      defaultProps: {
        disablePortal: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: (themeParams) =>
        `
        * {
        padding: 0;
        margin: 0;
        box-sizing: border-box !important;
        text-transform: none !important;
        
        :focus-visible {
          outline: none !important;
        }
      }
      
      @font-face {
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: 100;
        src: url("/fonts/NotoSansKR-Thin.woff2") format("woff2"),
          url("/fonts/NotoSansKR-Thin.woff") format("woff"),
          url("/fonts/NotoSansKR-Thin.otf") format("opentype");
      }
      
      @font-face {
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: 300;
        src: url("/fonts/NotoSansKR-Light.woff2") format("woff2"),
          url("/fonts/NotoSansKR-Light.woff") format("woff"),
          url("/fonts/NotoSansKR-Light.otf") format("opentype");
      }
      
      @font-face {
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: 400;
        src: url("/fonts/NotoSansKR-Regular.woff2") format("woff2"),
          url("/fonts/NotoSansKR-Regular.woff") format("woff"),
          url("/fonts/NotoSansKR-Regular.otf") format("opentype");
      }
      
      @font-face {
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: 500;
        src: url("/fonts/NotoSansKR-Medium.woff2") format(" woff2"),
          url("/fonts/NotoSansKR-Medium.woff") format("woff"),
          url("/fonts/NotoSansKR-Medium.otf") format("opentype");
      }
      
      @font-face {
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: 700;
        src: url("/fonts/NotoSansKR-Bold.woff2") format("woff2"),
          url("/fonts/NotoSansKR-Bold.woff") format("woff"),
          url("/fonts/NotoSansKR-Bold.otf") format("opentype");
      }
      
      html,
      body {
        // min-width: 1920px;
        min-height: 100%;
        width: 100%;
        height: 100%;
        font-family: "Noto Sans KR", "Malgun gothic", "맑은고딕", Dotum,
          "돋움", arial, sans-serif;
        font-size: 16px;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        counter-reset: section;
        word-spacing: 1px;
        line-height: 1.5;
        letter-spacing: -0.4px;
        color:#222222;

        
      }
      
      body {
        -webkit-touch-callout: none;
      }
      
      html,
      body,
      div,
      span,
      applet,
      object,
      iframe,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      blockquote,
      pre,
      a,
      abbr,
      acronym,
      address,
      big,
      cite,
      code,
      del,
      dfn,
      em,
      img,
      ins,
      kbd,
      q,
      s,
      samp,
      small,
      strike,
      strong,
      sub,
      sup,
      tt,
      var,
      b,
      u,
      i,
      center,
      dl,
      dt,
      dd,
      ol,
      ul,
      li,
      fieldset,
      form,
      label,
      legend,
      table,
      caption,
      tbody,
      tfoot,
      thead,
      tr,
      th,
      td,
      article,
      aside,
      canvas,
      details,
      embed,
      figure,
      figcaption,
      footer,
      header,
      hgroup,
      menu,
      nav,
      output,
      ruby,
      section,
      summary,
      time,
      mark,
      audio,
      video {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
      
      /* HTML5 display-role reset for older browsers */
      article,
      aside,
      details,
      figcaption,
      figure,
      footer,
      header,
      hgroup,
      menu,
      nav,
      section {
        display: block;
      }
      
      input,
      button,
      select,
      optgroup,
      textarea {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
      }
      
      em {
        font-style: normal;
      }
      
      ol,
      ul {
        li {
          list-style: none;
        }
      }
      
      blockquote,
      q {
        quotes: none;
      }
      
      a {
        color: inherit;
      }
      
      blockquote:before,
      blockquote:after,
      q:before,
      q:after {
        content: "";
        content: none;
      }
      
      table {
        border-collapse: collapse;
        border-spacing: 0;
      }
      
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: background-color 5000s;
        WebkitTextFillColor: auto !important;
      }
      
      
      button {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        cursor: pointer;
        
        &:disabled {
          cursor:initial;
        }
      }
      
      #root {
        width: 100%;
        height: 100%;
      }
      
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input::-ms-reveal, input::-ms-clear {
        display: none !important;
      }
      
      .flex {
        display: flex;
        align-items: center;
      }
      
      .col {
        display: flex;
        flex-direction: column;
      }
      
      .row {
        display: flex;
        flex-direction: row;
      }
      
      .justify-content-between {
        justify-content: space-between;
      }
      
      .justify-content-around {
        justify-content: space-around;
      }
      
      .justify-content-center {
        justify-content: center;
      }
      
      .align-items-center {
        align-items: center;
      }

      
      
      .align-items-start {
        align-items: flex-start;
      }
      
      .align-items-end {
        align-items: flex-end;
      }
      
      .align-self-end {
        align-self: flex-end;
      }

      .align-self-start {
        align-self: flex-start;
      }

      .align-self-center {
        align-self: center;
      }

      .no-gap {
        gap: 0;
      }
      
      .gap-10 {
        gap: 0.625rem;
      }
      
      .gap-20 {
        gap: 1.25rem;
      }
      
      .gap-30 {
        gap: 1.875rem;
      }
      
      .gap-40 {
        gap: 2.5rem;
      }
      
      .gap-50 {
        gap: 3.125rem;
      }
      
      .flex-1 {
        flex: 1;
      }
      
      .ml-10 {
        margin-left: 0.625rem;
      }
      
      .mr-10 {
        margin-right: 0.625rem;
      }
      
      .mt-10 {
        margin-top: 0.625rem;
      }
      
      .mb-10 {
        margin-bottom: 0.625rem;
      }
      
      .pl-10 {
        padding-left: 0.625rem;
      }
      
      .pr-10 {
        padding-right: 0.625rem;
      }
      
      .pb-10 {
        padding-bottom: 0.625rem;
      }
      
      .pt-10 {
        padding-top: 0.625rem;
      }
      
      .w-100 {
        width: 100%;
      }
      `,
    },
  },
  transitions: {
    easing: {
      inout1: "cubic-bezier(0.26, 0.06, 0, 1)",
      inout2: "cubic-bezier(0.18, 0.06, 0.23, 1)",
      inout3: "cubic-bezier(0.43, 0.05, 0.17, 1)",
      inout4: "cubic-bezier(0.47, 0.16, 0.24, 1)",
      inout5: "cubic-bezier(.44,.14,.18,1)",
    },
  },
});

export default theme;
