import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  Radio,
  css,
} from "@mui/material";
import { useMenuManager } from "@/pages/system/menu/_hook/use-menu";
import { MenuDetailFormType, MenuItemType } from "@/types/system/menu";
import AlertDialog from "@/components/ui/alert-dialog";
import { useEffect, useState } from "react";
import theme from "@/assets/ts/theme";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Controller, useForm } from "react-hook-form";
import { XTFormHelperText, XTInput } from "@/components/mui/xt-input";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState } from "recoil";
import { alertDialogState } from "@/state";

export default function Menu() {
  const {
    menuTypes,
    depthList,
    update,
    updateSord,
    handleSord,
    resetQuery,
    selectMenu,
    setSelectMenu,
  } = useMenuManager();

  const schema = yup
    .object()
    .shape({
      nm: yup.string().trim().required("메뉴명을 입력해주세요."),
    })
    .required();

  const {
    register,
    trigger,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, submitCount },
  } = useForm<MenuDetailFormType>({
    defaultValues: {
      cd: "",
      nm: "",
      useYn: "N",
    },
    shouldFocusError: false,
    resolver: yupResolver(schema),
  });

  const [alertDialog, setAlertDialog] = useRecoilState(alertDialogState);
  const handleClose = async () => {
    setAlertDialog({
      ...alertDialog,
      open: false,
    });
  };

  const handleUpdate = async () => {
    try {
      let data = getValues();
      const res = await update.mutateAsync(data);

      if (res) {
        // resetQuery();
        setAlertDialog({
          title: "",
          text: "수정되었습니다.",
          open: true,
          type: "complete",
          handleOK: () => {

            resetQuery();
            handleClose();
          },
          handleClose: null
        });
      }
    } catch (err) {
      handleClose();
    }
  };

  const onSubmit = async (data: MenuDetailFormType) => {
    setAlertDialog({
      open: true,
      title: "알림",
      text: "수정하시겠습니까?",
      type: "confirm",
      handleOK: () => handleUpdate(),
      handleClose: () => handleClose(),
    });
  };

  const checkSelected = (item: MenuItemType) => {
    if (selectMenu?.cd.includes(item.cd)) {
      return true;
    }
    return false;
  };
  const handleClickMenu = (item: MenuItemType, type: string) => {

    setSelectMenu(item);
  };

  const handleSaveSord = async (key: string) => {
    let list = (depthList && depthList[selectMenu!.tp]) || [];

    let form: string[] = list!.map((item: MenuItemType) => {
      return item.cd;
    });


    try {
      const res = await updateSord.mutateAsync({ cdArray: form });

      if (res) {
        setAlertDialog({
          title: "",
          text: "저장되었습니다.",
          open: true,
          type: "complete",
          handleOK: () => {

            if (key == "2") {
              setSelectMenu({
                ...selectMenu, // 이전 상태를 유지하면서
                menuList: list, // menuList 속성을 새 값으로 설정
              });
            }

            resetQuery();
            handleClose();
          },
          handleClose: null
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {


    if (selectMenu)
      reset({
        cd: selectMenu.cd,
        nm: selectMenu.nm,
        useYn: selectMenu.useYn,
      });
  }, [selectMenu]);

  return (
    <main id="sample" css={globalCSS.listContainer}>
      <div className="title-wrap">
        <div className="breadcrumbs">
          <div className="b-main">
            <h2>관리자 메뉴관리</h2>
          </div>
          <div className="b-sub">
            <span>홈</span>
            <span>시스템관리</span>
            <span>관리자 메뉴관리 상세</span>
          </div>
        </div>
      </div>
      <div className="cont-wrap" css={style.container}>
        <div className="cont-inner">
          {/* <h3 className="sub-title">파기내용</h3> */}
          <Grid
            container
            spacing={3}
            justifyContent="flex-end"
            className="code-wrap"
          >
            {menuTypes &&
              Object.keys(menuTypes).map((key) => (
                <Grid item xs={6} className="code-box" key={key}>
                  <Card>
                    <CardActions>{key} Depth</CardActions>
                    <Divider />
                    <CardContent className="scroller">
                      <List component="nav" aria-label="main mailbox folders">
                        {depthList![key] &&
                          depthList![key].map((depth) => (

                            <ListItem
                              key={depth.cd}
                              selected={checkSelected(depth)}
                              onClick={(e) => {
                                handleClickMenu(depth, key);
                              }}
                            >
                              {depth.nm}
                            </ListItem>
                          ))}
                      </List>

                    </CardContent>
                    {selectMenu && selectMenu.tp === Number(key) && (
                      <>
                        <Divider />
                        <CardActions>
                          <Grid container>
                            <Grid item xs={12}>
                              {/* {selected && selected.cd.length === 2 && ( */}
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                sx={{
                                  gap: "10px",
                                }}
                              >
                                <IconButton
                                  aria-label="close"
                                  onClick={(e) => {
                                    handleSord("top");
                                  }}
                                >
                                  <KeyboardDoubleArrowUpIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="close"
                                  onClick={(e) => {
                                    handleSord("up");
                                  }}
                                >
                                  <KeyboardArrowUpIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="close"
                                  onClick={(e) => {
                                    handleSord("down");
                                  }}
                                >
                                  <KeyboardArrowDownIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="close"
                                  onClick={(e) => {
                                    handleSord("bottom");
                                  }}
                                >
                                  <KeyboardDoubleArrowDownIcon />
                                </IconButton>
                              </Box>
                              {/* )} */}
                            </Grid>
                          </Grid>
                        </CardActions>
                      </>
                    )}
                  </Card>
                  {selectMenu && selectMenu.tp === Number(key) && (
                    <Box mt={2} display="flex" justifyContent="flex-end">
                      <XTButton
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onClick={() => {
                          handleSaveSord(key);
                        }}
                      >
                        정렬 저장
                      </XTButton>
                    </Box>
                  )}
                </Grid>
              ))}
          </Grid>
        </div>
        {selectMenu && (
          <div className="cont-inner">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h3 className="sub-title">메뉴정보</h3>
              <table css={globalCSS.formTable}>
                <colgroup>
                  <col style={{ width: "20%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <InputLabel required>메뉴명</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="nm"
                        control={control}
                        render={({ field }) => (
                          <div className="input-length">
                            <XTInput
                              {...field}
                              aria-describedby="error-nm"
                              aria-label="메뉴명을 입력해주세요."
                              aria-invalid="true"

                            />
                          </div>
                        )}
                      />
                      {errors.nm?.message && (
                        <XTFormHelperText id="error-nm" className="error">
                          {errors.nm?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel required>사용여부</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Controller
                        name="useYn"
                        control={control}
                        render={({ field }) => (
                          <XTRadioGroup row defaultValue="Y" {...field}>
                            <XTRadio
                              value={"Y"}
                              control={<Radio />}
                              label={"Y"}
                            />
                            <XTRadio
                              value={"N"}
                              control={<Radio />}
                              label={"N"}
                            />
                          </XTRadioGroup>
                        )}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div css={globalCSS.bottomWrap}>
                <Grid container justifyContent={"flex-end"}>
                  <XTButton
                    variant="outlined"
                    color="primary"
                    size="medium"
                    type="submit"
                  >
                    수정
                  </XTButton>
                </Grid>
                <AlertDialog
                  open={alertDialog.open}
                  text={alertDialog.text}
                  type={alertDialog.type}
                  handleOK={alertDialog.handleOK}
                  handleClose={alertDialog.handleClose}
                  preventCloseEvent={true}
                />
              </div>
            </form>
          </div>
        )}
      </div>
    </main>
  );
}

const style = {
  container: css`
    .scroller {
      padding: 0;
      overflow-y: auto;
      min-height: ${theme.typography.pxToRem(300)};
      max-height: ${theme.typography.pxToRem(300)};
      .MuiList-root {
        padding: 0;
        .MuiListItem-root {
          cursor: pointer;
        }
      }
    }
    form {
      width: 50%;
    }
  `,
};
