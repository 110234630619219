import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTFormHelperText, XTInput } from "@/components/mui/xt-input";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Box, Checkbox, Grid, InputLabel, Radio } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { PermissionRegistParamsType } from "@/types/system/permission";
import { XTTreeItem, XTTreeView } from "@/components/mui/xt-tree";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useMenuTreeManager } from "@/pages/system/menu/_hook/use-menu";
import { XTCheckBox } from "@/components/mui/xt-checkbox";
import { useRecoilState } from "recoil";
import { alertDialogState } from "@/state";
import { usePermissionDetail } from "./_hook/use-permission";
import { formatYmdHis, formatYmdHisDot } from "@/assets/ts/common";

export default function PermissionDetail() {
  const { menuList } = useMenuTreeManager(null);
  const { detailData, seq, regist, update, deleted } = usePermissionDetail();
  const navigate = useNavigate();
  const [list, setList] = useState<
    PermissionRegistParamsType["authDetailList"]
  >([]);
  const [alertDialog, setAlertDialog] = useRecoilState(alertDialogState);

  const schema = yup.object().shape({
    ttl: yup.string().required("권한을 입력해주세요."),
    authDetailList: yup.array().min(1, "권한 메뉴를 선택해주세요."),
  });

  const {
    trigger,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    clearErrors,
    formState: { errors, submitCount },
  } = useForm<PermissionRegistParamsType>({
    shouldFocusError: false,
    defaultValues: {
      ttl: "",
      cont: "",
      useYn: "Y",
      authDetailList: [],
    },
    resolver: yupResolver(schema),
  });

  const permissionRegist = async () => {
    try {
      const result = await regist.mutateAsync(getValues());
      setAlertDialog({
        title: "",
        text: "등록되었습니다.",
        open: true,
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          navigate(-1);
        },
        handleClose: null,
      });
      // Handle success
    } catch (error) {
      setAlertDialog({
        title: "",
        text: "데이터가 중복되었습니다.",
        open: true,
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
        handleClose: null,
      });
      console.error("Mutation error:", error);
      // Handle error
    }
  };

  const permissionUpdate = async () => {
    try {
      const result = await update.mutateAsync({
        ...getValues(),
        seq: seq!,
      });
      setAlertDialog({
        title: "",
        text: "수정되었습니다.",
        open: true,
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          navigate(-1);
        },
        handleClose: null,
      });
      // Handle success
    } catch (error) {
      console.error("Mutation error:", error);
      // Handle error
    }
  };

  const permissionDelete = async (seq: string) => {
    try {
      await deleted.mutateAsync(seq!);
      setAlertDialog({
        title: "",
        text: "삭제되었습니다.",
        open: true,
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          navigate(-1);
        },
        handleClose: null,
      });
      // Handle success
    } catch (error) {
      console.error("Mutation error:", error);
      // Handle error
    }
  };

  const onSubmit = async () => {
    setAlertDialog({
      title: "",
      text: !seq ? "등록하시겠습니까?" : "수정하시겠습니까?",
      open: true,
      type: "confirm",
      handleOK: () => {
        if (!seq) {
          permissionRegist();
        } else {
          permissionUpdate();
        }
      },
      handleClose: () => {
        setAlertDialog({
          ...alertDialog,
          open: false,
        });
      },
    });
  };

  const onError = () => {};

  useEffect(() => {
    if (detailData) {
      reset({
        ttl: detailData?.auth?.ttl,
        cont: detailData?.auth?.cont,
        useYn: detailData?.auth?.useYn,
        authDetailList: detailData?.auth?.authDetailList,
      });
    }
  }, [detailData]);

  useEffect(() => {
    if (menuList) {
      let list: any = [];
      menuList.menuList.map(
        (menu) => (
          list.push({
            smCd: menu.cd,
          }),
          menu.menuList.map((menu2) =>
            list.push({
              smCd: menu2.cd,
            })
          )
        )
      );
      setList(list);
      if (!seq) {
        reset({
          authDetailList: list,
        });
      }
    }
  }, [menuList]);

  const handleCheckList = (cd: string) => {
    if (watch("authDetailList").some((item) => item.smCd === cd)) {
      // 제거
      const filteredList = watch("authDetailList").filter(
        (item) => item.smCd !== cd
      );
      if (cd.length == 2) {
        const subFilteredList = filteredList.filter(
          (item) => !item.smCd.startsWith(cd)
        );
        setValue("authDetailList", subFilteredList);
      } else {
        const subFilteredList = filteredList.filter(
          (item) => item.smCd != cd.substring(0, 2)
        );
        setValue("authDetailList", subFilteredList);
      }
    } else {
      // 추가
      if (cd.length == 2) {
        const filteredItems = list.filter((item) => item.smCd.startsWith(cd));

        filteredItems.forEach((item) => {
          setValue("authDetailList", [
            ...watch("authDetailList"),
            { smCd: item.smCd },
          ]);
        });
      } else {
        const num1 = list.filter(
          (item) => item.smCd.slice(0, 2) === cd.substring(0, 2)
        ).length;
        const num2 = watch("authDetailList").filter(
          (item) => item.smCd.slice(0, 2) === cd.substring(0, 2)
        ).length;

        if (num1 == num2 + 2) {
          const newList = [...watch("authDetailList"), { smCd: cd }];
          const newList2 = [...newList, { smCd: cd.substring(0, 2) }];
          setValue("authDetailList", newList2);
        } else {
          const newList = [...watch("authDetailList"), { smCd: cd }];
          setValue("authDetailList", newList);
        }
      }
    }

    trigger("authDetailList");
  };

  return (
    <>
      <main id="sample" css={globalCSS.listContainer}>
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>관리자 권한관리</h2>
            </div>
            <div className="b-sub">
              <span>시스템 관리</span>
              <span>관리자 권한관리 상세</span>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="cont-wrap">
            <div className="cont-inner">
              <h3 className="sub-title">
                권한정보(<span className="color-point">*</span> 필수항목)
              </h3>
              <table css={globalCSS.formTable}>
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "40%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <InputLabel required>권한</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="ttl"
                        control={control}
                        render={({ field }) => (
                          <div className="input-length">
                            <XTInput
                              {...field}
                              placeholder="권한을 입력해 주세요"
                              sx={{
                                minWidth: "50%",
                              }}
                            />
                          </div>
                        )}
                      />
                      {errors.ttl?.type === "required" && (
                        <XTFormHelperText className="error">
                          {errors.ttl?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <InputLabel>권한설명</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="cont"
                        control={control}
                        render={({ field }) => (
                          <div className="input-length">
                            <XTInput
                              {...field}
                              placeholder="권한설명을 입력해 주세요"
                              sx={{
                                minWidth: "50%",
                              }}
                            />
                          </div>
                        )}
                      />
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <InputLabel required>사용여부</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="useYn"
                        control={control}
                        render={({ field }) => (
                          <XTRadioGroup row {...field}>
                            <XTRadio
                              value="Y"
                              control={<Radio />}
                              label={"Y"}
                            />
                            <XTRadio
                              value="N"
                              control={<Radio />}
                              label={"N"}
                            />
                          </XTRadioGroup>
                        )}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel required>메뉴설정</InputLabel>
                    </th>
                    <td colSpan={3}>
                      {menuList && (
                        <XTTreeView
                          aria-label="icon expansion"
                          defaultCollapseIcon={<ArrowDropDownIcon />}
                          defaultExpandIcon={<ArrowRightIcon />}
                        >
                          {menuList.menuList.length > 0 &&
                            menuList.menuList.map((d1, i) => (
                              <XTTreeItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                key={i}
                                nodeId={d1.cd}
                                label={
                                  <XTCheckBox
                                    control={
                                      <Checkbox
                                        checked={watch("authDetailList").some(
                                          (item) => item.smCd === d1.cd
                                        )}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        onChange={(event) => {
                                          handleCheckList(d1.cd);
                                          event.stopPropagation();
                                        }}
                                      />
                                    }
                                    label={d1.nm}
                                  />
                                }
                              >
                                {d1.menuList &&
                                  d1.menuList.length > 0 &&
                                  d1.menuList.map((d2, j) => (
                                    <XTTreeItem
                                      key={j}
                                      nodeId={d2.cd}
                                      label={
                                        <XTCheckBox
                                          control={
                                            <Checkbox
                                              checked={watch(
                                                "authDetailList"
                                              ).some(
                                                (item) => item.smCd === d2.cd
                                              )}
                                              onChange={() => {
                                                handleCheckList(d2.cd);
                                              }}
                                            />
                                          }
                                          label={d2.nm}
                                        />
                                      }
                                    />
                                  ))}
                              </XTTreeItem>
                            ))}
                        </XTTreeView>
                      )}
                      {errors.authDetailList && (
                        <XTFormHelperText className="error">
                          {errors.authDetailList?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>
                  {seq && (
                    <>
                      <tr>
                        <th scope="row">
                          <InputLabel>등록자</InputLabel>
                        </th>
                        <td>
                          {detailData?.auth?.rgstrManagerAccount?.nm
                            ? `${detailData?.auth?.rgstrManagerAccount?.nm}(${detailData?.auth?.rgstrManagerAccount?.id})`
                            : "-"}
                        </td>
                        <th scope="row">
                          <InputLabel>등록일시</InputLabel>
                        </th>
                        <td>
                          {formatYmdHisDot(
                            detailData?.auth?.rgstYmd,
                            detailData?.auth?.rgstHis
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <InputLabel>수정자</InputLabel>
                        </th>
                        <td>
                          {detailData?.auth?.modrManagerAccount?.nm
                            ? `${detailData?.auth?.modrManagerAccount?.nm}(${detailData?.auth?.modrManagerAccount?.id})`
                            : "-"}
                        </td>
                        <th scope="row">
                          <InputLabel>수정일시</InputLabel>
                        </th>
                        <td>
                          {formatYmdHisDot(
                            detailData?.auth?.modYmd,
                            detailData?.auth?.modHis
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
              <Box css={globalCSS.bottomWrap} marginTop={4}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <XTButton
                      variant="outlined"
                      color="primary"
                      size="medium"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      {seq ? "목록" : "취소"}
                    </XTButton>
                  </Grid>
                  <Grid item>
                    {seq && (
                      <XTButton
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onClick={() => {
                          setAlertDialog({
                            title: "",
                            text: "삭제하시겠습니까?",
                            open: true,
                            type: "confirm",
                            handleOK: () => {
                              permissionDelete(seq);
                            },
                            handleClose: () => {
                              setAlertDialog({
                                ...alertDialog,
                                open: false,
                              });
                            },
                          });
                        }}
                      >
                        삭제
                      </XTButton>
                    )}
                    <XTButton
                      variant="outlined"
                      color="primary"
                      size="medium"
                      type="submit"
                    >
                      {seq ? "수정" : "등록"}
                    </XTButton>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
        </form>
      </main>
    </>
  );
}
