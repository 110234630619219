import {
  AdminListSchFormType,
  AdminRegistParamsType,
} from "@/types/system/manager";
import axios from "axios";

// 관리자 계정 목록 조회
export const loadAdminListAPI = async (form: AdminListSchFormType) => {
  return axios.get("/api/mg/manager-status/page-list", { params: { ...form } });
};

// 관리자 타입 조회
export const loadAdminTypesAPI = async () => {
  return axios.get("/api/mg/manager-status/get-type");
};

// 관리자 계정 상세 조회
export const loadAdminDetailAPI = async (seq: string) => {
  return axios.get(`/api/mg/manager-status/get`, {
    params: {
      seq: seq,
    },
  });
};

export const loadCommonAdminDetailAPI = async (seq: string) => {
  return axios.get(`/api/mg/manager-status/common-get`, {
    params: {
      seq: seq,
    },
  });
};

// 관리자 계정 등록
export const registAdminAPI = async (params: AdminRegistParamsType) => {
  return axios.post("/api/mg/manager/regist", { managerStatus: params });
};

//관리자 계정 수정
export const updateAdminAPI = async (
  seq: string,
  params: AdminRegistParamsType
) => {
  return axios.post("/api/mg/manager/update", {
    seq: seq,
    managerStatus: params,
  });
};

//관리자 계정 삭제
export const deleteAdminAPI = async (seq: string) => {
  return axios.post("/api/mg/manager/delete", { seq: seq });
};

//관리자 계정 중복 확인
export const adminDupCheckAPI = async (form: { schId: string }) => {
  return axios.get("/api/mg/manager-account/cnt", { params: { ...form } });
};

//관리자 계정 임시비밀번호 발송
export const adminTempPswAPI = async (params: { mgSeq: string }) => {
  return axios.post(
    "/api/mg/manager-status/update-change-temp-password",
    params
  );
};

//관리자 계정 잠금 해제
export const adminUnlockAPI = async (params: { mgSeq: string }) => {
  return axios.post("/api/mg/manager-status/update-change-lock", params);
};

//관리자 계정 휴면 해제
export const adminDormantAPI = async (params: { mgSeq: string }) => {
  return axios.post("/api/mg/manager-status/update-change-login", params);
};
