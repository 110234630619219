import globalCSS from "@/assets/ts/global-css";
import GuideLeft from "@/guide/components/sidebar";
import guideCSS from "@/assets/ts/guide-css";
import { Link } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { html } from "@codemirror/lang-html";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { useState } from "react";
import CodeMirror from "@uiw/react-codemirror";

export default function GuideIcon() {
  const [codemirror, setCodemirror] = useState<string | false>();
  const handleCodemirror =
    (panel: string) =>
      (event: React.SyntheticEvent, newcodemirror: boolean) => {
        setCodemirror(newcodemirror ? panel : false);
      };

  const handleCody = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (e) { }
  };
  return (
    <div className="guide-area" css={guideCSS.guideWrap}>
      <GuideLeft />
      <div css={guideCSS.guideWrapper}>
        <div className="title-box">
          <h2 className="guide-h2">Icon</h2>
        </div>
        <div className="guide-box">
          <strong>
            디자인 가이드에 안내되어 있는 아이콘 색상과 다른 색상을 적용하는
            방법
          </strong>
          <br />
          <br />
          <Link to="https://codepen.io/sosuke/pen/Pjoqqp" target="_blank">
            이곳
          </Link>
          을 클릭해 링크로 이동한 뒤, Target color에 색상 코드를 입력 후 버튼을
          클릭하면 filter CSS 속성이 출력됩니다. 그 부분을 CSS로 적용하시면
          됩니다.
        </div>
        <br />
        <div className="guide-box">
          <strong>아이콘을 클릭하면 클립보드에 복사가 가능합니다</strong>
        </div>
        <br />
        <div className="guide-content">
          <div>
            <h3 className="guide-h3">24x24 Icon</h3>
            <div style={{ display: "flex", gap: "20px" }}>
              <i
                css={globalCSS.icoDelete}
                data-code="<i css={globalCSS.icoDelete} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoCalender}
                data-code="<i css={globalCSS.icoCalender} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoChat}
                data-code="<i css={globalCSS.icoChat} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoCheck}
                data-code="<i css={globalCSS.icoCheck} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoClose}
                data-code="<i css={globalCSS.icoClose} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoConstruction}
                data-code="<i css={globalCSS.icoConstruction} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoUp}
                data-code="<i css={globalCSS.icoUp} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoDown}
                data-code="<i css={globalCSS.icoDown} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoError}
                data-code="<i css={globalCSS.icoError} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoFileFilled}
                data-code="<i css={globalCSS.icoFileFilled} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoFile}
                data-code="<i css={globalCSS.icoFile} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoHome}
                data-code="<i css={globalCSS.icoHome} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoLink}
                data-code="<i css={globalCSS.icoLink} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoLock}
                data-code="<i css={globalCSS.icoLock} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoUnlock}
                data-code="<i css={globalCSS.icoUnlock} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoPrev}
                data-code="<i css={globalCSS.icoPrev} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoNext}
                data-code="<i css={globalCSS.icoNext} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoNotice}
                data-code="<i css={globalCSS.icoNotice} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoOrder}
                data-code="<i css={globalCSS.icoOrder} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoPartner}
                data-code="<i css={globalCSS.icoPartner} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoProducts}
                data-code="<i css={globalCSS.icoProducts} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoReset}
                data-code="<i css={globalCSS.icoReset} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoReturn}
                data-code="<i css={globalCSS.icoReturn} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoSearch}
                data-code="<i css={globalCSS.icoSearch} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoSell}
                data-code="<i css={globalCSS.icoSell} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoChatFilled}
                data-code="<i css={globalCSS.icoChatFilled} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
            </div>
            <br />
            <Accordion
              sx={{
                margin: 0,
                p: 0,
                border: "none",
                outline: "none",
                boxShadow: "none",
                "&::before": {
                  position: "relative",
                },
              }}
              expanded={codemirror === "icon24x24"}
              onChange={handleCodemirror("icon24x24")}
              className="accordion-codemirror"
            >
              <AccordionSummary>
                <p className="guide-codeMore">코드보기</p>
              </AccordionSummary>
              <AccordionDetails>
                <CodeMirror
                  theme={vscodeDark}
                  extensions={[html()]}
                  value={`
import guideCSS from "@/assets/ts/guide-css";

/* Delete 아이콘 */
<i css={globalCSS.icoDelete} />

/* 달력 아이콘 */
<i css={globalCSS.icoCalender} />

/* 채팅 아이콘 */
<i css={globalCSS.icoChat} />

/* 체크 아이콘 */
<i css={globalCSS.icoCheck} />

/* 닫기 아이콘 */
<i css={globalCSS.icoClose} />

/* 도구 아이콘 */
<i css={globalCSS.icoConstruction} />

/* Up 아이콘 */
<i css={globalCSS.icoUp} />

/* Down 아이콘 */
<i css={globalCSS.icoDown} />

/* 에러 아이콘 */
<i css={globalCSS.icoError} />

/* 파일 아이콘(색상 채움) */
<i css={globalCSS.icoFileFilled} />

/* 파일 아이콘 */
<i css={globalCSS.icoFile} />

/* 홈 아이콘 */
<i css={globalCSS.icoHome} />

/* 링크 아이콘 */
<i css={globalCSS.icoLink} />

/* 잠금 아이콘 */
<i css={globalCSS.icoLock} />

/* 잠금 해제 아이콘 */
<i css={globalCSS.icoUnlock} />

/* 이전 아이콘 */
<i css={globalCSS.icoPrev} />

/* 다음 아이콘 */
<i css={globalCSS.icoNext} />

/* 공지사항 아이콘 */
<i css={globalCSS.icoNotice} />

/* 주문 아이콘 */
<i css={globalCSS.icoOrder} />

/* 파트너 아이콘 */
<i css={globalCSS.icoPartner} />

/* 상품 모아보기 아이콘 */
<i css={globalCSS.icoProducts} />

/* 리셋 아이콘 */
<i css={globalCSS.icoReset} />

/* 이전으로 아이콘 */
<i css={globalCSS.icoReturn} />

/* 검색 아이콘 */
<i css={globalCSS.icoSearch} />

/* 판매 아이콘 */
<i css={globalCSS.icoSell} />

/* 채팅 아이콘 (primary 색상) */
<i css={globalCSS.icoChatFilled} />
`}
                ></CodeMirror>
              </AccordionDetails>
            </Accordion>
          </div>
          <hr className="guide-hr" />
          <div>
            <h3 className="guide-h3">24x24 Icon - Gray</h3>
            <div style={{ display: "flex", gap: "20px" }}>
              <div style={{ display: "flex", gap: "20px" }}>
                <i
                  css={globalCSS.icoDelete}
                  className="gray"
                  data-code="<i css={globalCSS.icoDelete} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoCalender}
                  className="gray"
                  data-code="<i css={globalCSS.icoCalender} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoChat}
                  className="gray"
                  data-code="<i css={globalCSS.icoChat} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoCheck}
                  className="gray"
                  data-code="<i css={globalCSS.icoCheck} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoClose}
                  className="gray"
                  data-code="<i css={globalCSS.icoClose} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoConstruction}
                  className="gray"
                  data-code="<i css={globalCSS.icoConstruction} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoUp}
                  className="gray"
                  data-code="<i css={globalCSS.icoUp} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoDown}
                  className="gray"
                  data-code="<i css={globalCSS.icoDown} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoError}
                  className="gray"
                  data-code="<i css={globalCSS.icoError} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoFileFilled}
                  className="gray"
                  data-code="<i css={globalCSS.icoFileFilled} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoFile}
                  className="gray"
                  data-code="<i css={globalCSS.icoFile} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoHome}
                  className="gray"
                  data-code="<i css={globalCSS.icoHome} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoLink}
                  className="gray"
                  data-code="<i css={globalCSS.icoLink} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoLock}
                  className="gray"
                  data-code="<i css={globalCSS.icoLock} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoUnlock}
                  className="gray"
                  data-code="<i css={globalCSS.icoUnlock} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoPrev}
                  className="gray"
                  data-code="<i css={globalCSS.icoPrev} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoNext}
                  className="gray"
                  data-code="<i css={globalCSS.icoNext} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoNotice}
                  className="gray"
                  data-code="<i css={globalCSS.icoNotice} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoOrder}
                  className="gray"
                  data-code="<i css={globalCSS.icoOrder} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoPartner}
                  className="gray"
                  data-code="<i css={globalCSS.icoPartner} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoProducts}
                  className="gray"
                  data-code="<i css={globalCSS.icoProducts} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoReset}
                  className="gray"
                  data-code="<i css={globalCSS.icoReset} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoReturn}
                  className="gray"
                  data-code="<i css={globalCSS.icoReturn} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoSearch}
                  className="gray"
                  data-code="<i css={globalCSS.icoSearch} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
                <i
                  css={globalCSS.icoSell}
                  className="gray"
                  data-code="<i css={globalCSS.icoSell} className='gray' />"
                  onClick={(e) => handleCody(e.target.dataset.code)}
                />
              </div>
            </div>
            <br />
            <br />
            <Accordion
              sx={{
                margin: 0,
                p: 0,
                border: "none",
                outline: "none",
                boxShadow: "none",
                "&::before": {
                  position: "relative",
                },
              }}
              expanded={codemirror === "icon24x24Gray"}
              onChange={handleCodemirror("icon24x24Gray")}
              className="accordion-codemirror"
            >
              <AccordionSummary>
                <p className="guide-codeMore">코드보기</p>
              </AccordionSummary>
              <AccordionDetails>
                <CodeMirror
                  theme={vscodeDark}
                  extensions={[html()]}
                  value={`
import guideCSS from "@/assets/ts/guide-css";

/* Delete 아이콘 */
<i css={globalCSS.icoDelete} className="gray" />

/* 달력 아이콘 */
<i css={globalCSS.icoCalender} className="gray" />

/* 채팅 아이콘 */
<i css={globalCSS.icoChat} className="gray" />

/* 체크 아이콘 */
<i css={globalCSS.icoCheck} className="gray" />

/* 닫기 아이콘 */
<i css={globalCSS.icoClose} className="gray" />

/* 도구 아이콘 */
<i css={globalCSS.icoConstruction} className="gray" />

/* Up 아이콘 */
<i css={globalCSS.icoUp} className="gray" />

/* Down 아이콘 */
<i css={globalCSS.icoDown} className="gray" />

/* 에러 아이콘 */
<i css={globalCSS.icoError} className="gray" />

/* 파일 아이콘(색상 채움) */
<i css={globalCSS.icoFileFilled} className="gray" />

/* 파일 아이콘 */
<i css={globalCSS.icoFile} className="gray" />

/* 홈 아이콘 */
<i css={globalCSS.icoHome} className="gray" />

/* 링크 아이콘 */
<i css={globalCSS.icoLink} className="gray" />

/* 잠금 아이콘 */
<i css={globalCSS.icoLock} className="gray" />

/* 잠금 해제 아이콘 */
<i css={globalCSS.icoUnlock} className="gray" />

/* 이전 아이콘 */
<i css={globalCSS.icoPrev} className="gray" />

/* 다음 아이콘 */
<i css={globalCSS.icoNext} className="gray" />

/* 공지사항 아이콘 */
<i css={globalCSS.icoNotice} className="gray" />

/* 주문 아이콘 */
<i css={globalCSS.icoOrder} className="gray" />

/* 파트너 아이콘 */
<i css={globalCSS.icoPartner} className="gray" />

/* 상품 모아보기 아이콘 */
<i css={globalCSS.icoProducts} className="gray" />

/* 리셋 아이콘 */
<i css={globalCSS.icoReset} className="gray" />

/* 이전으로 아이콘 */
<i css={globalCSS.icoReturn} className="gray" />

/* 검색 아이콘 */
<i css={globalCSS.icoSearch} className="gray" />

/* 판매 아이콘 */
<i css={globalCSS.icoSell} className="gray" />
`}
                ></CodeMirror>
              </AccordionDetails>
            </Accordion>
          </div>
          <hr className="guide-hr" />
          <div>
            <h3 className="guide-h3">36x36 Icon</h3>
            <div style={{ display: "flex", gap: "20px" }}>
              <i
                css={globalCSS.icoFilter}
                data-code="<i css={globalCSS.icoFilter} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoJoin}
                data-code="<i css={globalCSS.icoJoin} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoLogin}
                data-code="<i css={globalCSS.icoLogin} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoLogout}
                data-code="<i css={globalCSS.icoLogout} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoMemberEdit}
                data-code="<i css={globalCSS.icoMemberEdit} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoMenu}
                data-code="<i css={globalCSS.icoMenu} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoMypage}
                data-code="<i css={globalCSS.icoMypage} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoProduct}
                data-code="<i css={globalCSS.icoProduct} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoWrite}
                data-code="<i css={globalCSS.icoWrite} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoBack}
                data-code="<i css={globalCSS.icoBack} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoSearch36}
                data-code="<i css={globalCSS.icoSearch36} />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
            </div>
            <br />
            <Accordion
              sx={{
                margin: 0,
                p: 0,
                border: "none",
                outline: "none",
                boxShadow: "none",
                "&::before": {
                  position: "relative",
                },
              }}
              expanded={codemirror === "icon36x36"}
              onChange={handleCodemirror("icon36x36")}
              className="accordion-codemirror"
            >
              <AccordionSummary>
                <p className="guide-codeMore">코드보기</p>
              </AccordionSummary>
              <AccordionDetails>
                <CodeMirror
                  theme={vscodeDark}
                  extensions={[html()]}
                  value={`
import guideCSS from "@/assets/ts/guide-css";

/* 필터 아이콘 */
<i css={globalCSS.icoFilter} />

/* 회원가입 아이콘 */
<i css={globalCSS.icoJoin} />

/* 로그인 아이콘 */
<i css={globalCSS.icoLogin} />

/* 로그아웃 아이콘 */
<i css={globalCSS.icoLogout} />

/* 정보 수정 아이콘 */
<i css={globalCSS.icoMemberEdit} />

/* 매뉴 아이콘 */
<i css={globalCSS.icoMenu} />

/* 마이페이지 아이콘 */
<i css={globalCSS.icoMypage} />

/* 상품 아이콘 */
<i css={globalCSS.icoProduct} />

/* 작성 아이콘 */
<i css={globalCSS.icoWrite} />

/* 뒤로 가기 아이콘 */
<i css={globalCSS.icoBack} />

/* 검색 아이콘 */
<i css={globalCSS.icoSearch36} />
`}
                ></CodeMirror>
              </AccordionDetails>
            </Accordion>
          </div>
          <hr className="guide-hr" />
          <div>
            <h3 className="guide-h3">36x36 Icon - Gray</h3>
            <div style={{ display: "flex", gap: "20px" }}>
              <i
                css={globalCSS.icoFilter}
                className="gray"
                data-code="<i css={globalCSS.icoFilter} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoJoin}
                className="gray"
                data-code="<i css={globalCSS.icoJoin} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoLogin}
                className="gray"
                data-code="<i css={globalCSS.icoLogin} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoLogout}
                className="gray"
                data-code="<i css={globalCSS.icoLogout} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoMemberEdit}
                className="gray"
                data-code="<i css={globalCSS.icoMemberEdit} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoMenu}
                className="gray"
                data-code="<i css={globalCSS.icoMenu} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoMypage}
                className="gray"
                data-code="<i css={globalCSS.icoMypage} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoProduct}
                className="gray"
                data-code="<i css={globalCSS.icoProduct} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoWrite}
                className="gray"
                data-code="<i css={globalCSS.icoWrite} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
            </div>
            <br />
            <Accordion
              sx={{
                margin: 0,
                p: 0,
                border: "none",
                outline: "none",
                boxShadow: "none",
                "&::before": {
                  position: "relative",
                },
              }}
              expanded={codemirror === "icon36x36Gray"}
              onChange={handleCodemirror("icon36x36Gray")}
              className="accordion-codemirror"
            >
              <AccordionSummary>
                <p className="guide-codeMore">코드보기</p>
              </AccordionSummary>
              <AccordionDetails>
                <CodeMirror
                  theme={vscodeDark}
                  extensions={[html()]}
                  value={`
import guideCSS from "@/assets/ts/guide-css";

/* 필터 아이콘 */
<i css={globalCSS.icoFilter} className="gray" />

/* 회원가입 아이콘 */
<i css={globalCSS.icoJoin} className="gray" />

/* 로그인 아이콘 */
<i css={globalCSS.icoLogin} className="gray" />

/* 로그아웃 아이콘 */
<i css={globalCSS.icoLogout} className="gray" />

/* 정보 수정 아이콘 */
<i css={globalCSS.icoMemberEdit} className="gray" />

/* 매뉴 아이콘 */
<i css={globalCSS.icoMenu} className="gray" />

/* 마이페이지 아이콘 */
<i css={globalCSS.icoMypage} className="gray" />

/* 상품 아이콘 */
<i css={globalCSS.icoProduct} className="gray" />

/* 작성 아이콘 */
<i css={globalCSS.icoWrite} className="gray" />
`}
                ></CodeMirror>
              </AccordionDetails>
            </Accordion>
          </div>
          <hr className="guide-hr" />
          <div>
            <h3 className="guide-h3">40x40 Icon</h3>
            <div style={{ display: "flex", gap: "20px" }}>
              <i
                css={globalCSS.icoMail}
                data-code="<i css={globalCSS.icoMail} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoTime}
                data-code="<i css={globalCSS.icoTime} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoComplete}
                data-code="<i css={globalCSS.icoComplete} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
            </div>
            <br />
            <Accordion
              sx={{
                margin: 0,
                p: 0,
                border: "none",
                outline: "none",
                boxShadow: "none",
                "&::before": {
                  position: "relative",
                },
              }}
              expanded={codemirror === "icon40x40"}
              onChange={handleCodemirror("icon40x40")}
              className="accordion-codemirror"
            >
              <AccordionSummary>
                <p className="guide-codeMore">코드보기</p>
              </AccordionSummary>
              <AccordionDetails>
                <CodeMirror
                  theme={vscodeDark}
                  extensions={[html()]}
                  value={`
import guideCSS from "@/assets/ts/guide-css";

// Mail icon
<i css={globalCSS.icoMail} />

// Time icon
<i css={globalCSS.icoTime} />

// Complete icon
<i css={globalCSS.icoComplete} />

`}
                ></CodeMirror>
              </AccordionDetails>
            </Accordion>
          </div>
          <hr className="guide-hr" />
          <div>
            <h3 className="guide-h3">40x40 Icon - Gray</h3>
            <div style={{ display: "flex", gap: "20px" }}>
              <i
                css={globalCSS.icoMail}
                className="gray"
                data-code="<i css={globalCSS.icoMail} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoTime}
                className="gray"
                data-code="<i css={globalCSS.icoTime} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoComplete}
                className="gray"
                data-code="<i css={globalCSS.icoComplete} className='gray'  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
            </div>
            <br />
            <Accordion
              sx={{
                margin: 0,
                p: 0,
                border: "none",
                outline: "none",
                boxShadow: "none",
                "&::before": {
                  position: "relative",
                },
              }}
              expanded={codemirror === "icon40x40Gray"}
              onChange={handleCodemirror("icon40x40Gray")}
              className="accordion-codemirror"
            >
              <AccordionSummary>
                <p className="guide-codeMore">코드보기</p>
              </AccordionSummary>
              <AccordionDetails>
                <CodeMirror
                  theme={vscodeDark}
                  extensions={[html()]}
                  value={`
import guideCSS from "@/assets/ts/guide-css";

// Mail icon
<i css={globalCSS.icoMail} className="gray" />

// Time icon
<i css={globalCSS.icoTime} className="gray" />

// Complete icon
<i css={globalCSS.icoComplete} className="gray" />
`}
                ></CodeMirror>
              </AccordionDetails>
            </Accordion>
          </div>
          <hr className="guide-hr" />
          <div>
            <h3 className="guide-h3">Mobile</h3>
            <div style={{ display: "flex", gap: "20px" }}>
              <i
                css={globalCSS.icoConstructionMo}
                data-code="<i css={globalCSS.icoConstructionMo}  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoCloseMo}
                data-code="<i css={globalCSS.icoCloseMo}  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoMypageMo}
                data-code="<i css={globalCSS.icoMypageMo}  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoOrderMo}
                data-code="<i css={globalCSS.icoOrderMo}  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoPartnerMo}
                data-code="<i css={globalCSS.icoPartnerMo}  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoProductsMo}
                data-code="<i css={globalCSS.icoProductsMo}  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoResetMo}
                data-code="<i css={globalCSS.icoResetMo}  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoReturnMo}
                data-code="<i css={globalCSS.icoReturnMo}  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
              <i
                css={globalCSS.icoSellMo}
                data-code="<i css={globalCSS.icoSellMo}  />"
                onClick={(e) => handleCody(e.target.dataset.code)}
              />
            </div>
            <br />
            <Accordion
              sx={{
                margin: 0,
                p: 0,
                border: "none",
                outline: "none",
                boxShadow: "none",
                "&::before": {
                  position: "relative",
                },
              }}
              expanded={codemirror === "iconMobile"}
              onChange={handleCodemirror("iconMobile")}
              className="accordion-codemirror"
            >
              <AccordionSummary>
                <p className="guide-codeMore">코드보기</p>
              </AccordionSummary>
              <AccordionDetails>
                <CodeMirror
                  theme={vscodeDark}
                  extensions={[html()]}
                  value={`
import guideCSS from "@/assets/ts/guide-css";

// 도구 아이콘
<i css={globalCSS.icoConstructionMo} />

// 닫기 아이콘
<i css={globalCSS.icoCloseMo} />

// 마이 페이지 아이콘
<i css={globalCSS.icoMypageMo} />

// 주문 아이콘
<i css={globalCSS.icoOrderMo} />

// 파트너 아이콘
<i css={globalCSS.icoPartnerMo} />

// 상품들 아이콘
<i css={globalCSS.icoProductsMo} />

// 리셋 아이콘
<i css={globalCSS.icoResetMo} />

// 리턴 아이콘
<i css={globalCSS.icoReturnMo} />

// 판매 아이콘
<i css={globalCSS.icoSellMo} />
`}
                ></CodeMirror>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}
