import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { AccordionProps } from "@mui/material/Accordion";
import { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { AccordionDetailsProps } from "@mui/material/AccordionDetails";
import { styled } from "@mui/system";

export const XTAccordion = styled(Accordion)<AccordionProps>(({ theme }) => ({
  margin: "0 !important",
  width: "100%",
  border: "none",
  borderRadius: 0,
  boxShadow: "none",

  "&:before": {
    display: "none",
  },
}));

export const XTAccordionSummary = styled(
  AccordionSummary
)<AccordionSummaryProps>(({ theme }) => ({
  padding: "15px 30px",
  minHeight: "initial !important",
  transition: "background 0.2s",

  "&.Mui-focusVisible": {
    background: "transparent",
  },

  "&:hover": {
    background: theme.palette.bg_main,
  },

  "&.Mui-expanded": {
    background: theme.palette.bg_main,
  },

  ".MuiAccordionSummary-content": {
    border: "none",
    margin: "0 !important",
    marginRight: "10px",
    minHeight: "initial !important",
    fontSize: "14px",
    fontWeight: 700,
    wordBreak: "break-word",
    lineHeight: 1,
    color: theme.palette.default,
  },
}));

export const XTAccordionDetail = styled(
  AccordionDetails
)<AccordionDetailsProps>(({ theme }) => ({
  padding: 0,
  margin: "20px 36px 0",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: 1,

  ".dot-btn": {
    position: "relative",
    paddingLeft: "15px",
    color: theme.palette.text_sub2,
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1,

    "&:before": {
      content: "''",
      display: "block",
      position: "absolute",
      left: "0",
      top: "calc(50% + 1px)",
      transform: "translateY(-50%)",
      width: "4px",
      height: "4px",
      borderRadius: "50%",
      background: theme.palette.text_sub2,
    },

    "&.active": {
      fontWeight: 700,
      color: theme.palette.primary.main,

      "&:before": {
        background: theme.palette.primary.main,
      },
    },

    "&:hover": {
      fontWeight: 700,
      color: theme.palette.primary.main,

      "&:before": {
        background: theme.palette.primary.main,
      },
    },
  },

  "&:last-of-type": {
    marginBottom: "20px",
  },
}));
