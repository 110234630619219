import globalCSS from "@/assets/ts/global-css";
import guideCSS from "@/assets/ts/guide-css";
import GuideLayout from "@/guide/components/layout";
import Sidebar from "@/components/sidebar";
import { css, Theme } from "@emotion/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Layout({ children }: any) {
  const { pathname } = useLocation();

  return (
    <div
      css={
        pathname.indexOf("guide") !== -1
          ? guideCSS.guideWrapper
          : globalCSS.layout
      }
    >
      {pathname.indexOf("guide") !== -1 && <GuideLayout />}

      {pathname.indexOf("guide") === -1 &&
        pathname !== "/login" &&
        pathname !== "/temp-password-login" &&
        pathname !== "/health_check" && <Sidebar />}

      <div className="layout-inner">
        <div id="inner-contents">{children}</div>
      </div>
    </div>
  );
}
