import axios from "axios";
import {
  AccessTokenData,
  CommonHeaderProperties,
  DecodedTokenType,
} from "@/types";
import lodash, { debounce } from "lodash";
import deepdash from "deepdash";
import {
  clearAllLocalStorage,
  clearAllSessionStorage,
  getLocalStorage,
  getSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from "@/assets/ts/common";
import { useRecoilState } from "recoil";
import { alertDialogState } from "@/state";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useLoginManager } from "@/pages/login/_hook/use-login";
import { AlternateEmail } from "@mui/icons-material";
import { access } from "fs";

const _ = deepdash(lodash);

let interceptors: any = null;

export default function InterceptorsContainer() {
  const { handleSetUserInfo } = useLoginManager();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [alertDialog, setAlertDialog] = useRecoilState(alertDialogState);

  const refreshTokenAPI = (): Promise<AccessTokenData> => {
    return new Promise((resolve) => {
      axios
        .post(`/api/auth/token`, {
          grantType: "refresh_token",
          refreshToken: getSessionStorage("X-REFRESH-TOKEN-ADMIN"),
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          clearAllSessionStorage();
          clearAllLocalStorage();
          setAlertDialog({
            ...alertDialog,
            type: "complete",
            open: true,
            text: "장시간 사이트 이용이 없어 세션이 종료되었습니다. <br /> 계속 이용하시려면 확인 버튼 클릭 후 로그인 해주세요.",
            handleOK: () => {
              setAlertDialog({
                ...alertDialog,
                open: false,
              });
              navigate("/login");
            },
          });

          resolve(error.response);
        });
    });
  };

  const refreshTokenAPIScheduler = debounce(refreshTokenAPI, 200, {
    leading: true,
    trailing: false,
  });

  //페이지 이동 시 토큰 값 유무에 따라 로그인 화면으로 이동
  useEffect(() => {
    if (
      !getSessionStorage("X-AUTH-TOKEN-ADMIN") ||
      !getSessionStorage("X-REFRESH-TOKEN-ADMIN")
    ) {
      navigate("/login");
    }
  }, [pathname]);

  //새로고침
  useEffect(() => {
    const token = getSessionStorage("X-AUTH-TOKEN-ADMIN");
    //새로고침 시 유저 정보 recoil 담음
    if (token && pathname !== "/login") {
      const decodedToken: DecodedTokenType = jwt_decode(token);

      let seq = decodedToken.seq?.toString();
      handleSetUserInfo(seq!);
    }
  }, []);

  if (interceptors) {
    axios.interceptors.response.eject(interceptors);
    return <></>;
  }

  interceptors = axios.interceptors.response.use(
    (config) => {
      return config;
    },
    async (error) => {
      const originRequest = error.config;

      if (error.config.url === "/api/mg/manager-token/update-logout") {
        return Promise.reject(error);
      }

      if (error.response.data.status === 406) {
        setAlertDialog({
          ...alertDialog,
          type: "complete",
          open: true,
          text: "메뉴 접근 권한이 없습니다. <br /> 관리자에게 문의해주세요.",
          handleOK: () => {
            setAlertDialog({
              ...alertDialog,
              open: false,
            });
            navigate("/login");
          },
        });
      }

      if (
        getSessionStorage("X-REFRESH-TOKEN-ADMIN") &&
        error.response.data.status === 401
      ) {
        const newToken: AccessTokenData = await refreshTokenAPIScheduler();

        if (!newToken.data.accessToken) {
          return Promise.reject(error);
        }

        setSessionStorage(
          "X-AUTH-TOKEN-ADMIN",
          `${newToken.data.tokenType} ${newToken.data.accessToken}`
        );

        setSessionStorage(
          "X-REFRESH-TOKEN-ADMIN",
          `${newToken.data.refreshToken}`
        );

        axios.defaults.headers = {
          Authorization: `${newToken.data.tokenType} ${newToken.data.accessToken}`,
        } as CommonHeaderProperties;

        error.config.headers = {
          Authorization: `${newToken.data.tokenType} ${newToken.data.accessToken}`,
        } as CommonHeaderProperties;

        originRequest.headers.Authorization = `${newToken.data.tokenType} ${newToken.data.accessToken}`;

        return axios(originRequest);
      } else if (
        !getSessionStorage("X-REFRESH-TOKEN-ADMIN") &&
        error.response.data.status === 401
      ) {
        window.location.href = "/login";
      }

      return Promise.reject(error);
    }
  );

  return <></>;
}
