import {
  ProductRegistParamsType,
  StockDeleteParamsType,
  StockListSchFormType,
  StockRegistParamsType,
} from "@/types/product";
import axios from "axios";

// 물품 리스트 조회
export const loadProductListAPI = async () => {
  return axios.get("/api/gd/goods/full-list");
};

export const loadProductTypesAPI = async () => {
  return axios.get("/api/gd/goods-stock/get-type");
};

// 물품 공통 리스트 조회(스타터키트, 알콜솜, 니들 6mm, 니들 4mm)
export const loadCommonProductListAPI = async () => {
  return axios.get("/api/gd/goods/common-full-list");
};

// 물품 추가
export const registProductAPI = async (form: ProductRegistParamsType) => {
  return axios.post("/api/gd/goods/regist", { ...form });
};

// 물품 삭제
export const deleteProductAPI = async (form: StockDeleteParamsType) => {
  return axios.post("/api/gd/goods/update-use", { ...form });
};

//재고 리스트 조회
export const loadStockListAPI = async (form: StockListSchFormType) => {
  return axios.get("/api/gd/goods-stock/page-list", {
    params: { ...form, schTpArr: form.schTpArr.join(",") },
  });
};

//재고 등록
export const registStockAPI = async (form: StockRegistParamsType) => {
  return axios.post("/api/gd/goods-stock/regist", { ...form });
};
