import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, IconButton, css } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Timer from "@/components/common/timer";
import { useLoginManager } from "@/pages/login/_hook/use-login";
import {
  XTDialog,
  XTDialogActions,
  XTDialogContent,
  XTDialogTitle,
} from "@/components/mui/xt-dialog";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import {
  XTFormControl,
  XTFormHelperText,
  XTInput,
} from "@/components/mui/xt-input";
import theme from "@/assets/ts/theme";
import AlertDialog from "@/components/ui/alert-dialog";
import { loadCommonMenuListAPI } from "@/api/system/menu";

export default function EmailDialog(props: {
  open: boolean;
  seq: string;
  cd: string;
  email: string;
  handleClose: () => void;
}) {
  const { open, seq, cd, email, handleClose } = props;
  const { modify, modifyCd, handleSetUserInfo } = useLoginManager();
  const navigate = useNavigate();
  const [resetTimer, setResetTimer] = useState<number | null>(null);
  const [alertDialog, setAlertDialog] = useState({
    open: false,
    title: "",
    text: "",
    type: "complete",
    handleOK: () => {},
  });

  const schema = yup
    .object()
    .shape({ cd: yup.string().trim().required("인증번호를 입력해주세요.") })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      mgSeq: "",
      cd: "",
    },
    shouldFocusError: false,
    resolver: yupResolver(schema),
  });

  const handleResend = () => {
    modify.mutate(seq, {
      onSuccess: (res) => {
        setValue("cd", res.data.data.cd);
        setResetTimer((prevResetTimer) => prevResetTimer! + 1);
      },
      onError: (error) => {
        console.log("error: ", error);
      },
    });
  };

  const handleOpen = () => {
    setAlertDialog({
      open: true,
      text: "인증번호 입력시간이 초과되었습니다. 다시 시도해주세요.",
      type: "complete",
      title: "알림",
      handleOK: () => {
        setAlertDialog({
          ...alertDialog,
          open: false,
        });
        handleClose();
      },
    });
  };

  const onSubmit = () => {
    modifyCd.mutate(
      {
        mgSeq: seq,
        cd: getValues("cd"),
      },
      {
        onSuccess: async (res) => {
          setAlertDialog({
            open: true,
            title: "Success",
            text: "사용자 인증이 완료되었습니다.",
            type: "complete",
            handleOK: async () => {
              const userResponse = await handleSetUserInfo(seq);

              setAlertDialog({
                ...alertDialog,
                open: false,
              });

              const result = await loadCommonMenuListAPI("Y");

              const firstMenuUri =
                result.data.data?.menuList[0]?.menuList?.[0]?.uri ||
                result.data.data?.menuList[0]?.uri;

              if (
                userResponse &&
                userResponse.data.data.managerStatus.tmpPwdYn === "Y"
              ) {
                navigate("/temp-password-login");
              } else {
                if (firstMenuUri) {
                  navigate(firstMenuUri);
                }
              }
            },
          });
        },
        onError: (error) => {
          setAlertDialog({
            open: true,
            title: "Error",
            text: "잘못된 인증번호입니다.",
            type: "complete",
            handleOK: () => {
              setAlertDialog({
                ...alertDialog,
                open: false,
              });
            },
          });
        },
      }
    );
  };

  useEffect(() => {
    if (process.env.REACT_APP_FETCH_URL == "https://devadm.noriter-app.com") {
      setValue("cd", cd);
    }
  }, [open]);

  return (
    <>
      <XTDialog disablePortal className="sm" open={open} onClose={handleClose}>
        <XTDialogTitle className="flex-title">
          <span>이메일 인증</span>
          <button type="button" className="close-btn" onClick={handleClose}>
            <span css={globalCSS.hidden}>닫기</span>
            <i css={globalCSS.icoClose} />
          </button>
        </XTDialogTitle>
        <XTDialogContent css={style.container}>
          <p className="noti">아래 이메일로 발송된 인증번호를 입력해주세요.</p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
            }}
          >
            <div className="cont">
              <XTFormControl>
                <Grid container spacing={1}>
                  <Grid item>
                    <XTInput
                      id="email"
                      inputProps={{
                        maxLength: 12,
                      }}
                      value={email}
                      disabled
                      type="text"
                    />
                  </Grid>
                  <Grid item>
                    <XTButton
                      className="rebtn"
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleResend}
                    >
                      재발송
                    </XTButton>
                  </Grid>
                </Grid>
              </XTFormControl>
              <Controller
                name="cd"
                control={control}
                render={({ field }) => (
                  <XTFormControl>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTInput
                          id="cd"
                          {...field}
                          placeholder="인증번호를 입력해주세요."
                          inputProps={{
                            maxLength: 12,
                          }}
                          type="text"
                          aria-describedby="error-cd"
                          aria-label="인증번호를 입력해주세요."
                          aria-invalid="true"
                          endAdornment={
                            <>
                              {getValues("cd") ? (
                                <IconButton onClick={() => setValue("cd", "")}>
                                  <span css={globalCSS.hidden}>
                                    내용 삭제하기
                                  </span>
                                  <i css={globalCSS.icoDelete} />
                                </IconButton>
                              ) : null}
                            </>
                          }
                        />
                      </Grid>
                      <Grid item>
                        <XTButton
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                        >
                          인증
                        </XTButton>
                      </Grid>
                    </Grid>
                    {errors.cd?.message && (
                      <XTFormHelperText id="error-cd" className="error">
                        인증번호를 입력해주세요.
                      </XTFormHelperText>
                    )}
                  </XTFormControl>
                )}
              ></Controller>
              <div className="timer">
                <Timer resetTimer={resetTimer} setOpen={handleOpen} />
              </div>
            </div>
          </form>
        </XTDialogContent>
        <XTDialogActions className="flex-end"></XTDialogActions>
      </XTDialog>
      <AlertDialog
        title={alertDialog.title}
        open={alertDialog.open}
        text={alertDialog.text}
        type={alertDialog.type}
        handleOK={alertDialog.handleOK}
        preventCloseEvent={true}
      />
    </>
  );
}

const style = {
  container: css`
    .noti {
      margin-bottom: ${theme.typography.pxToRem(40)};
    }
    form {
      width: 100%;
      display: flex;
      justify-content: center;

      .cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
      }
      .rebtn {
        padding-left: 8px;
        padding-right: 8px;
      }
      .timer {
        width: 100%;
        text-align: right;
      }
    }
  `,
};
