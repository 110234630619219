/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import moment from "moment";
import deepdash from "deepdash";
import lodash from "lodash";
import theme from "@/assets/ts/theme";
import { FileType, ImageType } from "@/types";
const _ = deepdash(lodash);

interface Test {
  test: string;
}

export const convertFileSize = (sizeInBytes: number) => {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;

  if (sizeInBytes >= megabyte) {
    return (sizeInBytes / megabyte).toFixed(2) + " MB";
  } else if (sizeInBytes >= kilobyte) {
    return (sizeInBytes / kilobyte).toFixed(2) + " KB";
  } else {
    return sizeInBytes + " bytes";
  }
};

export const getFileName = (contentDisposition) => {
  let fileName = "";
  if (contentDisposition) {
    const [fileNameMatch] = contentDisposition
      .split(";")
      .filter((str) => str.includes("fileName"));
    if (fileNameMatch) [, fileName] = fileNameMatch.split("=");
  }
  fileName = fileName.replaceAll('"', "");
  return decodeURIComponent(fileName);
};

export function pxToVw(value: number, minValue?: number): string {
  const windowWidth = window.innerWidth;
  const vwValue = (value / 1920) * 100;

  if (minValue) {
    if (windowWidth <= 1024) {
      return `${minValue}px`;
    }
  }

  return `${vwValue}vw`;
}

export const regDeleteSpacing = (value: string) => {
  return value.replace(/\s/g, "");
};

export const rem = (size: number) => {
  let defaultSize = 16;
  const rem = size / defaultSize;
  return rem + "rem";
};

export const regOnlyEnglish = (value: string) => {
  return value.replace(/[^\a-zA-Z ]/g, "");
};

export const regEnglishNum = (value: string) => {
  return value.replace(/[^\a-zA-Z0-9]/g, "");
};

export const regOnlyKor = (value: string) => {
  return value.replace(/[^ㄱ-ㅎ|ㅏ-ㅣ|가-힣| ]/g, "");
};

export const regKorEtc = (value: string) => {
  return value.replace(/[a-zA-Z|0-9]/g, "");
};

export const regEnglishEtc = (value: string) => {
  return value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|0-9]/g, "");
};

export const regDeleteSpecial = (value: string) => {
  return value.replace(/[^\a-zA-Z0-9ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, "");
};

export const regOnlyNumber = (value: string) => {
  return value.replace(/[^0-9]/g, "");
};

export const regNumberDot = (value: string) => {
  return value.replace(/[^0-9.]$/g, "");
};

export const regDeleteZero = (value: string) => {
  return value.replace(/(^0+)/, "");
};

export const regOnlyPrice = (value: string) => {
  const zero = value.replace(/(^0+)/, "");

  return zero.replace(/[^0-9]/g, "");
};

export const regUrl = (value: string) => {
  return value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣 ]/g, "");
};

export const regPhoneNumber = (value: string) => {
  return value
    ? value
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
    : "-";
};

export const regComma = (value: any) => {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return value;
  }
};

export const regUnComma = (value: any) => {
  if (value) {
    return value.toString().replace(/[^\d]+/g, "");
  } else {
    return value;
  }
};

export function formatYmd(
  ymd: string | Date | undefined,
  his: boolean = false
): string {
  return ymd
    ? moment(ymd).format(!his ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm")
    : "-";
}

export function formatHis(
  ymd: string | Date | undefined,
  his: string | Date | undefined
): string {
  if (!ymd || !his) {
    return "-";
  }
  return moment(ymd + " " + his).format("HH:mm:ss");
}

export function formatDotYmd(ymd: string): string {
  return ymd ? moment(ymd).format("YYYY.MM.DD") : "-";
}

export function formatHyphenYmd(ymd: string): string {
  return ymd ? moment(ymd).format("YYYY-MM-DD") : "-";
}

export function formatYmdHis(ymd: string, his: string): string {
  if (!ymd || !his) {
    return "-";
  }
  return moment(ymd + " " + his).format("YYYY-MM-DD HH:mm");
}
export function formatYmdHisDot(ymd: string, his: string): string {
  if (!ymd || !his) {
    return "-";
  }
  return moment(ymd + " " + his).format("YYYY.MM.DD HH:mm:ss");
}

export function formatRangeYmd(strtYmd: string, endYmd: string) {
  if (!strtYmd || !endYmd) {
    return "-";
  }

  return `${moment(strtYmd).format("YYYY-MM-DD")} ~ ${moment(endYmd).format(
    "YYYY-MM-DD"
  )}`;
}

export const handleChangeSort = (
  schForm: { [key: string]: any },
  fld: string | null = null
) => {
  let form = JSON.parse(JSON.stringify(schForm));

  if (fld) {
    form.sordFld = fld;
  }

  if (form.sordMetd === "DESC") {
    form.sordMetd = "ASC";
  } else {
    form.sordMetd = "DESC";
  }

  return form;
};

export const getHighlightText = (targetTxt: string, schTxt: string) => {
  const highlightTxt = targetTxt.toUpperCase();
  const find = schTxt.toUpperCase();
  const regex = new RegExp(find, "gi");
  const stringIdx = highlightTxt.indexOf(find);
  const substr = targetTxt.substr(stringIdx, find.length);

  let result = targetTxt.replace(
    regex,
    `<span style="color: ${theme.palette.primary.main} !important">` +
      substr +
      "</span>"
  );

  return !find ? targetTxt : result;
};

export const maxLen = (value: string, len: number) => {
  return value.slice(0, len);
};

export const changeSubmitDate = (date: Date | string) => {
  return date ? moment(date).format("YYYYMMDD") : "";
};

export const changeSubmitDateTime = (date: Date | string) => {
  return date ? moment(date).format("YYYYMMDD HHmmss") : "";
};

export const changeDataSubmitDateTime = (date: string, type: string = "") => {
  let arr = ["", ""];
  let seconds = "00";

  switch (type) {
    case "start":
      seconds = "00";
      break;
    case "end":
      seconds = "59";
      break;

    default:
      seconds = "00";
      break;
  }

  if (date) {
    let ymd = moment(date).format("YYYYMMDD");
    let his = moment(date).format(`HHmm${seconds}`);
    arr = [ymd, his];
  }
  return arr;
};

export const changeDatepickerDate = (dateStr: Date | string) => {
  if (!dateStr) return "";

  let date = moment(dateStr, "YYYYMMDDHHmm").toDate();

  return date;
};

export const changeSubmitTime = (time: Date | string) => {
  return time ? moment(time).format("HHmm") : "";
};

export const changeTimepickerDate = (itemStr: Date | string) => {
  if (!itemStr) return "";

  let date = moment(itemStr, "HHmm").toDate();

  return date;
};

export const fileterUsePrdYmd = (
  strtYmd: string,
  strtHis: string,
  endYmd: string,
  endHis: string
) => {
  return `${formatYmdHisDot(strtYmd, strtHis)} ~ ${formatYmdHisDot(
    endYmd,
    endHis
  )}`;
};

export function fileDownload(
  afSeq: number | string,
  filePath: string,
  auth: boolean = false
) {
  axios
    .get(`/api/st/${auth ? "auth-" : ""}attach-file/download/${afSeq}`, {
      responseType: "blob",
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filePath);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}

export const isEqualValue = (value1: any, value2: any) => {
  return _.isEqual(value1, value2);
};

export const fileUpload = async (
  e: any,
  data: any,
  i: number | null = null
) => {
  const {
    domain,
    setValue,
    setKey,
    submitCount,
    type,
    info,
    auth = null,
  } = data;

  const file = e.target.files[0];

  let formData = new FormData();
  let url: string = ``;

  switch (type) {
    case "FILE":
      formData.append("file", file);
      url = `/api/at/${auth ? "auth-" : ""}attach-file/upload?domain=${domain}`;
      break;
    case "IMAGE":
      formData.append("image", file);
      url = `/api/at/${
        auth ? "auth-" : ""
      }attach-image/upload?domain=${domain}`;
      break;
  }

  if (auth) {
    formData.append("menu", auth.menu);
    formData.append("obj", auth.obj);
  }

  let res = {
    errorCd: "",
  };

  await axios
    .post(url, formData)
    .then((res) => {
      const fileResponse = res.data.data;
      let resFile = {};

      if (type === "FILE") {
        resFile = {
          seq: fileResponse.seq,
          orgNm: fileResponse.orgNm,
          uri: fileResponse.uri,
          capa: fileResponse.capa,
          ext: fileResponse.ext,
        };
      } else if (type === "IMAGE") {
        resFile = {
          seq: fileResponse.seq,
          orgNm: fileResponse.orgNm,
          uri: fileResponse.uri,
          capa: fileResponse.capa,
          ext: fileResponse.ext,
        };

        if (info || info === "") {
          resFile = { ...resFile, info };
        }
      }

      if (i != null) {
        setValue(
          `${setKey}.${i}`,
          { ...resFile },
          {
            shouldValidate: submitCount && submitCount > 0 ? true : false,
          }
        );
      } else {
        setValue(
          setKey,
          { ...resFile },
          {
            shouldValidate: submitCount && submitCount > 0 ? true : false,
          }
        );
      }
    })
    .catch((error) => {
      console.log(error.response.data.code);
      res.errorCd = error.response.data.code;
    });

  return res;
};

export const fileSampleDownload = () => {
  axios
    .get(`/api/cm/construction/sample`, {
      responseType: "blob",
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", "sample.xlsx");
      document.body.appendChild(link);
      link.click();
    });
};

export const handleDownloadExcel = async (
  ttl: string,
  apiUrl: string,
  method: string,
  form: any = {}
) => {
  let res: any = "";

  if (method === "POST") {
    res = await axios.post(apiUrl, form, {
      responseType: "blob",
    });
  } else if (method === "GET") {
    res = await axios.get(apiUrl, {
      params: form,
      responseType: "blob",
    });
  }

  const url = window.URL.createObjectURL(new Blob([res.data]));

  const link = document.createElement("a");

  link.href = url;

  const currentYmd = moment().format("YYYYMMDD");

  link.setAttribute("download", `${ttl + "_" + currentYmd + ".xlsx"}`);

  document.body.appendChild(link);

  link.click();
};

/* 퍼센트로 된 letter-spacing 변환 */
export const lsToPx = (fontSize: number, percent: number) => {
  const value = fontSize * percent * -0.01;
  return `${value}px`;
};

export const getLocalStorage = (key: string) => {
  let value = window.localStorage.getItem(key);

  return value ? JSON.parse(value) : null;
};

export const setLocalStorage = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const clearLocalStorage = (key: string) => {
  window.localStorage.removeItem(key);
};

export const clearAllLocalStorage = () => {
  window.localStorage.clear();
};

export const getSessionStorage = (key: string) => {
  let value = window.sessionStorage.getItem(key);

  return value ? JSON.parse(value) : null;
};

export const setSessionStorage = (key: string, value: any) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

export const clearSessionStorage = (key: string) => {
  window.sessionStorage.removeItem(key);
};

export const clearAllSessionStorage = () => {
  window.sessionStorage.clear();
};

export const getImage = (seq: string) => {
  return `${process.env.REACT_APP_FETCH_URL}/api/at/attach-image/get-image?seq=${seq}`;
};

export const getSubmitFileList = (
  type: string,
  list: FileType[] | ImageType[]
) => {
  let result: any = [];
  if (list.length > 0 && list[0].seq) {
    if (type === "FILE") {
      result = list
        .map((file) => (file.seq !== undefined ? { afSeq: file.seq } : null))
        .filter(Boolean);
    } else {
      result = list
        .map((file) => (file.seq !== undefined ? { aiSeq: file.seq } : null))
        .filter(Boolean);
    }
  }
  return result;
};
