import GuideLeft from "@/guide/components/sidebar";
import globalCSS from "@/assets/ts/global-css";
import guideCSS from "@/assets/ts/guide-css";
import { css } from "@emotion/react";
import theme from "@/assets/ts/theme";
import { useState } from "react";

const typographyOrganize = [
  {
    name: "h1",
    size: 45,
    lineHeight: 60,
    weight: 700,
  },
  {
    name: "h2",
    size: 20,
    lineHeight: 20,
    weight: 700,
  },
  {
    name: "h3",
    size: 16,
    lineHeight: 16,
    weight: 700,
  },
  {
    name: "subTitle",
    size: 14,
    lineHeight: 14,
    weight: 400,
  },
  {
    name: "bodyTitle",
    size: 16,
    lineHeight: 16,
    weight: 700,
  },
  {
    name: "body",
    size: 14,
    lineHeight: 14,
    weight: 400,
  },
  {
    name: "bodyBold",
    size: 14,
    lineHeight: 14,
    weight: 700,
  },
  {
    name: "article1",
    size: 40,
    lineHeight: 40,
    weight: 500,
  },
];

export default function GuideTypography() {
  const [currentTypo, setCurrentTypo] = useState("h1");

  return (
    <div
      className="guide-area"
      css={[guideCSS.guideWrap, style.guide]}>
      <GuideLeft />
      <div css={guideCSS.guideWrapper}>
        <div className="title-box">
          <h2 className="guide-h2">Typography</h2>
        </div>
        <div className="guide-content">
          <div className="guide-typo-wrapper">
            <span className="category">국문/영문</span>
            <div className="guide-typo-content">
              <div className="guide-typo-sample">
                <span>Noto Sans CJK KR</span>
                <span css={globalCSS[currentTypo]}>가 Aa</span>
              </div>
              <table className="guide-typo-table">
                <colgroup>
                  <col />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Size</th>
                    <th>Line Height</th>
                    <th>Weight</th>
                  </tr>
                </thead>
                <tbody>
                  {typographyOrganize.map((typo) => (
                    <tr
                      key={typo.name}
                      onClick={() => {
                        setCurrentTypo(typo.name);
                      }}>
                      <td>{typo.name}</td>
                      <td>{typo.size}</td>
                      <td>{typo.lineHeight}</td>
                      <td>{typo.weight}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const style = {
  guide: css`
    .guide-content {
      display: flex;
      flex-direction: column;
    }
  `,
};
