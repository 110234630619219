import {
  changeDatepickerDate,
  formatYmdHisDot,
  regComma,
} from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import XTDatePicker from "@/components/mui/xt-datepicker";
import { XTInput } from "@/components/mui/xt-input";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import { XTMenuItem, XTSelect } from "@/components/mui/xt-select";
import { usePermissionList } from "@/pages/system/permission/_hook/use-permission";

import {
  Box,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Radio,
} from "@mui/material";
import {
  GridColDef,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";

export default function Permission() {
  const {
    listData,
    isFetching,
    schForm,
    schViewForm,
    handleChangeInput,
    handleChangePage,
    handleChangePageSz,
    handleClickDateButton,
    handleResetInput,
    handleChangeDatePicker,
    handleSearch,
    navigate,
  } = usePermissionList();
  const apiRef = useGridApiRef();

  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "번호",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return listData!.authList?.number - params.row.index;
      },
    },
    {
      field: "ttl",
      headerName: "권한",
      headerAlign: "center",
      width: 150,
      flex: 1,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "useYn",
      headerName: "사용여부",
      headerAlign: "center",
      width: 80,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "cont",
      headerName: "등록자",
      headerAlign: "center",
      width: 200,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.rgstrManagerAccount &&
          params.row.rgstrManagerAccount.nm
          ? `${params.row.rgstrManagerAccount.nm}(${params.row.rgstrManagerAccount.id})`
          : "-";
      },
    },
    {
      field: "formatRgstYmdHis",
      headerName: "등록일시",
      headerAlign: "center",
      width: 200,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        formatYmdHisDot(params.row.rgstYmd, params.row.rgstHis),
    },
  ];
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <main id="sample" css={globalCSS.listContainer} className="only-list">
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>관리자 권한관리</h2>
            </div>
            <div className="b-sub">
              <span>시스템 관리</span>
              <span>관리자 권한관리 목록</span>
            </div>
          </div>
        </div>
        <div className="cont-wrap">
          <div className="search-wrap">
            <table css={globalCSS.formTable}>
              <colgroup>
                <col style={{ width: "200px" }} />
                <col />
                <col style={{ width: "200px" }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputLabel>등록일자</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(schViewForm.schStrtYmd)}
                          onChange={handleChangeDatePicker("schStrtYmd")}
                          maxDate={changeDatepickerDate(schViewForm.schEndYmd)}
                          placeholder="시작일을 선택하세요."
                        />
                      </Grid>
                      <Grid item>
                        <div className="divider">
                          <span>~</span>
                        </div>
                      </Grid>
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(schViewForm.schEndYmd)}
                          minDate={changeDatepickerDate(schViewForm.schStrtYmd)}
                          onChange={handleChangeDatePicker("schEndYmd")}
                          placeholder="종료일을 선택하세요."
                        />
                      </Grid>
                      <Grid item>
                        <div className="date-btn-group">
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("1")}
                          >
                            1개월
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("3")}
                          >
                            3개월
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("12")}
                          >
                            1년
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("")}
                          >
                            전체
                          </XTButton>
                        </div>
                      </Grid>
                    </Grid>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>사용여부</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTRadioGroup
                      name="schUseYn"
                      row
                      value={schViewForm.schUseYn}
                      onChange={handleChangeInput}
                    >
                      <XTRadio value="" control={<Radio />} label={"전체"} />
                      <XTRadio value="Y" control={<Radio />} label={"Y"} />
                      <XTRadio value="N" control={<Radio />} label={"N"} />
                    </XTRadioGroup>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>권한 검색</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTInput
                          name="schTxt"
                          value={schViewForm.schTxt}
                          fullWidth
                          placeholder="검색어를 입력해주세요."
                          onKeyDown={handleKeyPress}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="action-button-group">
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => {
                  handleResetInput();
                }}
              >
                초기화
              </XTButton>
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={(e) => {
                  handleSearch();
                }}
              >
                조회
              </XTButton>
            </div>
          </div>
          <div className="grid-wrap">
            <div className="total-wrap">
              <div className="total-cnt">
                전체 <span>{regComma(listData.authList.totalSize)}</span>건
              </div>
              <XTSelect
                name="pgSz"
                value={schForm.pgSz}
                onChange={handleChangePageSz}
                sx={{
                  minWidth: 150,
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 50,
                    horizontal: -10,
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
              >
                <XTMenuItem value={10}>10개 씩</XTMenuItem>
                <XTMenuItem value={20}>20개 씩</XTMenuItem>
                <XTMenuItem value={30}>30개 씩</XTMenuItem>
                <XTMenuItem value={50}>50개 씩</XTMenuItem>
                <XTMenuItem value={100}>100개 씩</XTMenuItem>
              </XTSelect>
            </div>

            <XTDataGrid
              loading={isFetching}
              className="clickable"
              columns={columns}
              rows={listData.authList.list}
              getRowId={(params) => params.id}
              apiRef={apiRef}
              experimentalFeatures={{
                ariaV7: true,
              }}
              onRowClick={(params) => {
                navigate(`detail/${params.row.seq}`);
              }}
              slots={{
                noRowsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
                noResultsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
                loadingOverlay: LinearProgress,
              }}
              rowHeight={38}
              columnHeaderHeight={38}
              hideFooter
            />
          </div>
          <Box css={globalCSS.bottomWrap}>
            <Pagination
              page={listData.authList.page}
              count={listData.authList.totalPageSize}
              onChange={handleChangePage}
            />
            <Grid container justifyContent="end" spacing={2}>
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={(e) => {
                    navigate(`detail`);
                  }}
                >
                  등록
                </XTButton>
              </Grid>
            </Grid>
          </Box>
        </div>
      </main>
    </>
  );
}
