import {
  changeDatepickerDate,
  formatYmdHisDot,
  regComma,
} from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import XTDatePicker from "@/components/mui/xt-datepicker";
import { XTInput } from "@/components/mui/xt-input";
import { XTMenuItem, XTSelect } from "@/components/mui/xt-select";
import {
  Box,
  Checkbox,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Radio,
} from "@mui/material";
import {
  GridColDef,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useStock } from "../_hook/use-stock";
import { XTCheckBox, XTCheckBoxGroup } from "@/components/mui/xt-checkbox";
import { useEffect } from "react";

export default function Stock(props: {
  item: {
    seq: string;
    id: number;
    index: number;
    useYn: string;
    nm: string;
    qty: number;
  };
}) {
  const { item } = props;
  const {
    stockListData,
    isFetching,
    schViewForm,
    handleChangeDatePicker,
    handleClickDateButton,
    handleChangeInput,
    handleChangePage,
    handleResetInput,
    handleSearch,
    handleCheckBox,
  } = useStock();
  const apiRef = useGridApiRef();

  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "번호",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return stockListData!.goodsStockList?.number - params.row.index;
      },
    },
    {
      field: "tp",
      headerName: "분류",
      width: 150,
      headerAlign: "center",
      align: "center",
      valueGetter: (params: GridValueGetterParams) => {
        return stockListData.GoodsStockType &&
          stockListData.GoodsStockType[params.value]
          ? stockListData.GoodsStockType[params.value]
          : "-";
      },
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "qty",
      headerName: "수량",
      headerAlign: "center",
      width: 150,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "cont",
      headerName: "사유",
      headerAlign: "center",
      flex: 1,
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? params.value : "-",
    },
    {
      field: "formatRgstYmdHis",
      headerName: "등록일시",
      headerAlign: "center",
      width: 160,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        formatYmdHisDot(params.row.rgstYmd, params.row.rgstHis),
    },
  ];

  useEffect(() => {
    handleResetInput(item.seq);
  }, [item]);

  return (
    <>
      <main id="sample" css={globalCSS.listContainer}>
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>{item.nm}</h2>
            </div>
          </div>
        </div>

        <div className="cont-wrap">
          <div className="search-wrap">
            <table css={globalCSS.formTable}>
              <colgroup>
                <col style={{ width: "200px" }} />
                <col />
                <col style={{ width: "200px" }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputLabel>등록일자</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(schViewForm.schStrtYmd)}
                          onChange={handleChangeDatePicker("schStrtYmd")}
                          maxDate={changeDatepickerDate(schViewForm.schEndYmd)}
                          placeholder="시작일을 선택하세요."
                        />
                      </Grid>
                      <Grid item>
                        <div className="divider">
                          <span>~</span>
                        </div>
                      </Grid>
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(schViewForm.schEndYmd)}
                          minDate={changeDatepickerDate(schViewForm.schStrtYmd)}
                          onChange={handleChangeDatePicker("schEndYmd")}
                          placeholder="종료일을 선택하세요."
                        />
                      </Grid>
                      <Grid item>
                        <div className="date-btn-group">
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("1")}
                          >
                            1개월
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("3")}
                          >
                            3개월
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("12")}
                          >
                            1년
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("")}
                          >
                            전체
                          </XTButton>
                        </div>
                      </Grid>
                    </Grid>
                  </td>
                </tr>

                <tr>
                  <th scope="row">
                    <InputLabel>분류</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <XTCheckBoxGroup row>
                        <XTCheckBox
                          control={
                            <Checkbox
                              checked={["1", "2", "3"].every((value) =>
                                schViewForm.schTpArr.includes(value)
                              )}
                              onChange={() => {
                                handleCheckBox("all");
                              }}
                            />
                          }
                          label={"전체"}
                        />
                        {Object.entries(stockListData!.GoodsStockType).map(
                          ([id, text]) => (
                            <XTCheckBox
                              control={
                                <Checkbox
                                  checked={schViewForm.schTpArr.includes(id)}
                                  onChange={() => {
                                    handleCheckBox(id);
                                  }}
                                />
                              }
                              label={text}
                            />
                          )
                        )}
                      </XTCheckBoxGroup>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="action-button-group">
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => {
                  handleResetInput(item.seq);
                }}
              >
                초기화
              </XTButton>
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={(e) => {
                  handleSearch();
                }}
              >
                조회
              </XTButton>
            </div>
          </div>
          <div className="grid-wrap">
            <div className="total-wrap">
              <div className="total-cnt">
                전체{" "}
                <span>{regComma(stockListData?.goodsStockList.totalSize)}</span>
                건
              </div>
              <XTSelect
                name="pgSz"
                value={10}
                onChange={handleChangeInput}
                sx={{
                  minWidth: 150,
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 50,
                    horizontal: -10,
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
              >
                <XTMenuItem value={10}>10개 씩</XTMenuItem>
                <XTMenuItem value={20}>20개 씩</XTMenuItem>
                <XTMenuItem value={30}>30개 씩</XTMenuItem>
                <XTMenuItem value={50}>50개 씩</XTMenuItem>
                <XTMenuItem value={100}>100개 씩</XTMenuItem>
              </XTSelect>
            </div>

            <XTDataGrid
              loading={isFetching}
              className="clickable"
              columns={columns}
              rows={stockListData!.goodsStockList.list}
              getRowId={(params) => params.id}
              apiRef={apiRef}
              experimentalFeatures={{
                ariaV7: true,
              }}
              onRowClick={(params) => {}}
              slots={{
                noRowsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
                noResultsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
                loadingOverlay: LinearProgress,
              }}
              rowHeight={38}
              columnHeaderHeight={38}
              hideFooter
            />
          </div>
          <Box css={globalCSS.bottomWrap}>
            <Pagination
              page={stockListData?.goodsStockList.page}
              count={stockListData?.goodsStockList.totalPageSize}
              onChange={handleChangePage}
            />
          </Box>
        </div>
      </main>
    </>
  );
}
