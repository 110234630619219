import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, InputLabel, TextField, css } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  XTDialog,
  XTDialogActions,
  XTDialogContent,
  XTDialogTitle,
} from "@/components/mui/xt-dialog";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTFormHelperText, XTTextarea } from "@/components/mui/xt-input";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import theme from "@/assets/ts/theme";
import { useEffect } from "react";
import { regOnlyNumber } from "@/assets/ts/common";
import { ProductStockType } from "@/types/product";

export default function StockRegistDialog(props: {
  open: {
    open: boolean;
    tp: string;
    stockQty: number;
    stockUpdate: ((qty: string, cont: string) => void) | null;
  };
  setOpen: (open: {
    open: boolean;
    tp: string;
    stockQty: number;
    stockUpdate: ((qty: string, cont: string) => void) | null;
  }) => void;
  productStockTypes: ProductStockType;
}) {
  const { open, setOpen, productStockTypes } = props;

  const schema = yup
    .object()
    .shape({
      qty: yup
        .string()
        .trim()
        .test("notEmpty", "수량을 입력해주세요.", (value1) => {
          if (value1 == "" || value1 == "0") {
            return false;
          } else {
            return true;
          }
        })
        .test(
          "lessThanEight",
          "현재 재고량을 확인 후 다시 입력해주세요.",
          (value) => {
            if (open.tp != "1") {
              const parsedValue = parseInt(value!);
              return !isNaN(parsedValue) && parsedValue <= open.stockQty;
            } else {
              return true;
            }
          }
        )
        .required(),
      cont: yup.string().when([], {
        is: () => open.tp === "3",
        then: yup.string().trim().required("수량 조정 사유를 입력해주세요."),
        otherwise: yup.string().trim(),
      }),
    })
    .required();

  const {
    trigger,
    reset,
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      qty: "",
      cont: "",
    },
    shouldFocusError: false,
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setOpen({
      ...open,
      open: false,
    });
  };

  const onSubmit = async () => {
    open.stockUpdate!(getValues().qty, getValues().cont);
  };

  useEffect(() => {
    if (!open.open) {
      reset({
        qty: "",
        cont: "",
      });
    }
  }, [open.open]);

  return (
    <>
      <XTDialog
        disablePortal
        className="sm"
        open={open.open}
        onClose={handleClose}
      >
        <XTDialogTitle className="flex-title">
          {productStockTypes.GoodsStockType[open.tp]} 등록
          <button type="button" className="close-btn" onClick={handleClose}>
            <span css={globalCSS.hidden}>닫기</span>
            <i css={globalCSS.icoClose} />
          </button>
        </XTDialogTitle>
        <XTDialogContent css={style.container}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="cont">
              <Grid style={{ marginBottom: "10px" }}>
                <Grid item>
                  <InputLabel required>수량</InputLabel>
                </Grid>
                <Grid item>
                  <Controller
                    rules={{
                      required: true,
                    }}
                    name="qty"
                    control={control}
                    render={({ field }) => (
                      <Grid container>
                        <Grid item>
                          <XTButton
                            className="num-btn"
                            color="primary"
                            size="small"
                            onClick={() => {
                              if (watch("qty") && parseInt(watch("qty")) > 0) {
                                let num = parseInt(watch("qty")) - 1;
                                setValue("qty", num.toString());
                              }

                              trigger("qty");
                            }}
                          >
                            <RemoveIcon />{" "}
                          </XTButton>
                        </Grid>
                        <Grid item>
                          <input
                            {...field}
                            className="num-input"
                            type="text"
                            placeholder="수량을 입력해주세요."
                            onChange={(e) => {
                              setValue("qty", regOnlyNumber(e.target.value));
                              trigger("qty");
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <XTButton
                            className="num-btn"
                            color="primary"
                            size="small"
                            onClick={() => {
                              if (watch("qty") == "") {
                                setValue("qty", "1");
                              } else {
                                let num = parseInt(watch("qty")) + 1;
                                setValue("qty", num.toString());
                              }
                              trigger("qty");
                            }}
                          >
                            <AddIcon />{" "}
                          </XTButton>
                        </Grid>
                      </Grid>
                    )}
                  />
                  {errors.qty?.type === "notEmpty" && (
                    <XTFormHelperText className="error">
                      {errors.qty?.message}
                    </XTFormHelperText>
                  )}
                  {errors.qty?.type === "lessThanEight" && (
                    <XTFormHelperText className="error">
                      {errors.qty?.message}
                    </XTFormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid style={{ marginBottom: "10px" }}>
                <Grid item>
                  <InputLabel required={open.tp == "3"}>
                    수량 조정 사유
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Controller
                    rules={{
                      required: open.tp == "3",
                    }}
                    name="cont"
                    control={control}
                    render={({ field }) => (
                      <XTTextarea
                        {...field}
                        name="textarea"
                        id="textarea"
                        multiline
                        placeholder="수량 조정 사유를 입력해주세요."
                        type="text"
                        sx={{
                          minWidth: 315,
                        }}
                      ></XTTextarea>
                    )}
                  />
                  {errors.cont?.type === "required" && (
                    <XTFormHelperText className="error">
                      {errors.cont?.message}
                    </XTFormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid container justifyContent="end">
                <XTButton
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                >
                  등록
                </XTButton>
              </Grid>
            </div>
          </form>
        </XTDialogContent>
        <XTDialogActions className="flex-end"></XTDialogActions>
      </XTDialog>
    </>
  );
}

const style = {
  container: css`
    form {
      width: 100%;
      display: flex;
      justify-content: center;

      .num-btn {
        &:disabled {
          color: ${theme.palette.text_sub2};
        }
      }

      .num-input {
        padding: 10px;
        width: 12.5rem;
        height: 1.8125rem;
        border: 1px solid #ccceca;
        border-left: none;
        border-right: none;

        ::placeholder {
          font-size: 14px;
          color: #ccceca;
        }

        &:disabled {
          background: ${theme.palette.disabled};
          color: ${theme.palette.text_sub2};

          ::placeholder {
            color: ${theme.palette.text_sub2};
          }
        }
      }

      .MuiInputLabel-root {
        font-size: 14px;
        font-weight: 700;
        color: ${theme.palette.default};

        .MuiInputLabel-asterisk {
          color: ${theme.palette.error.main};
        }
      }
    }
  `,
};
