import { styled } from "@mui/material/styles";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@mui/material";

export const XTRadioGroup = styled(RadioGroup)(({ theme }) => ({
  "&.MuiFormGroup-row": {
    "& .MuiFormControlLabel-root": {
      "& + .MuiFormControlLabel-root": {
        marginLeft: "30px",
      },
    },
  },

  "&.MuiFormGroup-root": {
    "&:not(.MuiFormGroup-row)": {
      "& .MuiFormControlLabel-root": {
        "& + .MuiFormControlLabel-root": {
          marginTop: "5px",
        },
      },
    },
  },
}));

export const XTRadio = styled(FormControlLabel)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: 0,

  "& .MuiButtonBase-root": {
    width: "18px",
    height: "18px",
    border: `1px solid ${theme.palette.line1} `,
    padding: 0,
    borderRadius: "50%",
    backgroundColor: `${theme.palette.white}`,

    "&:hover": {
      backgroundColor: `${theme.palette.white}`,
    },

    "&.Mui-checked": {
      position: "relative",
      border: "none",
      backgroundColor: `${theme.palette.primary.main}`,

      "&::after": {
        content: "''",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        background: "url('/images/icon/ico-checked.svg') no-repeat center",
      },
    },
    "&.Mui-disabled": {
      border: "none",
      backgroundColor: `${theme.palette.disabled}`,

      "&.Mui-checked": {
        position: "relative",
        backgroundColor: `${theme.palette.disabled};`,
      },
    },
  },
  "& svg": {
    display: "none",
  },
  "& .MuiTypography-root": {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: `${theme.palette.default}`,
    marginLeft: "6px",
  },
}));
