import {
  changeDatepickerDate,
  formatYmdHisDot,
  regComma,
  regDeleteSpecial,
  regPhoneNumber,
} from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import XTDatePicker from "@/components/mui/xt-datepicker";
import { XTInput } from "@/components/mui/xt-input";
import { XTMenuItem, XTSelect } from "@/components/mui/xt-select";
import {
  Box,
  Checkbox,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Radio,
} from "@mui/material";
import {
  GridColDef,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import { useNurseDetail, useNurseList } from "./_hook/use-nurse";
import { useEffect, useState } from "react";
import { XTCheckBox } from "@/components/mui/xt-checkbox";
import { alertDialogState } from "@/state";
import { useRecoilState } from "recoil";
import CancelDialog from "@/pages/nurse/_component/cancel-dialog";

export default function Nurse() {
  const {
    listData,
    schForm,
    schViewForm,
    isFetching,
    refetch,
    order,
    setOrder,
    handleChangeInput,
    handleChangePage,
    handleChangePageSz,
    handleResetInput,
    navigate,
    handleChangeDatePicker,
    handleClickDateButton,
    handleSearch,
  } = useNurseList();

  const { nurseCancel } = useNurseDetail();
  const apiRef = useGridApiRef();

  const [alertDialog, setAlertDialog] = useRecoilState(alertDialogState);
  const [cancelDialog, setCancelDialog] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "번호",
      width: 50,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return listData!.applyList?.number - params.row.index;
      },
    },
    {
      field: "seqCd",
      headerName: "신청번호",
      headerAlign: "center",
      width: 120,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "aplyrTp",
      headerName: "신청구분",
      headerAlign: "center",
      width: 150,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return listData.ApplyType[params.value]
          ? listData.ApplyType[params.value]
          : "-";
      },
    },
    {
      field: "stat",
      headerName: "처리상태",
      headerAlign: "center",
      width: 80,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return listData!.ApplyStatus[params.value];
      },
    },
    {
      field: "nm",
      headerName: "수령인",
      headerAlign: "center",
      width: 170,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.applyPrivacy ? params.row.applyPrivacy.nm : "-";
      },
    },
    {
      field: "cph",
      headerName: "휴대폰번호",
      headerAlign: "center",
      width: 120,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.applyPrivacy
          ? regPhoneNumber(params.row.applyPrivacy.cph)
          : "-";
      },
    },
    {
      field: "chldrNm",
      headerName: "자녀이름",
      headerAlign: "center",
      width: 120,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.applyPrivacy && params.row.applyPrivacy.chldrNm
          ? params.row.applyPrivacy.chldrNm
          : "-";
      },
    },
    {
      field: "addr",
      headerName: "배송지",
      headerAlign: "center",
      width: 300,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.applyPrivacy && params.row.applyPrivacy.addr
          ? `${params.row.applyPrivacy.addr} ${
              params.row.applyPrivacy.addrDtl
                ? params.row.applyPrivacy.addrDtl
                : ""
            } `
          : "-";
      },
    },
    {
      field: "goods",
      headerName: "신청물품",
      headerAlign: "center",
      width: 350,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.applyGoodsList
          .map(
            (item: {
              seq: number;
              qty: string;
              goods: {
                seq: number;
                nm: string;
              };
            }) => item.goods.nm + " " + item.qty
          )
          .join(",")}`,
    },
    {
      field: "ivcNo",
      headerName: "운송장번호",
      headerAlign: "center",
      width: 300,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <p style={{ margin: "auto" }}>
              {params.value ? params.value : "-"}
            </p>
            {params.value && (
              <XTButton
                variant="contained"
                color="primary"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  window.open(
                    `https://www.cjlogistics.com/ko/tool/parcel/tracking?gnbInvcNo=${params.value}`,
                    "_blank"
                  );
                }}
              >
                조회
              </XTButton>
            )}
          </Grid>
        );
      },
    },
    {
      field: "aplyYmd",
      headerName: "신청일시",
      headerAlign: "center",
      width: 160,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return formatYmdHisDot(params.value, params.row.aplyHis);
      },
    },
    {
      field: "tagdsYmd",
      headerName: "출고일시",
      headerAlign: "center",
      width: 160,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return formatYmdHisDot(params.value, params.row.tagdsHis);
      },
    },
    {
      field: "cancYmd",
      headerName: "취소일시",
      headerAlign: "center",
      width: 160,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return formatYmdHisDot(params.value, params.row.cancHis);
      },
    },
  ];

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleCancelOrder = async (cont: string) => {
    const params = {
      seqArray: order!,
      cancCont: cont,
    };

    try {
      const result = await nurseCancel.mutateAsync(params);
      setAlertDialog({
        open: true,
        text: "취소처리 되었습니다.",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          setCancelDialog(false);
          setOrder([]);
          refetch();
        },
        handleClose: null,
      });
    } catch (err) {
      setAlertDialog({
        open: true,
        text: "취소처리 실패했습니다.",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          setCancelDialog(false);
        },
        handleClose: null,
      });
    }
  };

  return (
    <>
      <main id="sample" css={globalCSS.listContainer} className="only-list">
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>물품신청</h2>
            </div>
            <div className="b-sub">
              <span>물품신청</span>
            </div>
          </div>
        </div>
        <div className="cont-wrap">
          <div className="search-wrap">
            <table css={globalCSS.formTable}>
              <colgroup>
                <col style={{ width: "200px" }} />
                <col />
                <col style={{ width: "200px" }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputLabel>기간선택</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTSelect
                          sx={{
                            minWidth: 150,
                          }}
                          name="schFldYmd"
                          onChange={handleChangeInput}
                          value={schViewForm.schFldYmd}
                          MenuProps={{
                            MenuListProps: { sx: { padding: 0 } },
                          }}
                        >
                          <XTMenuItem value="aplyYmd">신청일자</XTMenuItem>
                          <XTMenuItem value="tagdsYmd">출고일자</XTMenuItem>
                          <XTMenuItem value="cancYmd">취소일자</XTMenuItem>
                        </XTSelect>
                      </Grid>
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(schViewForm.schStrtYmd)}
                          onChange={handleChangeDatePicker("schStrtYmd")}
                          maxDate={changeDatepickerDate(schViewForm.schEndYmd)}
                          placeholder="시작일을 선택하세요."
                        />
                      </Grid>
                      <Grid item>
                        <div className="divider">
                          <span>~</span>
                        </div>
                      </Grid>
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(schViewForm.schEndYmd)}
                          minDate={changeDatepickerDate(schViewForm.schStrtYmd)}
                          onChange={handleChangeDatePicker("schEndYmd")}
                          placeholder="종료일을 선택하세요."
                        />
                      </Grid>
                      <Grid item>
                        <div className="date-btn-group">
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("1")}
                          >
                            1개월
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("3")}
                          >
                            3개월
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("12")}
                          >
                            1년
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("")}
                          >
                            전체
                          </XTButton>
                        </div>
                      </Grid>
                    </Grid>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>처리상태</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTRadioGroup
                      row
                      value={schViewForm.schStat}
                      name="schStat"
                      onChange={handleChangeInput}
                    >
                      <XTRadio value={""} control={<Radio />} label={"전체"} />
                      {Object.keys(listData.ApplyStatus).map((key) => (
                        <XTRadio
                          key={key}
                          value={key}
                          control={<Radio />}
                          label={listData.ApplyStatus[key]}
                        />
                      ))}
                    </XTRadioGroup>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>신청구분</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTRadioGroup
                      row
                      value={schViewForm.schTp}
                      name="schTp"
                      onChange={handleChangeInput}
                    >
                      <XTRadio value={""} control={<Radio />} label={"전체"} />
                      {Object.keys(listData.ApplyType).map((key) => (
                        <XTRadio
                          key={key}
                          value={key}
                          control={<Radio />}
                          label={listData.ApplyType[key]}
                        />
                      ))}
                    </XTRadioGroup>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>검색</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTSelect
                          placeholder={""}
                          name="schFld"
                          onChange={handleChangeInput}
                          value={schViewForm.schFld}
                          sx={{
                            minWidth: 150,
                          }}
                          MenuProps={{
                            MenuListProps: { sx: { padding: 0 } },
                          }}
                        >
                          <XTMenuItem value=" ">전체</XTMenuItem>
                          <XTMenuItem value="chldrNm">자녀이름</XTMenuItem>
                          <XTMenuItem value="nm">수령인</XTMenuItem>
                          <XTMenuItem value="cph">휴대폰번호</XTMenuItem>
                          <XTMenuItem value="ivcNo">운송장번호</XTMenuItem>
                        </XTSelect>
                      </Grid>
                      <Grid item>
                        <XTInput
                          name="schTxt"
                          value={schViewForm.schTxt}
                          fullWidth
                          placeholder="검색어를 입력해주세요."
                          onKeyDown={handleKeyPress}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="action-button-group">
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => {
                  handleResetInput();
                }}
              >
                초기화
              </XTButton>
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={(e) => {
                  handleSearch();
                }}
              >
                조회
              </XTButton>
            </div>
          </div>
          <span className="helper-text">
            ※ 해당 메뉴는 하이케어 관리자와 연동되는 부분입니다. 데이터가
            정상적으로 노출되지 않을 시, 새로고침을 해주시기 바랍니다.
          </span>
          <div className="grid-wrap">
            <div className="total-wrap">
              <div className="total-cnt">
                전체 <span>{regComma(listData.applyList.totalSize)}</span>건
              </div>

              <XTSelect
                name="pgSz"
                value={schForm.pgSz}
                onChange={handleChangePageSz}
                sx={{
                  minWidth: 150,
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 50,
                    horizontal: -10,
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
              >
                <XTMenuItem value={10}>10개 씩</XTMenuItem>
                <XTMenuItem value={20}>20개 씩</XTMenuItem>
                <XTMenuItem value={30}>30개 씩</XTMenuItem>
                <XTMenuItem value={50}>50개 씩</XTMenuItem>
                <XTMenuItem value={100}>100개 씩</XTMenuItem>
              </XTSelect>
            </div>
            <XTDataGrid
              loading={isFetching}
              className="clickable"
              columns={columns}
              rows={listData.applyList.list}
              getRowId={(params) => params.seq}
              onCellClick={(params, event) => {
                if (params.field === "num") {
                  event.stopPropagation(); // 클릭 이벤트 전파 중단
                }
              }}
              apiRef={apiRef}
              experimentalFeatures={{
                ariaV7: true,
              }}
              disableRowSelectionOnClick
              onRowSelectionModelChange={(newSelection, details) => {
                setOrder(newSelection as number[]);
              }}
              checkboxSelection
              rowSelectionModel={order}
              isRowSelectable={(params) =>
                params.row.stat !== 3 &&
                params.row.stat !== 2 &&
                params.row.divYn !== "Y"
                  ? true
                  : false
              }
              onRowClick={(params) => {
                navigate(`detail/${params.row.seq}`, {
                  state: {
                    prevPath: window.location.pathname + window.location.search,
                  },
                });
              }}
              slots={{
                noRowsOverlay: () => (
                  <div className="no-data">등록된 내역이 없습니다.</div>
                ),
                noResultsOverlay: () => (
                  <div className="no-data">등록된 내역이 없습니다.</div>
                ),
                loadingOverlay: LinearProgress,
              }}
              rowHeight={38}
              columnHeaderHeight={38}
              hideFooter
            />
          </div>
          <Box css={globalCSS.bottomWrap}>
            <Pagination
              page={listData.applyList.page}
              count={listData.applyList.totalPageSize}
              onChange={handleChangePage}
            />
            <Grid container justifyContent="end" spacing={2}>
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={(e) => {
                    if (order.length > 0) {
                      setCancelDialog(true);
                    } else {
                      setAlertDialog({
                        open: true,
                        text: "취소처리하실 신청내역을 선택해주세요.",
                        type: "complete",
                        title: "알림",
                        handleOK: () => {
                          setAlertDialog({
                            ...alertDialog,
                            open: false,
                          });
                        },
                        handleClose: null,
                      });
                    }
                  }}
                >
                  취소
                </XTButton>
              </Grid>
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={(e) => {
                    navigate("/nurse/detail", {
                      state: {
                        prevPath:
                          window.location.pathname + window.location.search,
                      },
                    });
                  }}
                >
                  신청
                </XTButton>
              </Grid>
            </Grid>
          </Box>
        </div>
      </main>
      <CancelDialog
        open={cancelDialog}
        setOpen={setCancelDialog}
        handleOK={handleCancelOrder}
      />
    </>
  );
}
