import {
  fileterUsePrdYmd,
  formatYmdHisDot,
  regComma,
} from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton, XTTextButton } from "@/components/mui/xt-button";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import XTDatePicker from "@/components/mui/xt-datepicker";
import { XTInput } from "@/components/mui/xt-input";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import {
  XTMenuItem,
  XTMenuItemWrap,
  XTSelect,
} from "@/components/mui/xt-select";
import AlertDialog from "@/components/ui/alert-dialog";
import { NoticeSchFormType } from "@/types/notice";
import {
  Box,
  Grid,
  InputLabel,
  LinearProgress,
  Pagination,
  Radio,
  SelectChangeEvent,
} from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-premium";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  useNoticeManager,
  useNoticeTypeManager,
} from "@/pages/notice/_hook/use-notice";
import moment from "moment";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function NoticeList() {
  const navigate = useNavigate();
  const location = useLocation();

  // custom hook
  const { schParams, noticeData, isSuccess, isFetching } = useNoticeManager();
  // const { noticeTypes } = useNoticeTypeManager();

  // const params = new URLSearchParams(location.search);

  const initialData: NoticeSchFormType = {
    schFldYmd: "rgstYmd",
    schStrtYmd: "",
    schEndYmd: "",
    schUseYn: "",
    schNotfUseYn: "",
    schNotfTp: "",
    schFld: "",
    schTxt: "",
    pg: "1",
    pgSz: "10",
    sordFld: "rgstYmd",
    sordMetd: "DESC",
  };

  const [schForm, setSchForm] = useState<NoticeSchFormType>(schParams);

  useEffect(() => {
    reset(schParams);
    setSchForm(schParams);
  }, [location.search]);

  const goPage = (form: NoticeSchFormType) => {
    // setSchForm(form);
    // reset(form);

    const queryStringResult = queryString.stringify(form);
    navigate("/notice?" + queryStringResult);
  };

  const {
    register,
    trigger,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, submitCount },
  } = useForm<NoticeSchFormType>({
    shouldFocusError: false,
    defaultValues: schParams,
  });

  const handleChangeInput = useCallback((event: SelectChangeEvent<unknown>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const data = getValues();
    let form = {
      ...data,
      pg: 1,
      pgSz: Number(value),
    };
    goPage(form);
  }, []);

  const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    const data = getValues();
    let form = {
      ...data,
      pg: value,
    };
    goPage(form);
  };

  const columns: GridColDef[] = [
    {
      field: "no",
      headerName: "번호",
      width: 90,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return noticeData!.noticeList?.number - params.row.index;
      },
    },
    {
      field: "ttl",
      headerName: "제목",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "useYn",
      headerName: "게시여부",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "notfUseYn",
      headerName: "알림함 노출",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "noticeNotification.tp",
      headerName: "중요알림",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.noticeNotification?.tp
          ? noticeData?.NoticeNotificationType[params.row.noticeNotification.tp]
          : "-";
      },
    },
    {
      field: "usePrdStrtYmd",
      headerName: "게시기간",
      width: 250,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return `${
          params.row.usePrdYn === "Y"
            ? fileterUsePrdYmd(
                params.row.usePrdStrtYmd,
                params.row.usePrdStrtHis,
                params.row.usePrdEndYmd,
                params.row.usePrdEndHis
              )
            : "상시"
        }`;
      },
      renderHeader: (params: any) => {
        return (
          <XTTextButton
            onClick={(e) => {
              handleChangeSort("strtYmd");
            }}
          >
            게시기간{" "}
            {schForm.sordFld === "strtYmd" && schForm.sordMetd === "ASC" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </XTTextButton>
        );
      },
    },
    {
      field: "formatRgstYmdHis",
      headerName: "등록일시",
      width: 160,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params: any) => {
        return (
          <XTTextButton
            onClick={(e) => {
              handleChangeSort("rgstYmd");
            }}
          >
            등록일시{" "}
            {schForm.sordFld === "rgstYmd" && schForm.sordMetd === "ASC" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </XTTextButton>
        );
      },
      valueGetter: (params: GridValueGetterParams) =>
        formatYmdHisDot(params.row.rgstYmd, params.row.rgstHis),
    },
  ];

  const handleChangeSort = (sordFld: string) => {
    let sordMetd = "";
    if (schForm.sordFld !== sordFld) {
      sordMetd = "ASC";
    } else {
      sordMetd = schForm.sordMetd === "DESC" ? "ASC" : "DESC";
    }

    let form = {
      ...schForm,
      sordMetd: sordMetd,
      sordFld: sordFld,
    };
    goPage(form);
  };

  const handleClickDateButton = useCallback(
    (month: string | null) => {
      let startDate = "";
      let endDate = "";

      if (month) {
        startDate = moment().subtract(month, "months").format("YYYYMMDD");
        endDate = moment().format("YYYYMMDD");
      }

      reset({
        ...getValues(),
        pg: 1,
        schStrtYmd: startDate,
        schEndYmd: endDate,
      });
    },
    [getValues()]
  );

  const handleReset = () => {
    goPage(initialData);
  };

  const onSubmit = (data: NoticeSchFormType) => {
    let form = {
      ...data,
      pg: 1,
    };

    goPage(form);
  };

  const onError = (data: any) => {
    console.log("error", data);
  };

  return (
    <>
      <main id="sample" css={globalCSS.listContainer} className="only-list">
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>공지사항</h2>
            </div>
            <div className="b-sub">
              <span>홈</span>
              <span>공지사항</span>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="cont-wrap">
            <div className="search-wrap">
              <table css={globalCSS.formTable}>
                <colgroup>
                  <col style={{ width: "200px" }} />
                  <col />
                  <col style={{ width: "200px" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <InputLabel>기간선택</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Grid container spacing={1} className="form-grid">
                        <Grid item>
                          <Controller
                            name="schFldYmd"
                            control={control}
                            render={({ field }) => (
                              <XTSelect
                                {...field}
                                sx={{
                                  minWidth: 150,
                                }}
                                {...field}
                                MenuProps={{
                                  MenuListProps: { sx: { padding: 0 } },
                                  PaperProps: {
                                    sx: {
                                      ...XTMenuItemWrap,
                                    },
                                  },
                                }}
                                displayEmpty
                              >
                                <XTMenuItem value={"rgstYmd"}>
                                  등록일자
                                </XTMenuItem>
                                <XTMenuItem value={"strtYmd"}>
                                  게시기간
                                </XTMenuItem>
                              </XTSelect>
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <Controller
                            name="schStrtYmd"
                            control={control}
                            render={({ field }) => (
                              <XTDatePicker
                                value={watch("schStrtYmd")}
                                onChange={(newDate) => {
                                  field.onChange(newDate);
                                }}
                                maxDate={watch("schEndYmd")}
                                placeholder="시작일을 선택하세요."
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <div className="divider">
                            <span>~</span>
                          </div>
                        </Grid>
                        <Grid item>
                          <Controller
                            name="schEndYmd"
                            control={control}
                            render={({ field }) => (
                              <XTDatePicker
                                value={watch("schEndYmd")}
                                minDate={watch("schStrtYmd")}
                                onChange={(newDate) => {
                                  field.onChange(newDate);
                                }}
                                placeholder="종료일을 선택하세요."
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <div className="date-btn-group">
                            <XTButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className="xs"
                              onClick={() => handleClickDateButton("1")}
                            >
                              1개월
                            </XTButton>
                            <XTButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className="xs"
                              onClick={() => handleClickDateButton("3")}
                            >
                              3개월
                            </XTButton>
                            <XTButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className="xs"
                              onClick={() => handleClickDateButton("12")}
                            >
                              1년
                            </XTButton>
                            <XTButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className="xs"
                              onClick={() => handleClickDateButton("")}
                            >
                              전체
                            </XTButton>
                          </div>
                        </Grid>
                      </Grid>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>게시여부</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Controller
                        name="schUseYn"
                        control={control}
                        render={({ field }) => (
                          <XTRadioGroup row defaultValue="" {...field}>
                            <XTRadio
                              value={""}
                              control={<Radio />}
                              label={"전체"}
                            />
                            <XTRadio
                              value={"Y"}
                              control={<Radio />}
                              label={"Y"}
                            />
                            <XTRadio
                              value={"N"}
                              control={<Radio />}
                              label={"N"}
                            />
                          </XTRadioGroup>
                        )}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>알림함 노출</InputLabel>
                    </th>
                    <td>
                      <Controller
                        name="schNotfUseYn"
                        control={control}
                        render={({ field }) => (
                          <XTRadioGroup row defaultValue="" {...field}>
                            <XTRadio
                              value={""}
                              control={<Radio />}
                              label={"전체"}
                            />
                            <XTRadio
                              value={"Y"}
                              control={<Radio />}
                              label={"Y"}
                            />
                            <XTRadio
                              value={"N"}
                              control={<Radio />}
                              label={"N"}
                            />
                          </XTRadioGroup>
                        )}
                      />
                    </td>
                    <th scope="row">
                      <InputLabel>중요알림</InputLabel>
                    </th>
                    <td>
                      {noticeData && (
                        <Controller
                          name="schNotfTp"
                          control={control}
                          render={({ field }) => (
                            <XTRadioGroup row defaultValue="" {...field}>
                              <XTRadio
                                value={""}
                                control={<Radio />}
                                label={"전체"}
                              />
                              {Object.keys(
                                noticeData.NoticeNotificationType
                              ).map((key: string) => (
                                <XTRadio
                                  key={key}
                                  value={key}
                                  control={<Radio />}
                                  label={
                                    noticeData!.NoticeNotificationType[
                                      key.toString()
                                    ]
                                  }
                                />
                              ))}
                            </XTRadioGroup>
                          )}
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>검색</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Grid container spacing={1} className="form-grid">
                        <Grid item>
                          <Controller
                            name="schFld"
                            control={control}
                            render={({ field }) => (
                              <XTSelect
                                {...field}
                                sx={{
                                  minWidth: 150,
                                }}
                                MenuProps={{
                                  MenuListProps: { sx: { padding: 0 } },
                                  PaperProps: {
                                    sx: {
                                      ...XTMenuItemWrap,
                                    },
                                  },
                                }}
                                displayEmpty
                              >
                                <XTMenuItem value={""}>전체</XTMenuItem>
                                <XTMenuItem value={"ttl"}>제목</XTMenuItem>
                                <XTMenuItem value={"cont"}>내용</XTMenuItem>
                              </XTSelect>
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <Controller
                            name="schTxt"
                            control={control}
                            render={({ field }) => (
                              <XTInput
                                placeholder="검색어를 입력해주세요."
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="action-button-group">
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={handleReset}
                >
                  초기화
                </XTButton>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  type="submit"
                >
                  조회
                </XTButton>
              </div>
            </div>
            <div className="grid-wrap">
              <div className="total-wrap">
                <div className="total-cnt">
                  전체 <span>{regComma(noticeData?.noticeList.totalSize)}</span>
                  건
                </div>
                <XTSelect
                  defaultValue={10}
                  value={schForm.pgSz}
                  onChange={handleChangeInput}
                  sx={{
                    minWidth: 150,
                  }}
                  MenuProps={{
                    MenuListProps: { sx: { padding: 0 } },
                    PaperProps: {
                      sx: {
                        ...XTMenuItemWrap,
                      },
                    },
                  }}
                >
                  <XTMenuItem value={10}>10개</XTMenuItem>
                  <XTMenuItem value={20}>20개</XTMenuItem>
                  <XTMenuItem value={30}>30개</XTMenuItem>
                  <XTMenuItem value={50}>50개</XTMenuItem>
                  <XTMenuItem value={100}>100개</XTMenuItem>
                </XTSelect>
              </div>

              <XTDataGrid
                className="clickable"
                columns={columns}
                rows={noticeData?.noticeList?.list || []}
                experimentalFeatures={{
                  ariaV7: true,
                }}
                loading={isFetching}
                onRowClick={(params) => {
                  navigate(`/notice/detail/${params.row.seq}`);
                }}
                getRowId={(params) => params.seq}
                slots={{
                  noRowsOverlay: () => (
                    <div className="no-data">등록된 공지사항이 없습니다.</div>
                  ),
                  noResultsOverlay: () => (
                    <div className="no-data">등록된 공지사항이 없습니다.</div>
                  ),
                  loadingOverlay: LinearProgress,
                }}
                rowHeight={38}
                columnHeaderHeight={38}
                hideFooter
              />
            </div>
          </div>
          <Box css={globalCSS.bottomWrap}>
            <Pagination
              page={Number(schForm.pg)}
              count={noticeData?.noticeList.totalPageSize}
              onChange={handleChangePage}
            />
            <Grid container justifyContent="end" spacing={2}>
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={() => navigate("/notice/detail")}
                >
                  등록
                </XTButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </main>
    </>
  );
}
