import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

// Basic Button
export const XTButton = styled(Button)(({ theme }) => ({
  // ------------------------ common
  boxShadow: "none",

  "&.MuiButton-startIcon": {
    marginRight: `${theme.typography.pxToRem(6)}`,
  },

  "&:hover": {
    color: "unset",
    backgroundColor: "revert",
    // transition: "none",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
  },

  // ------------------------ contained
  "&.MuiButton-containedPrimary": {
    backgroundColor: `${theme.palette.primary.main}`,
    color: `${theme.palette.white}`,
    fontSize: `${theme.typography.pxToRem(16)}`,
    lineHeight: 1,
  },

  "&.MuiButton-contained.Mui-disabled": {
    backgroundColor: `${theme.palette.disabled}`,
    border: `1px solid ${theme.palette.disabled}`,
    color: `${theme.palette.white}`,
  },

  // ------------------------ outlined
  "&.MuiButton-outlinedPrimary": {
    borderColor: `${theme.palette.primary.main}`,

    "&:not(.btn-datepicker)": {
      "&:hover": {
        background: `${theme.palette.primary.main}`,
        color: theme.palette.white,
      },
    },
  },

  "&.MuiButton-outlinedSecondary": {
    "&:hover": {
      background: `${theme.palette.primary.main}`,
      color: theme.palette.white,
    },
  },

  "&.MuiButton-outlined.Mui-disabled": {
    borderColor: `${theme.palette.disabled}`,
    color: `${theme.palette.disabled}`,
  },

  "&.MuiButton-sizeLarge": {
    // ------------------------ type B
    // minWidth: `${theme.typography.pxToRem(353)}`,
    minHeight: `${theme.typography.pxToRem(53)}`,
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)}`,
    lineHeight: 1.6,
    fontSize: `${theme.typography.pxToRem(16)}`,
    fontWeight: "400",
    borderRadius: 15,

    // ------------------------ type A
    "&.xl": {
      justifyContent: "space-between",
      padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
        30
      )}`,
      minHeight: `${theme.typography.pxToRem(60)}`,
      fontSize: `${theme.typography.pxToRem(20)}`,
      lineHeight: 1,
      fontWeight: "400",
      borderRadius: "15px",

      ".MuiButton-iconSizeLarge": {
        margin: 0,
      },
    },

    "&.info-btn": {
      minWidth: "60px",
      width: "270px",
      justifyContent: "space-between",

      ".name-area": {
        flex: 1,
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",

        ".name": {
          marginLeft: "15px",
          fontWeight: "bold",
        },
      },

      ".icon-area": {
        position: "absolute",
        top: "50%",
        right: "30px",
        transform: "translateY(-50%)",
        width: "18px",
        height: "17px",
      },
    },

    // ------------------------ type A - box
    "&.box": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: `${theme.typography.pxToRem(60)}`,
      minHeight: `${theme.typography.pxToRem(60)}`,
      maxWidth: `${theme.typography.pxToRem(60)}`,
      maxHeight: `${theme.typography.pxToRem(60)}`,
      padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(
        8
      )}  ${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(8)} `,
      lineHeight: 1,
      fontSize: `${theme.typography.pxToRem(10)}`,
      fontWeight: "400",
      borderRadius: 15,
      letterSpacing: "-0.6px",
      transition: `background 0.2s ${theme.transitions.easing.inout2}, filter 0.2s ${theme.transitions.easing.inout2}, color 0.2s ${theme.transitions.easing.inout2}`,

      "&.icon": {
        ".MuiButton-startIcon": {
          margin: 0,
        },
      },

      "&.flex-column": {
        flexDirection: "column",
        justifyContent: "space-between",
      },

      ".MuiButton-startIcon": {
        margin: `0 0 ${theme.typography.pxToRem(2)} 0`,
      },

      "&.MuiButton-containedSecondary": {
        "&:hover": {
          backgroundColor: `${theme.palette.primary.main}`,
          color: `${theme.palette.white}`,
          fontWeight: "Bold",

          "i, img": {
            filter: "invert(100%) brightness(150%);",
          },
        },
      },

      "&.active": {
        backgroundColor: `${theme.palette.primary.main}`,
        color: `${theme.palette.white}`,
        fontWeight: "Bold",

        "i, img": {
          filter: "invert(100%) brightness(150%);",
        },
      },
    },
  },

  // ------------------------ type C
  "&.MuiButton-sizeMedium": {
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(14)}`,
    fontSize: `${theme.typography.pxToRem(14)}`,
    fontWeight: 600,
    lineHeight: 1,
    minWidth: `${theme.typography.pxToRem(54)}`,
    minHeight: `${theme.typography.pxToRem(40)}`,
    borderRadius: "77.8Px",
    background: theme.palette.white,

    "&.Mui-disabled": {
      borderColor: theme.palette.placeholder,
      color: theme.palette.placeholder,
    },

    "&.MuiButton-contained": {
      fontWeight: 400,
      lineHeight: 0.8,

      "&.Mui-disabled": {
        i: {
          filter:
            " invert(86%) sepia(4%) saturate(153%) hue-rotate(47deg) brightness(97%) contrast(88%);",
        },
      },
    },

    "&.btn-excel": {
      "&.Mui-disabled": {
        i: {
          filter: "grayscale(100%);",
        },
      },
    },
  },

  "&.btn-push": {
    "&.active": {
      i: {
        position: "relative",
        "&::before": {
          content: "''",
          position: "absolute",
          display: "block",
          top: -5,
          right: -5,
          width: `${theme.typography.pxToRem(5)};`,
          height: `${theme.typography.pxToRem(5)};`,
          backgroundColor: `${theme.palette.error.main};`,
          borderRadius: "50%",
        },
      },
    },
  },

  // ------------------------ type D
  "&.MuiButton-sizeSmall": {
    padding: `${theme.typography.pxToRem(7)} 10px`,
    minWidth: `${theme.typography.pxToRem(57)};`,
    height: `${theme.typography.pxToRem(29)};`,
    fontSize: `${theme.typography.pxToRem(14)}`,
    lineHeight: `${theme.typography.pxToRem(29)}`,
    borderRadius: 0,
    border: `1px solid ${theme.palette.line1}`,
    backgroundColor: `${theme.palette.white};`,
    color: `${theme.palette.default};`,

    "&.MuiButton-containedPrimary": {
      backgroundColor: `${theme.palette.primary.main}`,
      color: `${theme.palette.white}`,
      borderColor: `${theme.palette.primary.main}`,
    },

    "&.MuiButton-contained.Mui-disabled": {
      backgroundColor: `${theme.palette.disabled}`,
      border: `1px solid ${theme.palette.disabled}`,
      color: `${theme.palette.white}`,
    },
  },

  "&.btn-datepicker": {
    padding: `${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(
      5
    )} ${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(12)}`,
    minWidth: `${theme.typography.pxToRem(150)}`,
    width: `${theme.typography.pxToRem(200)}`,
    justifyContent: "space-between",

    "&.w-100": {
      width: "100%",
    },

    ".datepicker-placeholder": {
      display: "block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      color: `${theme.palette.line1}`,
      fontWeight: 400,
    },

    "&.Mui-disabled": {
      i: {
        filter:
          " invert(86%) sepia(4%) saturate(153%) hue-rotate(47deg) brightness(97%) contrast(88%);",
      },
    },
  },
}));

export const XTTextButton = styled(Button)(({ theme }) => ({
  padding: 0,
  minWidth: "initial",
  fontSize: theme.typography.pxToRem(16),
  lineHeight: theme.typography.pxToRem(26),
  fontWeight: 700,
  backgroundColor: "transparent !important",
  textDecoration: "none",
  // textUnderlinePosition: "under",
  "&:hover": {},
  "&.MuiButton-textPrimary": {
    color: `${theme.palette.primary};`,
  },
  "&.MuiButton-textinfo": {
    color: `${theme.palette.default};`,
  },

  "&.MuiButton-texterror": {
    color: `${theme.palette.error};`,
  },

  "&.gray": {
    color: `${theme.palette.bg66};`,
  },

  "&.Mui-disabled": {
    color: `${theme.palette.disabled};`,
  },

  "&.disabled": {
    color: theme.palette.bg_04,
  },
}));

export const XTTextUnderlineButton = styled(Button)(({ theme }) => ({
  padding: 0,
  minWidth: "initial",
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(20),
  fontWeight: 500,
  borderRadius: 0,
  backgroundColor: "transparent !important",
  color: theme.palette.default,
  textDecoration: "underline",
  textUnderlinePosition: "under",

  "&:hover": {
    textDecoration: "underline",
    textUnderlinePosition: "under",
  },

  "&.MuiButton-textPrimary": {
    color: theme.palette.primary.main,

    "&.Mui-disabled": {
      color: theme.palette.bg66,
    },
  },

  "&.gray": {
    color: theme.palette.bg52,
  },
}));
