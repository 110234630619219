import React, { useMemo } from "react";
import globalCSS from "@/assets/ts/global-css";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Box, Grid, InputLabel, TextField } from "@mui/material";
import { useIndication } from "./_hook/use-indication";
import { XTAutocomplete, XTPopper } from "@/components/mui/xt-autocomplete";
import { IndicationListType } from "@/types/dashboard/indication";
import {
  XTMenuItem,
  XTMenuItemWrap,
  XTSelect,
} from "@/components/mui/xt-select";

// CHART_COLORS constant for chart color scheme
const CHART_COLORS = [
  "#FFE9B3",
  "#FFEFE3",
  "#FFDEE0",
  "#FDCCDC",
  "#DDE9E6",
  "#B1DDE2",
  "#E7E0E6",
  "#EEECE8",
  "#D9E3FB",
  "#C4D5FB",
  "#A1B1CB",
];

const useChartData = (listData: IndicationListType) =>
  useMemo(() => {
    const updatedData = listData.childDiagnosisList
      .map((item, index) => {
        if (index < 10) {
          return item.cnt;
        } else if (index === 10) {
          return listData.childDiagnosisList
            .slice(10)
            .reduce((acc, curr) => acc + curr.cnt, 0);
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);

    return {
      labels: [
        "0개월 ~ 6개월",
        "6개월 ~ 1년",
        "1년 ~ 1년 6개월",
        "1년 6개월 ~ 2년",
        "2년 ~ 2년 6개월",
        "2년 6개월 ~ 3년",
        "3년 ~ 3년 6개월",
        "3년 6개월 ~ 4년",
        "4년 ~ 4년 6개월",
        "4년 6개월 ~ 5년",
        "5년 ~ ",
      ],
      datasets: [
        {
          data: updatedData,
          backgroundColor: CHART_COLORS,
          fill: false,
          borderColor: "white",
          tension: 0.1,
          barThickness: 10,
        },
      ],
    };
  }, [listData]);

export default function Indication() {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
  );
  const { listData, schForm, handleChangeInput } = useIndication();

  const chartData = useChartData(listData);

  const pieOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const barOptions = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        max: 5,
        ticks: {
          beginAtZero: true,
          stepSize: 25,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: "end",
        align: "top",
        formatter: function (value: any) {
          const total = listData.childDiagnosisList.reduce(
            (a, b) => a + b.cnt,
            0
          );
          const percent = ((parseInt(value) / total) * 100).toFixed(0);
          return percent + "%" + "\n" + value + "명";
        },
      },
    },
  };

  return (
    <>
      <main id="sample" css={globalCSS.listContainer} className="h-100">
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>적응증</h2>
            </div>
            <div className="b-sub">
              <span>적응증별 투여기간</span>
            </div>
          </div>
        </div>
        <div className="cont-wrap">
          <div className="search-wrap">
            <table css={globalCSS.formTable}>
              <colgroup>
                <col style={{ width: "200px" }} />
                <col />
                <col style={{ width: "200px" }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputLabel>적응증</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTSelect
                      name="schDgnsTp"
                      displayEmpty
                      value={schForm.schDgnsTp}
                      onChange={handleChangeInput}
                      sx={{
                        minWidth: 300,
                      }}
                      MenuProps={{
                        MenuListProps: { sx: { padding: 0 } },
                        PaperProps: {
                          sx: {
                            ...XTMenuItemWrap,
                          },
                        },
                      }}
                    >
                      {Object.keys(listData.ChildDiagnosisType).map((key) => (
                        <XTMenuItem key={key} value={key}>
                          {listData.ChildDiagnosisType[key]}
                        </XTMenuItem>
                      ))}
                    </XTSelect>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {listData.childDiagnosisList.length > 0 ? (
            <Grid container style={{ marginTop: "200px" }}>
              <Grid item>
                <Pie data={chartData} options={pieOptions} />
              </Grid>
              <Grid item style={{ width: "1200px" }}>
                <Bar
                  data={chartData}
                  options={barOptions as any}
                  plugins={[ChartDataLabels]}
                />
              </Grid>
            </Grid>
          ) : (
            <Box
              sx={{
                marginTop: "30px",
                width: "100%",
                height: "100%",
                fontSize: "14px",
              }}
            >
              결과가 존재하지 않습니다.
            </Box>
          )}
        </div>
      </main>
    </>
  );
}
