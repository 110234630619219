import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import { Checkbox, Grid } from "@mui/material";
import {
  GridColDef,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useProduct, useProductType } from "./_hook/use-product";
import { XTCheckBox } from "@/components/mui/xt-checkbox";
import { useState } from "react";
import ProductRegistDialog from "./_component/product-regist-dialog";
import Stock from "./_component/stock";
import { useRecoilState } from "recoil";
import { alertDialogState } from "@/state";
import StockRegistDialog from "./_component/stock-regist-dialog";
import {
  ProductRegistParamsType,
  StockRegistParamsType,
} from "@/types/product";

export default function Product() {
  const apiRef = useGridApiRef();
  const { productStockTypes } = useProductType();
  const {
    productListData,
    product,
    setProduct,
    regist,
    deleted,
    stockUpdate,
    refetch,
  } = useProduct();

  const [alertDialog, setAlertDialog] = useRecoilState(alertDialogState);
  const [open, setOpen] = useState(false);
  const [openStockDialog, setOpenStockDialog] = useState<{
    open: boolean;
    tp: string;
    stockQty: number;
    stockUpdate: ((qty: string, cont: string) => void) | null;
  }>({
    open: false,
    tp: "",
    stockQty: 0,
    stockUpdate: null,
  });

  const [item, setItem] = useState<{
    seq: string;
    id: number;
    index: number;
    useYn: string;
    nm: string;
    qty: number;
  }>({
    seq: "",
    id: 0,
    index: 0,
    useYn: "Y",
    nm: "",
    qty: 0,
  });

  const columns2: GridColDef[] = [
    {
      field: "checkbox",
      headerName: "",
      width: 50,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,

      renderHeader: () => {
        const filterList = productListData.goodsList
          .filter(
            (item) =>
              item.seq != 1 && item.seq != 2 && item.seq != 3 && item.seq != 4
          )
          .map((item) => item.seq);

        return (
          <XTCheckBox
            label={""}
            checked={filterList.every((seq) => product.includes(seq))}
            control={<Checkbox icon={<span className="checkbox-icon" />} />}
            onClick={(event) => {
              event.stopPropagation();

              if (filterList.every((seq) => product.includes(seq))) {
                setProduct([]);
              } else {
                setProduct(filterList);
              }
            }}
          />
        );
      },
      renderCell: (params) => {
        if (
          params.row.seq != 1 &&
          params.row.seq != 2 &&
          params.row.seq != 3 &&
          params.row.seq != 4
        ) {
          return (
            <XTCheckBox
              label={""}
              checked={product.includes(params.row.seq)}
              control={<Checkbox icon={<span className="checkbox-icon" />} />}
              onClick={(event) => {
                event.stopPropagation();
                if (product.includes(params.row.seq)) {
                  setProduct(product.filter((item) => item !== params.row.seq));
                } else {
                  setProduct([...product, params.row.seq]);
                }
              }}
            />
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      field: "index",
      headerName: "번호",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return productListData!.goodsList.length - params.row.index;
      },
    },
    {
      field: "nm",
      headerName: "물품명",
      headerAlign: "center",
      width: 150,
      flex: 1,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "qty",
      headerName: "재고량",
      headerAlign: "center",
      width: 190,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "a",
      headerName: "입고",
      headerAlign: "center",
      width: 80,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <XTButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setOpenStockDialog({
                open: true,
                tp: "1",
                stockQty: parseInt(params.row.qty),
                stockUpdate: (qty: string, cont: string) => {
                  const value = {
                    ggSeq: params.row.seq,
                    tp: 1,
                    qty: parseInt(qty),
                    cont: cont,
                  };
                  setOpenStockDialog({
                    ...openStockDialog,
                    open: false,
                  });

                  handleRegistStock(value);
                },
              });
            }}
          >
            등록
          </XTButton>
        );
      },
    },
    {
      field: "b",
      headerName: "출고",
      headerAlign: "center",
      width: 80,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <XTButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setOpenStockDialog({
                open: true,
                tp: "2",
                stockQty: parseInt(params.row.qty),
                stockUpdate: (qty: string, cont: string) => {
                  const value = {
                    ggSeq: params.row.seq,
                    tp: 2,
                    qty: -parseInt(qty),
                    cont: cont,
                  };
                  setOpenStockDialog({
                    ...openStockDialog,
                    open: false,
                  });

                  handleRegistStock(value);
                },
              });
            }}
          >
            등록
          </XTButton>
        );
      },
    },
    {
      field: "c",
      headerName: "폐기",
      headerAlign: "center",
      width: 80,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <XTButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setOpenStockDialog({
                open: true,
                tp: "3",
                stockQty: parseInt(params.row.qty),
                stockUpdate: (qty: string, cont: string) => {
                  const value = {
                    ggSeq: params.row.seq,
                    tp: 3,
                    qty: -parseInt(qty),
                    cont: cont,
                  };
                  setOpenStockDialog({
                    ...openStockDialog,
                    open: false,
                  });

                  handleRegistStock(value);
                },
              });
            }}
          >
            등록
          </XTButton>
        );
      },
    },
  ];

  const handleRegistProduct = async (params: ProductRegistParamsType) => {
    try {
      await regist.mutateAsync(params);
      setOpen(false);
      refetch();
      // Handle success
    } catch (error) {
      console.error("Mutation error:", error);
      // Handle error
    }
  };

  const handleDeleteProduct = async () => {
    const params = {
      seqArray: product,
    };
    try {
      await deleted.mutateAsync(params);
      setAlertDialog({
        title: "",
        text: "삭제되었습니다.",
        open: true,
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          refetch();
        },
        handleClose: null,
      });
      // Handle success
    } catch (error) {
      console.error("Mutation error:", error);
      // Handle error
    }
  };

  const handleRegistStock = async (params: StockRegistParamsType) => {
    try {
      await stockUpdate.mutateAsync(params);
      refetch();
      // Handle success
    } catch (error) {
      console.error("Mutation error:", error);
      // Handle error
    }
  };

  return (
    <>
      <main id="sample" css={globalCSS.listContainer}>
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>재고관리</h2>
            </div>
            <div className="b-sub">
              <span>재고관리</span>
            </div>
          </div>
        </div>
        <div className="cont-wrap">
          <div className="grid-wrap">
            <XTDataGrid
              className="clickable"
              columns={columns2}
              rows={productListData.goodsList}
              getRowId={(params) => params.id}
              disableRowSelectionOnClick
              onRowSelectionModelChange={(newSelection: any) => {
                setProduct(newSelection);
              }}
              onCellClick={(params, event) => {
                if (
                  params.field === "a" ||
                  params.field === "b" ||
                  params.field === "c"
                ) {
                  event.stopPropagation(); // 클릭 이벤트 전파 중단
                }
              }}
              apiRef={apiRef}
              experimentalFeatures={{
                ariaV7: true,
              }}
              onRowClick={(params) => {
                setItem(params.row);
              }}
              slots={{
                noRowsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
                noResultsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
              }}
              rowHeight={38}
              columnHeaderHeight={38}
              hideFooter
            />
            <Grid
              container
              justifyContent={"end"}
              spacing={1}
              style={{ marginTop: "10px", marginBottom: "30px" }}
            >
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={() => {
                    if (product.length > 0) {
                      setAlertDialog({
                        open: true,
                        title: "알림",
                        text: "선택하신 물품을 삭제하시겠습니까?",
                        type: "confirm",
                        handleOK: () => {
                          handleDeleteProduct();
                        },
                        handleClose: () => {
                          setAlertDialog({
                            ...alertDialog,
                            open: false,
                          });
                        },
                      });
                    } else {
                      setAlertDialog({
                        open: true,
                        title: "알림",
                        text: "삭제할 물품을 선택해주세요.",
                        type: "complete",
                        handleOK: () => {
                          setAlertDialog({
                            ...alertDialog,
                            open: false,
                          });
                        },
                        handleClose: null,
                      });
                    }
                  }}
                >
                  물품 삭제
                </XTButton>
              </Grid>
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  물품 추가
                </XTButton>
              </Grid>
            </Grid>
          </div>
          {item.seq != "" && <Stock item={item} />}
        </div>

        <ProductRegistDialog
          open={open}
          setOpen={setOpen}
          regist={handleRegistProduct}
        />
        <StockRegistDialog
          open={openStockDialog}
          setOpen={setOpenStockDialog}
          productStockTypes={productStockTypes}
        />
      </main>
    </>
  );
}
