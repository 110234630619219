import React from "react";
import {
  XTDialog,
  XTDialogActions,
  XTDialogContent,
  XTDialogContentText,
  XTDialogTitle,
} from "@/components/mui/xt-dialog";
import { XTTextButton } from "@/components/mui/xt-button";
import { css } from "@mui/material";

import globalCSS from "@/assets/ts/global-css";

export interface AlertDialogProps {
  css?: string;
  className?: string;
  open: boolean;
  text: string;
  type: string;
  handleOK?: (() => void) | null;
  handleClose?: (() => void) | null;
  preventCloseEvent?: boolean;
  disabled?: boolean;
  title?: string;
}

export default function AlertDialog(props: AlertDialogProps) {
  const {
    css = "",
    className = "",
    open,
    text,
    type,
    handleOK,
    handleClose,
    preventCloseEvent = false,
    disabled = false,
    title = "Alert",
  } = props;

  return (
    <XTDialog
      css={[css, style.customDialog]}
      className={className}
      open={open}
      onClose={() => {
        if (preventCloseEvent || disabled) return;

        if (type === "confirm") {
          handleClose && handleClose();
        } else if (type === "complete") {
          handleOK && handleOK();
        }
      }}
    >
      <XTDialogTitle className="flex-title">
        <span>{title}</span>
        <button
          type="button"
          className="close-btn"
          onClick={() => {
            if (type === "confirm") {
              handleClose && handleClose();
            } else if (type === "complete") {
              handleOK && handleOK();
            }
          }}
        >
          <span css={globalCSS.hidden}>닫기</span>
          <i css={globalCSS.icoClose} />
        </button>
      </XTDialogTitle>
      <XTDialogContent>
        <XTDialogContentText
          dangerouslySetInnerHTML={{ __html: text }}
        ></XTDialogContentText>
      </XTDialogContent>
      <XTDialogActions className="flex-end">
        {type === "confirm" && (
          <XTTextButton
            className="gray"
            disabled={disabled}
            onClick={() => {
              handleClose && handleClose();
            }}
          >
            취소
          </XTTextButton>
        )}
        <XTTextButton
          onClick={() => handleOK && handleOK()}
          color="primary"
          disabled={type === "confirm" && disabled}
        >
          확인
        </XTTextButton>
      </XTDialogActions>
    </XTDialog>
  );
}

const style = {
  customDialog: css`
    z-index: 999999 !important;
  `,
};
