import Main from "@/pages";
import Guide from "@/guide/page";
import GuideBadge from "@/guide/page/badge";
import GuideButton from "@/guide/page/button";
import GuideDatePicker from "@/guide/page/datepicker";
import GuideDialog from "@/guide/page/dialog";
import GuideForm from "@/guide/page/form";
import GuideIcon from "@/guide/page/icon";
import GuidePalette from "@/guide/page/palette";
import GuideSample from "@/guide/page/sample";
import GuideText from "@/guide/page/text";
import GuideTypography from "@/guide/page/typography";
import Login from "@/pages/login";
import Menu from "@/pages/system/menu";
import GuideDataGrid from "@/guide/page/datagrid";
import GuideTooltip from "@/guide/page/tooltip";
import Permission from "@/pages/system/permission";
import GuideTree from "@/guide/page/tree";
import Privacy from "@/pages/log/privacy";
import Admin from "@/pages/system/manager";
import AdminDetail from "@/pages/system/manager/detail";
import PrivacyDetail from "@/pages/log/privacy/detail";
import Destruction from "@/pages/log/destruction";
import DestructionDetail from "@/pages/log/destruction/detail";
import Notice from "@/pages/notice";
import NoticeDetail from "@/pages/notice/detail";
import Faq from "@/pages/faq";
import FaqDetail from "@/pages/faq/detail";
import ResetPassWord from "@/pages/reset-password";
import PermissionDetail from "@/pages/system/permission/detail";
import Provision from "@/pages/system/provision";
import ProvisionDetail from "@/pages/system/provision/detail";
import Popup from "@/pages/popup";
import PopupDetail from "@/pages/popup/detail";
import Product from "@/pages/product/index";
import Child from "@/pages/member/child";
import Parent from "@/pages/member/parent";
import Order from "@/pages/order";
import OrderDetail from "@/pages/order/detail";
import Nurse from "@/pages/nurse";
import NurseDetail from "@/pages/nurse/detail";
import Indication from "@/pages/dashboard/indication";
import Height from "@/pages/dashboard/height";

const RoutesData = [
  {
    component: Main,
    path: "",
  },
  {
    component: Login,
    path: "/login",
  },
  {
    component: ResetPassWord,
    path: "/temp-password-login",
  },
  // Guide
  {
    component: Guide,
    path: "/guide",
  },
  {
    component: GuideButton,
    path: "/guide/button",
  },
  {
    component: GuideDatePicker,
    path: "/guide/datepicker",
  },
  {
    component: GuideText,
    path: "/guide/text",
  },
  {
    component: GuideForm,
    path: "/guide/form",
  },
  {
    component: GuideIcon,
    path: "/guide/icon",
  },
  {
    component: GuideBadge,
    path: "/guide/badge",
  },
  {
    component: GuideDialog,
    path: "/guide/dialog",
  },
  {
    component: GuideDataGrid,
    path: "/guide/datagrid",
  },
  {
    component: GuidePalette,
    path: "/guide/palette",
  },
  {
    component: Menu,
    path: "/system/menu",
  },
  {
    component: GuideTypography,
    path: "/guide/typography",
  },
  {
    component: GuideTooltip,
    path: "/guide/tooltip",
  },
  {
    component: GuideTree,
    path: "/guide/tree",
  },
  {
    component: GuideSample,
    path: "/sample",
  },
  {
    component: Privacy,
    path: "/log/privacy",
  },
  {
    component: PrivacyDetail,
    path: "/log/privacy/detail/:seq",
  },
  {
    component: Destruction,
    path: "/log/destruction",
  },
  {
    component: DestructionDetail,
    path: "/log/destruction/detail/:seq",
  },
  {
    component: Admin,
    path: "/system/manager",
  },
  {
    component: AdminDetail,
    path: "/system/manager/detail",
  },
  {
    component: AdminDetail,
    path: "/system/manager/detail/:seq",
  },
  {
    component: Permission,
    path: "/system/permission",
  },
  {
    component: PermissionDetail,
    path: "/system/permission/detail",
  },
  {
    component: PermissionDetail,
    path: "/system/permission/detail/:seq",
  },
  {
    component: Provision,
    path: "/system/provision",
  },
  {
    component: ProvisionDetail,
    path: "/system/provision/detail",
  },
  {
    component: ProvisionDetail,
    path: "/system/provision/detail/:seq",
  },
  {
    component: Notice,
    path: "/notice",
  },
  {
    component: NoticeDetail,
    path: "/notice/detail",
  },
  {
    component: NoticeDetail,
    path: "/notice/detail/:seq",
  },
  {
    component: Faq,
    path: "/faq",
  },
  {
    component: FaqDetail,
    path: "/faq/detail",
  },
  {
    component: FaqDetail,
    path: "/faq/detail/:seq",
  },
  {
    component: Popup,
    path: "/popup",
  },
  {
    component: PopupDetail,
    path: "/popup/detail",
  },
  {
    component: PopupDetail,
    path: "/popup/detail/:seq",
  },
  {
    component: Product,
    path: "/product",
  },
  {
    component: Parent,
    path: "/member/parent",
  },
  {
    component: Child,
    path: "/member/child",
  },
  {
    component: Order,
    path: "/order"
  },
  {
    component: OrderDetail,
    path: "/order/detail"
  },
  {
    component: OrderDetail,
    path: "/order/detail/:seq",
  },
  {
    component: Nurse,
    path: "/nurse"
  },
  {
    component: NurseDetail,
    path: "/nurse/detail"
  },
  {
    component: NurseDetail,
    path: "/nurse/detail/:seq"
  },
  {
    component: Indication,
    path: "/dashboard/indication"
  },
  {
    component: Height,
    path: "/dashboard/height"
  }
];
export default RoutesData;
