import globalCSS from "@/assets/ts/global-css";
import guideCSS from "@/assets/ts/guide-css";
import GuideLeft from "@/guide/components/sidebar";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  IconButton,
  FormControl,
  TextField,
  Box,
  Checkbox,
  Radio,
  css,
  InputAdornment,
} from "@mui/material";
import { html } from "@codemirror/lang-html";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import {
  XTMenuItem,
  XTMenuItemWrap,
  XTSelect,
} from "@/components/mui/xt-select";
import { XTCheckBox, XTCheckBoxGroup } from "@/components/mui/xt-checkbox";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import {
  XTFormControl,
  XTFormHelperText,
  XTInput,
  XTInputLabel,
  XTTextarea,
} from "@/components/mui/xt-input";
import _, { debounce } from "lodash";
import XTEmailInput from "@/components/ui/xt-email-input";
import { XTButton } from "@/components/mui/xt-button";
import { XTAutocomplete, XTPopper } from "@/components/mui/xt-autocomplete";
import {
  XTFIleUploadArea,
  XTFileDownload,
  XTFileUpload,
  XTFileUploadButton,
} from "@/components/mui/xt-fileform";
import { MenuUpdateFormType } from "@/types/system/menu";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export default function GuideForm() {
  const [codemirror, setCodemirror] = useState<string | false>();
  const [email, setEmail] = useState("");
  const handleCodemirror =
    (panel: string) =>
      (event: React.SyntheticEvent, newcodemirror: boolean) => {
        setCodemirror(newcodemirror ? panel : false);
      };
  const [checked, setChecked] = useState(true);
  const [checked2, setChecked2] = useState(false);
  const [labelChecked, setLabelChecked] = useState(false);

  const schema = yup.object().shape({
    menuNm: yup.string().trim().required("메뉴명을 입력해주세요."),
    flag: yup.string(),
    dupMenuCd: yup.mixed().when("flag", {
      is: "I",
      then: yup
        .bool()
        .required()
        .oneOf([true], "메뉴코드 중복 확인이 필요합니다."),
      otherwise: yup.bool(),
    }),
  });

  const {
    trigger,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    reset,
    formState: { errors, submitCount, isDirty },
  } = useForm<MenuUpdateFormType>({
    shouldFocusError: false,
    resolver: yupResolver(schema),
    defaultValues: {
      menuCd: "",
      upperMenuCd: null,
      depth: 0,
      menuNm: "",
      menuIconPath: null,
      menuIconPathObjList: [
        {
          filePath: "",
          fileName: "",
          ext: "",
          fileSize: 0,
        },
      ],
      menuIconPathObj: {
        filePath: "",
        fileName: "",
        ext: "",
        fileSize: 0,
      },
      ord: 0,
      readAuthYn: "N",
      createAuthYn: "N",
      saveAuthYn: "N",
      deleteAuthYn: "N",
      printAuthYn: "N",
      excelAuthYn: "N",
      showYn: "N",
      flag: "",
      dupMenuCd: false,
    },
  });

  // 다운로드 파일
  const fileRoute: string = "/images/icon/ico-delete.svg";

  // 다운로드 할 때 파일명
  const fileName: string = "이미지 파일";

  // 텍스트
  const fileTitle: string = "이미지 파일";

  // 확장자
  const fileExt: string = ".jpg";

  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");
  const [input6, setInput6] = useState("");
  const [input7, setInput7] = useState("");
  const [input8, setInput8] = useState("");
  const [textarea, setTextarea] = useState("");
  const [textarea2, setTextarea2] = useState("");
  const [inputLength, setInputLength] = useState("");

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleInputLength = debounce((e) => {
    setInputLength(e.target.value);
  });

  const handleTextareaLength = debounce((e) => {
    setTextarea2(e.target.value);
  });

  const maxInputLength: number = 50;
  const maxTextareaLength: number = 2000;

  const list1 = [
    {
      label: "강아지",
    },
    {
      label: "고양이",
    },
    {
      label: "햄스터",
    },
    {
      label: "고슴도치",
    },
    {
      label: "라쿤",
    },
  ];
  const list2 = [
    {
      label: "강아지",
      desc: "말티즈",
    },
    {
      label: "고양이",
      desc: "삼색이",
    },
    {
      label: "햄스터",
      desc: "골든",
    },
    {
      label: "고슴도치",
      desc: "선인장",
    },
    {
      label: "라쿤",
      desc: "로켓",
    },
  ];

  return (
    <>
      <div className="guide-area" css={guideCSS.guideWrap}>
        <GuideLeft />
        <div css={guideCSS.guideWrapper}>
          <div className="title-box">
            <h2 className="gude-h2">Form</h2>
          </div>
          <div className="guide-content">
            {/* Select */}
            <div>
              <h3 className="guide-h3">Select</h3>
              <div className="content">
                <XTSelect
                  defaultValue={"placeholder"}
                  MenuProps={{
                    MenuListProps: { sx: { padding: 0 } },
                    PaperProps: {
                      sx: {
                        ...XTMenuItemWrap,
                      },
                    },
                  }}
                >
                  <XTMenuItem value="placeholder" selected disabled hidden>
                    <span className="placeholder">선택</span>
                  </XTMenuItem>

                  <XTMenuItem value={0}>리스트 1</XTMenuItem>
                  <XTMenuItem value={1}>리스트 2</XTMenuItem>
                  <XTMenuItem value={2}>리스트 3</XTMenuItem>
                  <XTMenuItem value={3}>리스트 4</XTMenuItem>
                </XTSelect>
                <br />
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "selectbox"}
                  onChange={handleCodemirror("selectbox")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTMenuItem, XTMenuItemWrap, XTSelect } from "components/mui/xt-select";
import guideCSS from "../../assets/ts/guide-css";

<XTSelect
  defaultValue={"placeholder"}
  MenuProps={{
    MenuListProps: { sx: { padding: 0 } },
    PaperProps: {
      sx: {
        ...XTMenuItemWrap,
      },
    },
  }}
>
  <XTMenuItem value="placeholder" selected disabled hidden>
    <span className="placeholder">방문 경로를 선택해 주세요.</span>
  </XTMenuItem>

  <XTMenuItem value={0}>검색</XTMenuItem>
  <XTMenuItem value={1}>소셜네트워크(SNS)</XTMenuItem>
  <XTMenuItem value={2}>보도자료</XTMenuItem>
  <XTMenuItem value={3}>컨퍼런스</XTMenuItem>
</XTSelect>
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <hr className="guide-hr" />
            <div>
              <h3 className="guide-h3">Select - Full Width</h3>
              <div className="content">
                <XTSelect
                  defaultValue={"placeholder"}
                  fullWidth
                  MenuProps={{
                    MenuListProps: { sx: { padding: 0 } },
                    PaperProps: {
                      sx: {
                        ...XTMenuItemWrap,
                      },
                    },
                  }}
                >
                  <XTMenuItem value="placeholder" selected disabled hidden>
                    <span className="placeholder">선택</span>
                  </XTMenuItem>

                  <XTMenuItem value={0}>리스트 1</XTMenuItem>
                  <XTMenuItem value={1}>리스트 2</XTMenuItem>
                  <XTMenuItem value={2}>리스트 3</XTMenuItem>
                  <XTMenuItem value={3}>리스트 4</XTMenuItem>
                </XTSelect>
                <br />
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "selectbox"}
                  onChange={handleCodemirror("selectbox")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTMenuItem, XTMenuItemWrap, XTSelect } from "components/mui/xt-select";
import guideCSS from "../../assets/ts/guide-css";

<XTSelect
  defaultValue={"placeholder"}
  fullWidth
  MenuProps={{
    MenuListProps: { sx: { padding: 0 } },
    PaperProps: {
      sx: {
        ...XTMenuItemWrap,
      },
    },
  }}
>
  <XTMenuItem value="placeholder" selected disabled hidden>
    <span className="placeholder">방문 경로를 선택해 주세요.</span>
  </XTMenuItem>

  <XTMenuItem value={0}>검색</XTMenuItem>
  <XTMenuItem value={1}>소셜네트워크(SNS)</XTMenuItem>
  <XTMenuItem value={2}>보도자료</XTMenuItem>
  <XTMenuItem value={3}>컨퍼런스</XTMenuItem>
</XTSelect>
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <hr className="guide-hr" />

            {/* Select - disabeld */}
            <div>
              <h3 className="guide-h3">Select - disabeld</h3>
              <div className="content">
                <FormControl>
                  <XTSelect
                    disabled
                    defaultValue={"placeholder"}
                    MenuProps={{
                      MenuListProps: { sx: { padding: 0 } },
                      PaperProps: {
                        sx: {
                          ...XTMenuItemWrap,
                        },
                      },
                    }}
                  >
                    <XTMenuItem value="placeholder" selected disabled hidden>
                      <span className="placeholder">선택</span>
                    </XTMenuItem>

                    <XTMenuItem value={0}>리스트 1</XTMenuItem>
                    <XTMenuItem value={1}>리스트 2</XTMenuItem>
                    <XTMenuItem value={2}>리스트 3</XTMenuItem>
                    <XTMenuItem value={3}>리스트 4</XTMenuItem>
                  </XTSelect>
                </FormControl>
                <br />
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "selectbox-disabled"}
                  onChange={handleCodemirror("selectbox-disabled")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTMenuItem, XTMenuItemWrap, XTSelect } from "components/mui/xt-select";
import guideCSS from "../../assets/ts/guide-css";

<XTSelect
  disabled
  defaultValue={"placeholder"}
  MenuProps={{
    MenuListProps: { sx: { padding: 0 } },
    PaperProps: {
      sx: {
        ...XTMenuItemWrap,
      },
    },
  }}
>
  <XTMenuItem value="placeholder" selected disabled hidden>
    <span className="placeholder">방문 경로를 선택해 주세요.</span>
  </XTMenuItem>

  <XTMenuItem value={0}>검색</XTMenuItem>
  <XTMenuItem value={1}>소셜네트워크(SNS)</XTMenuItem>
  <XTMenuItem value={2}>보도자료</XTMenuItem>
  <XTMenuItem value={3}>컨퍼런스</XTMenuItem>
</XTSelect>
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <hr className="guide-hr" />
            {/* Checkbox */}
            <div>
              <h3 className="guide-h3">Checkbox - row</h3>
              <div className="content">
                <Box>
                  <XTCheckBoxGroup row>
                    <XTCheckBox
                      control={
                        <Checkbox
                          icon={<span className="checkbox-icon" />}
                          checked={checked}
                          onChange={() => setChecked((prev) => !prev)}
                          onKeyUp={(e) =>
                            e.key == "Enter" && setChecked((prev) => !prev)
                          }
                        />
                      }
                      label={"체크박스1"}
                    />
                    <XTCheckBox
                      control={
                        <Checkbox
                          checked={checked2}
                          onChange={() => setChecked2((prev) => !prev)}
                          onKeyUp={(e) =>
                            e.key == "Enter" && setChecked2((prev) => !prev)
                          }
                        />
                      }
                      label={"체크박스2"}
                    />
                    <XTCheckBox
                      control={<Checkbox disabled />}
                      label={"체크박스3"}
                    />
                    <XTCheckBox
                      control={<Checkbox defaultChecked disabled />}
                      label={"체크박스4"}
                    />
                  </XTCheckBoxGroup>
                </Box>
                <br />
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "checkbox"}
                  onChange={handleCodemirror("checkbox")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTCheckBox, XTCheckBoxGroup } from "@/components/mui/xt-input";


// default Check
const [checked, setChecked] = useState(true);

const [checked, setChecked] = useState(false);

// default checked
<XTCheckBoxGroup row>
  <XTCheckBox
    control={
      <Checkbox
        icon={<span className="checkbox-icon" />}
        checked={checked}
        onChange={() => setChecked((prev) => !prev)}
        onKeyUp={(e) =>
          e.key == "Enter" && setChecked((prev) => !prev)
        }
      />
    }
    label={"체크박스1"}
  />
  <XTCheckBox
    control={
      <Checkbox
        checked={checked2}
        onChange={() => setChecked2((prev) => !prev)}
        onKeyUp={(e) =>
          e.key == "Enter" && setChecked2((prev) => !prev)
        }
      />
    }
    label={"체크박스2"}
  />
  <XTCheckBox
    control={<Checkbox disabled />}
    label={"체크박스3"}
  />
  <XTCheckBox
    control={<Checkbox defaultChecked disabled />}
    label={"체크박스4"}
  />
</XTCheckBoxGroup>
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <div
              style={{
                marginTop: "30px",
              }}
            >
              <h3 className="guide-h3">Checkbox - Column</h3>
              <div className="content">
                <Box>
                  <XTCheckBoxGroup>
                    <XTCheckBox
                      control={
                        <Checkbox
                          icon={<span className="checkbox-icon" />}
                          checked={checked}
                          onChange={() => setChecked((prev) => !prev)}
                          onKeyUp={(e) =>
                            e.key == "Enter" && setChecked((prev) => !prev)
                          }
                        />
                      }
                      label={"체크박스1"}
                    />
                    <XTCheckBox
                      control={
                        <Checkbox
                          checked={checked2}
                          onChange={() => setChecked2((prev) => !prev)}
                          onKeyUp={(e) =>
                            e.key == "Enter" && setChecked2((prev) => !prev)
                          }
                        />
                      }
                      label={"체크박스2"}
                    />
                    <XTCheckBox
                      control={<Checkbox disabled />}
                      label={"체크박스3"}
                    />
                    <XTCheckBox
                      control={<Checkbox defaultChecked disabled />}
                      label={"체크박스4"}
                    />
                  </XTCheckBoxGroup>
                </Box>
                <br />
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "checkbox"}
                  onChange={handleCodemirror("checkbox")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTCheckBox, XTCheckBoxGroup } from "@/components/mui/xt-input";


// default Check
const [checked, setChecked] = useState(true);

const [checked, setChecked] = useState(false);

// default checked
<XTCheckBoxGroup>
  <XTCheckBox
    control={
      <Checkbox
        icon={<span className="checkbox-icon" />}
        checked={checked}
        onChange={() => setChecked((prev) => !prev)}
        onKeyUp={(e) =>
          e.key == "Enter" && setChecked((prev) => !prev)
        }
      />
    }
    label={"체크박스1"}
  />
  <XTCheckBox
    control={
      <Checkbox
        checked={checked2}
        onChange={() => setChecked2((prev) => !prev)}
        onKeyUp={(e) =>
          e.key == "Enter" && setChecked2((prev) => !prev)
        }
      />
    }
    label={"체크박스2"}
  />
  <XTCheckBox
    control={<Checkbox disabled />}
    label={"체크박스3"}
  />
  <XTCheckBox
    control={<Checkbox defaultChecked disabled />}
    label={"체크박스4"}
  />
</XTCheckBoxGroup>
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <hr className="guide-hr" />
            {/* Radio */}
            <div>
              <h3 className="guide-h3">Radio - Row</h3>
              <div className="content">
                <Box>
                  <FormControl sx={{ display: "flex", flexDirection: "row" }}>
                    <div id="radio-guide">
                      <XTRadioGroup
                        row
                        aria-labelledby="radio-guide"
                        defaultValue="radio1"
                        name="radio-buttons-group"
                      >
                        <XTRadio
                          value="radio1"
                          control={<Radio />}
                          label={"라디오1"}
                        />
                        <XTRadio
                          value="radio2"
                          control={<Radio />}
                          label={"라디오2"}
                        />
                        <XTRadio
                          value="radio3"
                          control={<Radio />}
                          disabled
                          label={"라디오3"}
                        />
                      </XTRadioGroup>
                    </div>
                  </FormControl>
                </Box>

                <br />
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "radio-basic"}
                  onChange={handleCodemirror("radio-basic")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTRadio } from "@/components/mui/xt-radio";


<FormControl>
  <div id="radio-guide">
      <XTRadioGroup
      row
      aria-labelledby="radio-guide"
      defaultValue="radio1"
      name=""
    >

      <XTRadio value="radio1" control={<Radio />} />

      //disabled 

      <XTRadio value="radio2" control={<Radio />} disabled />
    </XTRadioGroup>
  </div>
</FormControl>
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <div
              style={{
                marginTop: "30px",
              }}
            >
              <h3 className="guide-h3">Radio - Column</h3>
              <div className="content">
                <Box>
                  <FormControl sx={{ display: "flex", flexDirection: "row" }}>
                    <div id="radio-guide">
                      <XTRadioGroup
                        aria-labelledby="radio-guide"
                        defaultValue="radio1"
                        name="radio-buttons-group"
                      >
                        <XTRadio
                          value="radio1"
                          control={<Radio />}
                          label={"라디오1"}
                        />
                        <XTRadio
                          value="radio2"
                          control={<Radio />}
                          label={"라디오2"}
                        />
                        <XTRadio
                          value="radio3"
                          control={<Radio />}
                          disabled
                          label={"라디오3"}
                        />
                      </XTRadioGroup>
                    </div>
                  </FormControl>
                </Box>

                <br />
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "radio-basic"}
                  onChange={handleCodemirror("radio-basic")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTRadio } from "@/components/mui/xt-radio";


<FormControl>
  <div id="radio-guide">
      <XTRadioGroup
      row
      aria-labelledby="radio-guide"
      defaultValue="radio1"
      name=""
    >

      <XTRadio value="radio1" control={<Radio />} />

      //disabled 

      <XTRadio value="radio2" control={<Radio />} disabled />
    </XTRadioGroup>
  </div>
</FormControl>
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <hr className="guide-hr" />
            <div
              style={{
                marginTop: "30px",
              }}
            >
              <h3 className="guide-h3">Radio - Date Radio</h3>
              <div className="content">
                <Box>
                  <FormControl sx={{ display: "flex", flexDirection: "row" }}>
                    <div id="radio-guide">
                      <XTRadioGroup
                        defaultValue=""
                        name="radio-buttons-group"
                        className="date-radio-group"
                        row
                      >
                        <XTRadio value="" control={<Radio />} label={"전체"} />
                        <XTRadio value="0" control={<Radio />} label={"오늘"} />
                        <XTRadio value="1" control={<Radio />} label={"어제"} />
                        <XTRadio
                          value="2"
                          control={<Radio />}
                          label={"1주일"}
                        />
                        <XTRadio
                          value="3"
                          control={<Radio />}
                          label={"1개월"}
                        />
                        <XTRadio value="4" control={<Radio />} label={"1년"} />
                      </XTRadioGroup>
                    </div>
                  </FormControl>
                </Box>

                <br />
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "radio-basic"}
                  onChange={handleCodemirror("radio-basic")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";


<XTRadioGroup
  defaultValue=""
  name="radio-buttons-group"
  className="date-radio-group"
  row
>
  <XTRadio value="" control={<Radio />} label={"전체"} />
  <XTRadio value="0" control={<Radio />} label={"오늘"} />
  <XTRadio value="1" control={<Radio />} label={"어제"} />
  <XTRadio
    value="2"
    control={<Radio />}
    label={"1주일"}
  />
  <XTRadio
    value="3"
    control={<Radio />}
    label={"1개월"}
  />
  <XTRadio value="4" control={<Radio />} label={"1년"} />
</XTRadioGroup>
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <hr className="guide-hr" />
            {/* Input */}
            <div>
              <h3 className="guide-h3">Input</h3>
              {/* 기본 Input */}
              <div>
                <p css={style.title}>기본 Input</p>
                <div
                  className="content"
                  css={{ display: "flex", flexDirection: "column" }}
                >
                  <div>
                    <XTInput placeholder="검색어를 입력해주세요." type="text" />
                  </div>
                  <br />
                  <Accordion
                    sx={{
                      margin: 0,
                      p: 0,
                      border: "none",
                      outline: "none",
                      boxShadow: "none",
                      "&::before": {
                        position: "relative",
                      },
                    }}
                    expanded={codemirror === "basic-input"}
                    onChange={handleCodemirror("basic-input")}
                    className="accordion-codemirror"
                  >
                    <AccordionSummary>
                      <p className="guide-codeMore">코드보기</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CodeMirror
                        theme={vscodeDark}
                        extensions={[html()]}
                        value={`
import { XTFormControl, XTInput } from "components/mui/xt-select";

<XTFormControl>
  <XTInput placeholder="검색어를 입력해주세요." type="text" />
</XTFormControl>
                `}
                      ></CodeMirror>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              <br />
              <br />
              <div>
                <p css={style.title}>Full Width</p>
                <div
                  className="content"
                  css={{ display: "flex", flexDirection: "column" }}
                >
                  <div>
                    <XTInput
                      placeholder="검색어를 입력해주세요."
                      fullWidth
                      type="text"
                    />
                  </div>
                  <br />
                  <Accordion
                    sx={{
                      margin: 0,
                      p: 0,
                      border: "none",
                      outline: "none",
                      boxShadow: "none",
                      "&::before": {
                        position: "relative",
                      },
                    }}
                    expanded={codemirror === "basic-input"}
                    onChange={handleCodemirror("basic-input")}
                    className="accordion-codemirror"
                  >
                    <AccordionSummary>
                      <p className="guide-codeMore">코드보기</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CodeMirror
                        theme={vscodeDark}
                        extensions={[html()]}
                        value={`
import { XTFormControl, XTInput } from "components/mui/xt-select";

<XTFormControl>
  <XTInput placeholder="검색어를 입력해주세요." fullWidth type="text" />
</XTFormControl>
                `}
                      ></CodeMirror>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              <br />
              {/* 글자 수 체크 input */}
              <div className="input-delete-btn">
                <p css={style.title}>Basic Input - 글자 수 제한</p>
                <div>
                  <XTInput
                    placeholder="Placeholder"
                    type="text"
                    onChange={(e) => {
                      handleInputLength(e);
                    }}
                    inputProps={{ maxLength: 50 }}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="count" css={globalCSS.inputLength}>
                          0&nbsp;/&nbsp;50
                        </span>
                      </InputAdornment>
                    }
                  />
                </div>

                {/* <XTFormControl>
                  
                </XTFormControl> */}
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "basic-input-length"}
                  onChange={handleCodemirror("basic-input-length")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTFormControl, XTInput } from "components/mui/xt-select";
import globalCSS from "@/assets/ts/global-css";


  <XTInput
    placeholder="Placeholder"
    type="text"
    inputProps={{ maxLength: maxInputLength }}
    endAdornment={
        <InputAdornment position="end">
          <span className="count" css={globalCSS.inputLength}>
            0&nbsp;/&nbsp;50
          </span>
        </InputAdornment>
    }

  />
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
              <br />
              {/* Delete 버튼 활성화 input */}
              <div className="input-delete-btn">
                <p css={style.title}>
                  Input Value가 있을 시 Delete 버튼 활성화
                </p>
                <XTInput
                  placeholder="Placeholder"
                  type="text"
                  value={input2}
                  onChange={(e) => {
                    setInput2(e.target.value);
                  }}
                  endAdornment={
                    <>
                      {input2 ? (
                        <IconButton
                          onClick={(e) => {
                            setInput2("");
                          }}
                        >
                          <span css={globalCSS.hidden}>
                            클릭 시 입력 내용이 지워집니다
                          </span>
                          <i css={globalCSS.icoDelete} />
                        </IconButton>
                      ) : null}
                    </>
                  }
                />
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "basic-input-click"}
                  onChange={handleCodemirror("basic-input-click")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTFormControl, XTInput } from "components/mui/xt-select";
import globalCSS from "@/assets/ts/global-css";
const [value, setValue] = useState("");

<XTFormControl>
  <XTInput
    placeholder="Placeholder"
    type="text"
    value={value}
    onChange={(e) => setValue(e.target.value)}
    endAdornment={
      <>
        {value ? (
          <IconButton onClick={() => setValue("")}>
            <span css={globalCSS.hidden}>
              클릭 시 입력 내용이 지워집니다
            </span>
            <i css={globalCSS.icoDelete} />
          </IconButton>
        ) : null}
      </>
    }
  />
</XTFormControl>
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
              <br />
              <br />
              {/* Search 아이콘 */}
              <div className="input-search">
                <p css={style.title}>Search 아이콘, 입력 시 내용 삭제</p>
                <XTInput
                  type="text"
                  value={input3}
                  onChange={(e) => setInput3(e.target.value)}
                  placeholder="검색 아이콘이 있는 Input"
                  endAdornment={
                    <>
                      {input3 ? (
                        <IconButton onClick={() => setInput3("")}>
                          <span css={globalCSS.hidden}>
                            클릭 시 입력 내용이 삭제됩니다
                          </span>
                          <i css={globalCSS.icoDelete} />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <i css={globalCSS.icoSearch} />
                        </IconButton>
                      )}
                    </>
                  }
                />
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "basic-input-search"}
                  onChange={handleCodemirror("basic-input-search")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTFormControl, XTInput } from "components/mui/xt-select";
import globalCSS from "@/assets/ts/global-css";
const [value, setValue] = useState("");

<XTFormControl>
  <XTInput
    type="text"
    value={value}
    onChange={(e) => setValue(e.target.value)}
    placeholder="검색 아이콘이 있는 Input"
    endAdornment={
      <>
        {value ? (
          <IconButton onClick={() => setValue("")}>
            <span css={globalCSS.hidden}>
              클릭 시 입력 내용이 삭제됩니다
            </span>
            <i css={globalCSS.icoDelete} />
          </IconButton>
        ) : (
          <IconButton>
            <i css={globalCSS.icoSearch} />
          </IconButton>
        )}
      </>
    }
  />
</XTFormControl>
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
                <br />
              </div>
              <br />
              {/* auto select */}
              <div className="auto-select">
                <p css={style.title}>Email Input</p>
                <XTEmailInput
                  value={email}
                  disabled={false}
                  placeholder="이메일"
                  error={false}
                  onChange={(value: any) => {
                    setEmail(value);
                  }}
                  endAdornment={
                    <>
                      <IconButton disabled>
                        <i css={globalCSS.icoCheck} />
                      </IconButton>
                    </>
                  }
                />
              </div>
              <br />
              <Accordion
                sx={{
                  margin: 0,
                  p: 0,
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  "&::before": {
                    position: "relative",
                  },
                }}
                expanded={codemirror === "emailautoselect"}
                onChange={handleCodemirror("emailautoselect")}
                className="accordion-codemirror"
              >
                <AccordionSummary>
                  <p className="guide-codeMore">코드보기</p>
                </AccordionSummary>
                <AccordionDetails>
                  <CodeMirror
                    theme={vscodeDark}
                    extensions={[html()]}
                    value={`
import XTEmailInput from "@/components/ui/xt-email-input";

<XTEmailInput
  value={""}
  disabled={false}
  placeholder="이메일"
  error={false}
  onChange={(value: any) => {}}
  endAdornment={<> </> }
/>
                `}
                  ></CodeMirror>
                </AccordionDetails>
              </Accordion>
              <br />
              <br />
              {/* disabled input */}
              <div className="input-disabled">
                <p css={style.title}>기본 Input - Disabled</p>
                <XTInput disabled placeholder="Disabled Input" type="text" />
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "basic-input-disabled"}
                  onChange={handleCodemirror("basic-input-disabled")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTFormControl, XTInput } from "components/mui/xt-select";

<XTFormControl>
  <XTInput disabled placeholder="Disabled Input" type="text" />
</XTFormControl>
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
              <br />
              <br />
              {/* disabled input */}
              <div className="input-disabled">
                <p css={style.title}>Input - className="custom-round"</p>
                <XTInput
                  className="custom-round"
                  placeholder="Rounded Input"
                  type="text"
                />
                <br />
                <br />
                <XTInput
                  className="custom-round"
                  placeholder="Rounded Input"
                  type={show ? "text" : "password"}
                  endAdornment={
                    <>
                      {show ? (
                        <IconButton onClick={() => setShow((prev) => !prev)}>
                          <span css={globalCSS.hidden}>비밀번호 보이기</span>
                          <i css={globalCSS.icoHide} />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setShow((prev) => !prev)}>
                          <span css={globalCSS.hidden}>비밀번호 숨기기</span>
                          <i css={globalCSS.icoShow} />
                        </IconButton>
                      )}
                    </>
                  }
                />
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "basic-input-disabled"}
                  onChange={handleCodemirror("basic-input-disabled")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTFormControl, XTInput } from "components/mui/xt-select";

<XTInput
  className="custom-round"
  placeholder="Rounded Input"
  type="text"
/>
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <hr className="guide-hr" />
            {/* Password */}
            <div>
              <h3 className="guide-h3">Password</h3>
              <div className="content">
                <div>
                  <XTInput
                    placeholder="비밀번호를 입력해 주세요"
                    type={show ? "text" : "password"}
                    endAdornment={
                      <>
                        {show ? (
                          <IconButton onClick={() => setShow((prev) => !prev)}>
                            <span css={globalCSS.hidden}>비밀번호 보이기</span>
                            <i css={globalCSS.icoHide} />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => setShow((prev) => !prev)}>
                            <span css={globalCSS.hidden}>비밀번호 숨기기</span>
                            <i css={globalCSS.icoShow} />
                          </IconButton>
                        )}
                      </>
                    }
                  />
                  <br />
                  <br />
                  <Accordion
                    sx={{
                      margin: 0,
                      p: 0,
                      border: "none",
                      outline: "none",
                      boxShadow: "none",
                      "&::before": {
                        position: "relative",
                      },
                    }}
                    expanded={codemirror === "password"}
                    onChange={handleCodemirror("password")}
                    className="accordion-codemirror"
                  >
                    <AccordionSummary>
                      <p className="guide-codeMore">코드보기</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CodeMirror
                        theme={vscodeDark}
                        extensions={[html()]}
                        value={`
import { XTFormControl, XTInput, XTInputLabel } from "components/mui/xt-select";
import globalCSS from "@/assets/ts/global-css";

// input 값
const [pwd, setPwd] = useState("");

// 클릭 시 input의 type 변경
const [show, setShow] = useState(false);

<XTFormControl>
  <XTInput
    placeholder="비밀번호를 입력해 주세요"
    value={pwd}
    type={show ? "text" : "password"}
    endAdornment={
      <>
        {show ? (
          <IconButton onClick={() => setShow((prev) => !prev)}>
            <span css={globalCSS.hidden}>비밀번호 보이기</span>
            <i css={globalCSS.icoHide} />
          </IconButton>
        ) : (
          <IconButton onClick={() => setShow((prev) => !prev)}>
            <span css={globalCSS.hidden}>비밀번호 숨기기</span>
            <i css={globalCSS.icoShow} />
          </IconButton>
        )}
      </>
    }
  ></XTInput>
</XTFormControl>
                `}
                      ></CodeMirror>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
            <hr className="guide-hr" />
            {/* Label + Input */}
            <div>
              <h3 className="guide-h3">Label + Input</h3>
              <Box className="guide-box">
                Label이 Input보다 아래에 위치해 있으므로 Input에
                aria-describedy와 Label의 id를 같은 값으로 통일시켜 주시기
                바랍니다.
                <br />
                (예) &#60;XTInput aria-describedby="msg"&#62; ...
                &#60;XTInputLabel id="msg"&#62;
                <br />
                <br />
                Input의 ID값과 Label의 htmlfor값은 동일하게 적어 주세요.
              </Box>
              <br />
              <div>
                <XTFormControl>
                  <XTInput
                    name="name"
                    id="message"
                    placeholder="Basic Input - Label"
                    onChange={(e) => setInput5(e)}
                    type="text"
                    aria-describedby="msg"
                    endAdornment={
                      <>
                        {input5 ? (
                          <IconButton onClick={() => setInput5("")}>
                            <span css={globalCSS.hidden}>내용 지우기</span>
                            <i css={globalCSS.icoDelete} />
                          </IconButton>
                        ) : null}
                      </>
                    }
                  ></XTInput>
                  <XTInputLabel htmlFor="message" shrink id="msg">
                    메시지를 입력해 주세요.
                  </XTInputLabel>
                </XTFormControl>
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "basic-input-label"}
                  onChange={handleCodemirror("basic-input-label")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTFormControl, XTInput, XTInputLabel } from "components/mui/xt-select";
import globalCSS from "@/assets/ts/global-css";

const [value, setValue] = useState(null)

<XTFormControl>
  <XTInput
    name="name"
    id="message"
    placeholder="Basic Input - Label"
    onChange={(e) => setValue(e)}
    type="text"
    aria-describedby="msg"
    endAdornment={
      <>
        {value ? (
          <IconButton onClick={() => setValue("")}>
            <span css={globalCSS.hidden}>내용 지우기</span>
            <i css={globalCSS.icoDelete} />
          </IconButton>
        ) : null}
      </>
    }
  ></XTInput>
  <XTInputLabel htmlFor="message" shrink id="msg">
    메시지를 입력해 주세요.
  </XTInputLabel>
</XTFormControl>
                `}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <hr className="guide-hr" />
            {/* Checkbox Input */}
            <div>
              <h3 className="guide-h3">Success Input</h3>
              <div className="content">
                <XTFormControl>
                  <XTInput
                    type="text"
                    placeholder="Check"
                    className="success"
                  />
                </XTFormControl>
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "basic-success"}
                  onChange={handleCodemirror("basic-success")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTFormControl, XTInput } from "components/mui/xt-select";

<XTFormControl>
  <XTInput type="text" placeholder="Check" className="success" />
</XTFormControl>
`}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
              <br />
              <br />
              <h3 className="guide-h3">Error Input</h3>
              <p css={globalCSS.hidden}>Email</p>
              <div className="content">
                <XTFormControl>
                  <XTInput
                    error={true}
                    type="email"
                    value={input6}
                    onChange={(e) => setInput6(e.target.value)}
                    placeholder="Error (e-mail example)"
                    aria-describedby="error-email"
                    aria-label="이메일 형식이 바르지 않습니다"
                    aria-invalid="true"
                    endAdornment={
                      <>
                        {input6 ? (
                          <IconButton onClick={() => setInput6("")}>
                            <span css={globalCSS.hidden}>내용 삭제하기</span>
                            <i css={globalCSS.icoDelete} />
                          </IconButton>
                        ) : null}
                      </>
                    }
                  />
                  <XTFormHelperText id="error-email" className="error">
                    이메일 형식이 바르지 않습니다.
                  </XTFormHelperText>
                </XTFormControl>
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "basic-input-error-email"}
                  onChange={handleCodemirror("basic-input-error-email")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTFormControl, XTInput, XTFormHelperText } from "components/mui/xt-select";
import globalCSS from "@/assets/ts/global-css";

const [value, setValue] = useState(null)

<XTFormControl>
  <XTInput
    error={true}
    type="email"
    value={value}
    onChange={(e) => setValue(e.target.value)}
    placeholder="Error (e-mail example)"
    aria-describedby="error-email"
    aria-label="이메일 형식이 바르지 않습니다"
    aria-invalid="true"
    endAdornment={
      <>
        {value ? (
          <IconButton onClick={() => setValue("")}>
            <span css={globalCSS.hidden}>내용 삭제하기</span>
            <i css={globalCSS.icoDelete} />
          </IconButton>
        ) : null}
      </>
    }
  />
  <XTFormHelperText id="error-email" className="error">
    이메일 형식이 바르지 않습니다.
  </XTFormHelperText>
</XTFormControl>
`}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <hr className="guide-hr" />

            {/* Textarea */}
            <div>
              <h3 className="guide-h3">Textarea</h3>
              <div className="content">
                <XTFormControl sx={{ position: "relative" }}>
                  <XTTextarea
                    name="textarea"
                    id="textarea"
                    multiline
                    placeholder="Textarea"
                    type="text"
                  ></XTTextarea>
                </XTFormControl>
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "textarea"}
                  onChange={handleCodemirror("textarea")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTFormControl, XTTextarea, XTInputLabel } from "components/mui/xt-select";

const [value, setValue] = useState("")

<XTFormControl>
  <XTTextarea
    name="textarea"
    id="textarea"
    multiline
    placeholder="Textarea"
    type="text"
  ></XTTextarea>
</XTFormControl>
`}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <br />
            {/* Textarea - 글자 수 제한 */}
            <div>
              <h3 className="guide-h3">Textarea - 글자 수 제한</h3>
              <div className="content">
                <XTFormControl>
                  <XTTextarea
                    name="textarea"
                    id="textarea"
                    multiline
                    placeholder="Textarea"
                    type="text"
                    onChange={(e) => handleTextareaLength(e)}
                    className={textarea2 ? "input-filled" : ""}
                    inputProps={{ maxLength: maxTextareaLength }}
                  ></XTTextarea>
                  <span css={globalCSS.textareaLength}>
                    {textarea2.length}
                    &nbsp;/&nbsp;
                    {maxTextareaLength
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </XTFormControl>
                <br />
                <br />
                <Accordion
                  sx={{
                    margin: 0,
                    p: 0,
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    "&::before": {
                      position: "relative",
                    },
                  }}
                  expanded={codemirror === "textarea"}
                  onChange={handleCodemirror("textarea")}
                  className="accordion-codemirror"
                >
                  <AccordionSummary>
                    <p className="guide-codeMore">코드보기</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CodeMirror
                      theme={vscodeDark}
                      extensions={[html()]}
                      value={`
import { XTFormControl, XTTextarea, XTInputLabel } from "components/mui/xt-select";
import _, { debounce } from "lodash";

const [value, setValue] = useState("");

// 최대 글자수 제한
const maxTextareaLength: number = 50;


// onChange debounce
const handleTextareaLength = debounce((e) => {
  setValue(e.target.value);
});

<XTFormControl>
  <XTTextarea
    name="textarea"
    id="textarea"
    multiline
    placeholder="Textarea"
    type="text"
    onChange={(e) => handleTextareaLength(e)}
    className={value && "input-filled"}
    inputProps={{ maxLength: maxTextareaLength }}
  ></XTTextarea>
  <span css={globalCSS.textareaLength}>
    {value.length}
    &nbsp;/&nbsp;
    {maxTextareaLength
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
  </span>
</XTFormControl>
`}
                    ></CodeMirror>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <hr className="guide-hr" />
            {/* 오토컴플릿 */}
            <div>
              <h3 className="guide-h3">Auto complete - Default</h3>
              <div>
                <XTAutocomplete
                  options={list1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="선택 또는 입력해 주세요."
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <div className="end-wrap">
                            <button
                              className="organization"
                              onClick={(e) => {
                                alert("open organization!");
                              }}
                            >
                              <i />
                            </button>
                            {params.InputProps.endAdornment}
                          </div>
                        ),
                      }}
                    />
                  )}
                  renderOption={(props, option: any) => (
                    <li {...props}>{option.label}</li>
                  )}
                  PopperComponent={XTPopper}
                  noOptionsText="검색 결과가 없습니다."
                />
              </div>

              <div style={{ marginTop: 30, width: "100%" }}>
                <XTAutocomplete
                  options={list1}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="선택 또는 입력해 주세요."
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <div className="end-wrap">
                            <button
                              className="organization"
                              onClick={(e) => {
                                alert("open organization!");
                              }}
                            >
                              <i />
                            </button>
                            {params.InputProps.endAdornment}
                          </div>
                        ),
                      }}
                    />
                  )}
                  renderOption={(props, option: any) => (
                    <li {...props}>{option.label}</li>
                  )}
                  PopperComponent={XTPopper}
                  noOptionsText="검색 결과가 없습니다."
                />
              </div>

              <div style={{ marginTop: 30 }}>
                <XTAutocomplete
                  options={list2}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="선택 또는 입력해 주세요."
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <span>{option.label}</span>
                      <span className="sub">특징: {option.desc}</span>
                    </li>
                  )}
                  PopperComponent={XTPopper}
                  noOptionsText="검색 결과가 없습니다."
                />
              </div>

              <div style={{ marginTop: 30 }}>
                <XTAutocomplete
                  options={list1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="선택 또는 입력해 주세요."
                    />
                  )}
                  renderOption={(props, option: any) => (
                    <li {...props}>{option.label}</li>
                  )}
                  PopperComponent={XTPopper}
                  noOptionsText="검색 결과가 없습니다."
                />
              </div>

              <br />
              <br />

              <Accordion
                sx={{
                  margin: 0,
                  p: 0,
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  "&::before": {
                    position: "relative",
                  },
                }}
                expanded={codemirror === "autocompleteA"}
                onChange={handleCodemirror("autocompleteA")}
                className="accordion-codemirror"
              >
                <AccordionSummary>
                  <p className="guide-codeMore">코드보기</p>
                </AccordionSummary>
                <AccordionDetails>
                  <CodeMirror
                    theme={vscodeDark}
                    extensions={[html()]}
                    value={`
import { XTAutocomplete, XTPopper } from "@/components/mui/xt-autocomplete";

  const list = [
    {
      label: "강아지",
    },
    {
      label: "고양이",
    },
    {
      label: "햄스터",
    },
    {
      label: "고슴도치",
    },
    {
      label: "라쿤",
    },
  ];

<XTAutocomplete
  options={list1}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="선택 또는 입력해 주세요."
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <div className="end-wrap">
            <button
              className="organization"
              onClick={(e) => {
                alert("open organization!");
              }}
            >
              <i />
            </button>
            {params.InputProps.endAdornment}
          </div>
        ),
      }}
    />
  )}
  renderOption={(props, option: any) => (
    <li {...props}>{option.label}</li>
  )}
  PopperComponent={XTPopper}
  noOptionsText="검색 결과가 없습니다."
/>

<XTAutocomplete
  options={list1}
  fullWidth
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="선택 또는 입력해 주세요."
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <div className="end-wrap">
            <button
              className="organization"
              onClick={(e) => {
                alert("open organization!");
              }}
            >
              <i />
            </button>
            {params.InputProps.endAdornment}
          </div>
        ),
      }}
    />
  )}
  renderOption={(props, option: any) => (
    <li {...props}>{option.label}</li>
  )}
  PopperComponent={XTPopper}
  noOptionsText="검색 결과가 없습니다."
/>

<XTAutocomplete
  options={list2}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="제품명을 입력해 주세요."
    />
  )}
  renderOption={(props, option) => (
    <li {...props}>
      <span>{option.label}</span>
      <span className="sub">특징: {option.desc}</span>
    </li>
  )}
  PopperComponent={XTPopper}
  noOptionsText="검색 결과가 없습니다."
/>
`}
                  ></CodeMirror>
                </AccordionDetails>
              </Accordion>
            </div>
            <br />
            <br />
            <br />

            {/* 오토컴플릿 */}
            <div>
              <h3 className="guide-h3">
                Auto complete - className="custom-round"
              </h3>
              <div style={{ background: "#666666", padding: "30px" }}>
                <XTAutocomplete
                  className="custom-round"
                  options={list1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="선택 또는 입력해 주세요."
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <div className="end-wrap">
                            <button
                              className="organization"
                              onClick={(e) => {
                                alert("open organization!");
                              }}
                            >
                              <i />
                            </button>
                            {params.InputProps.endAdornment}
                          </div>
                        ),
                      }}
                    />
                  )}
                  renderOption={(props, option: any) => (
                    <li {...props}>{option.label}</li>
                  )}
                  PopperComponent={XTPopper}
                  noOptionsText="검색 결과가 없습니다."
                />
              </div>
              <br />
              <br />

              <Accordion
                sx={{
                  margin: 0,
                  p: 0,
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  "&::before": {
                    position: "relative",
                  },
                }}
                expanded={codemirror === "autocompleteB"}
                onChange={handleCodemirror("autocompleteB")}
                className="accordion-codemirror"
              >
                <AccordionSummary>
                  <p className="guide-codeMore">코드보기</p>
                </AccordionSummary>
                <AccordionDetails>
                  <CodeMirror
                    theme={vscodeDark}
                    extensions={[html()]}
                    value={`
import { XTAutocomplete, XTPopper } from "@/components/mui/xt-autocomplete";

<XTAutocomplete
  className="custom-round"
  options={list1}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="선택 또는 입력해 주세요."
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <div className="end-wrap">
            <button
              className="organization"
              onClick={(e) => {
                alert("open organization!");
              }}
            >
              <i />
            </button>
            {params.InputProps.endAdornment}
          </div>
        ),
      }}
    />
  )}
  renderOption={(props, option: any) => (
    <li {...props}>{option.label}</li>
  )}
  PopperComponent={XTPopper}
  noOptionsText="검색 결과가 없습니다."
/>
`}
                  ></CodeMirror>
                </AccordionDetails>
              </Accordion>

              <hr className="guide-hr" />
              {/* File Upload */}
              <div>
                <h3 className="guide-h3">File Upload</h3>
                <div className="content">
                  <div>
                    <div
                      className="image-upload-preview-wrap"
                      css={globalCSS.imageUploadPreviewContainer}
                    >
                      <div className="image-upload-item">
                        <div className="image-upload-preview">
                          <InputLabel>preview</InputLabel>
                          <div className="preview-image">
                            {true ? (
                              <img src={"이미지Url"} />
                            ) : (
                              "등록된 이미지가 없습니다."
                            )}
                          </div>
                        </div>
                        <div className="image-uploag-button-wrap">
                          <XTFileUploadButton
                            file={watch("menuIconPathObj")}
                            data={{
                              domain: "menu",
                              key: "menuIconPathObj",
                              setValue,
                              limitMB: 0,
                              submitCount,
                              submitType: "FILE",
                              fileType: ["svg", "png"],
                              maxLength: 3,
                            }}
                          />
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                          >
                            삭제
                          </XTButton>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <h4>
                      - 다중파일의 경우 / multiple: true / file data Array
                    </h4>
                    <XTFileUpload
                      file={watch("menuIconPathObjList")}
                      data={{
                        domain: "menu",
                        key: "menuIconPathObjList",
                        setValue,
                        limitMB: 0,
                        submitCount,
                        submitType: "FILE",
                        fileType: ["svg", "png"],
                        maxLength: 3,
                        multiple: true,
                      }}
                    />
                    <br />
                    <br />
                    <h4>- 단일파일의 경우 / file data Object</h4>
                    <XTFileUpload
                      file={watch("menuIconPathObj")}
                      data={{
                        domain: "menu",
                        key: "menuIconPathObj",
                        setValue,
                        limitMB: 0,
                        submitCount,
                        submitType: "FILE",
                        fileType: ["svg", "png"],
                      }}
                    />
                    <br />
                    <br />
                    <XTFormControl>
                      <XTFileUploadButton
                        file={watch("menuIconPathObj")}
                        data={{
                          domain: "menu",
                          key: "menuIconPathObj",
                          setValue,
                          limitMB: 0,
                          submitCount,
                          submitType: "FILE",
                          fileType: ["svg", "png"],
                          maxLength: 3,
                        }}
                      />

                      <br />
                      <br />
                      <XTFIleUploadArea file="" data="" />
                      <br />
                      <br />
                      <XTFileDownload
                        fileRoute={fileRoute}
                        fileName={fileName}
                        fileTitle={fileTitle}
                        fileExt={fileExt}
                        onClick={() => {
                          alert("download");
                        }}
                      />
                    </XTFormControl>
                    <br />
                    <br />
                    <Accordion
                      sx={{
                        margin: 0,
                        p: 0,
                        border: "none",
                        outline: "none",
                        boxShadow: "none",
                        "&::before": {
                          position: "relative",
                        },
                      }}
                      expanded={codemirror === "password"}
                      onChange={handleCodemirror("password")}
                      className="accordion-codemirror"
                    >
                      <AccordionSummary>
                        <p className="guide-codeMore">코드보기</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CodeMirror
                          theme={vscodeDark}
                          extensions={[html()]}
                          value={`
import { XTFormControl, XTInput, XTInputLabel } from "components/mui/xt-select";
import globalCSS from "@/assets/ts/global-css";

// input 값
const [pwd, setPwd] = useState("");

// 클릭 시 input의 type 변경
const [show, setShow] = useState(false);

<XTFormControl>
  <XTInput
    placeholder="비밀번호를 입력해 주세요"
    value={pwd}
    type={show ? "text" : "password"}
    endAdornment={
      <>
        {show ? (
          <IconButton onClick={() => setShow((prev) => !prev)}>
            <span css={globalCSS.hidden}>비밀번호 보이기</span>
            <i css={globalCSS.icoHide} />
          </IconButton>
        ) : (
          <IconButton onClick={() => setShow((prev) => !prev)}>
            <span css={globalCSS.hidden}>비밀번호 숨기기</span>
            <i css={globalCSS.icoShow} />
          </IconButton>
        )}
      </>
    }
  ></XTInput>
</XTFormControl>
                `}
                        ></CodeMirror>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const style = {
  title: css`
    margin-bottom: 10px;
    font-weight: 400;
    color: #555;
  `,
};
