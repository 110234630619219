import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import {
  XTDialog,
  XTDialogActions,
  XTDialogContent,
  XTDialogTitle,
} from "@/components/mui/xt-dialog";
import { Grid } from "@mui/material";
import { css } from "@emotion/react";
import theme from "@/assets/ts/theme";
import { useEffect, useState } from "react";
import { XTPopupFile } from "@/components/mui/xt-fileform";

export default function UploadDialog(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onChange: (file: File) => void;
}) {
  const { open, setOpen, onChange } = props;
  const [file, setFile] = useState(null);
  const handleCloseDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFile(null);
  }, [open]);

  return (
    <XTDialog
      disablePortal
      className="sm"
      open={open}
      css={[globalCSS.detailContainer, style.cancel]}
    >
      <XTDialogTitle className="flex-title">
        <div>
          <span>운송장 업로드</span>
        </div>

        <button
          type="button"
          className="close-btn"
          onClick={() => {
            handleCloseDialog();
          }}
        >
          <span css={globalCSS.hidden}>닫기</span>
          <i css={globalCSS.icoClose} />
        </button>
      </XTDialogTitle>

      <XTDialogContent>
        <div className="cont-wrap">
          <div className="cont-inner">
            <XTPopupFile
              file={file}
              data={{
                domain: "popup",
                type: "EXCEL",
                fileType: ["xlsx", "xls"],
                limitMB: 10,
                setValue: setFile,
                setKey: "attachImage",
              }}
            />
          </div>
        </div>
        <p className="notetext">※ 최대 1개의 엑셀 파일만 업로드 가능합니다.</p>
        <p className="notetext">
          ※ 운송장 템플릿을 다운로드 하여 입력 후 업로드 해주세요.
        </p>
      </XTDialogContent>
      <XTDialogActions className="flex-end">
        <Grid container spacing={2} justifyContent="end">
          <Grid item>
            <XTButton
              variant="outlined"
              color="primary"
              size="medium"
              onClick={() => {
                handleCloseDialog();
              }}
            >
              취소
            </XTButton>
          </Grid>
          <Grid item>
            <XTButton
              variant="outlined"
              color="primary"
              size="medium"
              onClick={() => {
                onChange(file!);
              }}
            >
              확인
            </XTButton>
          </Grid>
        </Grid>
      </XTDialogActions>
    </XTDialog>
  );
}

const style = {
  cancel: css`
    .noti {
      margin-bottom: ${theme.typography.pxToRem(15)};
    }
    .notetext {
      margin-top: 10px;
      font-size: 13px;
      color: #999999;

      + .notetext {
        margin-top: 5px;
      }
    }
  `,
};
