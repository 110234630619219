import deepdash from "deepdash";
import { MenuItemType, MenuListType } from "../../../../types/system/menu";
import {
  loadCommonMenuListAPI,
  loadMenuListAPI,
  updateMenuAPI,
  updateSordAPI,
} from "@/api/system/menu";
import { MenuDetailFormType, MenuSordFormType } from "@/types/system/menu";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import lodash from "lodash";
import { TypesType } from "@/types";

const _ = deepdash(lodash);

type ListType = {
  [key: string]: MenuItemType[];
};

export const useMenuManager = () => {
  const [menuTypes, setMenuTypes] = useState<TypesType>();
  const [selectMenu, setSelectMenu] = useState<MenuItemType>();
  const [depthList, setDepthList] = useState<ListType>();

  const queryClient = useQueryClient();
  const {
    data: menuData,
    isFetching,
    isFetched,
    isSuccess,
  } = useQuery<
    AxiosResponse<{
      data: MenuListType;
    }>,
    AxiosError,
    MenuListType
  >({
    queryKey: ["loadMenuList"],
    queryFn: async () => await loadMenuListAPI(),
    select: (res) => {
      return res.data.data;
    },
  });

  const generateDepthList2 = (
    items: MenuItemType[],
    depth: number,
    result: ListType
  ): void => {
    const key = depth.toString();

    if (items && items.length > 0) {
      if (!result[key]) {
        result[key] = [];
      }
      result[key] = items;
    } else {
      result[depth] = [];
    }
  };

  const generateDepthList = (
    items: MenuItemType[],
    depth: number,
    result: ListType
  ): void => {
    const key = depth.toString();

    if (items && items.length > 0) {
      if (!result[key]) {
        result[key] = [];
      }
      result[key] = items;

      if (selectMenu) {
        // 선택된 메뉴가 있으면 선택된 메뉴에 따른 하위 메뉴 가져오기
        if (selectMenu.menuList.length > 0) {
          const subItems = selectMenu.menuList;
          generateDepthList2(subItems, depth + 1, result);
        }
      } else {
        // 선택된 메뉴가 없으면 최상단의 메뉴에 하위 메뉴 가져오기
        if (items[0].menuList.length > 0) {
          const subItems = items[0].menuList;
          generateDepthList2(subItems, depth + 1, result);
        }
      }
    } else {
      result[depth] = [];
    }
  };

  const initialDepthList = (menuList: MenuItemType[]) => {
    const result: ListType = {};

    generateDepthList(menuList, 1, result);
    setDepthList(result);
  };

  useEffect(() => {
    if (isSuccess && menuData.menuList.length > 0) {
      setMenuTypes(menuData.MenuType);

      if (menuData!.menuList.length > 0) initialDepthList(menuData.menuList);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (selectMenu) {
      const result: ListType = {};

      let selectedMenu = menuData?.menuList.find(
        (item: MenuItemType) => item.cd === selectMenu!.cd
      );
      if (selectedMenu && selectMenu && selectMenu.tp) {
        generateDepthList(
          selectedMenu.menuList,
          selectMenu!.tp + 1,
          depthList!
        );
        setDepthList({ ...depthList });
      }
    }
  }, [selectMenu]);

  const update = useMutation({
    mutationFn: (form: MenuDetailFormType) => updateMenuAPI(form),
  });

  const updateSord = useMutation({
    mutationFn: (form: MenuSordFormType) => updateSordAPI(form),
  });

  const resetQuery = () => {
    queryClient.resetQueries({ queryKey: ["loadMenuList"] });
  };

  const handleSord = (type: string) => {
    let targetIdx: number;
    let newIdx: number;

    const arr = JSON.parse(
      JSON.stringify(depthList && depthList[selectMenu!.tp])
    );

    let selected = arr.find((item: MenuItemType) => item.cd === selectMenu!.cd);

    let target: MenuItemType;

    arr.map((item: MenuItemType, i: number) => {
      item.sord = Number(i + 1);
    });

    switch (type) {
      case "up":
        if (selected.sord == 1) return;
        targetIdx = selected.sord - 1;

        newIdx = targetIdx - 1;

        target = arr.splice(targetIdx, 1)[0];

        arr.splice(newIdx, 0, target);

        break;

      case "down":
        if (selected.sord == arr.length) return;
        targetIdx = selected.sord - 1;

        newIdx = targetIdx + 1;

        target = arr.splice(targetIdx, 1)[0];

        arr.splice(newIdx, 0, target);

        break;

      case "top":
        if (selected.sord == 1) return;
        targetIdx = selected.sord - 1;

        target = arr.splice(targetIdx, 1)[0];

        arr.unshift(target);

        break;

      case "bottom":
        if (selected.sord == arr.length) return;

        targetIdx = selected.sord - 1;

        target = arr.splice(targetIdx, 1)[0];

        arr.push(target);

        break;
    }

    setDepthList({
      ...depthList,
      [selectMenu!.tp]: arr,
    });
  };

  return {
    menuTypes,
    depthList,
    setDepthList,
    isFetched,
    isSuccess,
    update,
    updateSord,
    handleSord,
    resetQuery,
    selectMenu,
    setSelectMenu,
  };
};

export const useMenuTreeManager = (schMgYn: string | null) => {
  const {
    data: menuList,
    refetch: refetchMenu,
    isFetching,
    isFetched,
    isSuccess,
    refetch,
  } = useQuery<
    AxiosResponse<{
      data: MenuListType;
    }>,
    AxiosError,
    MenuListType
  >({
    queryKey: ["loadMenuList", schMgYn ?? ""],
    queryFn: async () => await loadCommonMenuListAPI(schMgYn ?? ""),
    select: (res) => {
      return res.data.data;
    },
    staleTime: 0,
    gcTime: Infinity,
  });

  return {
    menuList,
    isFetched,
    isSuccess,
    refetchMenu,
  };
};
