import GuideLeft from "./sidebar";
import { css } from "@emotion/react";
import theme from "../../assets/ts/theme";

const GuideLayout = () => {
  const toggleMenu = () => {
    const btn = document.querySelector(".toggle-sidebar");
    const sidebar = document.querySelector(".sidebar-wrap");
    btn.classList.toggle("open");
    sidebar.classList.toggle("open");
  };

  // if (router.pathname.indexOf("guide/template") === 1) {
  //   return;
  // }

  return (
    <div>
      <div
        className="toggle-sidebar"
        css={style.openSidebar}
        onClick={toggleMenu}
      >
        <button className="btn-sidebar">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <GuideLeft />
      {/* {router.pathname.indexOf("guide/template") === -1 && <GuideLeft />} */}
    </div>
  );
};

const style = {
  openSidebar: css`
    display: none;
    position: absolute;
    top: 33px;
    right: 30px;
    z-index: 10;
    width: 28px;
    height: 45px;
    cursor: pointer;
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      display: block;
    }

    &.open {
      span {
        &:nth-of-type(1) {
          top: 9px;
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
          left: -60px;
        }
        &:nth-of-type(3) {
          top: 9px;
          -webkit-transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
      }
    }

    .btn-sidebar {
      width: 28px;
      height: 45px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      background: transparent;
      border: none;
      outline: none;
    }
    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: black;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;

      &:nth-of-type(1) {
        top: 0px;
      }
      &:nth-of-type(2) {
        top: 9px;
      }
      &:nth-of-type(3) {
        top: 18px;
      }
    }
  `,
};

export default GuideLayout;
