import globalCSS from "@/assets/ts/global-css";
import {
  XTDialog,
  XTDialogActions,
  XTDialogContent,
  XTDialogTitle,
} from "@/components/mui/xt-dialog";
import DaumPostcode from "react-daum-postcode";

export default function DaumAddress(props: {
  openDialog: boolean;
  setOpenDialog: (openDialog: boolean) => void;
  onChange: (params: { zonecode: string; roadAddress: string }) => void;
}) {
  const { openDialog, setOpenDialog, onChange } = props;
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onCompletePost = (data: any) => {
    onChange(data);
    setOpenDialog(false);
  }; // onCompletePost 함수

  return (
    <XTDialog
      disablePortal
      className="sm"
      open={openDialog}
      css={globalCSS.detailContainer}
    >
      <XTDialogTitle className="flex-title">
        <div>
          <span>우편번호 찾기</span>
        </div>

        <button
          type="button"
          className="close-btn"
          onClick={() => {
            handleCloseDialog();
          }}
        >
          <span css={globalCSS.hidden}>닫기</span>
          <i css={globalCSS.icoClose} />
        </button>
      </XTDialogTitle>

      <XTDialogContent>
        <DaumPostcode
          autoClose
          onComplete={onCompletePost}
          onClose={() => {
            setOpenDialog(false);
          }}
        ></DaumPostcode>
      </XTDialogContent>
      <XTDialogActions></XTDialogActions>
    </XTDialog>
  );
}
