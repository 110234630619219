import { regDeleteSpacing } from "@/assets/ts/common";
import { emailDomainList } from "@/assets/ts";

import theme from "@/assets/ts/theme";
import { XTInput } from "@/components/mui/xt-input";
import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";

const XTEmailInput = (props: any) => {
  const { type, value, placeholder, error, onChange, endAdornment, disabled } =
    props;
  const [emailList, setEmailList] = useState(emailDomainList); //추천 이메일 리스트를 확인, 이메일 리스트 상태 관리
  const [selected, setSelected] = useState(0); //키보드 선택
  const [showDropdown, setShowDropdown] = useState(false); // 드롭박스 유무
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleInputKeyDown = (e: any) => {
    if (showDropdown) {
      if (e.key === "ArrowDown") {
        if (emailList.length - 1 > selected) {
          setSelected(selected + 1);
        } else {
          setSelected(0);
        }
      }

      if (e.key === "ArrowUp") {
        if (selected > 0) {
          setSelected(selected - 1);
        } else {
          setSelected(emailList.length - 1);
        }
      }
      if (e.key === "Enter" && selected >= 0) {
        e.preventDefault();
        e.stopPropagation();
        handleClickDropdown(emailList[selected]);
      }
    }
  };

  const handleClickDropdown = (domain: string) => {
    const emailId: string = value.split("@")[0] + "@";

    onChange(emailId + domain);

    setSelected(0);
    setShowDropdown(false);
  };

  return (
    <div css={style.customInput} ref={inputRef} className="custom-input-wrap">
      <XTInput
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        error={error}
        onFocus={(e) => {
          if (
            e.target.value.includes("@") &&
            e.target.value.split("@").length === 2
          ) {
            const filter = emailDomainList.filter((item) => {
              return item
                .substr(0, e.target.value.split("@")[1].length)
                .includes(e.target.value.split("@")[1]);
            });

            if (filter.length === 0) {
              setShowDropdown(false);
            } else {
              setEmailList(filter);
              setShowDropdown(true);
            }
          } else {
            setShowDropdown(false);
          }
        }}
        onChange={(e) => {
          onChange(regDeleteSpacing(e.target.value));

          if (
            e.target.value.includes("@") &&
            e.target.value.split("@").length === 2
          ) {
            const filter = emailDomainList.filter((item) => {
              return item
                .substr(0, e.target.value.split("@")[1].length)
                .includes(e.target.value.split("@")[1]);
            });

            if (filter.length === 0) {
              setShowDropdown(false);
            } else {
              setEmailList(filter);
              setShowDropdown(true);
            }
          } else {
            setShowDropdown(false);
          }
        }}
        onKeyDown={handleInputKeyDown}
        endAdornment={endAdornment}
      />
      {/* ST:: 이메일 드롭다운 영역 */}
      {showDropdown && (
        <div className="email-dropdown">
          <ul>
            {emailList.map((item, i) => (
              <li
                key={i}
                onClick={(e) => {
                  handleClickDropdown(item);
                }}
                className={selected === i ? "selected" : ""}
              >
                <span className="id">{value.split("@")[0]}@</span>
                <span className="domain">{item}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* EN:: 이메일 드롭다운 영역 */}
    </div>
  );
};

const style = {
  customInput: css`
    position: relative;
    min-width: 305px;

    .MuiInputBase-root {
      min-width: 305px;
    }

    .email-dropdown {
      position: absolute;
      left: 0;
      top: 39px;
      z-index: 999;
      width: 100%;
      max-height: ${theme.typography.pxToRem(212)};
      font-weight: 400;
      color: #000000;
      background: ${theme.palette.white};
      border-radius: 0px;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);

      @media (max-width: ${theme.breakpoints.values.sm}px) {
        display: none;
      }

      ul {
        li {
          display: flex;
          align-items: center;
          padding: ${theme.typography.pxToRem(10)};
          height: ${theme.typography.pxToRem(34)};
          border-radius: 0px;
          font-size: 14px;
          cursor: pointer;

          &:hover {
            color: ${theme.palette.primary.main};
          }

          &.selected {
            color: ${theme.palette.primary.main};
          }
        }
      }
    }
  `,
};

export default XTEmailInput;
