import { loadCommonMenuListAPI } from "@/api/system/menu";
import { XTButton } from "@/components/mui/xt-button";
import { css } from "@emotion/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  const handleClickBtn = async () => {
    const result = await loadCommonMenuListAPI("Y");
    const firstMenuUri =
      result.data.data?.menuList[0]?.menuList?.[0]?.uri ||
      result.data.data?.menuList[0]?.uri;

    if (firstMenuUri) {
      navigate(firstMenuUri, { replace: true });
    } else {
      window.location.href = "/login";
    }
  };

  return (
    <div css={style.container}>
      <h1 className="title">주소를 확인해주세요.</h1>
      <p className="desc">
        페이지의 주소가 잘못 입력되었거나, <br /> 변경 또는 삭제되어 요청하신
        페이지를 찾을 수 없습니다. <br /> 주소를 다시 확인해주세요.
      </p>
      <XTButton
        variant="contained"
        color="primary"
        size="small"
        onClick={handleClickBtn}
      >
        홈으로 이동
      </XTButton>
    </div>
  );
}

const style = {
  container: css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
      font-weight: 700;
      font-size: 30px;
      line-height: 1;
      color: #000000;
      letter-spacing: -1px;
    }

    .desc {
      margin-top: 14px;
      margin-bottom: 14px;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }
  `,
};
