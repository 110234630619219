import { activeMenuState } from "@/components/sidebar";
import {
  Badge,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogContentText,
  FormControlLabel,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { useState } from "react";
import { useRecoilValue } from "recoil";

export const XTDialog = styled(Dialog)<DialogProps>(({ theme }) => {
  // const [test, setTest] = useState("");
  const activeMenu = useRecoilValue(activeMenuState);

  return {
    zIndex: 999,
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },

    "&.sm": {
      "& .MuiPaper-root": {
        minWidth: "500px",
        maxWidth: "500px",
        maxHeight: "650px",
      },
    },

    "&.md": {
      "& .MuiPaper-root": {
        minWidth: "650px",
        maxWidth: "650px",
        maxHeight: "700px",
      },
    },

    "&.lg": {
      "&.active": {
        "& > .MuiDialog-container": {
          "& > .MuiPaper-root": {
            width: "calc(100% - 370px)",
            minWidth: "calc(100% - 370px)",
            maxWidth: "calc(100% - 370px)",
          },
        },
      },

      "& > .MuiDialog-container": {
        justifyContent: "flex-end",
        alignItems: "flex-end",

        "& > .MuiPaper-root": {
          flex: 1,
          margin: "0 20px 20px 0",
          width: activeMenu ? "calc(100% - 370px)" : "calc(100% - 160px)",
          height: "calc(100% - 120px)",
          minWidth: activeMenu ? "calc(100% - 370px)" : "calc(100% - 160px)",
          maxWidth: activeMenu ? "calc(100% - 370px)" : "calc(100% - 160px)",
          minHeight: "calc(100% - 120px)",
          maxHeight: "calc(100% - 120px)",
          transition: `all 0.4s ${theme.transitions.easing.inout2}`,
        },
      },
    },

    "& .MuiPaper-root": {
      minWidth: "350px",
      width: "auto",
      maxWidth: "auto !important",
      maxHeight: "auto !important",
      boxShadow: "none",
      borderRadius: "4px",
      margin: 0,
      boxSizing: "border-box",

      "& .MuiDialogTitle-root": {
        fontSize: "22px",
        fontWeight: 700,
      },
    },
  };
});

export const XTDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: `20px 20px 20px`,
  fontSize: "16px",
  fontWeight: 500,

  "&.flex-title": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    "& .close-btn": {
      cursor: "pointer",
    },
  },
}));

export const XTDialogContent = styled(DialogContent)(({ theme }) => ({
  backgroundColor: theme.palette.white,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: `0 20px 0`,
  wordBreak: "break-word",
  border: "none",
  overflow: "auto",
}));

export const XTDialogContentText = styled(DialogContentText)(({ theme }) => ({
  fontSize: `${theme.typography.pxToRem(14)}`,
  lineHeight: `${theme.typography.pxToRem(24)}`,
  fontWeight: 400,
  color: theme.palette.default,
}));

export const XTDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: "20px 20px 20px",
  justifyContent: "flex-end",

  "& button": {
    "& + button": {
      marginLeft: "20px",
    },
  },
  "&.flex-end": {
    justifyContent: "flex-end",
  },
}));
