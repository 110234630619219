import globalCSS from "@/assets/ts/global-css";
import guideCSS from "@/assets/ts/guide-css";
import GuideLeft from "@/guide/components/sidebar";

export default function GuideBadge() {
  return (
    <>
      <div className="guide-area" css={guideCSS.guideWrap}>
        <GuideLeft />
        <div css={guideCSS.guideWrapper}>
          <div className="title-box">
            <h2 className="guide-h2">Badge</h2>
          </div>
          <div className="guide-content">
            <div>
              <h3 className="guide-h3">
                Basic Badge: css=&#123; globalCSS.badge &#125;
              </h3>
              <div style={{ display: "flex", gap: "20px" }}>
                <span css={globalCSS.badge}>공지</span>
              </div>
            </div>
            <hr className="guide-hr" />
            <div>
              <h3 className="guide-h3">
                Outline Badge: css=&#123; globalCSS.badge &#125;
                className="outline"
              </h3>
              <div style={{ display: "flex", gap: "20px" }}>
                <span css={globalCSS.badge} className="outline">
                  공지
                </span>
              </div>
            </div>
            <hr className="guide-hr" />
            <div>
              <h3 className="guide-h3">
                disabled Badge: css=&#123; globalCSS.badge &#125;
                className="disabled"
              </h3>
              <div style={{ display: "flex", gap: "20px" }}>
                <span css={globalCSS.badge} className="disabled">
                  공지
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
