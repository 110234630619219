import {
  changeDataSubmitDateTime,
  formatYmdHisDot,
  maxLen,
} from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";

import {
  Box,
  Grid,
  InputAdornment,
  InputLabel,
  Radio,
  SelectChangeEvent,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AlertDialog from "@/components/ui/alert-dialog";
import { useEffect, useState } from "react";
import {
  usePopupDetailManager,
  usePopupTypeManager,
} from "@/pages/popup/_hook/use-popup";
import { Controller, useForm } from "react-hook-form";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import { PopupFormType } from "@/types/popup";
import XTDatePicker from "@/components/mui/xt-datepicker";
import { XTFormHelperText, XTInput } from "@/components/mui/xt-input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { XTFileUpload } from "@/components/mui/xt-fileform";

export default function PopupDetail() {
  const navigate = useNavigate();
  const { popupData, registPopup, updatePopup, deletePopup } =
    usePopupDetailManager();
  const { popupTypes } = usePopupTypeManager();
  const params: any = useParams();

  const [alertDialog, setAlertDialog] = useState({
    open: false,
    title: "",
    text: "",
    type: "complete",
    handleOK: () => {},
    handleClose: () => handleCloseDialog(),
  });

  const handleCloseDialog = () => {
    setAlertDialog({
      ...alertDialog,
      open: false,
    });
  };

  const schema = yup
    .object()
    .shape({
      ttl: yup.string().trim().required("제목을 입력해 주세요."),
      usePrdStrtYmd: yup.mixed().when("usePrdYn", {
        is: "Y",
        then: yup.string().nullable().required("게시기간을 선택해주세요."),
        otherwise: yup.mixed(),
      }),
      usePrdEndYmd: yup.mixed().when("usePrdYn", {
        is: "Y",
        then: yup.string().nullable().required("게시기간을 선택해주세요."),
        otherwise: yup.mixed(),
      }),
      attachImage: yup.object().shape({
        seq: yup.string().trim().required("팝업 이미지를 등록해주세요."),
      }),
      link: yup.mixed().when("linkTp", {
        is: "1",
        then: yup.mixed(),
        otherwise: yup.string().nullable().required("링크를 입력해주세요."),
      }),
    })
    .required();

  const {
    register,
    trigger,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, submitCount },
  } = useForm<PopupFormType>({
    shouldFocusError: false,
    defaultValues: {
      seq: "",
      useYn: "Y",
      usePrdYn: "N",
      // 사용 기간 여부
      usePrdStrtYmd: "",
      // 사용 기간 시작 일자
      usePrdStrtHis: "",
      // 사용 기간 시작 시간
      usePrdEndYmd: "",
      // 사용 기간 종료 일자
      usePrdEndHis: "",
      // 사용 기간 종료 시간
      ttl: "",
      // 제목
      linkTp: "1",
      link: "",
    },
    resolver: yupResolver(schema),
  });

  const onDeletePopup = async () => {
    try {
      let res = await deletePopup.mutateAsync(params.seq);
      if (res) {
        setAlertDialog({
          ...alertDialog,
          open: true,
          type: "complete",
          text: "삭제되었습니다.",
          handleOK: () => {
            navigate(-1);
          },
          handleClose: () => {
            navigate(-1);
          },
        });
      }
    } catch (e) {
      setAlertDialog({
        ...alertDialog,
        open: true,
        type: "complete",
        text: `삭제를 실패했습니다.`,
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
      });
    }
  };

  const handleDelete = () => {
    setAlertDialog({
      ...alertDialog,
      open: true,
      type: "confirm",
      text: "삭제 하시겠습니까?",
      handleOK: () => {
        onDeletePopup();
      },
      handleClose: () => {
        setAlertDialog({
          ...alertDialog,
          open: false,
        });
      },
    });
  };

  const handleChangeRadio = (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const value = e.target.value as "Y" | "N"; // 타입 단언을 사용하여 "Y" | "N"으로 처리
    setValue("usePrdYn", value);
    if (value === "Y") {
      setValue("usePrdStrtYmd", watch("usePrdStrtYmd"));
      setValue("usePrdEndYmd", watch("usePrdEndYmd"));
    } else {
      setValue("usePrdStrtYmd", "");
      setValue("usePrdEndYmd", "");
    }

    if (submitCount > 0) {
      trigger(["usePrdStrtYmd", "usePrdEndYmd"]);
    }
  };
  const handleDataSubmit = async () => {
    let data = getValues();
    let form: PopupFormType = {
      ...data,
      usePrdStrtYmd: changeDataSubmitDateTime(data.usePrdStrtYmd, "start")[0],
      usePrdStrtHis: changeDataSubmitDateTime(data.usePrdStrtYmd, "start")[1],
      usePrdEndYmd: changeDataSubmitDateTime(data.usePrdEndYmd, "end")[0],
      usePrdEndHis: changeDataSubmitDateTime(data.usePrdEndYmd, "end")[1],
      aiSeq: data.attachImage?.seq,
    };

    try {
      let res = {};
      if (data.seq) {
        res = await updatePopup.mutateAsync(form);
      } else {
        res = await registPopup.mutateAsync(form);
      }

      if (res) {
        setAlertDialog({
          open: true,
          title: "알림",
          text: `${data.seq ? "수정" : "등록"} 되었습니다.`,
          type: "complete",
          handleOK: () => {
            navigate(-1);
          },
          handleClose: () => {
            navigate(-1);
          },
        });
      }
    } catch (error: any) {
      setAlertDialog({
        ...alertDialog,
        open: true,
        type: "complete",
        text: `${data.seq ? "수정" : "등록"}을 실패했습니다.`,
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
      });
    }
  };
  const onSubmit = (data: PopupFormType) => {
    let seq = data.seq;
    setAlertDialog({
      ...alertDialog,
      open: true,
      type: "confirm",
      text: `${seq ? "수정" : "등록"} 하시겠습니까?`,
      handleOK: () => handleDataSubmit(),
      handleClose: () => {
        setAlertDialog({
          ...alertDialog,
          open: false,
        });
      },
    });
  };

  const handleCancel = (seq: string) => {
    if (seq) {
      navigate(-1);
    } else {
      setAlertDialog({
        ...alertDialog,
        type: "confirm",
        text: "취소하시겠습니까?",
        open: true,
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            type: "complete",
            text: "취소되었습니다.",
            open: true,
            handleOK: () => {
              navigate(-1);
            },
            handleClose: () => {
              navigate(-1);
            },
          });
        },
        handleClose: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
      });
    }
  };

  useEffect(() => {
    if (popupData && popupData.popup) {
      reset({
        seq: popupData.popup.seq || "",
        useYn: popupData.popup.useYn,
        usePrdYn: popupData.popup.usePrdYn,
        usePrdStrtYmd: popupData.popup.usePrdStrtYmd
          ? `${popupData.popup.usePrdStrtYmd} ${popupData.popup.usePrdStrtHis}`
          : "",
        usePrdStrtHis: popupData.popup.usePrdStrtHis ?? "",
        usePrdEndYmd: popupData.popup.usePrdEndYmd
          ? `${popupData.popup.usePrdEndYmd} ${popupData.popup.usePrdEndHis}`
          : "",
        usePrdEndHis: popupData.popup.usePrdEndHis ?? "",
        ttl: popupData.popup.ttl,
        linkTp: popupData.popup.linkTp.toString(),
        link: popupData.popup.link,
        attachImage: popupData.popup.attachImage,
        // popupImageList: AIType[];
      });
    }
  }, [popupData]);
  // if (isFetching) return <></>;

  return (
    <main id="sample" css={globalCSS.listContainer}>
      <div className="title-wrap">
        <div className="breadcrumbs">
          <div className="b-main">
            <h2>팝업</h2>
          </div>
          <div className="b-sub">
            <span>홈</span>
            <span>팝업</span>
            <span>상세</span>
          </div>
        </div>
      </div>
      <div className="cont-wrap">
        <div className="cont-inner">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="sub-title">
              팝업 정보(<span className="color-point">*</span> 필수항목)
            </h3>
            <table css={globalCSS.formTable}>
              <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "40%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "40%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputLabel required>제목</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Controller
                      name="ttl"
                      control={control}
                      render={({ field }) => (
                        <XTInput
                          {...field}
                          fullWidth
                          placeholder="제목을 입력해 주세요."
                          inputProps={{ maxLength: 100 }}
                          onChange={(e) => {
                            let value = maxLen(e.target.value, 100);
                            field.onChange(value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <span
                                className="count"
                                css={globalCSS.inputLength}
                              >
                                {watch("ttl").length}&nbsp;/&nbsp;100
                              </span>
                            </InputAdornment>
                          }
                        />
                      )}
                    />
                    {errors.ttl?.message && (
                      <XTFormHelperText className="error">
                        {errors.ttl?.message}
                      </XTFormHelperText>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel required>게시여부</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Controller
                      name="useYn"
                      control={control}
                      render={({ field }) => (
                        <XTRadioGroup row {...field}>
                          <XTRadio
                            value={"Y"}
                            control={<Radio />}
                            label={"Y"}
                          />
                          <XTRadio
                            value={"N"}
                            control={<Radio />}
                            label={"N"}
                          />
                        </XTRadioGroup>
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel required>게시기간</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container className="form-grid" spacing={2}>
                      <Grid item>
                        <Controller
                          name="usePrdYn"
                          control={control}
                          render={({ field }) => (
                            <XTRadioGroup
                              row
                              {...field}
                              onChange={handleChangeRadio}
                            >
                              <XTRadio
                                value="N"
                                control={<Radio />}
                                label={"상시"}
                              />
                              <XTRadio
                                value="Y"
                                control={<Radio />}
                                label={"기간 선택"}
                              />
                            </XTRadioGroup>
                          )}
                        />
                      </Grid>
                      {watch("usePrdYn") == "Y" && (
                        <>
                          <Grid item>
                            <Controller
                              name="usePrdStrtYmd"
                              control={control}
                              render={({ field }) => (
                                <XTDatePicker
                                  timePicker={true}
                                  value={watch("usePrdStrtYmd")}
                                  onChange={(newDate) => {
                                    field.onChange(newDate);
                                  }}
                                  maxDate={watch("usePrdEndYmd")}
                                  placeholder="시작일을 선택하세요."
                                />
                              )}
                            />
                          </Grid>
                          <Grid item>
                            <div className="divider">
                              <span>~</span>
                            </div>
                          </Grid>
                          <Grid item>
                            <Controller
                              name="usePrdEndYmd"
                              control={control}
                              render={({ field }) => (
                                <XTDatePicker
                                  timePicker={true}
                                  value={watch("usePrdEndYmd")}
                                  minDate={watch("usePrdStrtYmd")}
                                  onChange={(newDate) => {
                                    field.onChange(newDate);
                                  }}
                                  placeholder="종료일을 선택하세요."
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    {(errors.usePrdStrtYmd || errors.usePrdEndYmd) && (
                      <XTFormHelperText className="error">
                        {errors?.usePrdStrtYmd?.message ||
                          errors?.usePrdEndYmd?.message}
                      </XTFormHelperText>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel required>팝업 이미지</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTFileUpload
                      file={watch(`attachImage`)}
                      data={{
                        domain: "popup",
                        type: "IMAGE",
                        fileType: ["jpg", "jpeg", "png"],
                        limitMB: 10,
                        setValue,
                        setKey: "attachImage",
                        submitCount,
                      }}
                    />
                    {errors.attachImage?.seq?.message && (
                      <XTFormHelperText className="error">
                        {errors.attachImage?.seq?.message}
                      </XTFormHelperText>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel required>연결링크</InputLabel>
                  </th>
                  <td colSpan={3}>
                    {popupTypes && (
                      <Controller
                        name="linkTp"
                        control={control}
                        render={({ field }) => (
                          <XTRadioGroup
                            row
                            defaultValue=""
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              if (e.target.value === "1") setValue("link", "");
                            }}
                          >
                            {Object.keys(popupTypes!.PopupLinkType).map(
                              (key: string) => (
                                <XTRadio
                                  value={key}
                                  control={<Radio />}
                                  label={
                                    popupTypes!.PopupLinkType[key.toString()]
                                  }
                                />
                              )
                            )}
                          </XTRadioGroup>
                        )}
                      />
                    )}
                    <Box mt={1}>
                      <Controller
                        name="link"
                        control={control}
                        disabled={watch("linkTp") === "1"}
                        render={({ field }) => (
                          <XTInput
                            {...field}
                            placeholder="링크를 입력해 주세요."
                          />
                        )}
                      />
                      {errors.link?.message && (
                        <XTFormHelperText className="error">
                          {errors.link?.message}
                        </XTFormHelperText>
                      )}
                    </Box>
                  </td>
                </tr>

                {popupData?.popup?.seq && (
                  <>
                    <tr>
                      <th scope="row">
                        <InputLabel>등록자</InputLabel>
                      </th>
                      <td>
                        {popupData?.popup?.rgstrManagerAccount?.nm
                          ? `${popupData?.popup?.rgstrManagerAccount?.nm}(${popupData?.popup?.rgstrManagerAccount?.id})`
                          : "-"}
                      </td>
                      <th scope="row">
                        <InputLabel>등록일시</InputLabel>
                      </th>
                      <td>
                        {formatYmdHisDot(
                          popupData?.popup?.rgstYmd,
                          popupData?.popup?.rgstHis
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <InputLabel>수정자</InputLabel>
                      </th>
                      <td>
                        {popupData?.popup?.modrManagerAccount?.nm
                          ? `${popupData?.popup?.modrManagerAccount?.nm}(${popupData?.popup?.modrManagerAccount?.id})`
                          : "-"}
                      </td>
                      <th scope="row">
                        <InputLabel>수정일시</InputLabel>
                      </th>
                      <td>
                        {formatYmdHisDot(
                          popupData?.popup?.modYmd,
                          popupData?.popup?.modHis
                        )}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            <div css={globalCSS.bottomWrap}>
              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  <XTButton
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick={() => {
                      handleCancel(popupData?.popup?.seq || "");
                    }}
                  >
                    {popupData?.popup?.seq ? "목록" : "취소"}
                  </XTButton>
                </Grid>
                <Grid item>
                  {popupData?.popup?.seq && (
                    <XTButton
                      variant="outlined"
                      color="primary"
                      size="medium"
                      onClick={handleDelete}
                    >
                      삭제
                    </XTButton>
                  )}
                  <XTButton
                    variant="outlined"
                    color="primary"
                    size="medium"
                    type="submit"
                  >
                    {popupData?.popup?.seq ? "수정" : "등록"}
                  </XTButton>
                </Grid>
              </Grid>
              <AlertDialog
                open={alertDialog.open}
                text={alertDialog.text}
                type={alertDialog.type}
                handleOK={alertDialog.handleOK}
                handleClose={alertDialog.handleClose}
                preventCloseEvent={true}
              />
            </div>
            <AlertDialog
              open={alertDialog.open}
              text={alertDialog.text}
              type={alertDialog.type}
              handleOK={alertDialog.handleOK}
              handleClose={alertDialog.handleClose}
              preventCloseEvent={true}
            />
          </form>
        </div>
      </div>
    </main>
  );
}
