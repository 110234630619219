import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { css, Theme } from "@emotion/react";

interface PropsType {
  handleClickInquiry?: (() => void) | null | undefined;
  handleClickRegist?: (() => void) | null | undefined;
  handleClickSave?: (() => void) | null | undefined;
  handleClickDelete?: (() => void) | null | undefined;
  handleClickPrint?: (() => void) | null | undefined;
  handleClickExcel?: (() => void) | null | undefined;
}

// TODO:: 관리자 권한에 따른 추가 disabled 처리 필요!
export default function ActionButtonGroup(props: PropsType) {
  const {
    handleClickInquiry = null,
    handleClickRegist = null,
    handleClickSave = null,
    handleClickDelete = null,
    handleClickPrint = null,
    handleClickExcel = null,
  } = props;

  return (
    <div className="action-button-group" css={style.container}>
      <XTButton
        variant="outlined"
        color="primary"
        size="medium"
        disabled={!handleClickInquiry}
        onClick={(e) => {
          handleClickInquiry && handleClickInquiry();
        }}
      >
        조회
      </XTButton>
      <XTButton
        variant="outlined"
        color="primary"
        size="medium"
        disabled={!handleClickRegist}
        onClick={(e) => {
          handleClickRegist && handleClickRegist();
        }}
      >
        신규
      </XTButton>
      <XTButton
        variant="outlined"
        color="primary"
        size="medium"
        disabled={!handleClickSave}
        onClick={(e) => {
          handleClickSave && handleClickSave();
        }}
      >
        저장
      </XTButton>
      <XTButton
        variant="outlined"
        color="primary"
        size="medium"
        disabled={!handleClickDelete}
        onClick={(e) => {
          handleClickDelete && handleClickDelete();
        }}
      >
        삭제
      </XTButton>
      <XTButton
        variant="outlined"
        color="primary"
        size="medium"
        disabled={!handleClickPrint}
        onClick={(e) => {
          handleClickPrint && handleClickPrint();
        }}
      >
        인쇄
      </XTButton>
      <XTButton
        variant="outlined"
        color="primary"
        size="medium"
        disabled={!handleClickExcel}
        onClick={(e) => {
          handleClickExcel && handleClickExcel();
        }}
      >
        <i css={globalCSS.icoExcel} />
      </XTButton>
    </div>
  );
}

const style = {
  container: (theme: Theme) => css`
    display: flex;
    flex-direction: row;

    .MuiButtonBase-root {
      + .MuiButtonBase-root {
        margin-left: 6px;
      }
    }
  `,
};
