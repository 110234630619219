import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { changeSubmitDate } from "@/assets/ts/common";
import moment from "moment";
import { ChildListSchFormType, ChildListType } from "@/types/member/child";
import { loadChildListAPI } from "@/api/member/child";
import { SelectChangeEvent } from "@mui/material";

export const useChildList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const initialSchFormData: ChildListSchFormType = {
    pg: params.get("pg") || "1",
    pgSz: params.get("pgSz") || "10",
    sordFld: params.get("sordFld") || "seq",
    sordMetd: params.get("sordMetd") || "DESC",
    schFldYmd: params.get("schFldYmd") || "gds_tagds",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schFld: params.get("schFld") || " ",
    schTxt: params.get("schTxt") || "",
  };
  const [schForm, setSchForm] = useState(initialSchFormData);

  const [schViewForm, setSchViewForm] = useState({
    schFldYmd: params.get("schFldYmd") || "gds_tagds",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schFld: params.get("schFld") || " ",
    schTxt: params.get("schTxt") || "",
  });

  const initialData: ChildListType = {
    ChildDosageCntType: {},
    innoBoostChildList: {
      list: [],
      pageSize: 0,
      totalSize: 0,
      idx: 0,
      page: 0,
      number: 0,
      nextPageAvailable: false,
      previousPageAvailable: false,
      firstPage: false,
      pageIndex: 0,
      middlePage: false,
      lastPage: false,
      totalPageSize: 10,
      empty: false,
    },
  };

  const {
    data: listData = initialData,
    isFetching,
    refetch,
  } = useQuery<
    AxiosResponse<{ data: ChildListType }>,
    AxiosError,
    ChildListType
  >({
    queryKey: ["loadChildList", schForm],
    queryFn: async () => await loadChildListAPI(schForm),
    select: (res) => {
      let list = res.data.data.innoBoostChildList.list;

      list.map((item, i) => {
        item.id = item.seq;
        item.index = i;
      });

      return res.data.data;
    },
  });

  const handleChangeInput: React.ChangeEventHandler<
    HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const target = event.target as
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement;
    const name = target.name;

    setSchViewForm((prev) => ({
      ...prev,
      [name]: target.value,
    }));
  };

  const handleChangePage = async (
    e: React.ChangeEvent<unknown>,
    value: number
  ) => {
    let form = {
      ...schForm,
      pg: value.toString(),
    };
    setSchForm(form);

    const queryStringResult = queryString.stringify(form);
    navigate("/member/child?" + queryStringResult);
  };

  const handleChangePageSz = (event: SelectChangeEvent<unknown>) => {
    const target = event.target;
    const name = target.name;

    let form = {
      ...schForm,
      pg: "1",
      [name]: target.value,
    };
    setSchForm(form);

    const queryStringResult = queryString.stringify(form);
    navigate("/member/child?" + queryStringResult);
  };

  const handleResetInput = () => {
    let resetForm = {
      pg: "1",
      pgSz: "10",
      sordFld: "seq",
      sordMetd: "DESC",
      schFldYmd: "gds_tagds",
      schStrtYmd: "",
      schEndYmd: "",
      schFld: " ",
      schTxt: "",
    };

    setSchForm(resetForm);

    setSchViewForm({
      schFldYmd: "gds_tagds",
      schStrtYmd: "",
      schEndYmd: "",
      schFld: " ",
      schTxt: "",
    });
    const queryStringResult = queryString.stringify(resetForm);
    navigate("/member/child?" + queryStringResult);
  };

  const search = () => {
    let form = {
      ...schForm,
      pg: "1",
      pgSz: "10",
      schFldYmd: schViewForm.schFldYmd,
      schStrtYmd: schViewForm.schStrtYmd,
      schEndYmd: schViewForm.schEndYmd,
      schFld: schViewForm.schFld,
      schTxt: schViewForm.schTxt,
    };
    setSchForm(form);
    const queryStringResult = queryString.stringify(form);
    navigate("/member/child?" + queryStringResult);
  };

  const handleChangeDatePicker = (name: string) => (value: Date) => {
    setSchViewForm((prev) => ({
      ...prev,
      pg: "1",
      [name]: changeSubmitDate(value),
    }));
  };

  const handleClickDateButton = (month: string | null) => {
    let startDate = "";
    let endDate = "";

    if (month) {
      startDate = moment().subtract(month, "months").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    }

    setSchViewForm((prev) => ({
      ...prev,
      pg: "1",
      schStrtYmd: changeSubmitDate(startDate),
      schEndYmd: changeSubmitDate(endDate),
    }));
  };

  useEffect(() => {
    setSchForm({
      ...initialSchFormData,
    });
    setSchViewForm({
      ...initialSchFormData,
    });
  }, [location.search]);

  return {
    listData,
    isFetching,
    schForm,
    schViewForm,
    handleChangeInput,
    handleResetInput,
    handleChangeDatePicker,
    handleClickDateButton,
    navigate,
    search,
    handleChangePage,
    handleChangePageSz,
  };
};
