import globalCSS from "@/assets/ts/global-css";
import { XTButton, XTTextUnderlineButton } from "@/components/mui/xt-button";
import { Box, Grid, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { XTInput } from "@/components/mui/xt-input";
import { useRecoilState, useSetRecoilState } from "recoil";
import { alertDialogState } from "@/state";
import { useEffect, useState } from "react";
import CancelDialog from "./_component/cancel-dialog";
import { useOrderDetail } from "./_hook/use-order";
import { formatYmdHisDot, regOnlyNumber } from "@/assets/ts/common";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function OrderDetail() {
  const [inputFlag, setInputFlag] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [alertDialog, setAlertDialog] = useRecoilState(alertDialogState);
  const {
    seq,
    navigate,
    detailData,
    refetch,
    regist,
    cancel,
    number,
    num,
    setNum,
  } = useOrderDetail();

  const handleRegistNumber = async () => {
    if (num) {
      if (!inputFlag && detailData?.apply?.ivcNo) {
        setInputFlag(true);

        return;
      }

      const params = {
        seq: seq!,
        ivcNo: num,
      };
      try {
        const result = await number.mutateAsync(params);
        setAlertDialog({
          open: true,
          text: "저장되었습니다.",
          type: "complete",
          title: "알림",
          handleOK: () => {
            setAlertDialog({
              ...alertDialog,
              open: false,
            });
            setInputFlag(false);
            refetch();
          },
          handleClose: null,
        });
      } catch (err) {
        console.log("err", err);
      }
    } else {
      setAlertDialog({
        open: true,
        text: "운송장번호를 입력해주세요",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
        handleClose: null,
      });
    }
  };

  const handleCancelOrder = async (cont: string) => {
    const parmas = {
      seqArray: [seq!],
      cancCont: cont,
    };

    try {
      const result = await cancel.mutateAsync(parmas);
      setAlertDialog({
        open: true,
        text: "취소처리 되었습니다.",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          setCancelDialog(false);
          refetch();
        },
        handleClose: null,
      });
    } catch (err) {
      setAlertDialog({
        open: true,
        text: "취소처리 실패했습니다.",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          setCancelDialog(false);
        },
        handleClose: null,
      });
    }
  };

  const handleRegistOrder = async () => {
    const parmas = {
      seqArray: [seq!],
    };
    try {
      const result = await regist.mutateAsync(parmas);
      setAlertDialog({
        open: true,
        text: "출고처리 완료했습니다.",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });

          refetch();
        },
        handleClose: null,
      });
    } catch (err) {
      if (err && err.response && err.response.data.code === "CM008") {
        setAlertDialog({
          open: true,
          text: "출고처리 실패했습니다. <br />재고를 확인 후 다시 시도해주세요.",
          type: "complete",
          title: "알림",
          handleOK: () => {
            setAlertDialog({
              ...alertDialog,
              open: false,
            });
          },
          handleClose: null,
        });

        return;
      } else if (err && err.response && err.response.data.code === "CM003") {
        setAlertDialog({
          open: true,
          text: "출고처리 실패했습니다.",
          type: "complete",
          title: "알림",
          handleOK: () => {
            setAlertDialog({
              ...alertDialog,
              open: false,
            });
          },
          handleClose: null,
        });

        return;
      } else {
        setAlertDialog({
          open: true,
          text: "출고처리 실패했습니다.",
          type: "complete",
          title: "알림",
          handleOK: () => {
            setAlertDialog({
              ...alertDialog,
              open: false,
            });
          },
          handleClose: null,
        });
      }
    }
  };

  useEffect(() => {
    setNum(detailData?.apply?.ivcNo);
  }, [detailData]);
  return (
    <>
      <main id="sample" css={globalCSS.listContainer}>
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>배송관리</h2>
            </div>
            <div className="b-sub">
              <span>배송관리</span>
              <span>배송관리 상세</span>
            </div>
          </div>
        </div>
        <>
          <div className="cont-wrap">
            <div className="cont-inner">
              <table css={globalCSS.formTable}>
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "40%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <InputLabel>신청구분</InputLabel>
                    </th>
                    <td colSpan={3}>
                      {detailData?.ApplyType[detailData?.apply?.aplyrTp]}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>신청일시</InputLabel>
                    </th>
                    <td>
                      {formatYmdHisDot(
                        detailData?.apply?.aplyYmd,
                        detailData?.apply?.aplyHis
                      )}
                    </td>
                    <th scope="row">
                      <InputLabel>처리상태</InputLabel>
                    </th>
                    <td>
                      {detailData?.apply?.stat === 3
                        ? `${
                            detailData?.ApplyStatus[detailData?.apply?.stat]
                          }(${
                            detailData?.ApplyCancelType[
                              detailData?.apply?.cancrTp
                            ]
                          })`
                        : detailData?.ApplyStatus[detailData?.apply?.stat]}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>수령인</InputLabel>
                    </th>
                    <td>{detailData?.apply?.applyPrivacy?.nm ?? "-"}</td>
                    <th scope="row">
                      <InputLabel>휴대폰번호</InputLabel>
                    </th>
                    <td>{detailData?.apply?.applyPrivacy?.cph ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>자녀이름</InputLabel>
                    </th>
                    <td colSpan={3}>
                      {detailData?.apply?.applyPrivacy?.chldrNm ?? "-"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>배송지</InputLabel>
                    </th>
                    <td>
                      {detailData?.apply?.applyPrivacy?.addr
                        ? `${detailData?.apply?.applyPrivacy?.addr} ${
                            detailData?.apply?.applyPrivacy?.addrDtl
                              ? detailData?.apply?.applyPrivacy?.addrDtl
                              : ""
                          }`
                        : "-"}
                    </td>
                    <th scope="row">
                      <InputLabel>운송장번호</InputLabel>
                    </th>
                    <td colSpan={3}>
                      {/* 운송장 등록여부 */}
                      <Grid container spacing={2} alignItems="center">
                        {detailData?.apply?.stat == 1 &&
                        detailData?.apply?.divYn !== "Y" ? (
                          <>
                            <Grid item>
                              {inputFlag || !detailData?.apply?.ivcNo ? (
                                <XTInput
                                  value={num}
                                  sx={{
                                    minWidth: 210,
                                  }}
                                  type="text"
                                  placeholder="운송장번호를 입력해 주세요."
                                  onChange={(e) => {
                                    setNum(regOnlyNumber(e.target.value));
                                  }}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    minWidth: 210,
                                  }}
                                >
                                  {num}
                                </Box>
                              )}
                            </Grid>
                            <Grid item>
                              <XTButton
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => {
                                  handleRegistNumber();
                                }}
                              >
                                {detailData?.apply?.ivcNo && !inputFlag
                                  ? "수정"
                                  : "등록"}
                              </XTButton>
                            </Grid>
                          </>
                        ) : (
                          <Grid item>
                            <p>{detailData?.apply?.ivcNo}</p>
                          </Grid>
                        )}
                        {detailData?.apply?.ivcRgstYn == "Y" && (
                          <>
                            <Grid item>
                              <XTButton
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => {
                                  window.open(
                                    `https://www.cjlogistics.com/ko/tool/parcel/tracking?gnbInvcNo=${detailData?.apply?.ivcNo}`,
                                    "_blank"
                                  );
                                }}
                              >
                                조회
                              </XTButton>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>신청물품</InputLabel>
                    </th>
                    <td colSpan={3}>
                      {detailData?.apply?.applyGoodsList
                        .map(
                          (item: {
                            seq: number;
                            qty: string;
                            goods: {
                              seq: number;
                              nm: string;
                            };
                          }) => item.goods.nm + " " + item.qty
                        )
                        .join(",")}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel>출고일시</InputLabel>
                    </th>
                    <td>
                      {formatYmdHisDot(
                        detailData?.apply?.tagdsYmd,
                        detailData?.apply?.tagdsHis
                      )}
                    </td>
                    <th scope="row">
                      <InputLabel>취소일시</InputLabel>
                    </th>
                    <td>
                      {formatYmdHisDot(
                        detailData?.apply?.cancYmd,
                        detailData?.apply?.cancHis
                      )}
                    </td>
                  </tr>
                  {detailData?.apply?.stat == 3 && (
                    <>
                      <tr>
                        <th scope="row">
                          <InputLabel>취소구분</InputLabel>
                        </th>
                        <td colSpan={3}>
                          {
                            detailData?.ApplyCancelType[
                              detailData?.apply?.cancrTp
                            ]
                          }
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <InputLabel>취소사유</InputLabel>
                        </th>
                        <td colSpan={3}>
                          {detailData?.apply?.cancCont
                            ? detailData?.apply?.cancCont
                            : "-"}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>

              <Box css={globalCSS.bottomWrap} marginTop={4}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <XTButton
                      variant="outlined"
                      color="primary"
                      size="medium"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      목록
                    </XTButton>
                  </Grid>
                  {detailData?.apply?.stat == 1 &&
                    detailData?.apply?.divYn !== "Y" && (
                      <Grid item>
                        <XTButton
                          variant="outlined"
                          color="primary"
                          size="medium"
                          onClick={() => {
                            setCancelDialog(true);
                          }}
                        >
                          취소
                        </XTButton>

                        <XTButton
                          variant="outlined"
                          color="primary"
                          size="medium"
                          onClick={() => {
                            setAlertDialog({
                              open: true,
                              text: "출고처리 하시겠습니까?",
                              type: "confirm",
                              title: "알림",
                              handleOK: () => {
                                handleRegistOrder();
                              },
                              handleClose: () => {
                                setAlertDialog({
                                  ...alertDialog,
                                  open: false,
                                });
                              },
                            });
                          }}
                        >
                          출고
                        </XTButton>
                      </Grid>
                    )}
                </Grid>
              </Box>
            </div>
          </div>
        </>
        <CancelDialog
          open={cancelDialog}
          setOpen={setCancelDialog}
          handleOK={handleCancelOrder}
        />
      </main>
    </>
  );
}
