import {
  changeDatepickerDate,
  formatDotYmd,
  formatHyphenYmd,
  formatYmdHisDot,
  regComma,
} from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton, XTTextButton } from "@/components/mui/xt-button";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import XTDatePicker from "@/components/mui/xt-datepicker";
import { XTInput } from "@/components/mui/xt-input";
import { XTMenuItem, XTSelect } from "@/components/mui/xt-select";
import { useProvisionList } from "@/pages/system/provision/_hook/use-provision";
import {
  Box,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  SelectChangeEvent,
} from "@mui/material";
import {
  GridColDef,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function Provision() {
  const {
    listData,
    isFetching,
    schForm,
    schViewForm,
    handleChangeInput,
    handleChangePage,
    handleChangePageSz,
    handleClickDateButton,
    handleResetInput,
    handleChangeDatePicker,
    handleChangeSort,
    handleSearch,
    navigate,
  } = useProvisionList();
  const apiRef = useGridApiRef();

  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "번호",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return listData!.provisionList?.number - params.row.index;
      },
    },
    {
      field: "tp",
      headerName: "약관구분",
      headerAlign: "center",
      width: 150,
      flex: 1,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return listData!.ProvisionType[params.value];
      },
    },
    {
      field: "ver",
      headerName: "버전",
      headerAlign: "center",
      width: 80,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "strtYmd",
      headerName: "적용일자",
      headerAlign: "center",
      width: 190,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return formatDotYmd(params.value);
      },
      renderHeader: (params: any) => {
        return (
          <XTTextButton
            onClick={(e) => {
              handleChangeSort("strtYmd");
            }}
          >
            적용일자{" "}
            {schForm.sordFld === "strtYmd" && schForm.sordMetd === "ASC" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </XTTextButton>
        );
      },
    },
    {
      field: "formatRgstYmdHis",
      headerName: "등록일시",
      headerAlign: "center",
      width: 160,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params: any) => {
        return (
          <XTTextButton
            onClick={(e) => {
              handleChangeSort("rgstYmd");
            }}
          >
            등록일시{" "}
            {schForm.sordFld === "rgstYmd" && schForm.sordMetd === "ASC" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </XTTextButton>
        );
      },
      valueGetter: (params: GridValueGetterParams) =>
        formatYmdHisDot(params.row.rgstYmd, params.row.rgstHis),
    },
  ];

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <main id="sample" css={globalCSS.listContainer} className="only-list">
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>약관관리</h2>
            </div>
            <div className="b-sub">
              <span>시스템 관리</span>
              <span>약관관리 목록</span>
            </div>
          </div>
        </div>
        <div className="cont-wrap">
          <div className="search-wrap">
            <table css={globalCSS.formTable}>
              <colgroup>
                <col style={{ width: "200px" }} />
                <col />
                <col style={{ width: "200px" }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputLabel>기간선택</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTSelect
                          sx={{
                            minWidth: 150,
                          }}
                          name="schFldYmd"
                          onChange={handleChangeInput}
                          value={schViewForm.schFldYmd}
                          MenuProps={{
                            MenuListProps: { sx: { padding: 0 } },
                          }}
                        >
                          <XTMenuItem value="rgstYmd">등록일자</XTMenuItem>
                          <XTMenuItem value="strtYmd">적용일자</XTMenuItem>
                        </XTSelect>
                      </Grid>
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(schViewForm.schStrtYmd)}
                          onChange={handleChangeDatePicker("schStrtYmd")}
                          maxDate={changeDatepickerDate(schViewForm.schEndYmd)}
                          placeholder="시작일을 선택하세요."
                        />
                      </Grid>
                      <Grid item>
                        <div className="divider">
                          <span>~</span>
                        </div>
                      </Grid>
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(schViewForm.schEndYmd)}
                          minDate={changeDatepickerDate(schViewForm.schStrtYmd)}
                          onChange={handleChangeDatePicker("schEndYmd")}
                          placeholder="종료일을 선택하세요."
                        />
                      </Grid>
                      <Grid item>
                        <div className="date-btn-group">
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("1")}
                          >
                            1개월
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("3")}
                          >
                            3개월
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("12")}
                          >
                            1년
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("")}
                          >
                            전체
                          </XTButton>
                        </div>
                      </Grid>
                    </Grid>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>약관구분</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTSelect
                      placeholder={""}
                      sx={{
                        minWidth: 355,
                      }}
                      name="schTp"
                      onChange={handleChangeInput}
                      value={schViewForm.schTp}
                      MenuProps={{
                        MenuListProps: { sx: { padding: 0 } },
                      }}
                    >
                      <XTMenuItem value=" ">전체</XTMenuItem>
                      {Object.entries(listData.ProvisionType).map(
                        ([id, text]) => (
                          <XTMenuItem key={id} value={id}>
                            {text}
                          </XTMenuItem>
                        )
                      )}
                    </XTSelect>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>버전</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTInput
                          name="schTxt"
                          value={schViewForm.schTxt}
                          fullWidth
                          placeholder="버전을 입력해주세요."
                          onKeyDown={handleKeyPress}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="action-button-group">
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => {
                  handleResetInput();
                }}
              >
                초기화
              </XTButton>
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={(e) => {
                  handleSearch();
                }}
              >
                조회
              </XTButton>
            </div>
          </div>
          <div className="grid-wrap">
            <div className="total-wrap">
              <div className="total-cnt">
                전체 <span>{regComma(listData.provisionList.totalSize)}</span>건
              </div>
              <XTSelect
                name="pgSz"
                value={schForm.pgSz}
                onChange={handleChangePageSz}
                sx={{
                  minWidth: 150,
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 50,
                    horizontal: -10,
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
              >
                <XTMenuItem value={10}>10개 씩</XTMenuItem>
                <XTMenuItem value={20}>20개 씩</XTMenuItem>
                <XTMenuItem value={30}>30개 씩</XTMenuItem>
                <XTMenuItem value={50}>50개 씩</XTMenuItem>
                <XTMenuItem value={100}>100개 씩</XTMenuItem>
              </XTSelect>
            </div>

            <XTDataGrid
              loading={isFetching}
              className="clickable"
              columns={columns}
              rows={listData.provisionList.list}
              getRowId={(params) => params.id}
              apiRef={apiRef}
              experimentalFeatures={{
                ariaV7: true,
              }}
              onRowClick={(params) => {
                navigate(`detail/${params.row.seq}`);
              }}
              slots={{
                noRowsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
                noResultsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
                loadingOverlay: LinearProgress,
              }}
              rowHeight={38}
              columnHeaderHeight={38}
              hideFooter
            />
          </div>
          <Box css={globalCSS.bottomWrap}>
            <Pagination
              page={listData.provisionList.page}
              count={listData.provisionList.totalPageSize}
              onChange={handleChangePage}
            />
            <Grid container justifyContent="end" spacing={2}>
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={(e) => {
                    navigate(`detail`);
                  }}
                >
                  등록
                </XTButton>
              </Grid>
            </Grid>
          </Box>
        </div>
      </main>
    </>
  );
}
