import {
  deletePopupAPI,
  loadPopupAPI,
  loadPopupListAPI,
  loadPopupTypeAPI,
  registPopupAPI,
  updatePopupAPI,
} from "@/api/popup";
import {
  PopupListType,
  PopupSchFormType,
  PopupTypesType,
  PopupDetailType,
} from "@/types/popup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const usePopupManager = () => {
  const queryClient = useQueryClient();
  const [popupData, setPopupData] = useState<PopupListType | null>(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let schParams: PopupSchFormType = {
    schFldYmd: params.get("schFldYmd") || "rgstYmd",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schUseYn: params.get("schUseYn") || "",
    schFld: params.get("schFld") || "",
    schTxt: params.get("schTxt") || "",
    pg: params.get("pg") || "1",
    pgSz: params.get("pgSz") || "10",
    sordFld: params.get("sordFld") || "rgstYmd",
    sordMetd: params.get("sordMetd") || "DESC",
  };

  const {
    data: popupList,
    isFetching,
    isSuccess,
  } = useQuery<
    AxiosResponse<{
      data: PopupListType;
    }>,
    AxiosError,
    PopupListType
  >({
    queryKey: ["loadPopupList", schParams],
    queryFn: async () => await loadPopupListAPI(schParams),
    select: (res) => {
      res.data.data?.popupList.list.map((item: any, i: number) => {
        item.index = i;
      });
      return res.data.data;
    },
  });

  const resetQuery = () => {
    queryClient.resetQueries({ queryKey: ["loadPopupList"] });
  };

  useEffect(() => {
    if (popupList) setPopupData(popupList);
  }, [isSuccess, popupList]);

  return {
    schParams,
    isFetching,
    isSuccess,
    popupData,
    resetQuery,
  };
};

export const usePopupDetailManager = () => {
  const params = useParams();
  const queryClient = useQueryClient();
  const {
    data: popupData,
    isFetching,
    isSuccess,
  } = useQuery<
    AxiosResponse<{
      data: PopupDetailType;
    }>,
    AxiosError,
    PopupDetailType
  >({
    queryKey: ["loadPopup", params.seq],
    queryFn: async () => await loadPopupAPI(params.seq!),
    enabled: !!params.seq,
    select: (res) => {
      return res.data.data;
    },
  });

  const registPopup = useMutation({
    mutationFn: (form: any) => registPopupAPI(form),
  });

  const updatePopup = useMutation({
    mutationFn: (form: any) => updatePopupAPI(form),
  });

  const deletePopup = useMutation({
    mutationFn: (form: any) => deletePopupAPI(form),
  });

  const resetQuery = () => {
    queryClient.resetQueries({ queryKey: ["loadPopup"] });
  };

  return {
    isFetching,
    isSuccess,
    popupData,
    registPopup,
    updatePopup,
    deletePopup,
    resetQuery,
  };
};

export const usePopupTypeManager = () => {
  const { data: popupTypes } = useQuery<
    AxiosResponse<{
      data: PopupTypesType;
    }>,
    AxiosError,
    PopupTypesType
  >({
    queryKey: ["loadPopupTypes"],
    queryFn: async () => await loadPopupTypeAPI(),
    select: (res) => {
      return res.data.data;
    },
  });

  return { popupTypes };
};
