import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";

import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputAdornment,
  InputLabel,
  TextareaAutosize,
  TextField,
} from "@mui/material";

export const XTFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  position: "relative",
  flex: 1,
}));

export const XTInput = styled(Input)(({ theme }) => ({
  boxSizing: "border-box",
  minWidth: "200px",
  maxWidth: "100%",
  height: "29px",
  backgroundColor: `${theme.palette.white}`,

  "&.input-limit": {
    paddingRight: `${theme.typography.pxToRem(70)};`,
  },

  "&::after": { display: "none" },
  "&:before": { display: "none", borderBottom: "none" },

  "&.custom-round": {
    height: "53px",
    borderRadius: "15px",

    "&.MuiInputBase-root .MuiInputBase-input": {
      padding: `0 20px`,
      fontSize: "16px",
    },
  },

  "&.MuiInputBase-root": {
    border: `1px solid ${theme.palette.line1}`,

    ".MuiInputBase-input": {
      padding: `0 12px`,
      height: `100%`,
      borderRadius: "0px",
    },

    "&.Mui-error": {
      borderColor: `${theme.palette.error.main}`,
    },

    "&.success": {
      borderColor: `${theme.palette.success.main};`,
    },

    "&.Mui-focused": {
      borderColor: `${theme.palette.primary.main}`,
    },
  },
  "& .MuiInput-input[aria-describedby='validation']": {},

  "label + &.MuiInputBase-root": {
    marginTop: 0,
  },

  "&.Mui-disabled": {
    backgroundColor: `${theme.palette.disabled};`,

    borderRadius: "0px",
    border: `none`,

    "& input": {
      color: `${theme.palette.text_sub1}`,
      fontSize: `${theme.typography.pxToRem(14)}`,
      lineHeight: `${theme.typography.pxToRem(14)}`,
    },
  },

  "& .MuiButtonBase-root": {
    background: `${theme.palette.white}`,
    padding: 0,
    position: "absolute",
    top: "50%",
    right: `${theme.typography.pxToRem(10)}`,
    transform: "translateY(-50%)",

    "&:hover": {
      background: `${theme.palette.white}`,
    },
  },

  "& input:-webkit-autofill": {
    backgroundColor: "transparent !important",
  },
  "& input:-webkit-autofill:focus": {
    backgroundColor: "transparent !important",
  },

  "& input": {
    fontFamily:
      "'Pretendard','Noto Sans KR', 'Malgun gothic', '맑은고딕', 'Dotum', '돋움', 'arial'",
    fontSize: `${theme.typography.pxToRem(14)}`,
    fontWeight: 400,
    lineHeight: `${theme.typography.pxToRem(14)}`,
    color: `${theme.palette.default}`,
    textOverflow: "ellipsis !important",

    "&::placeholder": {
      color: `${theme.palette.placeholder}`,
      textOverflow: "ellipsis !important",
      fontWeight: 400,
      opacity: 1,
      width: "90%",
    },
  },

  "& input.Mui-disabled": {
    WebkitTextFillColor: `${theme.palette.text_sub2} !important`,
    color: `${theme.palette.text_sub2} !important`,
    opacity: 1,
  },

  "&.MuiInputBase-sizeSmall": {
    minWidth: "auto !important",
    maxWidth: `${theme.typography.pxToRem(150)} !important;`,
  },
}));

export const XTInputLabel = styled(InputLabel)(({ theme }) => ({
  color: `${theme.palette.default}`,
  position: "relative",
  maxWidth: "fit-content",
  overflow: "inherit",
  transform: "none",
  display: "inline",
  marginTop: `${theme.typography.pxToRem(10)}`,
  "&.MuiFormLabel-root": {
    fontSize: `${theme.typography.pxToRem(13)}`,
    fontWeight: 500,
    lineHeight: `${theme.typography.pxToRem(13)}`,
    color: `${theme.palette.default};`,
  },

  "&.top-label": {
    marginTop: 0,
    marginBottom: `${theme.typography.pxToRem(10)};`,

    "&.MuiFormLabel-root": {
      fontSize: `${theme.typography.pxToRem(14)}`,
      lineHeight: `${theme.typography.pxToRem(24)}`,
      fontWeight: 600,
    },
  },
}));

export const XTFormHelperText = styled(FormHelperText)(({ theme }) => ({
  margin: 0,
  marginTop: `${theme.typography.pxToRem(10)}`,
  fontSize: `${theme.typography.pxToRem(13)}`,
  lineHeight: `${theme.typography.pxToRem(13)};`,
  fontWeight: 500,
  color: `${theme.palette.default}`,

  "&.success": {
    color: `${theme.palette.success.main}`,
  },
  "&.error": {
    color: `${theme.palette.error.main}`,
  },
}));

export const XTTextarea = styled(TextField)(({ theme }) => ({
  boxSizing: "border-box",
  borderRadius: "0px",
  resize: "none",
  outline: "none",
  fontWeight: 400,
  backgroundColor: `${theme.palette.white}`,
  height: `${theme.typography.pxToRem(160)}`,

  "&.limit": {
    ".MuiInputBase-input": {
      paddingBottom: `${theme.typography.pxToRem(26)}`,
    },
  },

  ".MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.palette.line1}`,
  },

  ".Mui-disabled": {
    background: `${theme.palette.bg96} !important`,

    ".MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },

  ".Mui-error": {
    ".MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.error.main} !important`,
    },

    ".Mui-focused": {
      ".MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },
  },

  "&.success": {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.success.main} !important`,
    },
  },

  "&:hover": {
    ".MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.line1}`,
    },
  },

  ".Mui-focused": {
    ".MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
  },

  ".MuiInputBase-root": { borderRadius: 0, height: "100%" },

  "& .MuiInputBase-input": {
    height: "100% !important",
    padding: `${theme.typography.pxToRem(11)} ${theme.typography.pxToRem(12)}`,
    fontSize: `${theme.typography.pxToRem(14)}`,
    lineHeight: `${theme.typography.pxToRem(18)}`,
    fontWeight: 400,

    "&::placeholder": {
      fontSize: `${theme.typography.pxToRem(14)}`,
      fontWeight: 400,
      opacity: 1,
      color: `${theme.palette.bg52}`,
      lineHeight: `${theme.typography.pxToRem(14)}`,
    },
  },
  "& .MuiInputBase-root": {
    padding: 0,
    fontWeight: 600,
  },

  "& .MuiInputBase-inputMultiline": {
    overflowY: "scroll !important",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export const XTInputAdornment = styled(InputAdornment)(({ theme }) => ({
  position: "absolute",
  bottom: `${theme.typography.pxToRem(10)};`,
  right: `${theme.typography.pxToRem(10)};`,
  span: {
    fontWeight: 400,
    fontSize: `${theme.typography.pxToRem(12)};`,
    lineHeight: 1,
    color: `${theme.palette.bg66};`,
  },
}));
