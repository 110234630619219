import { formatYmdHisDot, regComma } from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";

import { Grid, InputLabel } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  usePrivacyDetailManager,
  usePrivacyTypeManager,
} from "@/pages/log/privacy/_hook/use-privacy";
import AlertDialog from "@/components/ui/alert-dialog";
import { useState } from "react";

export default function PrivacyDetail() {
  const navigate = useNavigate();
  const { privacyData, updateSelect, resetQuery } = usePrivacyDetailManager();
  const { privacyTypes } = usePrivacyTypeManager();
  const params: any = useParams();

  const [alertDialog, setAlertDialog] = useState({
    open: false,
    title: "",
    text: "",
    type: "complete",
    handleOK: () => {},
    handleClose: () => {},
  });

  const handleClose = () => {
    setAlertDialog({
      ...alertDialog,
      open: false,
    });
  };

  const selectConfirm = async () => {
    try {
      const res = await updateSelect.mutateAsync({
        seqArray: [params.seq],
      });

      if (res) {
        setAlertDialog({
          open: true,
          title: "알림",
          text: "확인 처리가 완료되었습니다.",
          type: "complete",
          handleOK: () => {
            resetQuery();
            handleClose();
          },
          handleClose: () => {
            resetQuery();
            handleClose();
          },
        });
      }
    } catch (error: any) {}
  };

  const handleConfirm = async () => {
    setAlertDialog({
      open: true,
      title: "알림",
      text: "해당 항목을 확인 처리하시겠습니까?",
      type: "confirm",
      handleOK: selectConfirm,
      handleClose: () => {
        handleClose();
      },
    });
  };
  // if (isFetching) return <></>;
  return (
    <main id="sample" css={globalCSS.listContainer}>
      <div className="title-wrap">
        <div className="breadcrumbs">
          <div className="b-main">
            <h2>개인정보 조회 관리</h2>
          </div>
          <div className="b-sub">
            <span>홈</span>
            <span>개인정보 관리</span>
            <span>개인정보 조회 관리 상세</span>
          </div>
        </div>
      </div>
      <div className="cont-wrap">
        <div className="cont-inner">
          <h3 className="sub-title">조회내용</h3>
          <table css={globalCSS.formTable}>
            <colgroup>
              <col style={{ width: "10%" }} />
              <col style={{ width: "40%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "40%" }} />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  <InputLabel>조회자</InputLabel>
                </th>
                <td>
                  {privacyData?.logPrivacyRead?.rgstrManagerAccount?.nm}(
                  {privacyData?.logPrivacyRead?.rgstrManagerAccount?.id})
                </td>
                <th scope="row">
                  <InputLabel>조회자 IP</InputLabel>
                </th>
                <td>{privacyData?.logPrivacyRead?.rgstrIp}</td>
              </tr>
              <tr>
                <th scope="row">
                  <InputLabel>조회 구분</InputLabel>
                </th>
                <td>
                  {
                    privacyTypes?.LogPrivacyReadType[
                      privacyData?.logPrivacyRead!.tp
                    ]
                  }
                </td>
                <th scope="row">
                  <InputLabel>건 수</InputLabel>
                </th>
                <td>{regComma(privacyData?.logPrivacyRead?.cnt)}</td>
              </tr>
              <tr>
                <th scope="row">
                  <InputLabel>메뉴명</InputLabel>
                </th>
                <td>{privacyData?.logPrivacyRead?.menuNm}</td>
                <th scope="row">
                  <InputLabel>다운로드 사유</InputLabel>
                </th>
                <td>{privacyData?.logPrivacyRead?.rsn ?? "-"}</td>
              </tr>
              <tr>
                <th scope="row">
                  <InputLabel>조회 URL</InputLabel>
                </th>
                <td>{privacyData?.logPrivacyRead?.menuUrl}</td>
                <th scope="row">
                  <InputLabel>조회일시</InputLabel>
                </th>
                <td>
                  {formatYmdHisDot(
                    privacyData?.logPrivacyRead?.rgstYmd,
                    privacyData?.logPrivacyRead?.rgstHis
                  )}
                </td>
              </tr>
              {privacyData?.logPrivacyRead?.modYn === "Y" && (
                <tr>
                  <th scope="row">
                    <InputLabel>확인자</InputLabel>
                  </th>
                  <td>
                    {privacyData?.logPrivacyRead?.modrManagerAccount?.nm}(
                    {privacyData?.logPrivacyRead?.modrManagerAccount?.id})
                  </td>
                  <th scope="row">
                    <InputLabel>확인일시</InputLabel>
                  </th>
                  <td>
                    {formatYmdHisDot(
                      privacyData?.logPrivacyRead?.modYmd,
                      privacyData?.logPrivacyRead?.modHis
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div css={globalCSS.bottomWrap}>
            <Grid container justifyContent={"space-between"}>
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => {
                  navigate(-1);
                }}
              >
                목록
              </XTButton>
              {privacyData?.logPrivacyRead?.modYn !== "Y" && (
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={handleConfirm}
                >
                  확인
                </XTButton>
              )}
            </Grid>
            <AlertDialog
              open={alertDialog.open}
              text={alertDialog.text}
              type={alertDialog.type}
              handleOK={alertDialog.handleOK}
              handleClose={alertDialog.handleClose}
              preventCloseEvent={true}
            />
          </div>
        </div>
      </div>
    </main>
  );
}
